import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { KarteReport, KarteReportRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): KarteReportRepo => ({
  async fetchKarteReports(
    branchId: number,
    params: {
      customer_id: number
      page?: number
      per?: number
      sort?: string[]
      start_date?: string
      end_date?: string
      report_text?: string
      assessment_id?: number
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/karte_reports`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createKarteReport(branchId: number, report: KarteReport) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/karte_reports`,
        {
          karte_report: report,
        }
      )
      if (res.status === 201) {
        return res.data.karte_report
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateKarteReport(branchId: number, report: KarteReport) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/karte_reports/${report.id}`,
        {
          karte_report: report,
        }
      )
      if (res.status === 200) {
        return res.data.karte_report
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteKarteReport(branchId: number, reportId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/karte_reports/${reportId}`
      )
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
