








































import Vue from 'vue'
import { Download } from '~/types'

export default Vue.extend({
  computed: {
    currentDownload(): Download {
      return this.$store.state.download.currentDownload
    },
    snackbarContent(): { display: boolean; downloadStatus: string } {
      return this.$store.state.download.snackbarContent
    },
    snackbarContentLabel(): any {
      switch (this.snackbarContent.downloadStatus) {
        case 'in_progress':
          return this.$t('dashboard.inProgress')
        case 'failed':
          return this.$t('dashboard.failedDownload')
        case 'completed':
          return this.$t('dashboard.completedDownload')
        default:
          return ''
      }
    },
    percentage(): number {
      const percentage = Math.round(this.currentDownload.completion_percentage)
      const downloadStatus = this.snackbarContent.downloadStatus
      if (downloadStatus === 'in_progress' && percentage === 100) return 0
      return percentage || 0
    },
  },
  methods: {
    cancelDownload() {
      this.$store.commit('download/setSnackbarContent', {
        display: false,
        downloadStatus: '',
      })
      this.$store.commit('download/resetStore')
    },
  },
})
