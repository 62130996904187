import { ActionTree } from 'vuex'
import { User } from '~/models/User'
import { UserPermission } from '~/models/UserPermission'
import { UserPlugin } from '~/models/UserPlugin'

export const state = () => ({})

export type RootState = ReturnType<typeof state>

export const actions: ActionTree<RootState, RootState> = {
  //  This function is called on initial load
  async nuxtClientInit({ commit, dispatch }, ctx) {
    const user = localStorage.getItem('user')
    if (user) {
      const userObject: User = JSON.parse(user)
      if (!userObject.name) {
        localStorage.removeItem('user')
        commit('auth/resetUser')
        return
      }
      commit(
        'auth/setUser',
        new User(
          userObject.id,
          userObject.accessToken,
          userObject.tokenType,
          userObject.client,
          userObject.expiry,
          userObject.uid,
          userObject.name,
          userObject.activeBranch
        )
      )
      try {
        const staffId = userObject.id
        const route = location.pathname
        const routeAry = route.split('/')
        const isKarteFormPage = routeAry.includes('karte_form')
        const karte_access_token = localStorage.getItem('karte_access_token')
        const branch_access_token = localStorage.getItem('branch_access_token')
        const { staff } = karte_access_token
          ? await ctx.$api.staffRepo.fetchStaffById({
              staffId,
              karte_access_token: isKarteFormPage ? karte_access_token : null,
            })
          : branch_access_token
          ? await ctx.$api.staffRepo.fetchStaffById({
              staffId,
              branch_access_token: isKarteFormPage ? branch_access_token : null,
            })
          : await ctx.$api.staffRepo.fetchStaffById({ staffId })

        const permissions = await ctx.$api.permissionRepo.fetchPermissions()
        const pluginObject = branch_access_token
          ? await ctx.$api.pluginsRepo.fetchPlugins({
              branch_access_token: isKarteFormPage ? branch_access_token : null,
            })
          : await ctx.$api.pluginsRepo.fetchPlugins()
        if (staff) {
          const { permission_slugs, preset_id } = staff
          const userPermission = {
            permission_slugs,
            preset_id,
          }

          commit(
            'auth/setUserPermission',
            new UserPermission(userPermission, permissions)
          )
        }

        commit('auth/setUserPlugin', new UserPlugin(pluginObject.plugins))
      } catch (error) {
        commit('auth/resetUser')
        localStorage.removeItem('user')
      }

      const newKarteQuestionsBranchIds = [
        18,
        ctx.$config.mode === 'dev_server' ? 7 : 0,
      ]
      commit('karteForm/setKarteFormBranches', newKarteQuestionsBranchIds)

      // Restore Download Ids
      dispatch('download/restoreDownloadIds')

      // For demo account
      if (ctx.$config.mode === 'staging' || ctx.$config.mode === 'dev_server') {
        try {
          const timeSpent = await ctx.$api.demoRepo.fetchDemoTimeSpent()
          if (timeSpent) {
            setInterval(async () => {
              await ctx.$api.demoRepo.createDemoTimeSpent()
            }, 60000)
          }
        } catch (error) {}
      }
    }
  },
}
