





















































































import Vue from 'vue'
import { User } from '~/models/User'
import { UserPermission } from '~/models/UserPermission'
import { UserPlugin } from '~/models/UserPlugin'
import { isMatchUrl } from '~/helpers/urlUtils'
export default Vue.extend({
  data() {
    return {
      mobileDrawer: false,
      UserPermission,
    }
  },
  computed: {
    userPermission(): UserPermission {
      return this.$store.state.auth.userPermission
    },
    activeViewType(): string {
      return this.$store.state.layoutSettings.activeViewType
    },
    userPlugin(): UserPlugin {
      return this.$store.state.auth.userPlugin
    },
    activeViewTypeText(): any {
      return this.activeViewType === 'unit'
        ? this.$t('reservations.staffView')
        : this.$t('reservations.bedView')
    },
    sideBarItems(): {
      text: any
      icon: string
      link: string
      show: boolean
    }[] {
      return [
        {
          text: this.$t('sidebar.reservation'),
          icon: 'mdi-calendar',
          link: '/reservations',
          show: this.userPermission.isAllowed('branch_reservation'),
        },
        {
          text: this.$t('sidebar.shift'),
          icon: 'mdi-account-multiple',
          link: '/shifts',
          show: this.userPermission.isAllowed('branch_shift'),
        },
        {
          text: this.$t('sidebar.crm'),
          icon: 'leaderboard',
          link: '/hq/crm/hq_crm',
          show:
            this.userPlugin.isEnabled('crm') &&
            this.userPermission.isAllowed('hq_crm'),
        },
      ]
    },

    user(): User {
      return this.$store.state.auth.user
    },
    isReservationsPage(): boolean {
      return !!this.$route.path.split('/').includes('reservations')
    },
  },
  methods: {
    isMatchUrl,
    async logout() {
      try {
        await this.$api.authRepo.logout()
        const amplitude = (this as any).$amplitude
        amplitude.setUserId('')
      } catch (error) {
        this.$notifier.showMessage({
          content: (error as Error).message,
          color: 'error',
        })
      }
    },
    changeActiveViewType() {
      if (this.activeViewType === 'unit') {
        this.$store.commit('layoutSettings/setActiveViewType', 'staff')
      } else {
        this.$store.commit('layoutSettings/setActiveViewType', 'unit')
      }
    },
  },
})
