import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'

export default ($axios: NuxtAxiosInstance, $sentry: any) => ({
  async fetchHQBranches(params: {
    branch_ids?: number[]
    branch_name?: string
    start_date?: string
    end_date?: string
    sort?: string[]
    page?: number
    per?: number
  }) {
    try {
      const res = await $axios.get(`/therapists/hq/branches`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
