import { Plugin } from '@nuxt/types'
declare module 'vue/types/vue' {
  interface Vue {
    $confirm: { showConfirmWindow: any }
  }
}

const confirmPlugin: Plugin = (ctx, inject) => {
  inject('confirm', {
    showConfirmWindow(payload: any) {
      ctx.store.commit('confirmWindow/show', payload)
    },
  })
}

export default confirmPlugin
