import * as SentryTypes from '@sentry/minimal'
import {
  ApiError,
  UnProcessableEntityError,
  GenericError,
} from '~/helpers/errors'

export default function processError(error: any, $sentry: typeof SentryTypes) {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        $sentry.captureException(error)
        if (error.response.data.errors) {
          throw new ApiError(error.response.data.errors.base[0])
        }
        throw new ApiError('Bad request')
      case 401:
        $sentry.captureException(error)
        if (error.response.data.errors.message) {
          throw new ApiError(error.response.data.errors.message[0])
        }
        throw new ApiError(error.response.data.errors[0])
      case 403:
        $sentry.captureException(error)
        throw new ApiError('この操作をする権限がありません。')
      case 404:
        $sentry.captureException(error)
        if (error.response.data.errors) {
          throw new ApiError(error.response.data.errors[0])
        }
        throw new ApiError('Not Found')
      case 422:
        if (error.response.data.errors.base) {
          throw new ApiError(error.response.data.errors.base[0])
        }
        throw new UnProcessableEntityError(
          JSON.stringify(error.response.data.errors)
        )
      default:
        $sentry.captureException(error)
        throw new ApiError('不明なエラー')
    }
  }
  throw new GenericError(error)
}
