import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { KarteSymptomRegistrationRepo, KarteSymptomsParams } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): KarteSymptomRegistrationRepo => ({
  async fetchKarteSymptoms(params: {
    category_ids?: number[]
    branch_ids?: number[]
  }) {
    try {
      const res = await $axios.get(`/therapists/karte/symptoms`, { params })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async postKarteSymptom(symptom: KarteSymptomsParams) {
    try {
      const res = await $axios.post(`/therapists/karte/symptoms`, {
        symptom,
      })
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchKarteSymptom(id: number) {
    try {
      const res = await $axios.get(`/therapists/karte/symptoms/${id}`)
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async putKarteSymptom(symptom: KarteSymptomsParams) {
    try {
      const res = await $axios.put(`/therapists/karte/symptoms/${symptom.id}`, {
        symptom,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async deleteKarteSymptom(id: number) {
    try {
      await $axios.delete(`/therapists/karte/symptoms/${id}`)
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
