



















import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      objects: [] as {
        message: string
        timeout: number
        data: any
        color: string
        light: boolean
        contentClass: string
        icon: string | null
        iconColor: string
      }[],
      unsub: null as any,
    }
  },
  created() {
    this.unsub = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/showMessage') {
        this.objects.push({
          message: state.snackbar.content,
          timeout: state.snackbar.timeout,
          data: state.snackbar.data,
          color: state.snackbar.background,
          light: state.snackbar.light,
          contentClass: state.snackbar.class,
          icon: state.snackbar.icon,
          iconColor: state.snackbar.iconColor,
        })
      }
    })
  },
  beforeDestroy() {
    this.unsub()
  },
  methods: {
    async showClicked(data: any, close: any) {
      if (data.reservation) {
        await this.$router.push(this.localePath('/reservations'))
        setTimeout(
          () =>
            this.$store.commit(
              'reservation/showEditReservationForm',
              data.reservation
            ),
          500
        )
      }
      close()
    },
  },
})
