










import Vue from 'vue'

export default Vue.extend({
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: '#000',
    },
    textColor: {
      type: String,
      required: false,
      default: '#fff',
    },
    sm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
