import { MutationTree, ActionTree } from 'vuex'
import { Break, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  breaks: [] as Break[],
  breakPagination: {} as Pagination,

  tempBreak: {} as Break,
})

export type BreakState = ReturnType<typeof state>

export const mutations: MutationTree<BreakState> = {
  setBreaks(state, breaks: Break[]) {
    state.breaks = breaks
  },
  setBreakPagination(state, pagination: Pagination) {
    state.breakPagination = pagination
  },
  addNewBreak(state, breakData: Break) {
    state.breaks.push(breakData)
  },
  updateBreak(state, breakData: Break) {
    const index = state.breaks.findIndex((item) => item.id === breakData.id)
    if (index !== -1) state.breaks.splice(index, 1, breakData)
  },
  deleteBreak(state, id: number) {
    const index = state.breaks.findIndex((item) => item.id === id)
    if (index !== -1) state.breaks.splice(index, 1)
  },

  setTempBreak(state, tempBreak: Break) {
    state.tempBreak = tempBreak
  },
}

export const actions: ActionTree<BreakState, RootState> = {
  async fetchBreaks(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      start_date: string
      end_date?: string
      reason?: string
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { breaks, pagination } = (await params.api.breakRepo.fetchBreaks(
      branchId,
      {
        page: params.page,
        per: params.per,
        start_date: params.start_date,
        end_date: params.end_date || params.start_date,
      }
    )) as { breaks: Break[]; pagination: Pagination }
    if (pagination.total_entries > params.per) {
      const res = (await params.api.breakRepo.fetchBreaks(branchId, {
        page: 1,
        per: pagination.total_entries,
        start_date: params.start_date,
        end_date: params.end_date || params.start_date,
      })) as { breaks: Break[]; pagination: Pagination }
      commit('setBreaks', res.breaks)
      commit('setBreakPagination', res.pagination)
      return
    }
    commit('setBreaks', breaks)
    commit('setBreakPagination', pagination)
  },
  async createBreak(
    { commit, rootState },
    params: { api: Repositories; breakData: Break }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const breakData = await params.api.breakRepo.createBreak(
      branchId,
      params.breakData
    )
    commit('addNewBreak', breakData)
  },
  async updateBreak(
    { commit, rootState },
    params: { api: Repositories; breakData: Break }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const breakData = await params.api.breakRepo.updateBreak(
      branchId,
      params.breakData
    )
    commit('updateBreak', breakData)
  },
  async deleteBreak(
    { commit, rootState },
    params: { api: Repositories; breakId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api.breakRepo.deleteBreak(branchId, params.breakId)
    commit('deleteBreak', params.breakId)
  },
}
