import { MutationTree, ActionTree } from 'vuex'
import { Repositories, Pagination, Reservation } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  reservations: [] as Reservation[],
  reservationHistoryPagination: {} as Pagination,
  upcomingReservations: [] as Reservation[],
})

export type CustomerReservationHistoryState = ReturnType<typeof state>

export const mutations: MutationTree<CustomerReservationHistoryState> = {
  setReservations(state, reservations: Reservation[]) {
    state.reservations = reservations
  },
  upcomingReservations(state, upcomingReservations: Reservation[]) {
    state.upcomingReservations = upcomingReservations
  },
  setReservationHistoryPagination(state, pagination: Pagination) {
    state.reservationHistoryPagination = pagination
  },
}

export const actions: ActionTree<CustomerReservationHistoryState, RootState> = {
  async fetchCustomerReservationsHistory(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      page: number
      per: number
      customer_ids?: number[]
      session?: string
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { reservations, pagination } =
      (await params.api.reservationRepo.fetchReservations(branchId, {
        start_date: params.start_date,
        end_date: params.end_date,
        page: params.page || 1,
        per: params.per,
        customer_ids: params.customer_ids,
        session: params.session ?? 'all',
      })) as { reservations: Reservation[]; pagination: Pagination }
    commit(
      'setReservations',
      reservations.filter(
        (r) => r.reservation_type === 'blocking' || r.status !== 'cancelled'
      )
    )
    commit('setReservationHistoryPagination', pagination)
  },
  async fetchUpcomingCustomerReservations(
    { commit, rootState },
    params: {
      api: Repositories
      customer_ids: number[]
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { reservations } =
      (await params.api.reservationRepo.fetchReservations(branchId, {
        customer_ids: params.customer_ids,
        page: 1,
        per: 5,
        start_date: this.$dayjs(new Date()).format('YYYYMMDD'),
        sort: ['end_date.asc'],
      })) as { reservations: Reservation[] }
    commit(
      'upcomingReservations',
      reservations.filter(
        (r) => r.reservation_type === 'blocking' || r.status !== 'cancelled'
      )
    )
  },
}
