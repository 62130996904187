import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { KarteQuestionsRepo, CustomKarteQuestion } from '~/types'

let cancelSource: any = null
const cancelRequest = () => {
  if (cancelSource) {
    cancelSource.cancel('Previous request was cancelled')
  }
}

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): KarteQuestionsRepo => ({
  async fetchKarteQuestions(
    branchId: number,
    formId: number,
    params?: {
      section_ids: number[]
      page?: number
      per?: number
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/karte/forms/${formId}/questions`,
        { params }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createKarteQuestion(
    branchId: number,
    formId: number,
    params: CustomKarteQuestion
  ) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/karte/forms/${formId}/questions`,
        { question: params }
      )
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateKarteQuestion(
    branchId: number,
    formId: number,
    params: CustomKarteQuestion & { id: number }
  ) {
    try {
      cancelRequest()
      cancelSource = $axios.CancelToken.source()

      const res = await $axios.put(
        `therapists/branches/${branchId}/karte/forms/${formId}/questions/${params.id}`,
        { question: params },
        {
          cancelToken: cancelSource.token,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      let showError = true
      if (
        error &&
        (error as Error).message === 'Previous request was cancelled'
      ) {
        showError = false
      }
      if (showError) {
        processError(error, $sentry)
      }
    }
  },
  async deleteKarteQuestion(
    branchId: number,
    questionId: number,
    formId: number
  ) {
    try {
      const res = await $axios.delete(
        `therapists/branches/${branchId}/karte/forms/${formId}/questions/${questionId}`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async copyKarteQuestionnaire(
    branchId,
    copyBranchId: number[],
    form_id: number | undefined
  ) {
    try {
      // new api will be added here
      const res = await $axios.post(
        `therapists/branches/${branchId}/karte/forms/${form_id}/copy`,
        {
          form: {
            branch_ids: [...copyBranchId],
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
