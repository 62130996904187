import { MutationTree, ActionTree } from 'vuex'
import { Repositories, Pagination, Preset } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  presets: [] as Preset[],
  allPresets: [] as Preset[],
  presetsPagination: {} as Pagination,
  loadingList: {
    isPresetsLoading: false,
  },
})

export type PresetsState = ReturnType<typeof state>

export const mutations: MutationTree<PresetsState> = {
  setPresets(state, presets: Preset[]) {
    state.presets = presets
  },
  setAllPresets(state, presets: Preset[]) {
    state.allPresets = presets
  },
  addNewPreset(state, presets: Preset) {
    state.presets.push(presets)
  },
  removeCustomPreset(state) {
    const index = state.presets.findIndex((item) => item.name === '')
    if (index !== -1) state.presets.splice(index, 1)
  },
  updatePreset(state, presetData: Preset) {
    const index = state.presets.findIndex((item) => item.id === presetData.id)
    if (index !== -1) state.presets.splice(index, 1, presetData)
  },
  deletePreset(state, id: number) {
    const index = state.presets.findIndex((item) => item.id === id)
    if (index !== -1) state.presets.splice(index, 1)
  },
  setPresetPagination(state, pagination: Pagination) {
    state.presetsPagination = pagination
  },
}

export const actions: ActionTree<PresetsState, RootState> = {
  async fetchPresets(
    { commit },
    params: { api: Repositories; page: number; per: number }
  ) {
    const { presets, pagination } = await params.api.staffRepo.fetchPresets({
      page: params.page,
      per: params.per,
    })
    commit('setPresets', presets)
    commit('setPresetPagination', pagination)
  },
  async fetchAllPresets({ commit }, params: { api: Repositories }) {
    const { pagination } = await params.api.staffRepo.fetchPresets({
      page: 1,
      per: 1,
    })
    const { presets } = await params.api.staffRepo.fetchPresets({
      page: pagination.page,
      per: pagination.total_entries,
    })
    commit('setAllPresets', presets)
  },
  async createPreset(
    { commit },
    params: { api: Repositories; presetData: Preset }
  ) {
    const preset = await params.api.staffRepo.createPreset(params.presetData)
    commit('addNewPreset', preset)
    commit('removeCustomPreset')
  },
  async updatePreset(
    { commit },
    params: { api: Repositories; presetData: Preset }
  ) {
    const preset = await params.api.staffRepo.updatePreset(params.presetData)
    commit('updatePreset', preset)
  },
  async deletePreset(
    { commit },
    params: { api: Repositories; presetId: number }
  ) {
    await params.api.staffRepo.deletePreset(params.presetId)
    commit('deletePreset', params.presetId)
  },
}
