import { MutationTree } from 'vuex'
import { CrmFilterItems } from '~/types'

export const state = () => ({
  filterItems: [] as CrmFilterItems[],
})

export type CrmCustomerFilter = ReturnType<typeof state>

export const mutations: MutationTree<CrmCustomerFilter> = {
  setFilterItems(state, items: CrmFilterItems[]) {
    state.filterItems = items
  },
  addFilter(state, item: CrmFilterItems) {
    state.filterItems.push(item)
  },
  updateFilter(state, item: CrmFilterItems) {
    const index = state.filterItems.findIndex(
      (stateItem) => stateItem.key === item.key
    )
    state.filterItems.splice(index, 1, item)
  },
  deleteFilter(state, item: CrmFilterItems) {
    const index = state.filterItems.findIndex(
      (stateItem) => stateItem.key === item.key
    )
    state.filterItems.splice(index, 1)
  },
}
