import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Coupon, CouponRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): CouponRepo => ({
  async fetchCoupons(params?: {
    page?: number
    per?: number
    branch_ids?: number[]
    start_date?: string
    end_date?: string
  }) {
    try {
      const res = await $axios.get('therapists/coupons', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createCoupon(couponData: Coupon) {
    try {
      const res = await $axios.post('therapists/coupons', {
        coupon: couponData,
      })
      if (res.status === 201) {
        return res.data.coupon
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async fetchCouponById(couponId: number) {
    try {
      const res = await $axios.get(`therapists/coupons/${couponId}`)
      if (res.status === 200) {
        return res.data.coupon
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateCoupon(coupon: Coupon) {
    try {
      const res = await $axios.put(`therapists/coupons/${coupon.id}`, {
        coupon,
      })
      if (res.status === 200) {
        return res.data.coupon
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
