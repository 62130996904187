import { render, staticRenderFns } from "./ClinicSettingSecondarySideBar.vue?vue&type=template&id=54b8afca&scoped=true&"
import script from "./ClinicSettingSecondarySideBar.vue?vue&type=script&lang=ts&"
export * from "./ClinicSettingSecondarySideBar.vue?vue&type=script&lang=ts&"
import style0 from "./ClinicSettingSecondarySideBar.vue?vue&type=style&index=0&id=54b8afca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b8afca",
  null
  
)

/* custom blocks */
import block0 from "~/i18n/staffs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc207136660%2Fsrc%2Fthreease_pro%2Fcomponents%2Ffeatures%2FsettingsCRMSideBar%2FClinicSettingSecondarySideBar.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "~/i18n/topBarTitle.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc207136660%2Fsrc%2Fthreease_pro%2Fcomponents%2Ffeatures%2FsettingsCRMSideBar%2FClinicSettingSecondarySideBar.vue&lang=yaml"
if (typeof block1 === 'function') block1(component)
import block2 from "~/i18n/clinicSettings.yaml?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc207136660%2Fsrc%2Fthreease_pro%2Fcomponents%2Ffeatures%2FsettingsCRMSideBar%2FClinicSettingSecondarySideBar.vue&lang=yaml"
if (typeof block2 === 'function') block2(component)
import block3 from "~/i18n/cpa.yaml?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc207136660%2Fsrc%2Fthreease_pro%2Fcomponents%2Ffeatures%2FsettingsCRMSideBar%2FClinicSettingSecondarySideBar.vue&lang=yaml"
if (typeof block3 === 'function') block3(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ClinicSettingSecondarySideBarList: require('/codebuild/output/src207136660/src/threease_pro/components/features/settingsCRMSideBar/ClinicSettingSecondarySideBarList.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VCard,VIcon,VList})
