








































































import Vue, { PropOptions } from 'vue'
import { isMatchUrl } from '~/helpers/urlUtils'

export default Vue.extend({
  props: {
    item: {
      type: Object,
      required: true,
    } as PropOptions<{
      heading: string
      isSingle?: boolean
      subComponent: {
        text: any
        link: string
        id: string
        show: boolean
      }[]
    }>,
  },
  data() {
    return {
      expand: true,
    }
  },
  computed: {
    selectedSideBarItemId(): string {
      return this.$store.state.clinicSettings.selectedSideBarId
    },
    showSubMenuList(): {
      text: any
      link: string
      id: string
      show: boolean
    }[] {
      return this.item.subComponent.filter((item) => item.show)
    },
  },
  methods: {
    isMatchUrl,
    changePage(link: string, id: string) {
      this.$store.commit('clinicSettings/setSelectedSideBarId', id)
      this.$router.push(this.localePath(link))
    },
    getMathUrl(url: string) {
      return `${this.$route.path}` === `${this.localePath(url)}`
    },
  },
})
