

























































































































import Vue from 'vue'
import { UserPlugin } from '~/models/UserPlugin'
import { UserPermission } from '~/models/UserPermission'
import { isMatchUrl } from '~/helpers/urlUtils'
export default Vue.extend({
  props: {
    width: {
      type: String,
      required: false,
      default: '75',
    },
    height: {
      type: String,
      required: false,
      default: '100vh',
    },
    miniVariant: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedIcon: '',
    }
  },
  computed: {
    userPermission(): UserPermission {
      return this.$store.state.auth.userPermission
    },
    userPlugin(): UserPlugin {
      return this.$store.state.auth.userPlugin
    },
    sideBarItems(): {
      text: any

      link: string
      show: boolean
    }[] {
      return [
        {
          text: this.$t('sidebar.dashboard'),
          link: '/dashboard',
          show: this.userPermission.isAllowed('branch_home'),
        },
        {
          text: this.$t('sidebar.reservation'),
          link: '/reservations',
          show: this.userPermission.isAllowed('branch_reservation'),
        },
        {
          text: this.$t('sidebar.accountingManagement'),
          link: '/accounting',
          show: this.userPermission.isAllowed('branch_accounting'),
        },
        {
          text: this.$t('sidebar.shift'),
          link: '/shifts',
          show: this.userPermission.isAllowed('branch_shift'),
        },
        {
          text: this.$t('sidebar.customer'),
          link: '/customers',
          show: this.userPermission.isAllowed('branch_customer'),
        },

        {
          text: this.$t('sidebar.cms'),
          link: '/cms',
          show: this.userPlugin.isEnabled('cms'),
        },

        {
          text: this.$t('sidebar.message'),
          link: '/message/mails',
          show: this.userPlugin.isEnabled('message'),
        },
        {
          text: this.$t('sidebar.crm'),
          link: '/hq/crm/hq_crm',
          show:
            this.userPlugin.isEnabled('crm') &&
            this.userPermission.isAllowed('branch_crm'),
        },
        {
          text: this.$t('sidebar.setting'),
          link: '/clinic_setting',
          show: true,
        },
      ]
    },
  },
  created() {
    const path = this.$route.path.split('/')
    this.selectedIcon = `/${path[1]}`
  },
  methods: {
    isMatchUrl,
    setSelectedIcon(val: string) {
      this.selectedIcon = val
    },
  },
})
