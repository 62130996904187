import { MutationTree, ActionTree } from 'vuex'
import { Repositories, ReservationSlot } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  reservationSlots: [] as ReservationSlot[],
  weekViewReservationSelectedCountType: 'visit_count' as string,
  weekViewReservationSelectedMediumType: [
    'in_clinic',
    'web_reservation',
  ] as string[],
})

export type ReservationSlotState = ReturnType<typeof state>

export const mutations: MutationTree<ReservationSlotState> = {
  setReservationSlots(state, reservationSlots: ReservationSlot[]) {
    state.reservationSlots = reservationSlots
  },
  setWeekViewReservationSelectedCountType(
    state,
    weekViewReservationSelectedCountType: string
  ) {
    state.weekViewReservationSelectedCountType =
      weekViewReservationSelectedCountType
  },
  setWeekViewReservationSelectedMediumType(
    state,
    weekViewReservationSelectedMediumType: string[]
  ) {
    state.weekViewReservationSelectedMediumType =
      weekViewReservationSelectedMediumType
  },
}

export const actions: ActionTree<ReservationSlotState, RootState> = {
  async fetchReservationSlots(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      staff_ids: number[]
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { reservation_slots } =
      await params.api.reservationSlotsRepo.fetchReservationSlots(branchId, {
        start_date: params.start_date,
        end_date: params.end_date,
        staff_ids: params.staff_ids,
      })
    commit('setReservationSlots', reservation_slots)
  },
}
