import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '.'
import {
  CrmCustomerQueryParams,
  Customer,
  Pagination,
  Repositories,
  CrmDistributionAnalytics,
  CrmFilter,
  CrmCustomerApiQueryParams,
  Staff,
  Location,
} from '~/types'

export const state = () => ({
  customerData: [] as Customer[],
  staffs: [] as Staff[],
  crmDistributionAnalytics: {
    current: {
      gender_distribution: [],
      age_distribution: [],
    },
    comparison: {
      gender_distribution: [],
      age_distribution: [],
    },
  } as CrmDistributionAnalytics,
  params: {} as CrmCustomerQueryParams,
  customersPagination: {} as Pagination,
  isLoading: false,
  showAnalytics: true,
  useLastStaffIdsFilter: false,
  customerLocationData: [] as Location[],
})

export type CrmCustomerTableState = ReturnType<typeof state>

export const mutations: MutationTree<CrmCustomerTableState> = {
  setCrmCustomerParams(state, params: CrmCustomerQueryParams) {
    state.params = params
  },
  setCrmCustomerData(state, customerData: Customer[]) {
    state.customerData = customerData
  },
  setCrmCustomerLocationData(state, customerLocationData: Location[]) {
    state.customerLocationData = customerLocationData
  },
  setCustomersPagination(state, pagination: Pagination) {
    state.customersPagination = pagination
  },
  setCrmDistributionAnalytics(
    state,
    crmDistributionAnalytics: CrmDistributionAnalytics
  ) {
    state.crmDistributionAnalytics = crmDistributionAnalytics
  },
  setLoading(state, loading: boolean) {
    state.isLoading = loading
  },
  setShowAnalytics(state, showAnalytics: boolean) {
    state.showAnalytics = showAnalytics
  },
  setStaffs(state, staffs: Staff[]) {
    state.staffs = staffs
  },
  setUseLastStaffIdsFilter(state, value: boolean) {
    state.useLastStaffIdsFilter = value
  },
}
export const actions: ActionTree<CrmCustomerTableState, RootState> = {
  show(
    { commit, rootState },
    payload?: {
      filters: CrmFilter[]
      startEndDate?: { startDate: string; endDate: string }
    }
  ) {
    if (payload) {
      const { filters, startEndDate } = payload
      const date = startEndDate || (rootState as any).hqCrm.startEndDate
      const formatFilter = filters.map((data) => {
        if (data.value === 'total') return { key: data.key, value: undefined }
        if (data.key === 'non_visiting_customers') {
          return {
            key: 'customer_types',
            value: data.value || ['breakaway', 'cured'],
          }
        }
        return data
      })
      commit('setCrmCustomerParams', { ...date, filters: formatFilter } ?? [])
      return
    }
    commit(
      'setCrmCustomerParams',
      { ...(rootState as any).hqCrm.startEndDate, filters: [] } ?? []
    )
  },
  async getCrmCustomerData(
    { commit },
    params: {
      api: Repositories
      branchIds: number[]
      start_date: string
      end_date: string
    } & CrmCustomerApiQueryParams
  ) {
    commit('setLoading', true)
    const { customers, pagination } =
      await params.api.crmAnalyticsRepo.fetchCrmCustomerData({
        branch_ids: params.branchIds,
        page: params.page,
        per: params.per,
        start_date: params.start_date,
        end_date: params.end_date,
        gender: params.gender,
        age: params.age,
        symptom_ids: params.symptom_ids,
        last_visit: params.last_visit,
        customer_types: params.customer_types,
        referral_sub_source: params.referral_sub_source,
        time_of_day: params.time_of_day,
        product_types: params.product_types,
        payment_status: params.payment_status,
        payment_method: params.payment_method,
        sort: params.sort,
        reservation_status: params.reservation_status,
        cancel_reason: params.cancel_reason,
        followup: params.followup,
        reservation_source: params.reservation_source,
        referral_type: params.referral_type,
        staff_ids: params.staff_ids?.filter((staff_id) => staff_id !== -1),
        last_staff_ids: params.last_staff_ids,
        repeat_rate: params.repeat_rate,
        product_ids: params.product_ids,
        coupon_ids: params.coupon_ids,
        ticket_option_ids: params.ticket_option_ids,
        tag_ids: params.tag_ids,
      })
    commit('setCrmCustomerData', customers)
    commit('setCustomersPagination', pagination)
    commit('setLoading', false)
  },
  async getCrmCustomerLocationData(
    { commit },
    params: {
      api: Repositories
      branchIds: number[]
      start_date: string
      end_date: string
    } & CrmCustomerApiQueryParams
  ) {
    commit('setLoading', true)
    const { customers } =
      await params.api.crmAnalyticsRepo.fetchCrmCustomerLocationData({
        branch_ids: params.branchIds,
        start_date: params.start_date,
        end_date: params.end_date,
        gender: params.gender,
        age: params.age,
        symptom_ids: params.symptom_ids,
        last_visit: params.last_visit,
        customer_types: params.customer_types,
        time_of_day: params.time_of_day,
        product_types: params.product_types,
        payment_status: params.payment_status,
        payment_method: params.payment_method,
        sort: params.sort,
        reservation_status: params.reservation_status,
        cancel_reason: params.cancel_reason,
        followup: params.followup,
        reservation_source: params.reservation_source,
        referral_type: params.referral_type,
        referral_sub_source: params.referral_sub_source,
        staff_ids: params.staff_ids?.filter((staff_id) => staff_id !== -1),
        last_staff_ids: params.last_staff_ids,
        repeat_rate: params.repeat_rate,
        product_ids: params.product_ids,
        tag_ids: params.tag_ids,
      })
    commit('setCrmCustomerLocationData', customers)
    commit('setLoading', false)
  },
  async getCrmCustomerAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      comparison_start_date?: string
      comparison_end_date?: string
    } & CrmCustomerApiQueryParams
  ) {
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    const { analytics } =
      await params.api.crmAnalyticsRepo.fetchCrmCustomerAnalytics({
        start_date: params.start_date,
        end_date: params.end_date,
        gender: params.gender,
        age: params.age,
        customer_types: params.customer_types,
        referral_sub_source: params.referral_sub_source,
        time_of_day: params.time_of_day,
        symptom_ids: params.symptom_ids,
        last_visit: params.last_visit,
        product_types: params.product_types,
        payment_status: params.payment_status,
        payment_method: params.payment_method,
        branch_ids: params.branch_ids || branchIds,
        staff_ids: params.staff_ids?.filter((staff_id) => staff_id !== -1)
          ? params.staff_ids?.filter((staff_id) => staff_id !== -1)
          : params.last_staff_ids
          ? null
          : staffIds,
        last_staff_ids: params.last_staff_ids,
        comparison_start_date: params.comparison_start_date,
        comparison_end_date: params.comparison_end_date,
        repeat_rate: params.repeat_rate,
        product_ids: params.product_ids,
        coupon_ids: params.coupon_ids,
        ticket_option_ids: params.ticket_option_ids,
        tag_ids: params.tag_ids,
      })
    commit('setCrmDistributionAnalytics', analytics)
  },
  async getStaffs(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
      branch_ids?: number[]
      search?: string
    }
  ) {
    const { staff } = await params.api.staffRepo.fetchStaff({
      page: params.page,
      per: params.per,
      search: params.search,
      branch_ids: params.branch_ids,
    })
    commit('setStaffs', staff)
  },
}
