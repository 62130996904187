import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import {
  CrmCouponsParams,
  CrmCouponTableItem,
  CrmCouponAnalytics,
  Repositories,
} from '~/types'

const initCrmCouponTableItem = {
  coupons: [],
  total: {
    current_customer_count: 0,
    comparison_customer_count: 0,
    current_sales: 0,
    comparison_sales: 0,
    current_sold_count: 0,
    comparison_sold_count: 0,
    current_average_unit_price: 0,
  },
  pagination: {
    page: 1,
    per: 1,
    total_pages: 1,
    total_entries: 1,
  },
}

const initCrmCouponAnalytics = {
  current: {
    quantity_sold: 0,
    customer_count: 0,
    average_unit_price: 0,
    sales: 0,
    sales_contribution: 0,
    customer_contribution: 0,
    visiting_customers: [],
    customer_gender: [],
    customer_age: [],
  },
  comparison: {
    quantity_sold: 0,
    customer_count: 0,
    average_unit_price: 0,
    sales: 0,
    sales_contribution: 0,
    customer_contribution: 0,
    visiting_customers: [],
    customer_gender: [],
    customer_age: [],
  },
}

export const state = () => ({
  crmCouponTableItem: initCrmCouponTableItem as CrmCouponTableItem,
  crmCouponAnalytics: initCrmCouponAnalytics as CrmCouponAnalytics,
})

export type CrmCouponsState = ReturnType<typeof state>

export const mutations: MutationTree<CrmCouponsState> = {
  setCrmCouponTableItem(state, value: CrmCouponTableItem) {
    state.crmCouponTableItem = value
  },
  setCrmCouponAnalytics(state, value: CrmCouponAnalytics) {
    state.crmCouponAnalytics = value
  },
}

export const actions: ActionTree<CrmCouponsState, RootState> = {
  async getCrmCouponTableItem(
    { commit },
    params: {
      api: Repositories
    } & CrmCouponsParams
  ) {
    const res = await params.api.crmAnalyticsRepo.fetchCrmCoupons({
      branch_ids: params.branch_ids,
      start_date: params.start_date,
      end_date: params.end_date,
      comparison_start_date: params.comparison_start_date,
      comparison_end_date: params.comparison_end_date,
      coupon_ids: params.coupon_ids,
      text: params.text,
      sort: params.sort,
      staff_ids: params.staff_ids,
      page: params.page,
      per: params.per,
    })
    commit('setCrmCouponTableItem', res)
  },
  async getCrmCouponAnalytics(
    { commit },
    params: {
      api: Repositories
    } & { id: string } & CrmCouponsParams
  ) {
    const res = await params.api.crmAnalyticsRepo.fetchCrmCoupon({
      id: params.id,
      branch_ids: params.branch_ids,
      start_date: params.start_date,
      end_date: params.end_date,
      comparison_start_date: params.comparison_start_date,
      comparison_end_date: params.comparison_end_date,
      coupon_ids: params.coupon_ids,
      text: params.text,
      sort: params.sort,
      staff_ids: params.staff_ids,
    })
    commit('setCrmCouponAnalytics', res)
  },
}
