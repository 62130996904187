import dayjs from 'dayjs'
import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'

export const state = () => ({
  startEndDate: {
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  },
  tagType: 'reservation' as 'reservation' | 'customer',
})

export type AnalyticState = ReturnType<typeof state>

export const mutations: MutationTree<AnalyticState> = {
  setStartEndDate(
    state,
    dashboardStartEndDate: { startDate: string; endDate: string }
  ) {
    state.startEndDate = dashboardStartEndDate
  },
  setTagType(state, tagType: 'reservation' | 'customer') {
    state.tagType = tagType
  },
}

export const actions: ActionTree<AnalyticState, RootState> = {}
