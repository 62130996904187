import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AutomatedMailRepo, ReminderSetting, AutomatedMail } from '~/types'
import processError from '~/helpers/processError'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): AutomatedMailRepo => ({
  async fetchAutomatedMailSettings(
    branch_id: number,
    params: {
      start_date: string
      end_date: string
    }
  ) {
    try {
      const res = await $axios.get(
        `/therapists/branches/${branch_id}/reminder_settings`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async setAutomatedMailSettings(
    branch_id: number,
    reminder_setting: ReminderSetting
  ) {
    try {
      const res = await $axios.put(
        `/therapists/branches/${branch_id}/reminder_settings/${reminder_setting.id}`,
        {
          reminder_setting: {
            enabled: reminder_setting.enabled,
            subject: reminder_setting.subject,
            body: reminder_setting.body,
            triggers: reminder_setting.reminder_triggers,
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchAutomatedMails(
    branch_id: number,
    params: {
      page?: number
      per?: number
      sort?: string[]
    }
  ) {
    try {
      const res = await $axios.get(
        `/message/branches/${branch_id}/automated_mails`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createAutomatedMail(
    branch_id: number,
    automatedMailData: AutomatedMail
  ) {
    try {
      const res = await $axios.post(
        `message/branches/${branch_id}/automated_mails`,
        {
          automated_mail: automatedMailData,
        }
      )
      if (res.status === 201) {
        return res.data.automated_mail
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateAutomatedMail(
    branch_id: number,
    automatedMailData: AutomatedMail
  ) {
    try {
      const res = await $axios.put(
        `message/branches/${branch_id}/automated_mails/${automatedMailData.id}`,
        {
          automated_mail: automatedMailData,
        }
      )
      if (res.status === 200) {
        return res.data.automated_mail
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteAutomatedMail(branch_id: number, automatedMailId: number) {
    try {
      await $axios.delete(
        `message/branches/${branch_id}/automated_mails/${automatedMailId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
