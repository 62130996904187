import { MutationTree, ActionTree } from 'vuex'

import { Item, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  allWebReservationItems: [] as Item[],
  items: [] as Item[],
  itemPagination: {} as Pagination,
  loadingList: {
    isItemLoading: false,
  },
})

export type ItemState = ReturnType<typeof state>

export const mutations: MutationTree<ItemState> = {
  setItems(state, items: Item[]) {
    state.items = items
  },
  setAllWebReservationItems(state, items: Item[]) {
    state.allWebReservationItems = items
  },
  setItemPagination(state, pagination: Pagination) {
    state.itemPagination = pagination
  },
  addNewItem(state, itemData: Item) {
    state.items.unshift(itemData)
  },
  updateItem(state, itemData: Item) {
    const index = state.items.findIndex((item) => item.id === itemData.id)
    if (index !== -1) state.items.splice(index, 1, itemData)
  },
  deleteItem(state, id: number) {
    const index = state.items.findIndex((item) => item.id === id)
    if (index !== -1) state.items.splice(index, 1)
  },
  updatePagination(state) {
    state.itemPagination.total_entries += 1
    state.itemPagination.total_pages = Math.ceil(
      state.itemPagination.total_entries / 10
    )
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<ItemState, RootState> = {
  async fetchAllWebReservationItems(
    { commit, rootState },
    params: {
      api: Repositories
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { pagination } = (await params.api.itemRepo.fetchItems(branchId, {
      page: 1,
      per: 1,
      is_available_online: true,
    })) as { pagination: Pagination }

    const { items } = await params.api.itemRepo.fetchItems(branchId, {
      per: pagination.total_entries || 1,
      page: 1,
      is_available_online: true,
      sort: ['web_position'],
    })
    commit('setAllWebReservationItems', items)
    commit('setItemPagination', pagination)
  },
  async fetchAllItems(
    { commit, rootState },
    params: { api: Repositories; branchId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { pagination } = await params.api.itemRepo.fetchItems(
      params.branchId || branchId,
      {
        page: 1,
        per: 1,
      }
    )
    if (pagination === 0) return commit('setItems', [])
    const { items } = await params.api.itemRepo.fetchItems(
      params.branchId || branchId,
      {
        page: 1,
        per: pagination.total_entries || 1,
      }
    )
    commit('setItems', items)
  },

  async fetchItems(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      staff_id: number
      name?: string
      sort?: string[]
      product_types?: string[]
      start_date?: string
      is_available_online?: boolean
      branchId?: number
      for_new_customers?: boolean
    }
  ) {
    commit('setLoading', { key: 'isItemLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { items, pagination } = await params.api.itemRepo.fetchItems(
      params.branchId || branchId,
      {
        page: params.page,
        per: params.per,
        staff_id: params.staff_id,
        sort: params.sort,
        name: params.name,
        product_types: params.product_types,
        start_date: params.start_date,
        is_available_online: params.is_available_online,
        for_new_customers: params.for_new_customers,
      }
    )
    commit('setItems', items)
    commit('setItemPagination', pagination)
    commit('setLoading', { key: 'isItemLoading', loading: false })
  },
  async createItem(
    { commit, rootState },
    params: { api: Repositories; itemData: Item }
  ) {
    commit('setLoading', { key: 'isItemLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const item = await params.api.itemRepo.createItem(branchId, params.itemData)
    commit('addNewItem', item)
    commit('updatePagination')
    commit('setLoading', { key: 'isItemLoading', loading: false })
  },

  async updateItem(
    { commit, rootState },
    params: { api: Repositories; itemData: Item }
  ) {
    commit('setLoading', { key: 'isItemLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const item = await params.api.itemRepo.updateItem(branchId, params.itemData)
    commit('updateItem', item)
    commit('setLoading', { key: 'isItemLoading', loading: false })
  },
  async hideItem(
    { commit, rootState },
    params: { api: Repositories; itemData: Item }
  ) {
    commit('setLoading', { key: 'isItemLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const data = Object.assign({}, params.itemData)
    data.hidden = true
    const item = await params.api.itemRepo.updateItem(branchId, data)
    commit('deleteItem', item.id)
    commit('setLoading', { key: 'isItemLoading', loading: false })
  },
  async deleteItem(
    { commit, rootState },
    params: { api: Repositories; itemId: number }
  ) {
    commit('setLoading', { key: 'isItemLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api.itemRepo.deleteItem(branchId, params.itemId)
    commit('deleteItem', params.itemId)
    commit('setLoading', { key: 'isItemLoading', loading: false })
  },
  async setAllWebReservationItemsOrder(
    { rootState },
    params: {
      api: Repositories
      item: { id: number; new_web_position: number }
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api.itemRepo.setAllWebReservationItemsOrder(
      branchId,
      params.item
    )
  },
}
