





















import Vue from 'vue'

export default Vue.extend({
  props: {
    searchQuery: {
      type: String,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
  },
  methods: {
    changeSearchQuery(query: string) {
      this.$emit('changeSearchQuery', query)
    },
  },
})
