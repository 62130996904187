import { MutationTree, ActionTree } from 'vuex'
import { Branch, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  branchById: {} as Branch,
  branches: [] as Branch[],
  branchPagination: {} as Pagination,
  loadingList: {
    isBranchesLoading: false,
  },
  branchLocation: {
    lat: -1,
    lng: -1,
    label: '',
  },
})

export type BranchState = ReturnType<typeof state>

export const mutations: MutationTree<BranchState> = {
  setBranchById(state, branch: Branch) {
    state.branchById = branch
  },
  setBranches(state, branches: Branch[]) {
    state.branches = branches
  },
  setBranchPagination(state, pagination: Pagination) {
    state.branchPagination = pagination
  },
  addNewBranch(state, branchData: Branch) {
    state.branches.unshift(branchData)
  },
  updateBranch(state, branchData: Branch) {
    const index = state.branches.findIndex((item) => item.id === branchData.id)
    if (index !== -1) state.branches.splice(index, 1, branchData)
  },
  updateBranchPagination(state) {
    state.branchPagination.total_entries += 1
    state.branchPagination.total_pages = Math.ceil(
      state.branchPagination.total_entries / 10
    )
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  addActiveBranchLocation(state, branch: Branch) {
    state.branchLocation.lat = branch.latitude
    state.branchLocation.lng = branch.longitude
    state.branchLocation.label = branch.name
  },
}

export const actions: ActionTree<BranchState, RootState> = {
  async fetchBranches(
    { commit },
    params: { api: Repositories; page: number; per: number; status?: string }
  ) {
    commit('setLoading', { key: 'isBranchesLoading', loading: true })
    const { branches, pagination } = await params.api.branchRepo.fetchBranches({
      status: params.status,
      page: params.page,
      per: params.per,
    })
    commit('setBranches', branches)
    commit('setBranchPagination', pagination)
    commit('setLoading', { key: 'isBranchesLoading', loading: false })
  },
  async createBranch(
    { commit },
    params: { api: Repositories; branchData: Branch }
  ) {
    commit('setLoading', { key: 'isBranchesLoading', loading: true })
    const branchData = await params.api.branchRepo.createBranch(
      params.branchData
    )
    commit('addNewBranch', branchData)
    commit('updateBranchPagination')
    commit('setLoading', { key: 'isBranchesLoading', loading: false })
  },
  async fetchBranchById(
    { commit },
    params: {
      api: Repositories
      branchId: number
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    commit('setLoading', { key: 'isBranchesLoading', loading: true })
    const branch = await params.api.branchRepo.showBranch(
      params.branchId,
      params.karte_access_token,
      params.branch_access_token
    )
    commit('setBranchById', branch)
    commit('setLoading', { key: 'isBranchesLoading', loading: false })
  },
  async updateBranch(
    { commit },
    params: { api: Repositories; branchData: Branch }
  ) {
    commit('setLoading', { key: 'isBranchesLoading', loading: true })
    const branch = await params.api.branchRepo.updateBranch(params.branchData)
    commit('updateBranch', branch)
    commit('setLoading', { key: 'isBranchesLoading', loading: false })
  },
  async fetchBranchLocation(
    { commit },
    params: { api: Repositories; branch_id: number }
  ) {
    commit('setLoading', { key: 'isBranchesLoading', loading: true })
    const { branch } = await params.api.branchRepo.fetchBranchLocation(
      params.branch_id
    )
    commit('addActiveBranchLocation', branch)
    commit('setLoading', { key: 'isBranchesLoading', loading: false })
  },
}
