import { InstitutePlugin } from '~/types'

export class UserPlugin {
  userPlugins: InstitutePlugin[]
  constructor(userPlugins: InstitutePlugin[]) {
    this.userPlugins = userPlugins
  }

  isEnabled(pluginName: string): boolean {
    const plugin = this.userPlugins.find(
      (plugin: InstitutePlugin) => plugin.name === pluginName
    )
    if (plugin) {
      return plugin.status === 'enabled'
    }
    return false
  }
}
