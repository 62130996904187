//
// StarWebPrintTrader API
//
// Version 1.1.0
//
// Copyright 2012 STAR MICRONICS CO., LTD. All Rights Reserved.
//

const StarWebPrintTrader = function (a) {
  this.papertype = this.checkedblock = this.url = null
  this.timeout = 9e4
  this._json = this._url = this.onTimeout = this.onError = this.onReceive = null
  _handlerCallback = {}
  if (a) {
    a.url && (this.url = a.url)
    a.checkedblock && (this.checkedblock = a.checkedblock)
    a.papertype && (this.papertype = a.papertype)
    a.timeout && (this.timeout = a.timeout)
  }
}
let _handlerCallback = {}
const _onFinish = function (a) {
  const b = _handlerCallback[a.url]
  const c = a.response
  _handlerCallback[a.url] = null
  if (a.htmlCode === 200)
    b.onReceive({
      traderSuccess: c.slice(
        c.indexOf('&lt;success&gt;') + 15,
        c.indexOf('&lt;/success&gt;')
      ),
      traderCode: c.slice(
        c.indexOf('&lt;code&gt;') + 12,
        c.indexOf('&lt;/code&gt;')
      ),
      traderStatus: c.slice(
        c.indexOf('&lt;status&gt;') + 14,
        c.indexOf('&lt;/status&gt;')
      ),
      status: a.htmlCode,
      responseText: c,
    })
  else b.onError({ status: a.htmlCode, responseText: c })
}

StarWebPrintTrader.prototype._callMessageHandler = function () {
  const a = this
  if (!_handlerCallback[a._url]) {
    _handlerCallback[a._url] = a
    webkit.messageHandlers.sendMessageHandler.postMessage(a._json)
  } else {
    setTimeout(function () {
      a._callMessageHandler()
    }, 500)
  }
}
StarWebPrintTrader.prototype.sendMessage = function (a) {
  let b = '<root'
  a.checkedblock !== 0
    ? !1 === a.checkedblock && (b += ' checkedblock="false"')
    : !1 === this.checkedblock && (b += ' checkedblock="false"')
  a.papertype !== 0
    ? a.papertype === 'normal'
      ? (b += ' papertype="normal"')
      : a.papertype === 'black_mark'
      ? (b += ' papertype="black_mark"')
      : a.papertype === 'black_mark_and_detect_at_power_on' &&
        (b += ' papertype="black_mark_and_detect_at_power_on"')
    : this.papertype === 'normal'
    ? (b += ' papertype="normal"')
    : this.papertype === 'black_mark'
    ? (b += ' papertype="black_mark"')
    : papertype === 'black_mark_and_detect_at_power_on' &&
      (b += ' papertype="black_mark_and_detect_at_power_on"')
  b = b + ('>' + a.request + '</root>')
  let c
  c =
    '<StarWebPrint xmlns="http://www.star-m.jp" xmlns:i="http://www.w3.org/2001/XMLSchema-instance"><Request>'
  c += this._encodeEscapeSequence(b)
  c += '</Request>'
  c += '</StarWebPrint>'
  b = ''
  b = a.url ? a.url : this.url

  if (
    /^https?:\/\/(localhost|127\.0\.0\.1):8001\//.test(b.toLowerCase()) &&
    navigator.userAgent.includes('webPRNTSupportMessageHandler')
  ) {
    this._json = JSON.stringify({ url: b, body: c })
    this._url = b
    this._callMessageHandler()
  } else {
    let d = null
    if (window.XMLHttpRequest) d = new XMLHttpRequest()
    else if (window.ActiveXObject) d = new ActiveXObject('Microsoft.XMLHTTP')
    else {
      if (this.onError)
        this.onError({
          status: 10001,
          responseText: 'XMLHttpRequest is not supported.',
        })
      return
    }
    if (
      navigator.userAgent.includes('iPad;') ||
      navigator.userAgent.includes('iPhone;') ||
      navigator.userAgent.includes('iPod touch;') ||
      navigator.userAgent.includes('Android')
    )
      if (
        !navigator.userAgent.includes('WebPRNTSupportHTTPS') &&
        (b.toLowerCase().indexOf('https://localhost') === 0 ||
          b.toLowerCase().indexOf('https://127.0.0.1') === 0)
      )
        b = 'http://' + b.substring(8)
    try {
      d.open('POST', b, !0)
    } catch (f) {
      if (this.onError) this.onError({ status: 10002, responseText: f.message })
      return
    }
    try {
      a.timeout !== 0
        ? (d.timeout = a.timeout)
        : this.timeout && (d.timeout = this.timeout)
    } catch (h) {}
    d.setRequestHeader('Content-Type', 'text/xml; charset=UTF-8')
    const e = this
    d.onreadystatechange = function () {
      if (d.readyState === 4)
        try {
          if (d.status === 200) {
            const a = d.responseXML.getElementsByTagName('Response')
            if (a.length > 0) {
              if (e.onReceive) {
                const b = a[0].childNodes[0].nodeValue
                e.onReceive({
                  traderSuccess: b.slice(
                    b.indexOf('<success>') + 9,
                    b.indexOf('</success>')
                  ),
                  traderCode: b.slice(
                    b.indexOf('<code>') + 6,
                    b.indexOf('</code>')
                  ),
                  traderStatus: b.slice(
                    b.indexOf('<status>') + 8,
                    b.indexOf('</status>')
                  ),
                  status: d.status,
                  responseText: d.responseText,
                })
              }
            } else if (e.onError)
              e.onError({ status: d.status, responseText: d.responseText })
          } else if (e.onError)
            e.onError({ status: d.status, responseText: d.responseText })
        } catch (c) {
          if (e.onError)
            e.onError({
              status: 0,
              responseText: 'Connection timeout occurred.',
            })
        }
    }
    try {
      d.ontimeout = function () {
        if (e.onTimeout) e.onTimeout()
      }
    } catch (k) {}
    try {
      d.send(c)
    } catch (g) {
      if (this.onError) this.onError({ status: 10003, responseText: g.message })
    }
  }
}
StarWebPrintTrader.prototype._onHandlerSuccess = function (a) {
  if (trader.onReceive) trader.onReceive(a)
}
StarWebPrintTrader.prototype._onHandlerError = function (a) {
  if (trader.onError) trader.onError(a)
}
StarWebPrintTrader.prototype.isCoverOpen = function (a) {
  return parseInt(a.traderStatus.substr(4, 2), 16) & 32 ? !0 : !1
}
StarWebPrintTrader.prototype.isOffLine = function (a) {
  return parseInt(a.traderStatus.substr(4, 2), 16) & 8 ? !0 : !1
}
StarWebPrintTrader.prototype.isCompulsionSwitchClose = function (a) {
  return parseInt(a.traderStatus.substr(4, 2), 16) & 4 ? !0 : !1
}
StarWebPrintTrader.prototype.isEtbCommandExecute = function (a) {
  return parseInt(a.traderStatus.substr(4, 2), 16) & 2 ? !0 : !1
}
StarWebPrintTrader.prototype.isHighTemperatureStop = function (a) {
  return parseInt(a.traderStatus.substr(6, 2), 16) & 64 ? !0 : !1
}
StarWebPrintTrader.prototype.isNonRecoverableError = function (a) {
  return parseInt(a.traderStatus.substr(6, 2), 16) & 32 ? !0 : !1
}
StarWebPrintTrader.prototype.isAutoCutterError = function (a) {
  return parseInt(a.traderStatus.substr(6, 2), 16) & 8 ? !0 : !1
}
StarWebPrintTrader.prototype.isBlackMarkError = function (a) {
  return parseInt(a.traderStatus.substr(8, 2), 16) & 8 ? !0 : !1
}
StarWebPrintTrader.prototype.isPaperEnd = function (a) {
  return parseInt(a.traderStatus.substr(10, 2), 16) & 8 ? !0 : !1
}
StarWebPrintTrader.prototype.isPaperNearEnd = function (a) {
  return parseInt(a.traderStatus.substr(10, 2), 16) & 4 ? !0 : !1
}
StarWebPrintTrader.prototype.isPaperPresent = function (a) {
  return parseInt(a.traderStatus.substr(12, 2), 16) & 2 ? !0 : !1
}
StarWebPrintTrader.prototype.extractionEtbCounter = function (a) {
  let b = 0
  parseInt(a.traderStatus.substr(14, 2), 16) & 64 && (b |= 16)
  parseInt(a.traderStatus.substr(14, 2), 16) & 32 && (b |= 8)
  parseInt(a.traderStatus.substr(14, 2), 16) & 8 && (b |= 4)
  parseInt(a.traderStatus.substr(14, 2), 16) & 4 && (b |= 2)
  parseInt(a.traderStatus.substr(14, 2), 16) & 2 && (b |= 1)
  return b
}
StarWebPrintTrader.prototype._encodeEscapeSequence = function (a) {
  const b = /[<>&]/g
  b.test(a) &&
    (a = a.replace(b, function (a) {
      switch (a) {
        case '<':
          return '&lt;'
        case '>':
          return '&gt;'
      }
      return '&amp;'
    }))
  return a
}

if (typeof module !== 'undefined' && module.exports) {
  module.exports = StarWebPrintTrader
}
