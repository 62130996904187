import { MutationTree, ActionTree } from 'vuex'
import {
  Staff,
  Repositories,
  Pagination,
  Branch,
  Product,
  Certification,
  Credential,
  Preset,
  Permission,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  staff: [] as Staff[],
  staffPagination: {} as Pagination,
  staffBranches: [] as Branch[],
  staffProducts: [] as Product[],
  staffProductsPagination: {} as Pagination,
  staffCertifications: [] as Certification[],
  allStaff: [] as Staff[],
  unAvailableStaff: {} as Staff,
  loadingList: {
    isStaffLoading: false,
    isStaffBranchesLoading: false,
    isStaffProductsLoading: false,
    isStaffCertificationsLoading: false,
  },
  isStaffModalShow: false as Boolean,
  staffData: {} as Staff,
  presets: [] as Preset[],
  presetsPagination: {} as Pagination,
  permissions: [] as Permission[],
})

export type StaffState = ReturnType<typeof state>

export const mutations: MutationTree<StaffState> = {
  setStaff(state, staff: Staff[]) {
    state.staff = staff
  },
  setAllStaff(state, allStaff: Staff[]) {
    state.allStaff = allStaff
  },
  setStaffBranches(state, branches: Branch[]) {
    state.staffBranches = branches
  },
  setStaffProducts(state, products: Product[]) {
    state.staffProducts = products
  },
  setStaffProductsPagination(state, pagination: Pagination) {
    state.staffProductsPagination = pagination
  },
  setStaffPagination(state, pagination: Pagination) {
    state.staffPagination = pagination
  },
  updateStaff(state, staffData: Staff) {
    const index = state.staff.findIndex((item) => item.id === staffData.id)
    if (index !== -1) state.staff.splice(index, 1, staffData)
  },
  setStaffCertifications(state, certifications: Certification[]) {
    state.staffCertifications = certifications
  },
  setUnAvailableStaff(state, unAvailableStaff: Staff) {
    state.unAvailableStaff = unAvailableStaff
  },
  updateStaffList(state, staffIds: number[]) {
    state.staff = state.allStaff.filter((staff) =>
      staffIds.includes(staff.id as number)
    )
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  setStaffModal(state, value: Boolean) {
    state.isStaffModalShow = value
  },
  setStaffEditData(state, staffData: Staff) {
    state.staffData = staffData
  },
  addNewStaff(state, staffData: Staff) {
    state.staff.unshift(staffData)
  },
  updatePagination(state) {
    state.staffPagination.total_entries += 1
    state.staffPagination.total_pages = Math.ceil(
      state.staffPagination.total_entries / 10
    )
  },
  setPasswordCredentials(state, credentialData: Credential) {
    const { password, send_credentials } = credentialData
    state.staffData.password = password
    state.staffData.send_credentials = send_credentials
  },
  setPresets(state, presets: Preset[]) {
    state.presets = presets
  },
  addNewPreset(state, presets: Preset) {
    state.presets.push(presets)
  },
  removeCustomPreset(state) {
    const index = state.presets.findIndex((item) => item.name === '')
    if (index !== -1) state.presets.splice(index, 1)
  },
  updatePreset(state, presetData: Preset) {
    const index = state.presets.findIndex((item) => item.id === presetData.id)
    if (index !== -1) state.presets.splice(index, 1, presetData)
  },
  deletePreset(state, id: number) {
    const index = state.presets.findIndex((item) => item.id === id)
    if (index !== -1) state.presets.splice(index, 1)
  },
  setPresetPagination(state, pagination: Pagination) {
    state.presetsPagination = pagination
  },
}

export const actions: ActionTree<StaffState, RootState> = {
  async fetchStaff(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
      branch_ids?: number[]
      search?: string
      account_status?: string[]
      employment_status?: string[]
      preset_ids?: number[]
    }
  ) {
    commit('setLoading', { key: 'isStaffLoading', loading: true })
    const { staff, pagination } = await params.api.staffRepo.fetchStaff({
      page: params.page,
      per: params.per,
      search: params.search,
      branch_ids: params.branch_ids,
      account_status: params.account_status,
      employment_status: params.employment_status,
      preset_ids: params.preset_ids,
    })
    commit('setStaff', staff)
    commit('setAllStaff', staff)
    commit('setStaffPagination', pagination)
    commit('setLoading', { key: 'isStaffLoading', loading: false })
  },
  async fetchBranchStaffKarte(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      is_available_online: boolean
      account_status?: string[]
      employment_status?: string[]
    }
  ) {
    commit('setLoading', { key: 'isStaffLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch

    const { staff, pagination } = await params.api.staffRepo.fetchBranchStaff(
      branchId,
      {
        page: params.page,
        per: params.per,
        field_therapist: true,
        is_available_online: params.is_available_online,
        account_status: params.account_status,
        employment_status: params.employment_status,
      }
    )
    commit('setAllStaff', staff)
    commit('setStaffPagination', pagination)
    commit('setLoading', { key: 'isStaffLoading', loading: false })
  },
  async fetchAllStaff(
    { commit },
    params: {
      api: Repositories
      branch_ids?: number[]
      start_date?: string
      end_date?: string
    }
  ) {
    commit('setLoading', { key: 'isStaffLoading', loading: true })
    const { pagination } = await params.api.staffRepo.fetchStaff({
      branch_ids: params.branch_ids,
      start_date: params.start_date,
      end_date: params.end_date,
      page: 1,
      per: 1,
    })

    const { staff } = await params.api.staffRepo.fetchStaff({
      branch_ids: params.branch_ids,
      page: 1,
      per: pagination.total_entries || 1,
      start_date: params.start_date,
      end_date: params.end_date,
    })

    commit('setAllStaff', staff)
    commit('setLoading', { key: 'isStaffLoading', loading: false })
  },
  async updateStaff(
    { commit },
    params: { api: Repositories; staffData: Staff }
  ) {
    commit('setLoading', { key: 'isStaffLoading', loading: true })
    const staff = await params.api.staffRepo.updateStaff(params.staffData)
    commit('updateStaff', staff)
    commit('setLoading', { key: 'isStaffLoading', loading: false })
  },

  async fetchStaffById(
    { commit },
    params: {
      api: Repositories
      staffId: number
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    commit('setLoading', { key: 'isStaffLoading', loading: true })
    const { staff } = await params.api.staffRepo.fetchStaffById({
      staffId: params.staffId,
      karte_access_token: params.karte_access_token,
      branch_access_token: params.branch_access_token,
    })
    commit('setUnAvailableStaff', staff)
    commit('setLoading', { key: 'isStaffLoading', loading: false })
  },

  async fetchBranchStaff(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      is_available_online: boolean
      account_status?: string[]
      employment_status?: string[]
    }
  ) {
    commit('setLoading', { key: 'isStaffLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch

    const { staff, pagination } = await params.api.staffRepo.fetchBranchStaff(
      branchId,
      {
        page: params.page,
        per: params.per,
        field_therapist: true,
        is_available_online: params.is_available_online,
        account_status: params.account_status,
        employment_status: params.employment_status,
      }
    )
    commit('setStaff', staff)
    commit('setAllStaff', staff)
    commit('setStaffPagination', pagination)
    commit('setLoading', { key: 'isStaffLoading', loading: false })
  },

  async fetchStaffBranches(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
      staffId: number
    }
  ) {
    commit('setLoading', { key: 'isStaffBranchesLoading', loading: true })
    const staffId = params.staffId
    const { branches } = await params.api.branchRepo.fetchStaffBranches(
      staffId,
      {
        page: params.page,
        per: params.per,
      }
    )
    commit('setStaffBranches', branches)
    commit('setLoading', { key: 'isStaffBranchesLoading', loading: false })
  },
  async fetchStaffProducts(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
      staffId: number
    }
  ) {
    commit('setLoading', { key: 'isStaffProductsLoading', loading: true })
    const staffId = params.staffId
    const { products, pagination } =
      await params.api.productRepo.fetchStaffProducts(staffId, {
        page: params.page,
        per: params.per,
      })
    commit('setStaffProducts', products)
    commit('setStaffProductsPagination', pagination)
    commit('setLoading', { key: 'isStaffProductsLoading', loading: false })
  },

  async fetchStaffCertifications(
    { commit },
    params: { api: Repositories; page: number; per: number; staffId: number }
  ) {
    commit('setLoading', { key: 'isStaffCertificationsLoading', loading: true })
    const staffId = params.staffId
    const { certifications } =
      await params.api.licenceRepo.fetchStaffCertifications(staffId, {
        page: params.page,
        per: params.per,
      })
    commit('setStaffCertifications', certifications)
    commit('setLoading', {
      key: 'isStaffCertificationsLoading',
      loading: false,
    })
  },
  async createStaff(
    { commit },
    params: { api: Repositories; staffData: Staff }
  ) {
    commit('setLoading', { key: 'isStaffLoading', loading: true })
    const staff = await params.api.staffRepo.createStaff(params.staffData)
    commit('addNewStaff', staff)
    const terminationDate = this.$dayjs(staff.termination_date).format(
      'YYYY-MM-DD'
    )
    const joiningDate = this.$dayjs(staff.joining_date).format('YYYY-MM-DD')
    commit('setStaffEditData', {
      ...staff,
      termination_date: terminationDate,
      joining_date: joiningDate,
    })
    commit('updatePagination')
    commit('setLoading', { key: 'isStaffLoading', loading: false })
  },
  async resetPassword(
    { commit },
    params: { api: Repositories; staffId: number; credentialData: Credential }
  ) {
    const credential = await params.api.staffRepo.resetPassword(
      params.staffId,
      params.credentialData
    )
    commit('setPasswordCredentials', credential)
  },
  async fetchPresets(
    { commit },
    params: { api: Repositories; page: number; per: number }
  ) {
    const { presets, pagination } = await params.api.staffRepo.fetchPresets({
      page: params.page,
      per: params.per,
    })
    commit('setPresets', presets)
    commit('setPresetPagination', pagination)
  },
  async createPreset(
    { commit, rootState },
    params: { api: Repositories; presetData: Preset }
  ) {
    const staff = (rootState as any).staff.staffData
    const preset = await params.api.staffRepo.createPreset(params.presetData)
    commit('addNewPreset', preset)
    commit('setStaffEditData', {
      ...staff,
      preset_id: preset.id,
      permission_slugs: preset.permission_slugs,
    })
    commit('removeCustomPreset')
  },
  async updatePreset(
    { commit },
    params: { api: Repositories; presetData: Preset }
  ) {
    const preset = await params.api.staffRepo.updatePreset(params.presetData)
    commit('updatePreset', preset)
  },
  async deletePreset(
    { commit, rootState },
    params: { api: Repositories; presetId: number }
  ) {
    const staff = (rootState as any).staff.staffData
    await params.api.staffRepo.deletePreset(params.presetId)
    commit('deletePreset', params.presetId)
    commit('setStaffEditData', {
      ...staff,
      preset_id: null,
      permission_slugs: [],
    })
  },
}
