import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { SquareRepo } from '~/types'
import processError from '~/helpers/processError'

export default ($axios: NuxtAxiosInstance, $sentry: any): SquareRepo => ({
  async fetchSquare() {
    try {
      const res = await $axios.get('/therapists/square/account')
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async authSquare(params: {
    authorization_code: string | (string | null)[]
    client_state: string | (string | null)[]
  }) {
    try {
      const res = await $axios.post('/therapists/square/account', {
        square_account: params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchSquareLocations() {
    try {
      const res = await $axios.get('/therapists/square/locations')
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchSquareDevices(branchId: any) {
    try {
      const res = await $axios.get(
        `/therapists/branches/${branchId}/square/devices`
      )
      if (res.status === 200) {
        return res.data
      }
      if (res.status === 422) {
        return {}
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchSquareDetails(branchId: any) {
    try {
      const res = await $axios.get(
        `/therapists/branches/${branchId}/square/payment_details`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateSquareDetails(
    branchId: number,
    paymentDetails: {
      location_id: string | null
    }
  ) {
    try {
      const res = await $axios.put(
        `/therapists/branches/${branchId}/square/payment_details`,
        {
          payment_details: paymentDetails,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createdSquareDevice(
    branchId: number,
    device: { name: string },
    activeBranchId: number
  ) {
    try {
      const res = await $axios.post(
        `/therapists/branches/${branchId}/square/devices`,
        {
          device,
          active_branch_id: activeBranchId,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
