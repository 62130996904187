export default function ({ store, route, error }) {
  const userPermission = store.state.auth.userPermission
  let currentPath = ''
  if (route.path.slice(-1) === '/') {
    currentPath = route.path.slice(0, -1).split('/').pop()
  } else {
    currentPath = route.path.split('/').pop()
  }
  switch (currentPath) {
    case 'dashboard':
      if (
        !userPermission.isAllowed('branch_home') &&
        !userPermission.isAllowed('hq_dashboard')
      )
        return error({ statusCode: 403 })
      break
    case 'home':
      if (!userPermission.isAllowed('hq_dashboard'))
        return error({ statusCode: 403 })
      break
    case 'reservations':
      if (!userPermission.isAllowed('branch_reservation'))
        return error({ statusCode: 403 })
      break
    case 'items':
      if (!userPermission.isAllowed('branch_item'))
        return error({ statusCode: 403 })
      break
    case 'customers':
      if (!userPermission.isAllowed('branch_customer'))
        return error({ statusCode: 403 })
      break
    case 'accounting':
      if (!userPermission.isAllowed('branch_accounting'))
        return error({ statusCode: 403 })
      break
    case 'crm':
      if (!userPermission.isAllowed('branch_crm'))
        return error({ statusCode: 403 })
      break
    // case 'cms':
    //   if (!userPermission.isAllowed('branch_cms'))
    //     return error({ statusCode: 403 })
    //   break
    case 'mails':
      if (!userPermission.isAllowed('branch_message'))
        return error({ statusCode: 403 })
      break
    // case 'clinic_setting':
    //   if (!userPermission.isAllowed('branch_setting'))
    //     return error({ statusCode: 403 })
    //   break
    case 'automatedMails':
      if (!userPermission.isAllowed('branch_message'))
        return error({ statusCode: 403 })
      break
    // Settings
    case 'shifts':
      if (!userPermission.isAllowed('branch_shift'))
        return error({ statusCode: 403 })
      break
    // case 'units':
    //   if (!userPermission.isAllowed('hq_branch'))
    //     return error({ statusCode: 403 })
    //   break
    // case 'business_times':
    //   if (!userPermission.isAllowed('hq_branch'))
    //     return error({ statusCode: 403 })
    //   break
    case 'permissions':
      if (!userPermission.isAllowed('hq_staff'))
        return error({ statusCode: 403 })
      break
    case 'branches':
      if (!userPermission.isAllowed('hq_branch'))
        return error({ statusCode: 403 })
      break
    case 'staff':
      if (!userPermission.isAllowed('hq_staff'))
        return error({ statusCode: 403 })
      break
    case 'annual_trends':
      if (!userPermission.isAllowed('hq_crm')) return error({ statusCode: 403 })
      break
    case 'hq_crm':
      if (
        !userPermission.isAllowed('hq_crm') &&
        !userPermission.isAllowed('branch_crm')
      )
        return error({ statusCode: 403 })
      break
    case 'crm_staff':
      if (!userPermission.isAllowed('hq_crm')) return error({ statusCode: 403 })
      break
    case 'categories':
      if (!userPermission.isAllowed('hq_product'))
        return error({ statusCode: 403 })
      break
    case 'karte':
      if (!userPermission.isAllowed('branch_karte'))
        return error({ statusCode: 403 })
      break
    case 'products':
      if (!userPermission.isAllowed('hq_product'))
        return error({ statusCode: 403 })
      break
    case 'tickets':
      if (!userPermission.isAllowed('hq_product'))
        return error({ statusCode: 403 })
      break
    case 'coupons':
      if (!userPermission.isAllowed('hq_product'))
        return error({ statusCode: 403 })
      break
    case 'square':
      if (!userPermission.isAllowed('hq_square'))
        return error({ statusCode: 403 })
      break
  }
}
