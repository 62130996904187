import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import sortChecker from '~/helpers/sortChecker'
import {
  Reservation,
  ReservationLimit,
  ReservationRepo,
  TimeSlotSetting,
} from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): ReservationRepo => ({
  async fetchReservations(
    branchId: number,
    params: {
      start_date: string
      end_date?: string
      page?: number
      per?: number
      category_ids?: number[]
      staff_ids?: number[]
      customer_ids?: number[]
      status?: string
      type?: 'analytical'
      tag_ids?: number[]
      sort?: string[]
    }
  ) {
    try {
      const newParams = sortChecker(params)
      const res = await $axios.get(
        `therapists/branches/${branchId}/reservations`,
        {
          params: newParams,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async showReservation(branchId: number, reservationId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/reservations/${reservationId}`
      )
      if (res.status === 200) {
        return res.data.reservation
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createReservation(branchId: number, reservationData: Reservation) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/reservations`,
        {
          reservation: reservationData,
        }
      )
      if (res.status === 201) {
        return res.data.reservation
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateReservation(branchId: number, reservation: Reservation) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/reservations/${reservation.id}`,
        {
          reservation,
        }
      )
      if (res.status === 200) {
        return res.data.reservation
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteReservation(branchId: number, reservationId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/reservations/${reservationId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchReservationLimit(branchId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/reservations/limit`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateReservationLimit(
    branchId: number,
    reservation_limit: ReservationLimit
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/reservations/limit`,
        {
          reservation_limit,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },

  async fetchReservationTimeSlot(branchId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/reservations/time_slot_setting`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateReservationTimeSlot(
    branchId: number,
    timeSlotSettingData: TimeSlotSetting
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/reservations/time_slot_setting`,
        timeSlotSettingData
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
