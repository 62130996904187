import { GetterTree } from 'vuex'
import { RootState } from '~/store'

import kana_list from '~/assets/kana_list.json'

export const state = () => ({
  kanaList: kana_list,
})

export type KanaListState = ReturnType<typeof state>

export const getters: GetterTree<KanaListState, RootState> = {
  getNameKana: (state) => (name: string) => {
    let name_kana = ''
    if (name) {
      const nameWithoutSpace = name.replace(/\s+/g, '')
      for (let i = nameWithoutSpace.length - 1; i >= 1; i--) {
        const first_name = nameWithoutSpace.substring(0, i)
        const last_name = nameWithoutSpace.substring(i)
        const first_name_kana = state.kanaList[first_name]
        if (first_name_kana) {
          const last_name_kana = state.kanaList[last_name]
          if (last_name.match(/[ぁ-ん]+/)) {
            name_kana =
              first_name_kana +
              ' ' +
              last_name.replace(/[ぁ-ん]/g, function (s) {
                return String.fromCharCode(s.charCodeAt(0) + 0x60)
              })
            break
          } else if (last_name_kana) {
            name_kana = first_name_kana + ' ' + last_name_kana
            break
          }
        }
      }
    }
    return name_kana
  },
}
