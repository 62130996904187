import { MutationTree, ActionTree } from 'vuex'
import { Repositories, StaffEmploymentHistory } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  staffEmploymentHistories: [] as StaffEmploymentHistory[],
  staffEmploymentHistoryData: {} as StaffEmploymentHistory,
})

export type staffEmploymentHistoryState = ReturnType<typeof state>

export const mutations: MutationTree<staffEmploymentHistoryState> = {
  setStaffEmploymentHistories(
    state,
    staffEmploymentHistories: StaffEmploymentHistory[]
  ) {
    state.staffEmploymentHistories = staffEmploymentHistories
  },

  addNewStaffEmploymentHistory(
    state,
    staffEmploymentHistoryData: StaffEmploymentHistory
  ) {
    const index = state.staffEmploymentHistories.findIndex(
      (item) => item.branch_id === staffEmploymentHistoryData.branch_id
    )
    if (index !== -1) {
      const historyIndex = state.staffEmploymentHistories[
        index
      ].history.findIndex(
        (item) => item.id === staffEmploymentHistoryData.history[0].id
      )
      const histories = [...state.staffEmploymentHistories[index].history!]
      if (historyIndex !== -1) {
        state.staffEmploymentHistories[index].history.splice(historyIndex, 1)
        histories[historyIndex] = staffEmploymentHistoryData.history[0]
        state.staffEmploymentHistories[index] = {
          branch_name: state.staffEmploymentHistories[index].branch_name,
          branch_id: state.staffEmploymentHistories[index].branch_id,
          history: histories,
        }
      } else {
        state.staffEmploymentHistories[index].history.pop()
        state.staffEmploymentHistories[index].history.push(
          staffEmploymentHistoryData.history[0]
        )
      }
      state.staffEmploymentHistories = [...state.staffEmploymentHistories]
    } else {
      state.staffEmploymentHistories.unshift(staffEmploymentHistoryData)
    }
  },
  updateStaffEmploymentHistories(
    state,
    staffEmploymentHistoryData: StaffEmploymentHistory
  ) {
    const index = state.staffEmploymentHistories.findIndex(
      (item) => item.branch_id === staffEmploymentHistoryData.branch_id
    )
    if (index !== -1)
      state.staffEmploymentHistories.splice(
        index,
        1,
        staffEmploymentHistoryData
      )
  },
  deleteStaffEmploymentHistories(state, id: number) {
    const index = state.staffEmploymentHistories.findIndex((item) =>
      item.history?.some((value) => value.id === id)
    )

    if (state.staffEmploymentHistories[index].history?.length === 1) {
      state.staffEmploymentHistories.splice(index, 1)
      return
    }
    const historyIndex = state.staffEmploymentHistories[
      index
    ].history!.findIndex((item) => item.id === id)
    if (index !== -1)
      state.staffEmploymentHistories[index].history!.splice(historyIndex, 1)
  },
}

export const actions: ActionTree<staffEmploymentHistoryState, RootState> = {
  async fetchStaffEmploymentHistory(
    { commit },

    params: {
      api: Repositories
      start_date: string
      end_date: string
      staff_id: number
      branch_ids: number[]
    }
  ) {
    const { employment_histories } =
      await params.api.staffEmploymentHistoryRepo.fetchStaffEmploymentHistory(
        params.staff_id,
        {
          start_date: params.start_date,
          end_date: params.end_date,
          branch_ids: params.branch_ids,
        }
      )
    commit('setStaffEmploymentHistories', employment_histories)
  },

  async createStaffEmploymentHistory(
    { commit },
    params: {
      api: Repositories
      staffEmploymentHistories: StaffEmploymentHistory
      staff_id: number
    }
  ) {
    const historyCount = params.staffEmploymentHistories.history!.length

    const staffEmploymentHistory =
      await params.api.staffEmploymentHistoryRepo.createStaffEmploymentHistory(
        params.staff_id,

        {
          branch_id: params.staffEmploymentHistories.branch_id,
          start_date:
            params.staffEmploymentHistories.history![historyCount - 1]
              .start_date,
          end_date:
            params.staffEmploymentHistories.history![historyCount - 1]
              .end_date!,
        }
      )

    commit('addNewStaffEmploymentHistory', {
      branch_id: staffEmploymentHistory.branch_id,
      history: [
        {
          id: staffEmploymentHistory.id,
          end_date: staffEmploymentHistory.end_date,
          start_date: staffEmploymentHistory.start_date,
        },
      ],
    })
  },

  async updateStaffEmploymentHistory(
    { commit },
    params: {
      api: Repositories
      employment_history: StaffEmploymentHistory
      id: number
    }
  ) {
    const staffEmploymentHistory =
      await params.api.staffEmploymentHistoryRepo.updateStaffEmploymentHistory({
        id: params.id,
        branch_id: params.employment_history.branch_id,
        start_date: params.employment_history.history?.find(
          (value) => value.id === params.id
        )?.start_date!,
        end_date: params.employment_history.history?.find(
          (value) => value.id === params.id
        )?.end_date!,
      })

    commit('updateStaffEmploymentHistories', {
      branch_id: staffEmploymentHistory.branch_id,
      history: [
        {
          id: staffEmploymentHistory.id,
          end_date: staffEmploymentHistory.end_date,
          start_date: staffEmploymentHistory.start_date,
        },
      ],
    })
  },

  async deleteStaffEmploymentHistory(
    { commit },
    params: {
      api: Repositories
      id: number
    }
  ) {
    await params.api.staffEmploymentHistoryRepo.deleteStaffEmploymentHistory(
      params.id
    )
    commit('deleteStaffEmploymentHistories', params.id)
  },
}
