import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { CRMBranchHistoryAnalyticsRepo, HistoryApiQueryParams } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): CRMBranchHistoryAnalyticsRepo => ({
  async fetchBranchHistoryForVisitingCustomer(params: HistoryApiQueryParams) {
    try {
      const res = await $axios.get(`/crm/branch_history/visiting_customers`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchBranchHistoryForNonVisitingCustomer(
    params: HistoryApiQueryParams
  ) {
    try {
      const res = await $axios.get(
        `/crm/branch_history/non_visiting_customers`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
