export default () => {
  const input_types = 'input, select, button, textarea'

  return $('body').on('keydown', input_types, function (e) {
    const enter_key = 13
    const tab_key = 9

    if (e.keyCode === tab_key || e.keyCode === enter_key) {
      const self = $(this)

      // some controls should react as designed when pressing enter
      if (
        e.keyCode === enter_key &&
        (self.prop('type') === 'submit' || self.prop('type') === 'textarea')
      ) {
        return true
      }

      const form = self.parents('form:eq(0)')

      // Sort by tab indexes if they exist
      const tab_index = parseInt(self.attr('tabindex'))
      let input_array = ''
      if (tab_index) {
        input_array = form
          .find('[tabindex]')
          .filter(':visible')
          .sort(function (a, b) {
            return (
              parseInt($(a).attr('tabindex')) - parseInt($(b).attr('tabindex'))
            )
          })
      } else {
        input_array = form.find(input_types).filter(':visible')
      }

      // reverse the direction if using shift
      const move_direction = e.shiftKey ? -1 : 1
      let new_index = input_array.index(this) + move_direction

      // wrap around the controls
      if (new_index === input_array.length) {
        new_index = 0
      } else if (new_index === -1) {
        new_index = input_array.length - 1
      }

      const move_to = input_array.eq(new_index)
      move_to.focus()
      move_to.select()
      return false
    }
  })
}
