export const INSURANCE_PRODUCT_TYPES = [
  { id: 'insurance', label: '保険' },
  { id: 'judo_insurance', label: '整骨（窓口）' },
  { id: 'hari_insurance', label: '鍼灸（窓口）' },
  { id: 'anma_insurance', label: 'あんま（窓口）' },
]
export const PRODUCT_TYPES = [
  { text: '保険', value: 'insurance' },
  { text: '整骨（窓口）', value: 'judo_insurance' },
  { text: '鍼灸（窓口）', value: 'hari_insurance' },
  { text: 'あんま（窓口）', value: 'anma_insurance' },
  {
    text: '自費',
    value: 'self_payment',
  },
  {
    text: '整骨（自費）',
    value: 'judo_self_payment',
  },
  {
    text: '鍼灸（自費）',
    value: 'hari_self_payment',
  },
  {
    text: 'あんま（自費）',
    value: 'anma_self_payment',
  },
  { text: '物販', value: 'product' },
  { text: 'チケット', value: 'tickets' },
  {
    text: 'クーポン',
    value: 'coupon',
  },
  { text: 'その他', value: 'other' },
]

export const getProductTypes = ($t: any) => {
  return [
    { text: $t('products.insurance'), value: 'insurance' },
    { text: $t('products.judo_insurance'), value: 'judo_insurance' },
    { text: $t('products.hari_insurance'), value: 'hari_insurance' },
    { text: $t('products.anma_insurance'), value: 'anma_insurance' },
    {
      text: $t('products.self_payment'),
      value: 'self_payment',
    },
    {
      text: $t('products.judo_self_payment'),
      value: 'judo_self_payment',
    },
    {
      text: $t('products.hari_self_payment'),
      value: 'hari_self_payment',
    },
    {
      text: $t('products.anma_self_payment'),
      value: 'anma_self_payment',
    },
    { text: $t('products.product'), value: 'product' },
    { text: $t('products.tickets'), value: 'tickets' },
    {
      text: $t('products.coupon'),
      value: 'coupon',
    },
    { text: $t('products.other'), value: 'other' },
  ]
}

export const getProductTypesTreeData = ($t: any) => {
  return [
    {
      id: 'insurance_parent',
      label: $t('products.insurance'),
      children: [
        { id: 'insurance', label: $t('products.insurance') },
        { id: 'judo_insurance', label: $t('products.judo_insurance') },
        { id: 'hari_insurance', label: $t('products.hari_insurance') },
        { id: 'anma_insurance', label: $t('products.anma_insurance') },
      ],
    },
    {
      id: 'self_payment_parent',
      label: $t('products.self_payment'),
      children: [
        { id: 'self_payment', label: $t('products.self_payment') },
        { id: 'judo_self_payment', label: $t('products.judo_self_payment') },
        { id: 'hari_self_payment', label: $t('products.hari_self_payment') },
        { id: 'anma_self_payment', label: $t('products.anma_self_payment') },
      ],
    },

    { label: $t('products.product'), id: 'product' },

    { label: $t('products.other'), id: 'other' },
  ]
}

export const getInsuranceProductTypes = ($t: any) => {
  return [
    { id: 'insurance', label: $t('products.insurance') },
    { id: 'judo_insurance', label: $t('products.judo_insurance') },
    { id: 'hari_insurance', label: $t('products.hari_insurance') },
    { id: 'anma_insurance', label: $t('products.anma_insurance') },
  ]
}
