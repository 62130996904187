



































































import Vue from 'vue'

export default Vue.extend({
  props: {
    fillColor: {
      type: String,
      required: false,
      default: 'rgba(247, 249, 253, 1)',
    },
    textWeight: {
      type: String,
      required: false,
      default: '',
    },
    displayWithOutText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
