import { MutationTree, GetterTree, ActionTree } from 'vuex'
import dayjs from 'dayjs'
import { Shift, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  shifts: [] as Shift[],
  shiftPagination: {} as Pagination,
  shiftDate: new Date(),
  viewType: 'day',
  loadingList: {
    isShiftsLoading: false,
  },
  // For calling confirmCopy method in WeeklyShiftManagement component
  callConfirmCopyMethod: false,
})

export type ShiftState = ReturnType<typeof state>

export const mutations: MutationTree<ShiftState> = {
  setShifts(state, shifts: Shift[]) {
    state.shifts = shifts
  },
  setShiftPagination(state, pagination: Pagination) {
    state.shiftPagination = pagination
  },
  addNewShift(state, shiftData: Shift) {
    state.shifts.unshift(shiftData)
  },
  updateShift(state, shiftData: Shift) {
    const index = state.shifts.findIndex((item) => item.id === shiftData.id)
    if (index !== -1) state.shifts.splice(index, 1, shiftData)
  },
  deleteShift(state, id: number) {
    const index = state.shifts.findIndex((item) => item.id === id)
    if (index !== -1) state.shifts.splice(index, 1)
  },
  updatePagination(state) {
    state.shiftPagination.total_entries += 1
    state.shiftPagination.total_pages = Math.ceil(
      state.shiftPagination.total_entries / 10
    )
  },
  setShiftDate(state, date: Date | string) {
    state.shiftDate = dayjs(date).toDate()
  },
  setViewType(state, type: string) {
    state.viewType = type
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  setCallConfirmCopyMethod(state, called: boolean) {
    state.callConfirmCopyMethod = called
  },
}

export const getters: GetterTree<ShiftState, RootState> = {
  getShiftWithId: (state) => (id: Number) => {
    const index = state.shifts.findIndex((item) => item.id === id)
    return state.shifts[index]
  },
}

export const actions: ActionTree<ShiftState, RootState> = {
  async fetchShifts(
    { commit, rootState },
    params: { api: Repositories; page: number; per: number }
  ) {
    commit('setLoading', { key: 'isShiftsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { shifts, pagination } = await params.api.shiftRepo.fetchShifts(
      branchId,
      {
        page: params.page,
        per: params.per,
      }
    )
    commit('setShifts', shifts)
    commit('setShiftPagination', pagination)
    commit('setLoading', { key: 'isShiftsLoading', loading: false })
  },

  async createShift(
    { commit, rootState },
    params: { api: Repositories; shiftData: Shift }
  ) {
    commit('setLoading', { key: 'isShiftsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch

    const shift = await params.api.shiftRepo.createShift(
      branchId,
      params.shiftData
    )
    commit('addNewShift', shift)
    commit('updatePagination')
    commit('setLoading', { key: 'isShiftsLoading', loading: false })
  },

  async updateShift(
    { commit, rootState },
    params: { api: Repositories; shiftData: Shift }
  ) {
    commit('setLoading', { key: 'isShiftsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch

    const shift = await params.api.shiftRepo.updateShift(
      branchId,
      params.shiftData
    )
    commit('updateShift', shift)
    commit('setLoading', { key: 'isShiftsLoading', loading: false })
  },
  async hideShift(
    { commit, rootState },
    params: { api: Repositories; shiftData: Shift }
  ) {
    commit('setLoading', { key: 'isShiftsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const data = Object.assign({}, params.shiftData)
    data.hidden = true
    const shift = await params.api.shiftRepo.updateShift(branchId, data)
    commit('deleteShift', shift.id)
    commit('setLoading', { key: 'isShiftsLoading', loading: false })
  },
  async deleteShift(
    { commit, rootState },
    params: { api: Repositories; shiftId: number }
  ) {
    commit('setLoading', { key: 'isShiftsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch

    await params.api.shiftRepo.deleteShift(branchId, params.shiftId)
    commit('deleteShift', params.shiftId)
    commit('setLoading', { key: 'isShiftsLoading', loading: false })
  },
}
