























import Vue from 'vue'
import { KarteFormResource } from '~/types'
export default Vue.extend({
  data() {
    return {
      activeFormId: Number(this.$route.params.formId),
    }
  },
  computed: {
    activeKarteFormResource(): KarteFormResource {
      const data = this.$store.state.karteFormResource.karteFormResource
      let activeKarteForm = {} as KarteFormResource
      data.forEach((k: KarteFormResource) => {
        if (k.id === this.activeFormId) {
          activeKarteForm = k
        }
      })
      return activeKarteForm
    },

    isMobile(): Boolean {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    },
  },
})
