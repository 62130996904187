import { MutationTree } from 'vuex'

export const state = () => ({
  selectedBranchId: -1,
  selectedBranchName: '',
  selectedSideBarId: undefined as string | undefined,
  selectedTopBarMode: 'branches' as 'branches' | 'crm',
  isBranchSettingsSideBarOpen: true,
})

export type BreakState = ReturnType<typeof state>

export const mutations: MutationTree<BreakState> = {
  setSelectedBranchId(state, selectedBranchId: number) {
    state.selectedBranchId = selectedBranchId
  },
  setBranchSettingsSideBar(state, isBranchSettingsSideBarOpen: boolean) {
    state.isBranchSettingsSideBarOpen = isBranchSettingsSideBarOpen
  },
  setSelectedBranchName(state, selectedBranchName: string) {
    state.selectedBranchName = selectedBranchName
  },
  setSelectedSideBarId(state, selectedSideBarId: string) {
    state.selectedSideBarId = selectedSideBarId
  },
  setSelectedTopBarMode(state, selectedTopBarMode: 'branches' | 'crm') {
    state.selectedTopBarMode = selectedTopBarMode
  },
}
