import { MutationTree, ActionTree } from 'vuex'
import {
  StaffAnalytic,
  Repositories,
  CommonAnalytics,
  ReservationAnalytics,
  TagAnalytic,
  PaymentAnalytics,
  CustomerCountAnalytics,
  SubReferral,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  staffAnalytics: [] as StaffAnalytic[],
  commonAnalytics: {
    sales: {
      current: { total_value: 0, paid_value: 0 },
      previous: { total_value: 0, paid_value: 0 },
    },
    customer_count: {
      current: { value: 0 },
      previous: { value: 0 },
    },
    start_date: '',
    end_date: '',
    previous_start_date: '',
    previous_end_date: '',
  } as CommonAnalytics,
  customerCountAnalytics: {
    web_reservation_count: {
      value: 0,
      cohort: [] as { name: string; value: number; referrals: SubReferral[] }[],
    },
    cancel_reservation_count: {
      value: 0,
      cohort: [] as { name: string; value: number }[],
    },
    start_date: '',
    end_date: '',
  } as CustomerCountAnalytics,
  paymentAnalytics: {
    status: {
      paid: {
        current: { sales: 0, customer_count: 0 },
        previous: { sales: 0, customer_count: 0 },
      },
      pending: {
        current: { sales: 0, customer_count: 0 },
        previous: { sales: 0, customer_count: 0 },
      },
    },
    type: {
      cash: {
        current: { sales: 0, customer_count: 0 },
        previous: { sales: 0, customer_count: 0 },
      },
      card: {
        current: { sales: 0, customer_count: 0 },
        previous: { sales: 0, customer_count: 0 },
      },
    },
    reservation_count: {
      current: { value: 0 },
      previous: { value: 0 },
    },
    start_date: '',
    end_date: '',
    previous_start_date: '',
    previous_end_date: '',
  } as PaymentAnalytics,
  reservationTableAnalytics: {
    total: {
      card_sales: 0,
      cash_sales: 0,
      count: 0,
      customer_count: 0,
      sales: 0,
      visit_count: 0,
    },
  } as ReservationAnalytics,
  reservationChartAnalytics: {
    total: {
      card_sales: 0,
      cash_sales: 0,
      count: 0,
      customer_count: 0,
      sales: 0,
      visit_count: 0,
    },
  } as ReservationAnalytics,
  reservationTagAnalytics: [] as TagAnalytic[],
  customerTagAnalytics: [] as TagAnalytic[],
  loadingList: {
    isReservationAnalyticsLoading: false,
  },
  errorList: {
    reservationAnalyticsError: false,
  },
})

export type AnalyticState = ReturnType<typeof state>

export const mutations: MutationTree<AnalyticState> = {
  setStaffAnalytics(state, staffAnalytics: StaffAnalytic[]) {
    state.staffAnalytics = staffAnalytics
  },
  setCommonAnalytics(state, commonAnalytics: CommonAnalytics) {
    state.commonAnalytics = commonAnalytics
  },
  setCustomerCountAnalytics(
    state,
    customerCountAnalytics: CustomerCountAnalytics
  ) {
    state.customerCountAnalytics = customerCountAnalytics
  },
  setPaymentAnalytics(state, paymentAnalytics: PaymentAnalytics) {
    state.paymentAnalytics = paymentAnalytics
  },
  setReservationTableAnalytics(
    state,
    reservationAnalytics: ReservationAnalytics
  ) {
    state.reservationTableAnalytics = reservationAnalytics
  },
  setReservationChartAnalytics(
    state,
    reservationAnalytics: ReservationAnalytics
  ) {
    state.reservationChartAnalytics = reservationAnalytics
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  setReservationTagAnalytics(state, reservationTagAnalytics: TagAnalytic[]) {
    state.reservationTagAnalytics = reservationTagAnalytics
  },
  setCustomerTagAnalytics(state, customerTagAnalytics: TagAnalytic[]) {
    state.customerTagAnalytics = customerTagAnalytics
  },
  setErrors(state, value: { key: string; error: boolean }) {
    state.errorList[value.key] = value.error
  },
}

export const actions: ActionTree<AnalyticState, RootState> = {
  async fetchStaffAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      start_date: string
      end_date: string
    }
  ) {
    commit('setLoading', true)
    const branchId = (rootState as any).auth.user.activeBranch
    const { staff } = await params.api.analyticsRepo.fetchStaffAnalytics({
      page: params.page,
      per: params.per,
      branch_id: branchId,
      start_date: params.start_date,
      end_date: params.end_date,
    })
    commit('setStaffAnalytics', staff)
    commit('setLoading', false)
  },

  async getCommonAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      branchIds?: number[]
    }
  ) {
    const branchId = Number((rootState as any).auth.user.activeBranch)
    commit('setLoading', true)
    const { common } = await params.api.analyticsRepo.fetchCommonAnalytics({
      branch_ids: params.branchIds || [branchId],
      start_date: params.start_date,
      end_date: params.end_date,
    })
    commit('setCommonAnalytics', common)
    commit('setLoading', false)
  },
  async getCustomerCountAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      branchIds?: number[]
    }
  ) {
    const branchId = Number((rootState as any).auth.user.activeBranch)
    commit('setLoading', true)
    const customerCountAnalytics =
      await params.api.analyticsRepo.fetchCustomerCountAnalytics({
        branch_ids: params.branchIds || [branchId],
        start_date: params.start_date,
        end_date: params.end_date,
      })
    commit('setCustomerCountAnalytics', customerCountAnalytics)
    commit('setLoading', false)
  },
  async getPaymentAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      branchIds?: number[]
    }
  ) {
    const branchId = Number((rootState as any).auth.user.activeBranch)
    commit('setLoading', true)
    const { payments } = await params.api.analyticsRepo.fetchPaymentAnalytics({
      branch_ids: params.branchIds || [branchId],
      start_date: params.start_date,
      end_date: params.end_date,
    })
    commit('setPaymentAnalytics', payments)
    commit('setLoading', false)
  },
  async getReservationAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      branch_ids?: number[]
      category_ids?: number[]
      staff_ids?: number[]
      tag_ids?: number[]
      customer_ids?: number[]
      start_date: string
      end_date: string
      session?: string
      status?: string
      updateTableAnalytics?: boolean
      updateChartAnalytics?: boolean
      account_status?: string[]
      employment_status?: string[]
    }
  ) {
    try {
      const branchIds = params?.branch_ids
        ? params?.branch_ids
        : (rootState as any).hqCrm.selectedBranchIds
      commit('setLoading', {
        key: 'isReservationAnalyticsLoading',
        loading: true,
      })
      const { reservations } =
        await params.api.analyticsRepo.fetchReservationAnalytics({
          branch_ids: branchIds,
          category_ids: params.category_ids,
          staff_ids: params.staff_ids,
          tag_ids: params.tag_ids,
          customer_ids: params.customer_ids,
          start_date: params.start_date,
          end_date: params.end_date,
          status: params.status,
          session: params.session ?? 'all',
          account_status: params.account_status,
          employment_status: params.employment_status,
        })
      if (params.updateTableAnalytics)
        commit('setReservationTableAnalytics', reservations)
      if (params.updateChartAnalytics)
        commit('setReservationChartAnalytics', reservations)
      commit('setLoading', {
        key: 'isReservationAnalyticsLoading',
        loading: false,
      })
    } catch (error) {
      commit('setErrors', { key: 'reservationAnalyticsError', value: true })
    }
  },
  async getReservationTagAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
    }
  ) {
    const branchId = Number((rootState as any).auth.user.activeBranch)
    const { reservation_tags } =
      await params.api.analyticsRepo.fetchReservationTagAnalytics({
        branch_id: branchId,
        start_date: params.start_date,
        end_date: params.end_date,
      })
    commit('setReservationTagAnalytics', reservation_tags)
  },
  async getCustomerTagAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
    }
  ) {
    const branchId = Number((rootState as any).auth.user.activeBranch)
    const { customer_tags } =
      await params.api.analyticsRepo.fetchCustomerTagAnalytics({
        branch_id: branchId,
        start_date: params.start_date,
        end_date: params.end_date,
      })
    commit('setCustomerTagAnalytics', customer_tags)
  },
}
