import { MutationTree, ActionTree } from 'vuex'
import {
  Staff,
  Repositories,
  Assessment,
  AssessmentImage,
  AssessmentImageIds,
  Pagination,
  KarteAssessmentImages,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  assessment: {} as Assessment,
  isEditing: false,
  allStaff: [] as Staff[],
  staffPagination: {} as Pagination,
  assessments: [] as Assessment[],
  filteredAssessments: [] as Assessment[],
  assessmentPagination: {} as Pagination,
  karteAssessmentImages: [] as any,
  filterSearchQuery: null,
})

export type AssessmentState = ReturnType<typeof state>

export const mutations: MutationTree<AssessmentState> = {
  setAssessment(state, assessment: Assessment) {
    state.assessment = assessment
  },
  setIsEditing(state, isEditing: boolean) {
    state.isEditing = isEditing
  },
  setAllStaff(state, allStaff: Staff[]) {
    state.allStaff = allStaff
  },
  setStaffPagination(state, pagination: Pagination) {
    state.staffPagination = pagination
  },
  setAssessments(state, assessment: Assessment[]) {
    state.assessments = assessment
  },
  setFilteredAssessments(state, assessment: Assessment[]) {
    state.filteredAssessments = assessment
  },
  setAssessmentPagination(state, pagination: Pagination) {
    state.assessmentPagination = pagination
  },
  deleteAssessment(state, id: number) {
    const index = state.assessments.findIndex((item) => item.id === id)
    if (index !== -1) state.assessments.splice(index, 1)
  },
  setAssessmentImages(state, assessmentImage: KarteAssessmentImages[]) {
    state.karteAssessmentImages = assessmentImage
  },
  setAddAssessmentImages(state, AssessmentImagesData: AssessmentImage) {
    state.karteAssessmentImages.unshift(AssessmentImagesData)
  },
  setDeleteAssessmentImages(state, AssessmentImagesData: AssessmentImage) {
    state.karteAssessmentImages = AssessmentImagesData
  },
  setFilterSearchQuery(state, filterSearchQuery) {
    state.filterSearchQuery = filterSearchQuery
  },
  setAddNewAssessment(state, assessmentData: Assessment) {
    state.assessments.unshift(assessmentData)
  },
  setEditAssessmentData(state, assessmentData: Assessment) {
    const index = state.assessments.findIndex(
      (item) => item.id === assessmentData.id
    )
    if (index !== -1) state.assessments.splice(index, 1, assessmentData)
  },
}

export const actions: ActionTree<AssessmentState, RootState> = {
  async getAssessment(
    { commit, rootState },
    params: { api: Repositories; customerId: number; branchId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { assessments } = await params.api.assessmentRepo.fetchAssessment(
      branchId,
      params.customerId
    )
    commit('setAssessment', assessments)
  },

  async updateAssessment(
    { commit, rootState },
    params: {
      api: Repositories
      assessmentData: Assessment
      customerId: number
      assessmentId: number
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const assessment = await params.api.assessmentRepo.updateAssessment(
      params.assessmentData,
      branchId,
      params.customerId,
      params.assessmentId
    )
    commit('setAssessment', assessment)
  },
  async fetchAssessments(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      customerId: number
      branchId: number
      title: string
      assessmentId: number
      treatmentTypes: string[]
    }
  ) {
    const branchId = params.branchId
      ? params.branchId
      : (rootState as any).auth.user.activeBranch
    const { assessments, pagination } =
      await params.api.assessmentRepo.fetchAssessments(
        branchId,
        params.customerId,
        {
          page: params.page,
          per: params.per,
          title: params.title,
          id: params.assessmentId,
          treatment_types: params.treatmentTypes,
        }
      )
    if (!params.assessmentId) commit('setAssessments', assessments)
    commit('setFilteredAssessments', assessments)
    commit('setAssessmentPagination', pagination)
  },
  async fetchBranchStaff(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      is_available_online: boolean
      account_status?: string[]
      employment_status?: string[]
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const { staff, pagination } = await params.api.staffRepo.fetchBranchStaff(
      branchId,
      {
        page: params.page,
        per: params.per,
        field_therapist: true,
        is_available_online: params.is_available_online,
        account_status: params.account_status,
        employment_status: params.employment_status,
      }
    )
    commit('setAllStaff', staff)
  },
  async createAssessment(
    { commit, rootState },
    params: {
      api: Repositories
      customerId: number
      assessmentData: Assessment
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const assessment = await params.api.assessmentRepo.createAssessment(
      branchId,
      params.customerId,
      params.assessmentData
    )
    commit('setAddNewAssessment', assessment)
  },
  async editAssessment(
    { commit, rootState },
    params: {
      api: Repositories
      customerId: number
      assessmentData: Assessment
      assessmentId: number
      branchId: number
    }
  ) {
    const branchId = params.branchId
      ? params.branchId
      : (rootState as any).auth.user.activeBranch
    const assessment = await params.api.assessmentRepo.editAssessment(
      branchId,
      params.customerId,
      params.assessmentData,
      params.assessmentId
    )
    commit('setEditAssessmentData', assessment)
  },
  async deleteAssessment(
    { commit, rootState },
    params: { api: Repositories; customerId: number; assessmentId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api.assessmentRepo.deleteAssessment(
      branchId,
      params.customerId,
      params.assessmentId
    )
    commit('deleteAssessment', params.assessmentId)
  },
  async fetchAssessmentImages(
    { commit, rootState },
    params: { api: Repositories }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { karte_assessment_images } =
      await params.api.assessmentRepo.fetchAssessmentImages(branchId)
    commit('setAssessmentImages', karte_assessment_images)
  },
  async addAssessmentImages(
    { commit, rootState },
    params: { api: Repositories; assessmentImagesIds: AssessmentImageIds }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const karte_assessment_images =
      await params.api.assessmentRepo.addAssessmentImages(
        branchId,
        params.assessmentImagesIds
      )
    commit('setAssessmentImages', karte_assessment_images)
  },
  async deleteAssessmentImages(
    { commit, rootState },
    params: { api: Repositories; assessmentImagesIds: AssessmentImageIds }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const karte_assessment_images =
      await params.api.assessmentRepo.addAssessmentImages(
        branchId,
        params.assessmentImagesIds
      )
    commit('setDeleteAssessmentImages', karte_assessment_images)
  },
}
