import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { StoreCreditRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): StoreCreditRepo => ({
  async fetchStoreCredits(
    branchId: number,
    customerId: number,
    params: {
      page?: number
      per?: number
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/${customerId}/coupon_transactions`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
