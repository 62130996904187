






















import Vue from 'vue'
export default Vue.extend({
  data() {
    return {}
  },
  methods: {
    handelRedirectPage() {
      this.$router.push(this.localePath('/dashboard'))
    },
  },
})
