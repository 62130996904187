import { MutationTree, ActionTree } from 'vuex'
import dayjs from 'dayjs'
import { RootState } from '~/store'
import {
  Repositories,
  CrmStaffResource,
  ProductCategory,
  CrmStaffTotal,
  Product,
  BaseSort,
  CrmStaffTotalParams,
  Coupon,
  CrmTicketOption,
} from '~/types'

const initCrmStaffResource = {
  staffs: [],
  total: {
    current_customer_count: 0,
    comparison_customer_count: 0,
    current_sales: 0,
    comparison_sales: 0,
  },
  start_date: dayjs().startOf('month').format('YYYY-MM-DD'),
  end_date: dayjs().endOf('month').format('YYYY-MM-DD'),
  comparison_start_date: dayjs().startOf('month').format('YYYY-MM-DD'),
  comparison_end_date: dayjs().endOf('month').format('YYYY-MM-DD'),
  pagination: {
    page: 1,
    per: 10,
    total_pages: 1,
    total_entries: 0,
  },
} as CrmStaffResource

const initCrmStaffTotal = {
  total: {
    current_customer_count: 0,
    comparison_customer_count: 0,
    current_sales: 0,
    current_insurance: 0,
    current_sales_target: 0,
    comparison_sales: 0,
    comparison_insurance: 0,
    comparison_sales_target: 0,
  },
  start_date: '',
  end_date: '',
  comparison_start_date: '',
  comparison_end_date: '',
} as CrmStaffTotal
export const state = () => ({
  crmStaffResource: initCrmStaffResource as CrmStaffResource,
  allCrmStaffResources: initCrmStaffResource as CrmStaffResource,
  crmStaffTotalResource: initCrmStaffTotal,
  staffSort: {
    sortBy: [],
    sortDesc: [],
  } as BaseSort,
  startEndDate: {
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
  },
  dataOption: 'monthly',
  selectProductIds: [] as number[],
  selectedBranchIds: [] as number[],
  selectedStaffIds: [] as number[],
  productCategories: [] as ProductCategory[],
  products: [] as Product[],
  ticketOption: {} as CrmTicketOption,
  coupons: [] as Coupon[],
  viewType: 'graph',
  pageTitle: 'スタッフリスト',
  crmCompareStartEndDate: {
    startDate: '',
    endDate: '',
  },
  isCustomerDrillDownOpen: false,
  loadingList: {
    isStaffsLoading: false,
  },
  selectedRepeatRateType: 'withrawal',
  tabRefresh: false,
})

export type CRMStaffAnalyticState = ReturnType<typeof state>

export const mutations: MutationTree<CRMStaffAnalyticState> = {
  setCrmStaffResource(state, crmStaffResource: CrmStaffResource) {
    state.crmStaffResource = crmStaffResource
  },
  setAllCrmStaffResources(state, crmStaffResource: CrmStaffResource) {
    state.allCrmStaffResources = crmStaffResource
  },
  setCrmStaffTotalResource(state, crmStaffTotal: CrmStaffTotal) {
    state.crmStaffTotalResource = crmStaffTotal
  },
  setIsCustomerDrillDownOpen(state, isCustomerDrillDownOpen: boolean) {
    state.isCustomerDrillDownOpen = isCustomerDrillDownOpen
  },
  initCrmStaffResource(state) {
    state.crmStaffResource = initCrmStaffResource
  },
  initCrmStaffTotalResource(state) {
    state.crmStaffTotalResource = initCrmStaffTotal
  },
  setStartEndDate(
    state,
    dashboardStartEndDate: { startDate: string; endDate: string }
  ) {
    state.startEndDate = dashboardStartEndDate
  },
  setCrmCompareStartEndDate(
    state,
    crmCompareStartEndDate: { startDate: string; endDate: string }
  ) {
    state.crmCompareStartEndDate = crmCompareStartEndDate
  },
  setViewType(state, viewType: string) {
    state.viewType = viewType
  },
  setSelectedBranchIds(state, branchIds: number[]) {
    state.selectedBranchIds = branchIds
  },
  setSelectedStaffIds(state, selectedStaffIds: number[]) {
    if (selectedStaffIds.length === 0 && state.selectedStaffIds.length === 0) {
      return
    }
    state.selectedStaffIds = selectedStaffIds
  },
  setSelectProductIds(state, productIds: number[]) {
    state.selectProductIds = productIds
  },
  setSelectedRepeatRateType(state, selectedRepeatRateType: string) {
    state.selectedRepeatRateType = selectedRepeatRateType
  },
  setProductCategories(state, productCategories: ProductCategory[]) {
    state.productCategories = productCategories
  },
  setProducts(state, products: Product[]) {
    state.products = products
  },
  setCoupons(state, coupons: Coupon[]) {
    state.coupons = coupons
  },
  setTickets(state, ticketOption: CrmTicketOption) {
    state.ticketOption = ticketOption
  },
  setPageTitle(state, pageTitle: string) {
    state.pageTitle = pageTitle
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  setStaffSort(state, value: BaseSort) {
    state.staffSort = value
  },
  setDataOption(state, value: string) {
    state.dataOption = value
  },
  setTabRefresh(state, tabRefresh: boolean) {
    state.tabRefresh = tabRefresh
  },
}

export const actions: ActionTree<CRMStaffAnalyticState, RootState> = {
  async getCrmStaffResource(
    { commit },
    params: {
      api: Repositories
      branch_ids: number[]
      start_date: string
      end_date: string
      comparison_start_date?: string
      comparison_end_date?: string
      product_ids?: number[]
      product_types?: string[]
      customer_types?: string[]
      sort?: string[]
      staff_name?: string
      page?: number
      per?: number
    }
  ) {
    if (!params.branch_ids || params.branch_ids.length === 0) {
      commit('initCrmStaffResource')
      return
    }
    commit('setLoading', { key: 'isStaffsLoading', loading: true })
    const crmStaffResource =
      await params.api.crmAnalyticsRepo.fetchCrmStaffResource({
        branch_ids: params.branch_ids || [],
        start_date: params.start_date,
        end_date: params.end_date,
        comparison_start_date: params.comparison_start_date,
        comparison_end_date: params.comparison_end_date,
        product_ids: params.product_ids,
        product_types: params.product_types,
        customer_types: params.customer_types,
        sort: params.sort,
        staff_name: params.staff_name,
        page: params.page,
        per: params.per,
      })
    commit('setCrmStaffResource', crmStaffResource)
    commit('setLoading', { key: 'isStaffsLoading', loading: false })
  },
  async getAllCrmStaffResource(
    { commit },
    params: {
      api: Repositories
      branch_ids: number[]
      start_date: string
      end_date: string
    }
  ) {
    const { pagination } =
      await params.api.crmAnalyticsRepo.fetchCrmStaffResource({
        branch_ids: params.branch_ids || [],
        start_date: params.start_date,
        end_date: params.end_date,
        page: 1,
        per: 1,
      })
    const crmStaffResource =
      await params.api.crmAnalyticsRepo.fetchCrmStaffResource({
        branch_ids: params.branch_ids || [],
        start_date: params.start_date,
        end_date: params.end_date,
        page: pagination.page,
        per: pagination.total_entries,
      })
    commit('setAllCrmStaffResources', crmStaffResource)
  },
  async getCrmStaffTotalResource(
    { commit },
    params: { api: Repositories } & CrmStaffTotalParams
  ) {
    if (!params.branch_ids || params.branch_ids.length === 0) {
      commit('initCrmStaffTotalResource')
      return
    }
    commit('setLoading', { key: 'isStaffsLoading', loading: true })
    const crmStaffTotalResource =
      await params.api.crmAnalyticsRepo.fetchCrmStaffTotalResource({
        branch_ids: params.branch_ids || [],
        start_date: params.start_date,
        end_date: params.end_date,
        comparison_start_date: params.comparison_start_date,
        comparison_end_date: params.comparison_end_date,
        product_ids: params.product_ids,
        product_types: params.product_types,
        customer_types: params.customer_types,
        sort: params.sort,
        staff_name: params.staff_name,
        staff_code: params.staff_code,
      })
    commit('setCrmStaffTotalResource', crmStaffTotalResource)
    commit('setLoading', { key: 'isStaffsLoading', loading: false })
  },
  async getAllProducts(
    { commit },
    params: {
      api: Repositories
      includeInactive: boolean
    }
  ) {
    const { pagination } = await params.api.productRepo.fetchProducts({
      page: 1,
      per: 1,
      include_inactive: params.includeInactive,
    })
    if (pagination === 0) return commit('setProducts', [])
    const { products } = await params.api.productRepo.fetchProducts({
      page: 1,
      per: pagination.total_entries,
      include_inactive: params.includeInactive,
    })
    commit('setProducts', products)
  },
  async getAllProductCategories(
    { commit },
    params: { api: Repositories; page: number; per: number }
  ) {
    const { pagination, product_categories } =
      await params.api.productCategoryRepo.fetchProductCategories({
        page: params.page,
        per: params.per,
      })
    if (pagination.total_entries > 40) {
      const product =
        await params.api.productCategoryRepo.fetchProductCategories({
          page: pagination.total_entries,
          per: params.per,
        })
      commit('setProductCategories', product.product_categories)
    } else {
      commit('setProductCategories', product_categories)
    }
  },
}
