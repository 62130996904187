

























import Vue from 'vue'
import { User } from '~/models/User'
import { SquareDevice } from '~/types'
import { isMatchUrl } from '~/helpers/urlUtils'
export default Vue.extend({
  name: 'HQCrm',
  middleware: ['authenticated', 'accessCheck'],
  data() {
    return {
      fixed: false,
      right: true,
      rightDrawer: true,
      title: 'Threease pro',
      isCrmSidebarOpen: true,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
    devices(): SquareDevice[] {
      return this.$store.state.square.devices
    },
    isHQBranch(): boolean {
      return isMatchUrl(this.$route.path, 'hq')
    },
  },
  channels: {
    NotificationChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (this.checkReceivedData(data, 'device_code')) {
          this.handleDevicePairing(
            (data as { device_code: SquareDevice }).device_code
          )
        }
      },
      disconnected() {},
    },
  },
  mounted() {
    this.subscribeToNotifications()
  },
  methods: {
    checkReceivedData(receivedData: any, key: string): boolean {
      return Object.keys(receivedData).includes(key)
    },
    handleDevicePairing(devices: SquareDevice) {
      if (!devices.device_id) {
        this.$store.commit('square/setPairingStatus', 'error')
        return
      }
      const localDevices = this.devices

      const devicesIndex = localDevices.findIndex(
        (device) => devices.device_id === device.device_id
      )
      if (devicesIndex === -1) {
        this.$store.commit('square/setPairingStatus', 'error')
        return
      }
      this.$store.commit('square/updateDeviceContent', {
        value: devices.status,
        index: devicesIndex,
        key: 'status',
      })
      this.$store.commit('square/setPairingStatus', 'success')
    },
    branchChanged() {
      this.$cable.unsubscribe('NotificationChannel')
      this.subscribeToNotifications()
    },

    subscribeToNotifications() {
      if (this.$config.includeMockServer) {
        return
      }
      const { accessToken, uid, client, activeBranch } = this.$store.state.auth
        .user as User

      ;(this.$cable as any).connection.connect(
        `${this.$config.websocketUrl}?token=${encodeURIComponent(
          accessToken
        )}&uid=${encodeURIComponent(uid)}&client=${encodeURIComponent(client)}`
      )
      this.$cable.subscribe({
        channel: 'NotificationChannel',
        branch_id: activeBranch,
      })
    },
  },
})
