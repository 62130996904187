import * as amplitude from '@amplitude/analytics-browser'

export default (context, inject) => {
  const apiKey = process.env.AMPLITUDE_API_KEY
  amplitude.init(apiKey)

  const trackEvent = (eventName, properties = {}) => {
    const eventProperties = {
      ...properties,
      page: context.route.path,
      userId: context.store.state.auth.user.uid,
      branchId: context.store.state.auth.user.activeBranch,
    }

    amplitude.logEvent(eventName, eventProperties)
  }

  const setUserId = (id) => {
    amplitude.setUserId(id && id.length === 0 ? '' : `staff_${id}`)
  }

  inject('amplitude', {
    instance: amplitude,
    trackEvent,
    setUserId,
  })
}
