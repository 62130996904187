var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticStyle:{"background":"#fff","overflow":"visible","z-index":"21","top":"38px"},attrs:{"fixed":"","app":"","permanent":"","touchless":"","height":_vm.height,"width":_vm.width,"mini-variant-width":"55","mini-variant":_vm.miniVariant}},[_c('div',{staticClass:"d-flex fill-height flex-row"},[_c('div',{staticClass:"sidebar",staticStyle:{"width":"55px"},attrs:{"dark":""}},[_c('v-list',{staticClass:"sidebar-box pa-0 mt-3",staticStyle:{"min-width":"55px"},attrs:{"dark":""}},_vm._l((_vm.sideBarItems),function(sideBarItem,index){return _c('div',{key:index},[(sideBarItem.show)?_c('SideBarItem',{staticStyle:{"color":"rgba(0, 74, 219, 1)"},attrs:{"to":_vm.localePath(sideBarItem.link),"text":sideBarItem.text,"link":"","color":sideBarItem.link === _vm.selectedIcon
                  ? 'rgba(110, 158, 255, 1)'
                  : 'rgba(164, 171, 184, 0.7);'},on:{"clicked":function($event){return _vm.setSelectedIcon(sideBarItem.link)}}},[(sideBarItem.link === '/dashboard')?_c('HomeIcon',{staticClass:"the-sidebar-icon",attrs:{"fill-color":_vm.selectedIcon === '/dashboard'
                    ? 'rgba(110, 158, 255, 1)'
                    : 'rgba(164, 171, 184, 0.7)'}}):_vm._e(),_vm._v(" "),(sideBarItem.link === '/shifts')?_c('ShiftIcon',{staticClass:"the-sidebar-icon",attrs:{"fill-color":_vm.selectedIcon === '/shifts'
                    ? 'rgba(110, 158, 255, 1)'
                    : 'rgba(164, 171, 184, 0.7)'}}):_vm._e(),_vm._v(" "),(sideBarItem.link === '/reservations')?_c('ReservationIcon',{staticClass:"the-sidebar-icon",attrs:{"icon-color":_vm.selectedIcon === '/reservations'
                    ? 'rgba(110, 158, 255, 1)'
                    : 'rgba(164, 171, 184, 0.7)'}}):_vm._e(),_vm._v(" "),(sideBarItem.link === '/accounting')?_c('PaymentIcon',{staticClass:"the-sidebar-icon",attrs:{"fill-color":_vm.selectedIcon === '/accounting'
                    ? 'rgba(110, 158, 255, 1)'
                    : 'rgba(164, 171, 184, 0.7)'}}):_vm._e(),_vm._v(" "),(sideBarItem.link === '/customers')?_c('CustomerIcon',{staticClass:"the-sidebar-icon",attrs:{"fill-color":_vm.selectedIcon === '/customers'
                    ? 'rgba(110, 158, 255, 1)'
                    : 'rgba(164, 171, 184, 0.7)'}}):_vm._e(),_vm._v(" "),(sideBarItem.link === '/message/mails')?_c('MessageIcon',{staticClass:"the-sidebar-icon",attrs:{"fill-color":_vm.selectedIcon === '/message'
                    ? 'rgba(110, 158, 255, 1)'
                    : 'rgba(164, 171, 184, 0.7)'}}):_vm._e(),_vm._v(" "),(sideBarItem.link === '/cms')?_c('CmsIcon',{staticClass:"the-sidebar-icon",attrs:{"fill-color":_vm.selectedIcon === '/cms'
                    ? 'rgba(110, 158, 255, 1)'
                    : 'rgba(164, 171, 184, 0.7)'}}):_vm._e(),_vm._v(" "),(sideBarItem.link === '/hq/crm/hq_crm')?_c('CrmIcon',{staticClass:"the-sidebar-icon",attrs:{"fill-color":_vm.isMatchUrl(_vm.$route.path, '/hq/crm')
                    ? 'rgba(110, 158, 255, 1)'
                    : 'rgba(164, 171, 184, 0.7)'}}):_vm._e(),_vm._v(" "),(sideBarItem.link === '/clinic_setting')?_c('ClinicSettingIcon',{staticClass:"the-sidebar-icon",attrs:{"fill-color":_vm.selectedIcon === '/clinic_setting'
                    ? 'rgba(110, 158, 255, 1)'
                    : 'rgba(164, 171, 184, 0.7)'}}):_vm._e()],1):_vm._e()],1)}),0)],1),_vm._v(" "),_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }