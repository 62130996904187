import { MutationTree, ActionTree } from 'vuex'
import { Transaction, TransactionTotal } from '~/types/transactions'
import { Pagination, Repositories } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  transactions: [] as Transaction[],
  transactionsPagination: {} as Pagination,
  transactionsTotal: {} as TransactionTotal,
  loadingList: {
    isTransactionsLoading: false,
  },
})
export type TransactionState = ReturnType<typeof state>

export const mutations: MutationTree<TransactionState> = {
  setTransactions(state, transactions: Transaction[]) {
    state.transactions = transactions
  },
  setTransactionsPagination(state, pagination: Pagination) {
    state.transactionsPagination = pagination
  },
  setTransactionsTotal(state, total: TransactionTotal) {
    state.transactionsTotal = total
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<TransactionState, RootState> = {
  async fetchTransactions(
    { commit, rootState },
    params: {
      api: Repositories
      startDate: string
      endDate: string
      status: string
      session: string
      page: number
      per: number
    }
  ) {
    commit('setLoading', { key: 'isTransactionsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { transactions, pagination, total } =
      await params.api.transactionsRepo.fetchTransactions(branchId, {
        start_date: params.startDate,
        end_date: params.endDate,
        status: params.status,
        session: params.session,
        page: params.page,
        per: params.per,
      })
    commit('setTransactions', transactions || [])
    commit('setTransactionsTotal', total || {})
    commit('setTransactionsPagination', pagination)
    commit('setLoading', { key: 'isTransactionsLoading', loading: false })
  },
}
