import { render, staticRenderFns } from "./TheBranchSelect.vue?vue&type=template&id=387318f7&scoped=true&"
import script from "./TheBranchSelect.vue?vue&type=script&lang=ts&"
export * from "./TheBranchSelect.vue?vue&type=script&lang=ts&"
import style0 from "./TheBranchSelect.vue?vue&type=style&index=0&id=387318f7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387318f7",
  null
  
)

/* custom blocks */
import block0 from "~/i18n/hq.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc207136660%2Fsrc%2Fthreease_pro%2Fcomponents%2Fshared%2FBranchSelect%2FTheBranchSelect.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHQBranchSelect: require('/codebuild/output/src207136660/src/threease_pro/components/shared/BranchSelect/TheHQBranchSelect.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VIcon,VMenu})
