




































































import Vue from 'vue'
import { NotificationCategory, NotificationData } from '~/types'

export default Vue.extend({
  data() {
    return {
      showNotificationModal: false,
      popupNotificationsData: [],
    }
  },
  async fetch() {
    await this.getPopupNotifications()
  },
  computed: {
    popupNotifications() {
      return this.$store.state.notifications.popupNotifications
    },
  },
  watch: {
    popupNotifications() {
      if (this.popupNotifications.length > 0) {
        this.popupNotificationsData = this.popupNotifications[0]
        this.showNotificationModal = true
      }
    },
  },
  methods: {
    getCategoryString(categorySlug: NotificationCategory) {
      if (categorySlug === 'product_update') {
        return {
          text: this.$t('notifications.productUpdate'),
          backgroundColor: '#f4e9ff',
          textColor: '#9b51e0',
        }
      } else {
        return {
          text: this.$t('notifications.news'),
          backgroundColor: '#fcead9',
          textColor: '#f2994a',
        }
      }
    },
    async getPopupNotifications() {
      try {
        await this.$store.dispatch('notifications/fetchPopupNotifications', {
          api: this.$api,
          acknowledged_popup: false,
          is_popup: true,
        })
      } catch (error) {
        this.$notifier.showMessage({
          content: (error as Error).message,
          color: 'error',
        })
      }
    },
    async acceptNotiticaton(popupNotificationsData: NotificationData) {
      try {
        await this.$store.dispatch('notifications/acknowledgeNotification', {
          api: this.$api,
          notificationData: {
            ...popupNotificationsData,
            acknowledged_popup: true,
            acknowledged: true,
          },
        })
        this.showNotificationModal = false
      } catch (error) {
        this.$notifier.showMessage({
          content: (error as Error).message,
          color: 'error',
        })
      }
    },
  },
})
