import { MutationTree, ActionTree } from 'vuex'
import { Assignment, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'
import { handleBatchErrors } from '~/helpers/processBatchErrors'

export const state = () => ({
  assignments: [] as Assignment[],
  assignmentPagination: {} as Pagination,
  assignmentErrors: [] as {
    assignmentId: number | undefined
    message: string
  }[],
})

export type ShiftAssignmentState = ReturnType<typeof state>

export const mutations: MutationTree<ShiftAssignmentState> = {
  setAssignments(state, assignments: Assignment[]) {
    state.assignments = assignments
  },
  setAssignmentPagination(state, pagination: Pagination) {
    state.assignmentPagination = pagination
  },
  addNewAssignment(state, assignmentData: Assignment) {
    state.assignments.push(assignmentData)
  },
  updateAssignment(state, assignmentData: Assignment) {
    const index = state.assignments.findIndex(
      (item) => item.id === assignmentData.id
    )
    if (index !== -1) {
      state.assignments.splice(index, 1, assignmentData)
    }
  },
  deleteAssignment(state, id: number) {
    const index = state.assignments.findIndex((item) => item.id === id)
    if (index !== -1) state.assignments.splice(index, 1)
  },
  addAssignmentError(
    state,
    errorData: { assignmentId: number | undefined; message: string }
  ) {
    state.assignmentErrors.push(errorData)
  },
  deleteAssignmentError(state, assignmentId: number) {
    const index = state.assignmentErrors.findIndex(
      (error) => error.assignmentId === assignmentId
    )
    if (index !== -1) state.assignmentErrors.splice(index, 1)
  },
}

export const actions: ActionTree<ShiftAssignmentState, RootState> = {
  async fetchAssignments(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      start_date: string
      end_date?: string
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    let result = (await params.api.shiftAssignmentRepo.fetchAssignments(
      branchId,
      {
        page: params.page,
        per: params.per,
        start_date: params.start_date,
        end_date: params.end_date || params.start_date,
      }
    )) as { shift_assignments: Assignment[]; pagination: Pagination }
    if (result.pagination.total_entries > params.per) {
      params.per = result.pagination.total_entries
      result = (await params.api.shiftAssignmentRepo.fetchAssignments(
        branchId,
        {
          page: params.page,
          per: params.per,
          start_date: params.start_date,
          end_date: params.end_date || params.start_date,
        }
      )) as { shift_assignments: Assignment[]; pagination: Pagination }
    }
    commit('setAssignments', result.shift_assignments)
    commit('setAssignmentPagination', result.pagination)
  },
  async createAssignment(
    { commit, rootState },
    params: { api: Repositories; assignmentData: Assignment }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const assignment = await params.api.shiftAssignmentRepo.createAssignment(
      branchId,
      params.assignmentData
    )
    commit('addNewAssignment', assignment)
  },
  async batchAssign(
    { state, commit, rootState },
    params: { api: Repositories; assignmentData: Assignment[] }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { shift_assignments, errors } =
      await params.api.shiftAssignmentRepo.batchAssign(
        branchId,
        params.assignmentData
      )
    shift_assignments.forEach((assignment: Assignment) => {
      const index = state.assignments.findIndex(
        (storeAssignment) => storeAssignment.id === assignment.id
      )
      if (index !== -1) {
        commit('updateAssignment', assignment)
      } else {
        commit('addNewAssignment', assignment)
      }
    })
    handleBatchErrors(errors)
  },
  async updateAssignment(
    { commit, rootState },
    params: { api: Repositories; assignmentData: Assignment }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const assignment = await params.api.shiftAssignmentRepo.updateAssignment(
      branchId,
      params.assignmentData
    )
    commit('updateAssignment', assignment)
  },
  async deleteAssignment(
    { commit, rootState },
    params: { api: Repositories; assignmentId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api.shiftAssignmentRepo.deleteAssignment(
      branchId,
      params.assignmentId
    )
    commit('deleteAssignment', params.assignmentId)
  },
}
