import { render, staticRenderFns } from "./TheSideBar.vue?vue&type=template&id=aa386eca&scoped=true&"
import script from "./TheSideBar.vue?vue&type=script&lang=ts&"
export * from "./TheSideBar.vue?vue&type=script&lang=ts&"
import style0 from "./TheSideBar.vue?vue&type=style&index=0&id=aa386eca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa386eca",
  null
  
)

/* custom blocks */
import block0 from "~/i18n/sidebar.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc207136660%2Fsrc%2Fthreease_pro%2Fcomponents%2Fshared%2FSideBar%2FTheSideBar.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeIcon: require('/codebuild/output/src207136660/src/threease_pro/components/shared/icons/SideBar/HomeIcon.vue').default,ShiftIcon: require('/codebuild/output/src207136660/src/threease_pro/components/shared/icons/SideBar/ShiftIcon.vue').default,ReservationIcon: require('/codebuild/output/src207136660/src/threease_pro/components/shared/icons/SideBar/ReservationIcon.vue').default,PaymentIcon: require('/codebuild/output/src207136660/src/threease_pro/components/shared/icons/SideBar/PaymentIcon.vue').default,CustomerIcon: require('/codebuild/output/src207136660/src/threease_pro/components/shared/icons/SideBar/CustomerIcon.vue').default,MessageIcon: require('/codebuild/output/src207136660/src/threease_pro/components/shared/icons/SideBar/MessageIcon.vue').default,CmsIcon: require('/codebuild/output/src207136660/src/threease_pro/components/shared/icons/SideBar/CmsIcon.vue').default,CrmIcon: require('/codebuild/output/src207136660/src/threease_pro/components/shared/icons/SideBar/CrmIcon.vue').default,ClinicSettingIcon: require('/codebuild/output/src207136660/src/threease_pro/components/shared/icons/SideBar/ClinicSettingIcon.vue').default,SideBarItem: require('/codebuild/output/src207136660/src/threease_pro/components/shared/SideBar/SideBarItem.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VList,VNavigationDrawer})
