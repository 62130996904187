import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { KarteEntry, KarteEntryRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): KarteEntryRepo => ({
  async fetchEntries(
    branchId: number,
    customerId: number,
    params: {
      form_id: number
      customer_id: number
      start_date?: string
      end_date?: string
      page?: number
      per?: number
      assessment_name?: string
      assessment_id?: number
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/${customerId}/karte_entries`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createEntry(
    branchId: number,
    customerId: number,
    karte: KarteEntry,
    params: { karte_access_token?: string; branch_access_token?: string }
  ) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/customers/${customerId}/karte_entries`,
        {
          karte_entry: { ...karte },
          karte_access_token: params.karte_access_token,
          branch_access_token: params.branch_access_token,
        }
      )
      if (res.status === 201) {
        return res.data.karte_entry
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async showEntry(
    branchId: number,
    customerId: number,
    karteId: number,
    params: { karte_access_token?: string; branch_access_token?: string }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/${customerId}/karte_entries/${karteId}`,
        { params }
      )
      if (res.status === 200) {
        return res.data.karte_entry
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateEntry(branchId: number, customerId: number, karte: KarteEntry) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/customers/${customerId}/karte_entries/${karte.id}`,
        {
          karte_entry: karte,
        }
      )
      if (res.status === 200) {
        return res.data.karte_entry
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
