import { BatchError } from '~/helpers/errors'
import processError from '~/helpers/processError'

export const processBatchErrors = {
  processBatchErrorObject,
  handleBatchErrors,
  getErrorMessage,
}

export function processBatchErrorObject(errorObject: any, $sentry: any) {
  if (errorObject.response && errorObject.response.status < 500) {
    handleBatchErrors(errorObject.response)
  } else {
    processError(errorObject, $sentry)
  }
}

export function handleBatchErrors(errors: any) {
  if (errors) {
    const errorMessage = getErrorMessage(errors)
    if (errorMessage) {
      throw new BatchError(errorMessage)
    }
  }
}

export function getErrorMessage(errors: any): string {
  let errorMessage = ''
  if (!errorMessage && errors) {
    if (Array.isArray(errors) && errors.length !== 0) {
      for (const error of errors) {
        if (errorMessage) {
          break
        }
        if (typeof error === 'string') {
          return error
        } else {
          errorMessage = getErrorMessage(error)
        }
      }
    } else if (typeof errors === 'object') {
      if (Object.keys(errors).length !== 0) {
        for (const key in errors) {
          if (errorMessage) {
            break
          }
          if (errors[key] && typeof errors[key] === 'string') {
            return errors[key]
          } else {
            errorMessage = getErrorMessage(errors[key])
          }
        }
      }
    }
  }
  return errorMessage
}
