import { MutationTree, ActionTree } from 'vuex'

import {
  CustomKarteQuestion,
  Repositories,
  CustomKarteEditPosition,
} from '~/types'

import { RootState } from '~/store'

export const state = () => ({
  questions: [] as (CustomKarteQuestion & { position?: 'new' | number })[],
  editId: -1 as CustomKarteEditPosition,
  isLoading: false,
})

export type KarteQuestionsState = ReturnType<typeof state>
export const mutations: MutationTree<KarteQuestionsState> = {
  setQuestions(state, questions: CustomKarteQuestion[]) {
    state.questions = questions
  },
  setEditId(state, id: number) {
    state.editId = id
  },
  setLoading(state, loading: boolean) {
    state.isLoading = loading
  },
  updateNewQuestion(state, question: CustomKarteQuestion) {
    state.questions = state.questions.map((item) => {
      return item.id === 'new' ? question : item
    })
  },
  addQuestion(state, question: CustomKarteQuestion) {
    state.questions.push(question)
  },
  updateQuestion(state, question: CustomKarteQuestion) {
    state.questions = state.questions.map((item) => {
      return item.id === question.id ? question : item
    })
  },
  deleteQuestion(state, id: number) {
    state.questions = state.questions.filter((item) => item.id !== id)
  },
}

export const actions: ActionTree<KarteQuestionsState, RootState> = {
  async fetchKarteQuestions(
    { commit, rootState },
    params: {
      api: Repositories
      section_ids: []
      branchId: number
      formId: number
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { questions } =
      await params.api.karteQuestionsRepo.fetchKarteQuestions(
        params.branchId || branchId,
        params.formId,
        {
          section_ids: params.section_ids,
        }
      )
    commit('setQuestions', questions)
  },
  async createKarteQuestion(
    { commit, rootState },
    params: {
      api: Repositories
      position: number
      branchId: number
      formId: number
    } & CustomKarteQuestion
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { question } =
      await params.api.karteQuestionsRepo.createKarteQuestion(
        params.branchId || branchId,
        params.formId,
        {
          section_id: params.section_id,
          statement: params.statement,
          description: params.description,
          options: params.options,
          type: params.type,
          image_url: params.image_url,
          image_id: params.image_id,
          required: params.required,
          hidden: params.hidden,
        }
      )
    commit('setEditId', question.id)
    commit('updateNewQuestion', question)
  },
  async updateKarteQuestion(
    { commit, rootState },
    params: {
      api: Repositories
      id: number
      branchId: number
      formId: number
    } & CustomKarteQuestion
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const response = await params.api.karteQuestionsRepo.updateKarteQuestion(
      params.branchId || branchId,
      params.formId,
      {
        id: params.id,
        section_id: params.section_id,
        statement: params.statement,
        description: params.description,
        options: params.options,
        type: params.type,
        image_url: params.image_url,
        image_id: params.image_id,
        required: params.required,
        position: params.position,
        hidden: params.hidden,
      }
    )
    if (response) {
      commit('updateQuestion', response.question)
    }
  },
  async deleteKarteQuestion(
    { commit },
    params: {
      api: Repositories
      id: number
      branchId: number
      formId: number
    }
  ) {
    await params.api.karteQuestionsRepo.deleteKarteQuestion(
      params.branchId,
      params.id,
      params.formId
    )
    commit('deleteQuestion', params.id)
  },
}
