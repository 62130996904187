import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import {
  HistoryApiQueryParams,
  HQHistoryTabStatus,
  Pagination,
  Repositories,
  TotalHistory,
} from '~/types'

export const state = () => ({
  productHistories: [] as History[],
  totalProductHistories: [] as TotalHistory[],
  productPagination: {} as Pagination,
  page: 1,
  categoryPage: 1,
  per: 8,
  categoryPer: 8,
  selectProductCategoryIds: [] as number[],
  tabStatus: 'sales' as HQHistoryTabStatus,
  totalBranchHistories: [] as TotalHistory[],
  currentBranchHistories: {} as { key: number; value: History[] },
  salesToggleType: 'products' as 'products' | 'category',
  productCategoriesHistories: [] as History[],
  totalProductCategoriesHistories: [] as TotalHistory[],
  productCategoriesPagination: {} as Pagination,
  currentProductPagination: {} as { key: number; value: Pagination[] },
  currentProductHistories: {} as { key: number; value: History[] },
})

export type HQRanking = ReturnType<typeof state>

export const mutations: MutationTree<HQRanking> = {
  setProductHistories(state, productHistories: History[]) {
    state.productHistories = productHistories
  },
  setTabStatus(state, status: HQHistoryTabStatus) {
    state.tabStatus = status
  },
  setTotalProductHistories(state, totalProductHistories: TotalHistory[]) {
    state.totalProductHistories = totalProductHistories
  },
  setProductPagination(state, productPagination: Pagination) {
    state.productPagination = productPagination
  },
  resetPage(state) {
    state.page = 1
  },
  updatePage(state, page: number) {
    state.page = page
  },
  updatePer(state, per: number) {
    state.per = per
  },
  updatePagination(state) {
    state.productPagination.total_entries += 1
    state.productPagination.total_pages = Math.ceil(
      state.productPagination.total_entries / 10
    )
  },
  setTotalBranchHistories(state, totalProductHistories: TotalHistory[]) {
    state.totalProductHistories = totalProductHistories
  },
  updateCurrentBranchHistories(
    state,
    params: { id: number; productHistory: History[] }
  ) {
    state.currentBranchHistories = {
      ...state.currentBranchHistories,
      ...{ [params.id]: params.productHistory },
    }
  },
  resetCurrentBranchHistories(state) {
    state.currentBranchHistories = {} as {
      key: number
      value: History[]
    }
  },
  setSalesToggleType(state, salesToggleType: 'products' | 'category') {
    state.salesToggleType = salesToggleType
  },
  setSelectProductCategoriesIds(state, ids: number[]) {
    state.selectProductCategoryIds = ids
  },
  setProductCategoriesHistories(state, productCategoriesHistories: History[]) {
    state.productCategoriesHistories = productCategoriesHistories
  },
  setTotalProductCategoriesHistories(
    state,
    totalProductCategoriesHistories: TotalHistory[]
  ) {
    state.totalProductCategoriesHistories = totalProductCategoriesHistories
  },
  setProductCategoriesPagination(
    state,
    productCategoriesPagination: Pagination
  ) {
    state.productCategoriesPagination = productCategoriesPagination
  },
  resetCategoryPage(state) {
    state.categoryPage = 1
  },
  updateCategoryPage(state, categoryPage: number) {
    state.categoryPage = categoryPage
  },
  updateCategoryPer(state, categoryPer: number) {
    state.categoryPer = categoryPer
  },
  updateCategoryPagination(state) {
    state.productPagination.total_entries += 1
    state.productPagination.total_pages = Math.ceil(
      state.productPagination.total_entries / 10
    )
  },
  resetCurrentProductCategoriesHistories(state) {
    state.currentProductHistories = {} as {
      key: number
      value: History[]
    }
  },
  resetCurrentProductCategoriesPagination(state) {
    state.currentProductPagination = {} as {
      key: number
      value: Pagination[]
    }
  },
}

export const actions: ActionTree<HQRanking, RootState> = {
  async getProductHistories(
    { commit },
    params: {
      api: Repositories
    } & HistoryApiQueryParams
  ) {
    const { products, total_history, pagination } =
      await params.api.crmProductHistoryAnalyticsRepo.fetchProductHistory({
        branch_ids: params.branch_ids,
        start_date: params.start_date,
        end_date: params.end_date,
        group_by: params.group_by,
        product_types: params.product_types,
        customer_types: params.customer_types,
        product_ids: params.product_ids,
        page: params.page,
        per: params.per,
      })

    commit('setProductHistories', products)
    commit('setTotalProductHistories', total_history)
    commit('setProductPagination', pagination)
  },
  async getBranchHistories(
    { commit },
    params: {
      api: Repositories
    } & HistoryApiQueryParams
  ) {
    const { branches, total_history } =
      await params.api.crmBranchHistoryAnalyticsRepo.fetchBranchHistoryForVisitingCustomer(
        {
          branch_ids: params.branch_ids,
          start_date: params.start_date,
          end_date: params.end_date,
          group_by: params.group_by,
          product_types: params.product_types,
          customer_types: params.customer_types,
          product_ids: params.product_ids,
          product_category_ids: params.product_category_ids,
        }
      )
    if (params.product_ids) {
      commit('updateCurrentBranchHistories', {
        id: params.product_ids,
        productHistory: branches,
      })
      commit('setTotalBranchHistories', total_history)
    } else {
      commit('updateCurrentBranchHistories', {
        id: params.product_category_ids,
        productHistory: branches,
      })
      commit('setTotalBranchHistories', total_history)
    }
  },
  async getProductCategoriesHistories(
    { commit },
    params: {
      api: Repositories
    } & HistoryApiQueryParams
  ) {
    const { product_categories, total_history, pagination } =
      await params.api.crmProductHistoryAnalyticsRepo.fetchProductCategoryHistory(
        {
          branch_ids: params.branch_ids,
          start_date: params.start_date,
          end_date: params.end_date,
          page: params.page,
          per: params.per,
          product_category_ids: params.product_category_ids,
        }
      )
    commit('setProductCategoriesHistories', product_categories)
    commit('setTotalProductCategoriesHistories', total_history)
    commit('setProductCategoriesPagination', pagination)
  },
}
