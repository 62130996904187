import { NuxtAxiosInstance } from '@nuxtjs/axios'

import processError from '~/helpers/processError'
import sortChecker from '~/helpers/sortChecker'
import { Item, ItemRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): ItemRepo => ({
  async fetchItems(
    branchId: number,
    params?: {
      page?: number
      per?: number
      staff_id?: number
      sort?: string[]
      is_available_online?: boolean
      product_types: string[]
      for_new_customers?: boolean
    }
  ) {
    const newParams = sortChecker(params)
    if (params?.staff_id === -1) delete params?.staff_id
    try {
      const res = await $axios.get(`therapists/branches/${branchId}/items`, {
        params: newParams,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createItem(branchId: number, itemData: Item) {
    try {
      const res = await $axios.post(`therapists/branches/${branchId}/items`, {
        item: itemData,
      })
      if (res.status === 201) {
        return res.data.item
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateItem(branchId: number, item: Item) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/items/${item.id}`,
        {
          item,
        }
      )
      if (res.status === 200) {
        return res.data.item
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteItem(branchId: number, itemId: number) {
    try {
      await $axios.delete(`therapists/branches/${branchId}/items/${itemId}`)
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async setAllWebReservationItemsOrder(
    branchId: number,
    item: { id: number; new_web_position: number }
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/items/${item.id}/shuffle`,
        {
          item,
        }
      )
      if (res.status === 200) {
        return res.data.item
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
