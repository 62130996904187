import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Break, BreakRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): BreakRepo => ({
  async fetchBreaks(
    branchId: number,
    params?: {
      page?: number
      per?: number
      start_date?: string
      end_date?: string
      break?: string
    }
  ) {
    try {
      const res = await $axios.get(`therapists/branches/${branchId}/breaks`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createBreak(branchId: number, breakData: Break) {
    try {
      const res = await $axios.post(`therapists/branches/${branchId}/breaks`, {
        break: breakData,
      })
      if (res.status === 201) {
        return res.data.break
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateBreak(branchId: number, breakData: Break) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/breaks/${breakData.id}`,
        {
          break: breakData,
        }
      )
      if (res.status === 200) {
        return res.data.break
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteBreak(branchId: number, breakId: number) {
    try {
      await $axios.delete(`therapists/branches/${branchId}/breaks/${breakId}`)
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
