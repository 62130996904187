/* eslint-disable @typescript-eslint/no-unused-vars */

import { MutationTree, GetterTree, ActionTree } from 'vuex'
import dayjs from 'dayjs'
import {
  Repositories,
  Reservation,
  ReservationItem,
  ReservationBlocking,
  ReservationTicket,
  ReservationCoupon,
  Customer,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  date: new Date(),
  reservationData: {} as Reservation,
  followUpReservationData: {} as Reservation,
  stashReservationData: {} as Reservation,
  showEditForm: false,
  showFollowUp: true,
  reservationItems: [] as ReservationItem[],
  defaultItem: {} as ReservationItem,
  unitErrors: [],
  itemErrors: [],
  ticketErrors: [],
  couponErrors: [],
  unitId: -1,
  reservationDate: '',
  lastReservations: [] as Reservation[],
  copiedReservation: {} as Reservation,
  reservationCopied: false,
  reservationCustomerData: {} as Customer,
  isReservationPasted: false,
  reservationTickets: [] as ReservationTicket[],
  reservationCoupons: [] as ReservationCoupon[],
  isCustomerNew: false,
  itemsLoading: false,
})

export type ReservationFormState = ReturnType<typeof state>

export const mutations: MutationTree<ReservationFormState> = {
  setDate(state, date: Date) {
    state.date = date
  },
  setReservationData(state, reservationData: Reservation) {
    reservationData.staff_id = reservationData.staff_id || -1
    state.reservationData = reservationData
  },
  setCopiedReservation(state, reservationCopied: boolean) {
    state.reservationCopied = reservationCopied
  },
  setItemsLoading(state, itemsLoading: boolean) {
    state.itemsLoading = itemsLoading
  },
  setIsCustomerNew(state, isCustomerNew: boolean) {
    state.isCustomerNew = isCustomerNew
  },
  setReservationCustomerData(state, reservationCustomerData: Customer) {
    state.reservationCustomerData = reservationCustomerData
  },

  setFollowUpReservationData(state, reservationData: Reservation) {
    const {
      cancel_reason,
      cancel_reason_description,
      followup_reservation_id,
      ...data
    } = reservationData
    state.followUpReservationData = data
  },
  setReservationDate(state, date: string) {
    state.reservationDate = date
  },
  updateReservationData(state, { key, value }) {
    state.reservationData[key] = value
  },
  removeReservationData(state, key: string) {
    delete state.reservationData[key]
  },
  // Reservation Items
  setReservationItems(state, items: ReservationItem[]) {
    state.reservationItems = items
  },
  updateReservationItem(state, itemData: ReservationItem) {
    const index = state.reservationItems.findIndex(
      (item) => item.item_id === itemData.item_id
    )
    if (index !== -1) state.reservationItems.splice(index, 1, itemData)
  },
  removeReservationItem(state, itemData: ReservationItem) {
    const index = state.reservationItems.findIndex(
      (item) => item.item_id === itemData.item_id
    )
    if (index !== -1) state.reservationItems.splice(index, 1)
  },

  setReservationUnit(state, unitId: number) {
    state.unitId = unitId
  },
  setReservationUnitErrors(state, errors) {
    state.unitErrors = errors
  },
  setReservationItemsErrors(state, errors) {
    state.itemErrors = errors
  },
  setReservationTicketErrors(state, errors) {
    state.ticketErrors = errors
  },
  setReservationCouponErrors(state, errors) {
    state.couponErrors = errors
  },
  setShowEditForm(state, payload: boolean) {
    state.showEditForm = payload
  },
  setShowFollowUp(state, payload: boolean) {
    state.showFollowUp = payload
  },
  setLastReservations(state, reservation: Reservation[]) {
    state.lastReservations = reservation
  },
  setCopyReservation(state, reservation?: Reservation) {
    const reservationData = JSON.parse(
      JSON.stringify(reservation || state.reservationData)
    )
    const {
      cancel_reason,
      cancel_reason_description,
      followup_reservation_id,
      ...data
    } = reservationData
    state.copiedReservation = data
  },
  removeCopyReservation(state) {
    state.copiedReservation = {} as Reservation
    state.isReservationPasted = false
  },
  setIsReservationPasted(state, val: boolean) {
    state.isReservationPasted = val
  },
  resetReservationErrors(state) {
    state.itemErrors = []
    state.unitErrors = []
    state.ticketErrors = []
    state.couponErrors = []
  },
  pasteReservation(state) {
    const duration = dayjs(state.copiedReservation.end_time).diff(
      state.copiedReservation.start_time,
      'minutes'
    )
    const newReservation = Object.assign({}, state.copiedReservation, {
      start_time: state.reservationData.start_time,
      end_time: dayjs(state.reservationData.start_time)
        .add(duration, 'minutes')
        .format(),
      payment_status: '',
      staff_id: state.reservationData.staff_id,
      staff_name: state.reservationData.staff_name,
      status: state.reservationData.status,
      reservation_tickets: [],
      reservation_coupons: [],
    })
    delete newReservation.id
    state.reservationData = JSON.parse(JSON.stringify(newReservation))
    state.isReservationPasted = true
  },
  setInitialReservationData(state) {
    if (!state.reservationData.reservation_type) {
      state.reservationData.reservation_type = 'reservation'
    }
    if (!state.reservationData.status) {
      state.reservationData.status = 'confirmed'
    }
    if (!state.reservationData.staff_id) {
      state.reservationData.staff_id = -1
    }
    state.reservationData.start_time = dayjs(state.date).format()
    state.reservationData.end_time = state.reservationData.end_time
      ? dayjs(state.reservationData.end_time).format()
      : dayjs(state.reservationData.start_time).add(1, 'hours').format()
    if (
      dayjs(state.reservationData.start_time).isBefore(new Date()) &&
      !state.showEditForm
    ) {
      state.reservationData.status = 'completed'
    }
  },
  setStashReservationData(state, reservation: Reservation) {
    state.stashReservationData = Object.assign({}, reservation)
  },
  setReservationTickets(state, tickets: ReservationTicket[]) {
    state.reservationTickets = tickets
    state.reservationData.reservation_tickets = tickets
  },
  setReservationCoupons(state, coupons: ReservationCoupon[]) {
    state.reservationCoupons = coupons
    state.reservationData.reservation_coupons = coupons
  },
}

export const getters: GetterTree<ReservationFormState, RootState> = {}

export const actions: ActionTree<ReservationFormState, RootState> = {
  async submitReservationForm(
    { commit, state, dispatch },
    params: { api: Repositories }
  ) {
    if (state.reservationDate === '')
      commit(
        'setReservationDate',
        this.$dayjs(state.reservationData.start_time).format('YYYY-MM-DD')
      )
    const { staff_id, start_time, end_time, color, status } =
      state.reservationData
    const startTimeHHmm = dayjs(start_time).format('HH:mm')
    const endTimeHHmm = dayjs(end_time).format('HH:mm')
    const formatStartTime = dayjs(
      `${state.reservationDate} ${startTimeHHmm}`
    ).format()
    const formatEndTime = dayjs(
      `${state.reservationDate} ${endTimeHHmm}`
    ).format()
    const reservation_items =
      state.reservationData.reservation_type === 'temporary'
        ? []
        : status === 'cancelled'
        ? state.reservationItems.map((reservationItem) => ({
            ...reservationItem,
            ticket_packs: [],
          }))
        : state.reservationItems

    const data = {
      ...state.reservationData,
      staff_id: staff_id === -1 ? undefined : staff_id,
      color: color || null,
      start_time: formatStartTime,
      end_time: formatEndTime,
      reservation_items,
      unit_id:
        state.unitId !== -1 && status !== 'cancelled'
          ? state.unitId
          : undefined,
    }
    if (state.showEditForm) {
      await dispatch(
        'reservation/updateReservation',
        {
          api: params.api,
          reservationData: data,
        },
        { root: true }
      )
    } else {
      await dispatch(
        'reservation/createReservation',
        {
          api: params.api,
          reservationData: data,
          isFollowup: Object.keys(state.followUpReservationData).length !== 0,
        },
        { root: true }
      )

      if (state.followUpReservationData.id) {
        commit('setStashReservationData', {
          ...state.stashReservationData,
          followup_reservation_id: state.followUpReservationData.id,
        })
        commit('setFollowUpReservationData', {})
      }
    }
    if (state.copiedReservation.id !== state.reservationData.id) {
      commit('removeCopyReservation')
    }
  },
  async submitReservationBlockingForm(
    { dispatch },
    params: { api: Repositories; unitBreakData: ReservationBlocking }
  ) {
    const data = Object.assign({}, params.unitBreakData)
    const start_time = dayjs(data.start_time).format()
    const end_time = dayjs(data.end_time).format()
    const unit_id = data.unit_id === -1 ? undefined : data.unit_id
    if (data.id) {
      await dispatch(
        'reservation/updateReservation',
        {
          api: params.api,
          reservationData: {
            id: data.id,
            status: 'confirmed',
            reservation_type: 'blocking',
            reservation_tickets: [],
            reservation_coupons: [],
            reservation_items: [],
            staff_id: data.staff_id,
            start_time,
            end_time,
            unit_id,
          },
        },
        { root: true }
      )
    } else {
      await dispatch(
        'reservation/createReservation',
        {
          api: params.api,
          reservationData: {
            status: 'confirmed',
            reservation_type: 'blocking',
            reservation_tickets: [],
            reservation_coupons: [],
            reservation_items: [],
            staff_id: data.staff_id,
            start_time,
            end_time,
            unit_id,
          },
        },
        { root: true }
      )
    }
  },
  async fetchUpdatedReservation(
    { commit, state, rootState },
    params: { api: Repositories }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const reservationId = state.reservationData.id
    if (reservationId) {
      const updatedReservation =
        await params.api.reservationRepo.showReservation(
          branchId,
          reservationId
        )
      commit('updateReservationData', {
        key: 'payment_status',
        value: updatedReservation.payment_status,
      })
      commit('reservationForm/setReservationData', updatedReservation, {
        root: true,
      })
      commit('reservation/updateReservation', updatedReservation, {
        root: true,
      })
      commit('setReservationCoupons', updatedReservation.reservation_coupons)
    }
  },
}
