import { MutationTree, ActionTree } from 'vuex'
import {
  Pagination,
  PaginationQueryParams,
  Repositories,
  Ticket,
  TicketOption,
  TicketPack,
  TicketPackQueryParams,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  filters: {
    optionIds: [] as number[],
  },
  tickets: [] as Ticket[],
  ticketStats: {} as any,
  ticketOptions: [] as TicketOption[],
  ticketPagination: {} as Pagination,
  ticketPacks: [] as TicketPack[],
  ticketPackPagination: {} as Pagination,
  ticketPackStats: {} as any,
  customerTicketPacks: [] as TicketPack[],
  customerTicketPackPagination: {} as Pagination,
  loadingList: {
    isTicketsLoading: false,
    isCustomerTicketPacksLoading: false,
  },
})

export type TicketState = ReturnType<typeof state>

export const mutations: MutationTree<TicketState> = {
  setFilterOptionIds(state, optionIds) {
    state.filters.optionIds = optionIds
  },
  clearFilterOptionIds(state) {
    state.filters.optionIds = []
  },
  setTickets(state, tickets: Ticket[]) {
    state.tickets = tickets
  },
  setTicketOptions(state, ticketOptions: TicketOption[]) {
    state.ticketOptions = ticketOptions
  },
  setTicketStats(state, stats: any) {
    state.ticketStats = stats
  },
  setTicketPagination(state, pagination: Pagination) {
    state.ticketPagination = pagination
  },
  setCustomerTicketPacks(state, ticketPacks: TicketPack[]) {
    state.customerTicketPacks = ticketPacks
  },
  setCustomerTicketPackPagination(state, pagination: Pagination) {
    state.customerTicketPackPagination = pagination
  },
  addNewTicket(state, ticketData: Ticket) {
    state.tickets.unshift(ticketData)
  },
  updateTicket(state, ticketData: Ticket) {
    const index = state.tickets.findIndex((item) => item.id === ticketData.id)
    if (index !== -1) state.tickets.splice(index, 1, ticketData)
  },
  updatePagination(state) {
    state.ticketPagination.total_entries += 1
    state.ticketPagination.total_pages = Math.ceil(
      state.ticketPagination.total_entries / 10
    )
  },
  setTicketPacks(state, ticketPacks: TicketPack[]) {
    state.ticketPacks = ticketPacks
  },
  setTicketPackPagination(state, pagination: Pagination) {
    state.ticketPackPagination = pagination
  },
  setTicketPackStats(state, stats: any) {
    state.ticketPackStats = stats
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<TicketState, RootState> = {
  popFilterOptionIds({ commit, state }) {
    const optionIds = state.filters.optionIds
    commit('clearFilterOptionIds')
    return optionIds
  },
  async fetchTickets(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
      branchIds: number[]
      start_date?: string
      end_date?: string
    }
  ) {
    commit('setLoading', { key: 'isTicketsLoading', loading: true })
    const { tickets, meta } = await params.api.ticketRepo.fetchTickets({
      page: params.page,
      per: params.per,
      branch_ids: params.branchIds,
      start_date: params?.start_date,
      end_date: params?.end_date,
    })
    const { pagination, stats } = meta
    commit('setTickets', tickets)
    commit('setTicketPagination', pagination)
    commit('setTicketStats', stats)
    commit('setLoading', { key: 'isTicketsLoading', loading: false })
  },
  async createTicket(
    { commit },
    params: { api: Repositories; ticketData: Ticket }
  ) {
    commit('setLoading', { key: 'isTicketsLoading', loading: true })
    const ticket = await params.api.ticketRepo.createTicket(params.ticketData)
    commit('addNewTicket', ticket)
    commit('updatePagination')
    commit('setLoading', { key: 'isTicketsLoading', loading: false })
  },
  async updateTicket(
    { commit },
    params: { api: Repositories; ticketData: Ticket }
  ) {
    commit('setLoading', { key: 'isTicketsLoading', loading: true })
    const ticket = await params.api.ticketRepo.updateTicket(params.ticketData)
    commit('updateTicket', ticket)
    commit('setLoading', { key: 'isTicketsLoading', loading: false })
  },
  async fetchTicketOptions(
    { commit },
    params: { api: Repositories; page: number; per: number }
  ) {
    const { api, ...apiParams } = params

    const { ticket_options } = await api.ticketRepo.fetchTicketOptions(
      apiParams
    )
    commit('setTicketOptions', ticket_options)
  },
  async fetchCustomerTicketPacks(
    { commit, rootState },
    params: { api: Repositories; customerId: number } & PaginationQueryParams
  ) {
    commit('setLoading', { key: 'isCustomerTicketPacksLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { ticket_packs, pagination } =
      await params.api.ticketRepo.fetchCustomerTicketPacks(
        branchId,
        params.customerId,
        {
          page: params.page,
          per: params.per,
        }
      )
    commit('setCustomerTicketPacks', ticket_packs)
    commit('setCustomerTicketPackPagination', pagination)
    commit('setLoading', {
      key: 'isCustomerTicketPacksLoading',
      loading: false,
    })
  },
  async fetchTicketPacks(
    { commit },
    params: { api: Repositories } & TicketPackQueryParams &
      PaginationQueryParams
  ) {
    commit('setLoading', { key: 'isTicketsLoading', loading: true })
    const { api, ...apiParams } = params

    const { ticket_packs, meta } = await api.ticketRepo.fetchTicketPacks(
      apiParams
    )
    const { pagination, stats } = meta
    commit('setTicketPacks', ticket_packs)
    commit('setTicketPackPagination', pagination)
    commit('setTicketPackStats', stats)
    commit('setLoading', { key: 'isTicketsLoading', loading: false })
  },
}
