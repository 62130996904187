import { MutationTree, ActionTree } from 'vuex'
import { ProductCategory, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  productCategories: [] as ProductCategory[],
  allProductCategories: [] as ProductCategory[],
  productCategoryPagination: {} as Pagination,
  loadingList: {
    isCategoriesLoading: false,
  },
})

export type ProductCategoryState = ReturnType<typeof state>

export const mutations: MutationTree<ProductCategoryState> = {
  setProductCategories(state, productCategories: ProductCategory[]) {
    state.productCategories = productCategories
  },
  setAllProductCategories(state, allProductCategories: ProductCategory[]) {
    state.allProductCategories = allProductCategories
  },
  setProductCategoryPagination(state, pagination: Pagination) {
    state.productCategoryPagination = pagination
  },
  addNewProductCategory(state, productCategoryData: ProductCategory) {
    state.productCategories.unshift(productCategoryData)
  },
  updateProductCategory(state, productCategoryData: ProductCategory) {
    const index = state.productCategories.findIndex(
      (item) => item.id === productCategoryData.id
    )
    if (index !== -1)
      state.productCategories.splice(index, 1, productCategoryData)
  },
  updatePagination(state) {
    state.productCategoryPagination.total_entries += 1
    state.productCategoryPagination.total_pages = Math.ceil(
      state.productCategoryPagination.total_entries / 10
    )
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  deleteProductCategory(state, id: number) {
    const index = state.productCategories.findIndex((item) => item.id === id)
    if (index !== -1) state.productCategories.splice(index, 1)
  },
}

export const actions: ActionTree<ProductCategoryState, RootState> = {
  async fetchProductCategories(
    { commit },
    params: { api: Repositories; page: number; per: number }
  ) {
    commit('setLoading', { key: 'isCategoriesLoading', loading: true })
    const { product_categories, pagination } =
      await params.api.productCategoryRepo.fetchProductCategories({
        page: params.page,
        per: params.per,
      })
    commit('setProductCategories', product_categories)
    commit('setProductCategoryPagination', pagination)
    commit('setLoading', { key: 'isCategoriesLoading', loading: false })
  },
  async fetchAllProductCategories({ commit }, params: { api: Repositories }) {
    const { pagination } =
      await params.api.productCategoryRepo.fetchProductCategories({
        page: 1,
        per: 1,
      })
    if (pagination.total_entries === 0)
      return commit('setAllProductCategories', [])
    const { product_categories } =
      await params.api.productCategoryRepo.fetchProductCategories({
        page: 1,
        per: pagination.total_entries || 1,
      })
    commit('setAllProductCategories', product_categories)
  },

  async createProductCategory(
    { commit },
    params: { api: Repositories; productCategoryData: ProductCategory }
  ) {
    commit('setLoading', { key: 'isCategoriesLoading', loading: true })
    const productCategory =
      await params.api.productCategoryRepo.createProductCategory(
        params.productCategoryData
      )
    commit('addNewProductCategory', productCategory)
    commit('updatePagination')
    commit('setLoading', { key: 'isCategoriesLoading', loading: false })
  },

  async updateProductCategory(
    { commit },
    params: { api: Repositories; productCategoryData: ProductCategory }
  ) {
    commit('setLoading', { key: 'isCategoriesLoading', loading: true })
    const productCategory =
      await params.api.productCategoryRepo.updateProductCategory(
        params.productCategoryData
      )
    commit('updateProductCategory', productCategory)
    commit('setLoading', { key: 'isCategoriesLoading', loading: false })
  },
  async setAllProductCategoriesOrder(
    { rootState },
    params: {
      api: Repositories
      category: { id: number; position: number }
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api.productCategoryRepo.setAllProductCategoriesOrder(
      branchId,
      params.category
    )
  },
  async deleteProductCategory(
    { commit },
    params: { api: Repositories; productCategoryId: number }
  ) {
    await params.api.productCategoryRepo.deleteProductCategory(
      params.productCategoryId
    )
    commit('deleteProductCategory', params.productCategoryId)
  },
}
