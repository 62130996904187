import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import {
  CRMAnalyticsRepo,
  CrmCommonApiQueryParams,
  CrmCustomerApiQueryParams,
  ProductCategoryAnalyticsParams,
  StaffPerformanceParams,
  CrmProductTypeApiQueryParams,
  CrmStaffTotalParams,
  CrmTicketOptionsParams,
  CrmCouponsParams,
} from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): CRMAnalyticsRepo => ({
  async fetchCrmBranchAnalytics(
    params?: CrmCommonApiQueryParams | CrmCustomerApiQueryParams
  ) {
    try {
      const qs = require('qs')
      const res = await $axios.get('therapists/analytics/common', {
        params,
        paramsSerializer: (params) => {
          return Object.keys(params)
            .filter((key) => params[key])
            .map((key) => {
              const formatParams =
                key === 'age' && params[key] === 'not_defined'
                  ? { not_defined: true }
                  : params[key]
              return qs.stringify(
                { [key]: formatParams },
                {
                  arrayFormat: key === 'age' ? 'indices' : 'brackets',
                  encode: false,
                }
              )
            })
            .join('&')
        },
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerCommonAnalytics(params?: CrmCommonApiQueryParams) {
    try {
      const res = await $axios.get('/crm/customer/common/', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCrmSymptomAnalytics(params?: CrmCommonApiQueryParams) {
    try {
      const res = await $axios.get('/crm/karte/symptoms/', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerDayCount(params?: CrmCommonApiQueryParams) {
    try {
      const res = await $axios.get('/crm/per_day_count/', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerTimeOfDay(params?: CrmCommonApiQueryParams) {
    try {
      const res = await $axios.get('/crm/customer/time_of_day/', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchWorkingDaysCount(params?: CrmCommonApiQueryParams) {
    try {
      const res = await $axios.get('/crm/working_day_count/', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchCustomerCourseCategory(params?: CrmProductTypeApiQueryParams) {
    try {
      const res = await $axios.get('/crm/product_type/', {
        params,
      })

      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerCourseCategory_v2(params?: CrmProductTypeApiQueryParams) {
    try {
      const res = await $axios.get('/crm/product_type_v2/', {
        params,
      })

      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerAdvertisingSource(params?: CrmProductTypeApiQueryParams) {
    try {
      const res = await $axios.get('/crm/customer_advertising_source/', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerRepeatRate(params?: CrmCommonApiQueryParams) {
    try {
      const res = await $axios.get('/crm/customer/repeat_rate', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchRevenueCommonAnalytics(
    params?: CrmCommonApiQueryParams | CrmCustomerApiQueryParams
  ) {
    try {
      const qs = require('qs')
      const res = await $axios.get('/crm/sales/common/', {
        params,
        paramsSerializer: (params) => {
          return Object.keys(params)
            .filter((key) => params[key])
            .map((key) => {
              const formatParams =
                key === 'age' && params[key] === 'not_defined'
                  ? { not_defined: true }
                  : params[key]
              return qs.stringify(
                { [key]: formatParams },
                {
                  arrayFormat: key === 'age' ? 'indices' : 'brackets',
                  encode: false,
                }
              )
            })
            .join('&')
        },
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchItemsResource(
    params?:
      | CrmCommonApiQueryParams
      | {
          page?: number
          per?: number
          product_types?: string[]
          product_category_ids?: string[]
          item_ids?: number[]
          item_name?: string
          sort?: string[]
          staff_ids?: number[]
        }
  ) {
    try {
      const res = await $axios.get('/crm/items/', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchProductsDetailedAnalytics(
    product_id: number,
    params?: CrmCommonApiQueryParams
  ) {
    try {
      const res = await $axios.get(`/crm/products/${product_id}`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchSalesHistory(
    params:
      | CrmCommonApiQueryParams
      | {
          product_types?: string[]
        }
  ) {
    try {
      const res = await $axios.get('/crm/products/sales_history', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchCrmStaffResource(
    params:
      | CrmCommonApiQueryParams
      | {
          product_types?: string[]
          customer_types?: string[]
          product_ids?: number[]
          ticket_ids?: number[]
          coupon_ids?: number[]
          sort?: string[]
          page?: number
          per?: number
        }
  ) {
    try {
      const res = await $axios.get('/crm/staffs', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchCrmStaffTotalResource(params: CrmStaffTotalParams) {
    try {
      const res = await $axios.get('/crm/staffs/total', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  // CRM - Customer

  async fetchCrmCustomerData(
    params: CrmCommonApiQueryParams | CrmCustomerApiQueryParams
  ) {
    try {
      const qs = require('qs')
      const res = await $axios.get(`crm/customers`, {
        params,
        paramsSerializer: (params) => {
          return Object.keys(params)
            .filter((key) => params[key])
            .map((key) => {
              const formatParams =
                key === 'age' && params[key] === 'not_defined'
                  ? { not_defined: true }
                  : params[key]
              return qs.stringify(
                { [key]: formatParams },
                {
                  arrayFormat: key === 'age' ? 'indices' : 'brackets',
                  encode: false,
                }
              )
            })
            .filter(String)
            .join('&')
        },
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchCrmCustomerLocationData(
    params: CrmCommonApiQueryParams | CrmCustomerApiQueryParams
  ) {
    try {
      const qs = require('qs')
      const res = await $axios.get(`crm/customers/locations`, {
        params,
        paramsSerializer: (params) => {
          return Object.keys(params)
            .filter((key) => params[key])
            .map((key) => {
              const formatParams =
                key === 'age' && params[key] === 'not_defined'
                  ? { not_defined: true }
                  : params[key]
              return qs.stringify(
                { [key]: formatParams },
                {
                  arrayFormat: key === 'age' ? 'indices' : 'brackets',
                  encode: false,
                }
              )
            })
            .filter(String)
            .join('&')
        },
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchCrmCustomerAnalytics(
    params: CrmCommonApiQueryParams | CrmCustomerApiQueryParams
  ) {
    try {
      const qs = require('qs')
      const res = await $axios.get(`crm/customer/analytics/`, {
        params,
        paramsSerializer: (params) => {
          return Object.keys(params)
            .filter((key) => params[key])
            .map((key) => {
              const formatParams =
                key === 'age' && params[key] === 'not_defined'
                  ? { not_defined: true }
                  : params[key]
              return qs.stringify(
                { [key]: formatParams },
                {
                  arrayFormat: key === 'age' ? 'indices' : 'brackets',
                  encode: false,
                }
              )
            })
            .join('&')
        },
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchCrmStaffPerformance(
    staff_id: number,
    params: StaffPerformanceParams
  ) {
    try {
      const res = await $axios.get(`/crm/staffs/${staff_id}`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchProductsResource(params?: {
    page?: number
    per?: number
    branch_ids?: number[]
    start_date: string
    end_date: string
    comparison_start_date?: string
    comparison_end_date?: string
    product_types?: string[]
    product_ids?: number[]
    product_category_ids?: string[]
    sort?: string[]
    staff_ids?: number[]
    text?: string
  }) {
    try {
      const res = await $axios.get('crm/products/', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchProductCategoryAnalyticsResource(
    params: ProductCategoryAnalyticsParams
  ) {
    try {
      const res = await $axios.get(`/crm/product_categories/`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async exportCrmCustomerReport(
    params: CrmCommonApiQueryParams & CrmCustomerApiQueryParams
  ) {
    try {
      const res = await $axios.post('/crm/customers/export', {
        export: {
          ...params,
        },
      })
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async exportProductReport(params?: {
    branch_ids: number[]
    staff_ids?: number[]
    start_date: string
    end_date: string
  }) {
    try {
      const res = await $axios.post('/crm/products/export', {
        export: {
          ...params,
        },
      })
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCrmCoupons(params: CrmCouponsParams) {
    try {
      const res = await $axios.get('/crm/coupons', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCrmTicketOptions(params: CrmTicketOptionsParams) {
    try {
      const res = await $axios.get('/crm/ticket_options', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCrmCoupon(params: { id: string } & CrmCouponsParams) {
    try {
      const res = await $axios.get(`/crm/coupons/${params.id}`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCrmTicketOption(params: { id: string } & CrmTicketOptionsParams) {
    try {
      const res = await $axios.get(`/crm/ticket_options/${params.id}`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
