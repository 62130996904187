import { ReservationAnalytics, ReservationAnalyticData } from '~/types'
import { getProductTypes } from '~/helpers/constants/productTypes'

export const getCustomerCohortTypes = ($t: any) => {
  return [
    { text: $t('crm.newCustomer'), value: 'new' },
    { text: $t('crm.repeaters'), value: 'continuing' },
    { text: $t('crm.reExamination'), value: 'revenant' },
    { text: $t('crm.total'), value: 'total' },
  ]
}

export const getVisitingCustomersTypes = ($t: any) => {
  return [
    { text: $t('crm.newCustomer'), value: 'new' },
    { text: $t('crm.reExamination'), value: 'revenant' },
    { text: $t('crm.repeaters'), value: 'continuing' },
  ]
}

export const NON_VISITING_CUSTOMERS = [
  { value: 'breakaway', text: '受診中断' },
  { value: 'cured', text: 'セルフケア' },
]

export const getNonVisitingCustomersTypes = ($t: any) => {
  return [
    { value: 'breakaway', text: $t('crm.discontinuedCustomers') },
    { value: 'cured', text: $t('crm.cured') },
  ]
}

export const getAllCustomersTypes = ($t: any) => {
  return [...getVisitingCustomersTypes($t), ...getNonVisitingCustomersTypes($t)]
}

export const getCustomerGenderTypes = ($t: any) => {
  return [
    { text: $t('crm.male'), value: 'male' },
    { text: $t('crm.female'), value: 'female' },
    { text: $t('crm.other'), value: 'not_defined' },
  ]
}

export const getCustomerAgeGroupType1 = ($t: any) => {
  return [
    { text: '0 - 6 ' + $t('crm.yearsOld'), value: '0-6' },
    { text: '7 - 12 ' + $t('crm.yearsOld'), value: '7-12' },
    { text: '13 - 15 ' + $t('crm.yearsOld'), value: '13-15' },
    { text: '16 - 18 ' + $t('crm.yearsOld'), value: '16-18' },
    { text: '19 - 22 ' + $t('crm.yearsOld'), value: '19-22' },
    { text: '23 - 29 ' + $t('crm.yearsOld'), value: '23-29' },
    { text: '30 - 39 ' + $t('crm.yearsOld'), value: '30-39' },
    { text: '40 - 49 ' + $t('crm.yearsOld'), value: '40-49' },
    { text: '50 - 59 ' + $t('crm.yearsOld'), value: '50-59' },
    { text: '60 - 64 ' + $t('crm.yearsOld'), value: '60-64' },
    { text: '65 - 74 ' + $t('crm.yearsOld'), value: '65-74' },
    { text: $t('crm.75Above'), value: '75+' },
    { text: $t('crm.other'), value: 'not_defined' },
  ]
}

export const getCustomerAgeGroupType2 = ($t: any) => {
  return [
    { text: '0 - 5 ' + $t('crm.yearsOld'), value: '0-5' },
    { text: '6 - 10 ' + $t('crm.yearsOld'), value: '5-10' },
    { text: '11 - 20 ' + $t('crm.yearsOld'), value: '11-20' },
    { text: '21 - 30 ' + $t('crm.yearsOld'), value: '21-30' },
    { text: '31 - 40 ' + $t('crm.yearsOld'), value: '31-40' },
    { text: '41 - 50 ' + $t('crm.yearsOld'), value: '41-50' },
    { text: '51 - 60 ' + $t('crm.yearsOld'), value: '51-60' },
    { text: '61 - 70 ' + $t('crm.yearsOld'), value: '61-70' },
    { text: '71 - 80 ' + $t('crm.yearsOld'), value: '71-80' },
    { text: $t('crm.80Above'), value: '80+' },
    { text: $t('crm.other'), value: 'not_defined' },
  ]
}

export const getTimeOfTheDayTypes = ($t: any) => {
  return [
    { text: $t('crm.AM'), value: 'AM' },
    { text: $t('crm.PM'), value: 'PM' },
  ]
}

export const getPaymentStatusTypes = ($t: any) => {
  return [
    { text: $t('crm.pending'), value: 'pending' },
    { text: $t('crm.paid'), value: 'paid' },
  ]
}

export const getPaymentMethodTypes = ($t: any) => {
  return [
    { text: $t('crm.card'), value: 'card' },
    { text: $t('crm.cash'), value: 'cash' },
  ]
}

export const getAdSources = ($t: any) => {
  return [
    { value: 'facebook', text: 'Facebook' },
    { value: 'twitter', text: 'Twitter' },
    { value: 'line', text: 'Line' },
    { value: 'instagram', text: 'Instagram' },
    { value: 'home_page', text: $t('crm.homePage') },
    { value: 'epark', text: $t('crm.epark') },
    { value: 'ekiten', text: $t('crm.ekiten') },
    { value: 'hot_pepper', text: $t('crm.hotPepper') },
    { value: 'google_maps', text: $t('crm.googleMaps') },
    { value: 'portal_site', text: $t('crm.portalSite') },
    { value: 'blog_site', text: $t('crm.blogSite') },
    { value: 'leaflet', text: $t('crm.leaflet') },
    { value: 'poster', text: $t('crm.poster') },
    { value: 'referral_card', text: $t('crm.referralCard') },
    { value: 'events', text: $t('crm.events') },
    { value: 'word_of_month', text: $t('crm.wordOfMouth') },
    { value: 'door_sales', text: $t('crm.doorSales') },
    { value: 'by_patient', text: $t('crm.byPatient') },
    { value: 'by_non_patient', text: $t('crm.byNonPatient') },
    { value: 'by_staff', text: $t('crm.byStaff') },
    { value: 'other', text: $t('crm.other') },
  ]
}

export function getTypes(
  chartName: string,
  $t: any
): { text: string; value: string }[] {
  switch (chartName) {
    case 'visiting_customers':
      return getVisitingCustomersTypes($t)
    case 'customer_type':
      return getCustomerCohortTypes($t)
    case 'customer_gender':
      return getCustomerGenderTypes($t)
    case 'customer_age':
      return getCustomerAgeGroupType1($t)
    case 'time_of_the_day':
      return getTimeOfTheDayTypes($t)
    case 'payment_status':
      return getPaymentStatusTypes($t)
    case 'payment_method':
      return getPaymentMethodTypes($t)
    case 'product_types':
      return getProductTypes($t)
    case 'non_visiting_customers':
      return getNonVisitingCustomersTypes($t)
    case 'customer_types':
      return getAllCustomersTypes($t)
    case 'referral_sub_source':
      return getAdSources($t)
    default:
      return []
  }
}
export function getType(
  name: string | number,
  chartName: string,
  $t: any
): string {
  return (
    getTypes(chartName, $t).find((type) => type.value === name)?.text ||
    `${name}`
  )
}

export function getAgeExplanation(value: string, $t: any) {
  switch (value) {
    case '0-6':
      return $t('age.0to6')
    case '7-12':
      return $t('age.7to12')
    case '13-15':
      return $t('age.13to15')
    case '16-18':
      return $t('age.16to18')
    case '19-22':
      return $t('age.19to22')
    case '23-29':
      return $t('age.23to29')
    case '30-39':
      return $t('age.30to39')
    case '40-49':
      return $t('age.40to49')
    case '50-59':
      return $t('age.50to59')
    case '60-64':
      return $t('age.60to64')
    case '65-74':
      return $t('age.65to74')
    case '75+':
      return $t('age.75above')
    default:
      return ''
  }
}

export function getPrice(
  reservationAnalyticsData: ReservationAnalytics,
  type: 'insurance' | 'self_payment' | 'other' | 'total'
): number {
  const analyticsData = reservationAnalyticsData
  const selfPaymentList: ReservationAnalyticData[] = Object.keys(analyticsData)
    .filter((key) => {
      return checkAnalyticsDataType(key) === type
    })
    .map((key) => analyticsData[key])

  return selfPaymentList.reduce((sum, item: ReservationAnalyticData) => {
    return sum + item.sales
  }, 0)
}

export function checkAnalyticsDataType(
  type: string
): 'insurance' | 'self_payment' | 'other' | 'total' {
  switch (type) {
    case 'insurance':
    case 'judo_insurance':
    case 'hari_insurance':
    case 'anma_insurance':
      return 'insurance'
    case 'self_payment':
    case 'judo_self_payment':
    case 'hari_self_payment':
    case 'anma_self_payment':
      return 'self_payment'
    case 'total':
      return 'total'
    default:
      return 'other'
  }
}
