import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { CustomerInsuranceRepo, CustomerInsurance } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): CustomerInsuranceRepo => ({
  async fetchCustomerInsuranceCards(customerId: number) {
    try {
      const res = await $axios.get(
        `karte/customers/${customerId}/insurance_cards`
      )
      if (res.status === 200) {
        return res.data.insurance_cards
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createCustomerInsuranceCard(
    customerId: number,
    insuranceCard: CustomerInsurance
  ) {
    try {
      const res = await $axios.post(
        `karte/customers/${customerId}/insurance_cards`,
        {
          insurance_card: insuranceCard,
        }
      )
      if (res.status === 201) {
        return res.data.insurance_card
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateCustomerSettings(
    customerId: number,
    cardId: number,
    insuranceCard: CustomerInsurance
  ) {
    try {
      const res = await $axios.put(
        `karte/customers/${customerId}/insurance_cards/${cardId}`,
        {
          insurance_card: insuranceCard,
        }
      )
      if (res.status === 200) {
        return res.data.insurance_card
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteCustomerInsuranceCard(customerId: number, cardId: number) {
    try {
      await $axios.delete(
        `karte/customers/${customerId}/insurance_cards/${cardId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
