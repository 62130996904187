import { MutationTree, ActionTree } from 'vuex'
import { Repositories, Role } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  roles: [] as Role[],
})

export type RoleState = ReturnType<typeof state>

export const mutations: MutationTree<RoleState> = {
  setRoles(state, roles: Role[]) {
    state.roles = roles
  },
  addNewRole(state, roleData: Role) {
    state.roles.push(roleData)
  },
  updateRole(state, roleData: Role) {
    const index = state.roles.findIndex((item) => item.id === roleData.id)
    if (index !== -1) state.roles.splice(index, 1, roleData)
  },
}

export const actions: ActionTree<RoleState, RootState> = {
  async fetchRoles({ commit }, params: { api: Repositories }) {
    const { roles } = await params.api.roleRepo.fetchRoles()
    commit('setRoles', roles)
  },

  async createRole({ commit }, params: { api: Repositories; roleData: Role }) {
    const role = await params.api.roleRepo.createRole(params.roleData)
    commit('addNewRole', role)
  },

  async updateRole({ commit }, params: { api: Repositories; roleData: Role }) {
    const role = await params.api.roleRepo.updateRole(params.roleData)
    commit('updateRole', role)
  },
}
