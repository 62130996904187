import { MutationTree, ActionTree } from 'vuex'
import { NotificationData, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  notifications: [] as NotificationData[],
  notificationPagination: {} as Pagination,
  popupNotifications: [] as NotificationData[],
})

export type NotificationState = ReturnType<typeof state>

export const mutations: MutationTree<NotificationState> = {
  setNotifications(state, notifications: NotificationData[]) {
    state.notifications = notifications
  },
  setNotificationPagination(state, pagination: Pagination) {
    state.notificationPagination = pagination
  },
  acknowledgeNotification(state, notificationData: NotificationData) {
    const index = state.notifications.findIndex(
      (notification) => notification.id === notificationData.id
    )
    if (index !== -1) state.notifications.splice(index, 1, notificationData)
  },
  setPopupNotifications(state, popupNotifications: NotificationData[]) {
    state.popupNotifications = popupNotifications
  },
}

export const actions: ActionTree<NotificationState, RootState> = {
  async fetchNotifications(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
      acknowledged: boolean
    }
  ) {
    const { notifications, pagination } =
      await params.api.notificationRepo.fetchNotifications({
        page: params.page,
        per: params.per,
        acknowledged: params.acknowledged,
      })

    commit('setNotifications', notifications)
    commit('setNotificationPagination', pagination)
  },
  async fetchPopupNotifications(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
      acknowledged_popup: boolean
      is_popup: boolean
    }
  ) {
    const { notifications } =
      await params.api.notificationRepo.fetchNotifications({
        page: params.page,
        per: params.per,
        acknowledged_popup: params.acknowledged_popup,
        is_popup: params.is_popup,
      })

    commit('setPopupNotifications', notifications)
  },
  async acknowledgeNotification(
    { commit },
    params: {
      api: Repositories
      notificationData: NotificationData
    }
  ) {
    const notification =
      await params.api.notificationRepo.acknowledgeNotification(
        params.notificationData
      )
    commit('acknowledgeNotification', notification)
  },
}
