import { MutationTree, ActionTree } from 'vuex'
import { Unit, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  units: [] as Unit[],
  unitPagination: {} as Pagination,
  loadingList: {
    isUnitsLoading: false,
  },
})

export type UnitState = ReturnType<typeof state>

export const mutations: MutationTree<UnitState> = {
  setUnits(state, units: Unit[]) {
    state.units = units
  },
  setUnitPagination(state, pagination: Pagination) {
    state.unitPagination = pagination
  },
  addNewUnit(state, unitData: Unit) {
    state.units.unshift(unitData)
  },
  updateUnit(state, unitData: Unit) {
    const index = state.units.findIndex((item) => item.id === unitData.id)
    if (index !== -1) state.units.splice(index, 1, unitData)
  },
  deleteUnit(state, id: number) {
    const index = state.units.findIndex((item) => item.id === id)
    if (index !== -1) state.units.splice(index, 1)
  },
  updatePagination(state) {
    state.unitPagination.total_entries += 1
    state.unitPagination.total_pages = Math.ceil(
      state.unitPagination.total_entries / 10
    )
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<UnitState, RootState> = {
  async fetchUnits(
    { commit, rootState },
    params: { api: Repositories; page: number; per: number; branchId: number }
  ) {
    commit('setLoading', { key: 'isUnitsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { units, pagination } = await params.api.unitRepo.fetchUnits(
      params.branchId || branchId,
      {
        page: params.page,
        per: params.per,
      }
    )
    commit('setUnits', units)
    commit('setUnitPagination', pagination)
    commit('setLoading', { key: 'isUnitsLoading', loading: false })
  },
  async fetchAllUnits(
    { commit, rootState },
    params: { api: Repositories; branchId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { pagination } = await params.api.unitRepo.fetchUnits(
      params.branchId || branchId,
      {
        page: 1,
        per: 1,
      }
    )
    if (pagination === 0) return commit('setUnits', [])
    const { units } = await params.api.unitRepo.fetchUnits(
      params.branchId || branchId,
      {
        page: 1,
        per: pagination.total_entries || 1,
      }
    )
    commit('setUnits', units)
  },

  async createUnit(
    { commit, rootState },
    params: { api: Repositories; unitData: Unit; branchId: number }
  ) {
    commit('setLoading', { key: 'isUnitsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const unit = await params.api.unitRepo.createUnit(
      params.branchId || branchId,
      params.unitData
    )
    commit('addNewUnit', unit)
    commit('updatePagination')
    commit('setLoading', { key: 'isUnitsLoading', loading: false })
  },

  async updateUnit(
    { commit, rootState },
    params: { api: Repositories; unitData: Unit; branchId: number }
  ) {
    commit('setLoading', { key: 'isUnitsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch

    const unit = await params.api.unitRepo.updateUnit(
      params.branchId || branchId,
      params.unitData
    )
    commit('updateUnit', unit)
    commit('setLoading', { key: 'isUnitsLoading', loading: false })
  },
  async deleteUnit(
    { commit, rootState },
    params: { api: Repositories; unitId: number; branchId: number }
  ) {
    commit('setLoading', { key: 'isUnitsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch

    await params.api.unitRepo.deleteUnit(
      params.branchId || branchId,
      params.unitId
    )
    commit('deleteUnit', params.unitId)
    commit('setLoading', { key: 'isUnitsLoading', loading: false })
  },
  async hideUnit(
    { commit, rootState },
    params: { api: Repositories; unitData: Unit; branchId: number }
  ) {
    commit('setLoading', { key: 'isUnitsLoading', loading: true })
    const data = Object.assign({}, params.unitData)
    data.hidden = true
    const branchId = (rootState as any).auth.user.activeBranch

    const unit = await params.api.unitRepo.updateUnit(
      params.branchId || branchId,
      data
    )
    commit('deleteUnit', unit.id)
    commit('setLoading', { key: 'isUnitsLoading', loading: false })
  },
}
