import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Product, ProductBatch, ProductRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): ProductRepo => ({
  async fetchProducts(params?: {
    page?: number
    per?: number
    include_inactive?: boolean
    search?: string
    product_types: string[]
    sort: string[]
    for_new_customers?: boolean
  }) {
    try {
      const res = await $axios.get('therapists/products', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createProduct(productData: Product) {
    try {
      const res = await $axios.post('therapists/products', {
        product: productData,
      })
      if (res.status === 201) {
        return res.data.product
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateProduct(product: Product) {
    try {
      const res = await $axios.put(`therapists/products/${product.id}`, {
        product,
      })
      if (res.status === 200) {
        return res.data.product
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteProduct(productId: number) {
    try {
      await $axios.delete(`therapists/products/${productId}`)
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateBatchProduct(productId: number, batch: ProductBatch) {
    try {
      const res = await $axios.put(`therapists/products/${productId}/batch`, {
        batch,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async batchProductHide(productIds: number[], product: { hidden: boolean }) {
    try {
      const res = await $axios.put(`therapists/products/batch_hide`, {
        batch_hide: { product_ids: productIds, ...product },
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async fetchBranchProducts(
    branchId: number,
    params?: { page?: number; per?: number; active?: boolean }
  ) {
    try {
      const res = await $axios.get(`therapists/branches/${branchId}/products`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchStaffProducts(
    staffId: number,
    params?: { page?: number; per?: number }
  ) {
    try {
      const res = await $axios.get(`therapists/staff/${staffId}/products`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
