import { MutationTree, ActionTree } from 'vuex'
import { Repositories, RolePermission, Permission } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  permissions: [] as RolePermission[],
  permissionsView: [] as Permission[],
})

export type PermissionState = ReturnType<typeof state>

export const mutations: MutationTree<PermissionState> = {
  setPermissions(state, permissions: RolePermission[]) {
    state.permissions = permissions
  },
  setPermissionsView(state, permissions: Permission[]) {
    state.permissionsView = permissions
  },
}

export const actions: ActionTree<PermissionState, RootState> = {
  async fetchPermissions({ commit }, params: { api: Repositories }) {
    const permissions = await params.api.permissionRepo.fetchPermissions()
    const parentPermissionsList = permissions
      .map((permission: Permission) => {
        return {
          ...permission,
          id: permission.slug,
        }
      })
      .filter((permission: Permission) => !permission.dependent_on_slug)

    const childPermissionsList = permissions.filter(
      (permission: Permission) => permission.dependent_on_slug
    )

    const allPermissions = [...parentPermissionsList]
    childPermissionsList.map((permission: Permission) => {
      if (permission.dependent_on_slug) {
        const index = allPermissions.findIndex(
          (permissionItem) =>
            permissionItem.slug === permission.dependent_on_slug
        )
        if (index > -1) {
          allPermissions[index].children = [
            { ...permission, id: permission.slug },
          ]
        }
      }
      return permission
    })
    commit('setPermissionsView', allPermissions)
    commit('setPermissions', permissions)
  },
}
