import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import { CrmStaff, Repositories } from '~/types'

export const state = () => ({
  staffRankingsSortType: 'sales' as string,
  staffRankings: [] as CrmStaff[],
})

export type CrmStaffRanking = ReturnType<typeof state>

export const mutations: MutationTree<CrmStaffRanking> = {
  setStaffRankings(state, staffs: CrmStaff[]) {
    state.staffRankings = staffs
  },
  setStaffRankingsSortType(state, type: string) {
    state.staffRankingsSortType = type
  },
}

export const actions: ActionTree<CrmStaffRanking, RootState> = {
  async getCrmStaffRankingsResource(
    { commit },
    params: {
      api: Repositories
      branch_ids: number[]
      start_date: string
      end_date: string
      comparison_start_date?: string
      comparison_end_date?: string
      product_types?: string[]
      customer_types?: string[]
      sort?: string[]
      product_ids?: number[]
      coupon_ids?: number[]
      ticket_ids?: number[]
    }
  ) {
    const { staffs } = await params.api.crmAnalyticsRepo.fetchCrmStaffResource({
      branch_ids: params.branch_ids,
      start_date: params.start_date,
      end_date: params.end_date,
      comparison_start_date: params.comparison_start_date,
      comparison_end_date: params.comparison_end_date,
      product_types: params.product_types,
      customer_types: params.customer_types,
      sort: params.sort,
      product_ids: params.product_ids,
      coupon_ids: params.coupon_ids,
      ticket_ids: params.ticket_ids,
      page: 1,
      per: 5,
    })
    commit('setStaffRankings', staffs)
  },
}
