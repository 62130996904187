import dayjs from 'dayjs'
import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import { MailCustomerType, Mail, Pagination, Repositories } from '~/types'

export const state = () => ({
  startEndDate: {
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
  },
  renewDate: dayjs().startOf('month').add(1, 'month').format('YYYY-MM-DD'),
  mails: [] as Mail[],
  mailsPagination: {} as Pagination,
  mailCustomers: [] as MailCustomerType[],
  mailCustomersPagination: {} as Pagination,
  loadingList: {
    isMailLoading: false,
    isMailCustomersLoading: false,
  },
})

export type MessagesState = ReturnType<typeof state>

export const mutations: MutationTree<MessagesState> = {
  setMails(state, mails: Mail[]) {
    state.mails = mails
  },
  setMailsPagination(state, pagination: Pagination) {
    state.mailsPagination = pagination
  },
  setCustomers(state, mailCustomers: MailCustomerType[]) {
    state.mailCustomers = mailCustomers
  },
  setCustomersPagination(state, pagination: Pagination) {
    state.mailCustomersPagination = pagination
  },
  pushMails(state, mailData: Mail) {
    state.mails.unshift(mailData)
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<MessagesState, RootState> = {
  async getMails(
    { commit, rootState },
    params: {
      api: Repositories
      page?: number
      per?: number
      sort?: string[]
    }
  ) {
    commit('setLoading', { key: 'isMailLoading', loading: true })
    const branchId = Number((rootState as any).auth.user.activeBranch)
    const { group_mails, pagination } = await params.api.mailRepo.fetchMails(
      branchId,
      {
        page: params.page,
        per: params.per,
        sort: params.sort,
      }
    )
    commit('setMails', group_mails)
    commit('setMailsPagination', pagination)
    commit('setLoading', { key: 'isMailLoading', loading: false })
  },
  async getCustomersByMailId(
    { commit },
    params: {
      api: Repositories
      mailId: number
      page?: number
      per?: number
    }
  ) {
    commit('setLoading', { key: 'isMailCustomersLoading', loading: true })
    const { customers, pagination } =
      await params.api.mailRepo.fetchCustomersByMailId(params.mailId, {
        page: params.page,
        per: params.per,
      })
    commit('setCustomers', customers)
    commit('setCustomersPagination', pagination)
    commit('setLoading', { key: 'isMailCustomersLoading', loading: false })
  },
  async createMails(
    { commit, rootState },
    params: {
      api: Repositories
      customer_ids: number[]
      subject: string
      body: string
    }
  ) {
    commit('setLoading', { key: 'isMailLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { group_mail } = await params.api.mailRepo.createCustomerMails({
      branch_id: branchId,
      customer_ids: params.customer_ids,
      subject: params.subject,
      body: params.body,
    })
    commit('pushMails', group_mail)
    commit('setLoading', { key: 'isMailLoading', loading: false })
  },
}
