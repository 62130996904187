import dayjs from 'dayjs'
import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import { Pagination, Repositories } from '~/types'
import { AdvertisingSource } from '~/types/advertisingSource'

export const state = () => ({
  month: new Date(),
  branchPagination: {} as Pagination,
  startEndDate: {
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
  },
  loadingList: {
    isAdvertisingLoading: false,
  },
  selectedRow: [] as any,
  advertisingSource: [] as AdvertisingSource[],
  advertisingPagination: {} as Pagination,
  openBranchCPA: '',
  openBranchId: -1,
  selectAdSources: [] as string[],
})

export type LayoutSettingsState = ReturnType<typeof state>

export const mutations: MutationTree<LayoutSettingsState> = {
  setSelectedRow(state, selectedRow: any) {
    state.selectedRow = selectedRow
  },
  setMonth(state, date: Date | string) {
    state.month = dayjs(date).toDate()
  },
  setStartEndDate(
    state,
    advertisingStartEndDate: { startDate: string; endDate: string }
  ) {
    state.startEndDate = advertisingStartEndDate
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  setAdvertisingSource(state, advertisingSource: AdvertisingSource[]) {
    state.advertisingSource = advertisingSource
  },
  setAdvertisingSourcePagination(state, pagination: Pagination) {
    state.advertisingPagination = pagination
  },
  setOpenBranchCPA(state, openBranchCPA: string) {
    state.openBranchCPA = openBranchCPA
  },
  setOpenBranchId(state, openBranchId: number) {
    state.openBranchId = openBranchId
  },
  setSelectAdSources(state, selectAdSources: string[]) {
    state.selectAdSources = selectAdSources
  },
}
export const actions: ActionTree<LayoutSettingsState, RootState> = {
  async fetchAdvertisingSources(
    { commit },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      page: number
      per: number
      branch_ids?: number[]
      sources?: string[]
      search?: string
    }
  ) {
    commit('setLoading', { key: 'isAdvertisingLoading', loading: true })
    const { expense_data, pagination } =
      await params.api.advertisingRepo.fetchAdvertisingSource({
        start_date: params.start_date,
        end_date: params.end_date,
        page: params.page,
        branch_ids: params.branch_ids,
        sources: params.sources,
        per: params.per,
        search: params.search,
      })
    commit('setAdvertisingSource', expense_data || [])
    commit('setAdvertisingSourcePagination', pagination)
    commit('setLoading', { key: 'isAdvertisingLoading', loading: false })
  },
}
