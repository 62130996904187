import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { CRMAdSourceAnalyticsRepo, HistoryApiQueryParams } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): CRMAdSourceAnalyticsRepo => ({
  async fetchAdSourceHistory(params: HistoryApiQueryParams) {
    try {
      const res = await $axios.get(`/crm/ad_source_history`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
