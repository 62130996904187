import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Shift, ShiftRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): ShiftRepo => ({
  async fetchShifts(
    branchId: number,
    params?: { page?: number; per?: number }
  ) {
    try {
      const res = await $axios.get(`therapists/branches/${branchId}/shifts`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createShift(branchId: number, shiftData: Shift) {
    try {
      const res = await $axios.post(`therapists/branches/${branchId}/shifts`, {
        shift: shiftData,
      })
      if (res.status === 201) {
        return res.data.shift
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateShift(branchId: number, shift: Shift) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/shifts/${shift.id}`,
        {
          shift,
        }
      )
      if (res.status === 200) {
        return res.data.shift
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteShift(branchId: number, shiftId: number) {
    try {
      await $axios.delete(`therapists/branches/${branchId}/shifts/${shiftId}`)
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
