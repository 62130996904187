
































import Vue from 'vue'
import { Branch } from '~/types'
import { User } from '~/models/User'
import { isMatchUrl } from '~/helpers/urlUtils'
import { UserPermission } from '~/models/UserPermission'
export default Vue.extend({
  data() {
    return {
      openMenu: false,
    }
  },
  async fetch() {
    await this.fetchStaffBranches()
    if (this.isAllowedPermission) {
      await this.getAllBranches()
    }
  },
  computed: {
    userBranches(): Branch[] {
      return this.$store.state.auth.userBranches
    },
    selectedTopBarMode(): 'branches' | 'crm' {
      return this.$store.state.clinicSettings.selectedTopBarMode
    },
    user(): User {
      return this.$store.state.auth.user
    },
    allBranches(): Branch[] {
      return this.$store.state.branch.branches
    },
    activeBranch(): Branch | undefined {
      return this.userBranches.find(
        (branch) => branch.id === this.user.activeBranch
      )
    },
    activeBranchText(): string {
      if (this.isHQBranch) return this.$t('hq.hq').toString()
      return this.activeBranch ? this.activeBranch.name : ''
    },
    selectedBranchIds(): number[] {
      return this.$store.state.hqCrm.selectedBranchIds
    },
    isHQBranch(): boolean {
      return isMatchUrl(this.$route.path, 'hq')
    },
    userPermission(): UserPermission {
      return this.$store.state.auth.userPermission
    },
    isAllowedPermission(): Boolean {
      return this.userPermission.isAllowed('hq_crm')
    },
  },
  methods: {
    async fetchStaffBranches() {
      const staffId = this.$store.state.auth.user.id
      try {
        const { branches, pagination } =
          await this.$api.branchRepo.fetchStaffBranches(staffId, {
            page: 1,
            per: 60,
          })
        this.$store.commit('auth/setUserBranches', branches)
        this.$store.commit('auth/setUserBranchPagination', pagination)
      } catch (error) {}
    },
    async getAllBranches() {
      try {
        await this.$store.dispatch('branch/fetchBranches', {
          api: this.$api,
          page: 1,
          per: 100,
        })
      } catch (error) {}
    },
  },
})
