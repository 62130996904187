import { MutationTree, ActionTree } from 'vuex'
import dayjs from 'dayjs'
import { CashRegister } from '~/types/accounting'
import { Repositories } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  cashRegister: {} as CashRegister,
  loadingList: {
    isCashRegisterLoading: false,
  },
  cashRegisterDate: dayjs().format('YYYY-MM-DD'),
})

export type CashRegisterState = ReturnType<typeof state>

export const mutations: MutationTree<CashRegisterState> = {
  setCashRegister(state, cashRegister: CashRegister) {
    state.cashRegister = cashRegister
  },
  updateCashRegister(state, cashRegister: CashRegister) {
    state.cashRegister = cashRegister
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  setCashRegisterDate(state, date: string) {
    state.cashRegisterDate = date
  },
}

export const actions: ActionTree<CashRegisterState, RootState> = {
  async fetchCashRegisters(
    { commit, rootState },
    params: {
      api: Repositories
      date?: string
    }
  ) {
    commit('setLoading', { key: 'isCashRegisterLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { cash_registers } =
      await params.api.cashRegisterRepo.fetchCashRegisters(branchId, {
        date: params.date,
      })
    commit('setCashRegister', cash_registers[0] || {})
    commit('setLoading', { key: 'isCashRegisterLoading', loading: false })
  },
  async createCashRegister(
    { commit, rootState },
    params: { api: Repositories; cashRegisterData: CashRegister }
  ) {
    commit('setLoading', { key: 'isCashRegisterLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { cash_register } =
      await params.api.cashRegisterRepo.createCashRegister(
        branchId,
        params.cashRegisterData
      )
    commit('setCashRegister', cash_register)
    commit('setLoading', { key: 'isCashRegisterLoading', loading: false })
  },
  async fetchCashRegisterWithId(
    { commit, rootState },
    params: {
      api: Repositories
      cashRegisterId: number
    }
  ) {
    commit('setLoading', { key: 'isCashRegisterLoading', loading: false })
    const branchId = (rootState as any).auth.user.activeBranch
    const { cash_register } =
      await params.api.cashRegisterRepo.fetchCashRegisterWithId(
        branchId,
        params.cashRegisterId
      )
    commit('setCashRegister', cash_register)
    commit('setLoading', { key: 'isCashRegisterLoading', loading: false })
  },
  async updateCashRegister(
    { commit, rootState },
    params: {
      api: Repositories
      cashRegisterData: CashRegister
    }
  ) {
    commit('setLoading', { key: 'isCashRegisterLoading', loading: false })
    const branchId = (rootState as any).auth.user.activeBranch
    const { cash_register } =
      await params.api.cashRegisterRepo.updateCashRegister(
        branchId,
        params.cashRegisterData
      )
    commit('updateCashRegister', cash_register)
    commit('setLoading', { key: 'isCashRegisterLoading', loading: false })
  },
}
