import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { NotificationRepo, NotificationData } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): NotificationRepo => ({
  async fetchNotifications(params: { page: number; per: number }) {
    try {
      const res = await $axios.get(`therapists/notifications`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async acknowledgeNotification(notificationData: NotificationData) {
    try {
      const res = await $axios.put(
        `therapists/notifications/${notificationData.id}`,
        {
          notification: {
            acknowledged: notificationData.acknowledged,
            acknowledgment_popup: notificationData.acknowledged_popup,
          },
        }
      )
      if (res.status === 200) {
        return res.data.notification
      }
    } catch (error) {
      return processError(error, $sentry)
    }
  },
})
