import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  email,
  required,
  min,
  min_value,
  max,
  max_value,
  integer,
  regex,
} from 'vee-validate/dist/rules'
import dayjs from 'dayjs'
import { VALIDATIONS } from '~/helpers/constants/validations'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

const veeValidatePlugin = ({ i18n }) => {
  // Set locale validations
  const lv = VALIDATIONS[i18n.locale]
  extend('required', {
    ...required,
    message: lv.required,
  })

  extend('email', {
    ...email,
    message: lv.email,
  })

  extend('min', {
    ...min,
    message: lv.min,
  })

  extend('min_value', {
    ...min_value,
    message: (_field, value) => {
      return lv.min_value(value.min - 1)
    },
  })
  extend('min_coupon', {
    ...min_value,
    message: (_field, value) => {
      return lv.min_coupon(value.min - 1)
    },
  })

  extend('max', {
    ...max,
    message: lv.max,
  })

  extend('max_value', {
    ...max_value,
    message: (_field, value) => {
      return lv.max_value(value.max)
    },
  })

  extend('integer', {
    ...integer,
    message: lv.integer,
  })

  extend('password', {
    params: ['target'],
    validate(value, { target }) {
      return value === target
    },
    message: lv.password,
  })

  extend('lesserThanDate', {
    params: ['end_date'],
    validate(value, { end_date }) {
      if (end_date) {
        return Date.parse(value) < Date.parse(end_date)
      }
      return true
    },
    message: lv.lesserThanDate,
  })

  extend('greaterThanDate', {
    params: ['start_date'],
    validate(value, { start_date }) {
      if (start_date) {
        return Date.parse(value) > Date.parse(start_date)
      }
      return true
    },
    message: lv.greaterThanDate,
  })

  extend('lesserThanTime', {
    params: ['end_time'],
    validate(value, { end_time }) {
      if (end_time) {
        return value < end_time
      }
      return true
    },
    message: lv.lesserThanTime,
  })

  extend('lesserThanEqualTime', {
    params: ['end_time'],
    validate(value, { end_time }) {
      if (end_time) {
        return value <= end_time
      }
      return true
    },
    message: lv.lesserThanEqualTime,
  })

  extend('greaterThanTime', {
    params: ['start_time'],
    validate(value, { start_time }) {
      return value > start_time
    },
    message: lv.greaterThanTime,
  })

  extend('greaterThanEqualTime', {
    params: ['start_time'],
    validate(value, { start_time }) {
      return value >= start_time
    },
    message: lv.greaterThanEqualTime,
  })

  extend('isPastDate', {
    validate(value) {
      const date = new Date(value)
      if (date && !isNaN(date.getTime())) {
        return date < new Date()
      }
      return false
    },
    message: lv.isPastDate,
  })

  extend('allowedGenderValues', {
    validate(value) {
      return (
        value === '1' || value === '2' || value === '男性' || value === '女性'
      )
    },
    message: lv.allowedGenderValues,
  })

  extend('allowedSendDmValues', {
    validate(value) {
      return (
        value === '1' || value === '2' || value === '可' || value === '不可'
      )
    },
    message: lv.allowedSendDmValues,
  })

  extend('zipcodeFormat', {
    ...regex,
    message: lv.zipcodeFormat,
  })

  extend('dateFormat', {
    ...regex,
    message: lv.dateFormat,
  })

  extend('dateFormatYYYYMMDD', {
    validate(value) {
      if (
        value.includes('年') &&
        value.includes('月') &&
        value.includes('日')
      ) {
        value =
          value.substring(0, 4) + value.substring(5, 7) + value.substring(8, 10)
      }

      if (value.includes('/')) {
        const values = value.split('/')
        if (values.length !== 3) return false
        const year = values[0]
        if (year.length !== 4) return false
        const month = values[1]
        if (month.length !== 2) return false
        const day = values[2]
        if (day.length !== 2) return false
        value = `${year}${month}${day}`
      }

      let correct = value.length === 8

      if (correct) {
        if (isNaN(Number(value.substring(0, 4)))) {
          correct = false
        } else if (
          isNaN(Number(value.substring(4, 6))) ||
          Number(value.substring(4, 6)) > 12 ||
          Number(value.substring(4, 6)) < 1
        ) {
          correct = false
        } else if (
          isNaN(Number(value.substring(6, 8))) ||
          Number(value.substring(6, 8)) > 31 ||
          Number(value.substring(6, 8)) < 1
        ) {
          correct = false
        }
      }
      return correct
    },
    message: lv.dateFormatYYYYMMDD,
  })

  extend('dateFormatYYYY-MM-DD', {
    validate(value) {
      return dayjs(value, 'YYYY-MM-DD').isValid()
    },
    message: lv['dateFormatYYYY-MM-DD'],
  })

  extend('validTel', {
    ...regex,
    message: lv.validTel,
  })

  extend('validBirthdayVal', {
    validate(birthdayVal) {
      birthdayVal = birthdayVal.replace(/[ ,.]/g, '')
      let correct = birthdayVal.length === 7
      if (correct) {
        if (
          birthdayVal[0] === '1' ||
          birthdayVal[0] === 'm' ||
          birthdayVal[0] === 'M'
        ) {
          correct =
            birthdayVal.substring(1, 3) > 0 && birthdayVal.substring(1, 3) <= 45
        } else if (
          birthdayVal[0] === '2' ||
          birthdayVal[0] === 't' ||
          birthdayVal[0] === 'T'
        ) {
          correct =
            birthdayVal.substring(1, 3) > 0 && birthdayVal.substring(1, 3) <= 15
        } else if (
          birthdayVal[0] === '3' ||
          birthdayVal[0] === 's' ||
          birthdayVal[0] === 'S'
        ) {
          correct =
            birthdayVal.substring(1, 3) > 0 && birthdayVal.substring(1, 3) <= 64
        } else if (
          birthdayVal[0] === '4' ||
          birthdayVal[0] === 'h' ||
          birthdayVal[0] === 'H'
        ) {
          correct =
            birthdayVal.substring(1, 3) > 0 && birthdayVal.substring(1, 3) <= 30
        } else if (
          birthdayVal[0] === '5' ||
          birthdayVal[0] === 'r' ||
          birthdayVal[0] === 'R'
        ) {
          correct =
            birthdayVal.substring(1, 3) > 0 &&
            birthdayVal.substring(1, 3) <= new Date().getFullYear() - 2018
        } else {
          correct = false
        }

        if (isNaN(Number(birthdayVal.substring(1, 7)))) {
          correct = false
        } else if (Number(birthdayVal.substring(3, 5)) > 12) {
          correct = false
        } else if (
          [4, 6, 9, 11].includes(Number(birthdayVal.substring(3, 5))) &&
          Number(birthdayVal.substring(5, 7)) > 30
        ) {
          correct = false
        } else if (
          [2].includes(Number(birthdayVal.substring(3, 5))) &&
          Number(birthdayVal.substring(5, 7)) > 29
        ) {
          correct = false
        } else if (Number(birthdayVal.substring(5, 7)) > 31) {
          correct = false
        }
      }
      return correct
    },
    message: lv.validBirthdayVal,
  })

  extend('validEmail', {
    ...regex,
    message: lv.validEmail,
  })

  extend('validAlphaNumerical', {
    ...regex,
    message: lv.validAlphaNumerical,
  })

  extend('validStartEndTime', {
    params: ['startTime', 'endTime'],
    validate({ startTime, endTime }) {
      if (!startTime || !endTime) return false
      const startDate = dayjs(startTime, 'HH:mm').format('YYYY-MM-DD HH:mm')
      const endDate = dayjs(endTime, 'HH:mm').format('YYYY-MM-DD HH:mm')
      return dayjs(startDate).isBefore(endDate)
    },
    message: (_, placeholders) => {
      const { _value_ } = placeholders
      const startTime = _value_?.startTime
      const endTime = _value_?.endTime
      if (!startTime) return lv.startTime
      if (!endTime) return lv.endTime
      const startDate = dayjs(startTime, 'HH:mm').format('YYYY-MM-DD HH:mm')
      const endDate = dayjs(endTime, 'HH:mm').format('YYYY-MM-DD HH:mm')
      return dayjs(startDate).isBefore(endDate)
        ? lv.correctTime
        : lv.startBeforeEnd
    },
  })

  extend('validCustomBusinessStartEndDate', {
    validate(value) {
      const startDate = value.start_date
        ? value.start_date
        : value.end_date
        ? value.end_date
        : ''
      const endDate = value.end_date
        ? value.end_date
        : value.start_date
        ? value.start_date
        : ''
      return !!(startDate && endDate)
    },
    message: () => {
      return lv.validCustomBusinessStartEndDate
    },
  })

  extend('preset_required', {
    validate(value) {
      return value === undefined || value === null
    },
    message: lv.preset_required,
  })
}

export default veeValidatePlugin
