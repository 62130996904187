//
// StarWebPrintExtManager API
//
// Version 1.2.0
//
// Copyright (C) 2016-2019 STAR MICRONICS CO., LTD. All Rights Reserved.
//
const StarWebPrintTrader = require('./StarWebPrintTrader')
const StarWebPrintExtManager = function (a) {
  this.url =
    this.onStatusUpdate =
    this.onWrite =
    this.onAccessoryDisconnect =
    this.onAccessoryConnectFailure =
    this.onAccessoryConnectSuccess =
    this.onDisplayDisconnect =
    this.onDisplayConnect =
    this.onDisplayImpossible =
    this.onBarcodeDataReceive =
    this.onBarcodeReaderDisconnect =
    this.onBarcodeReaderConnect =
    this.onBarcodeReaderImpossible =
    this.onCashDrawerClose =
    this.onCashDrawerOpen =
    this.onPrinterCoverClose =
    this.onPrinterCoverOpen =
    this.onPrinterPaperEmpty =
    this.onPrinterPaperNearEmpty =
    this.onPrinterPaperReady =
    this.onPrinterOffline =
    this.onPrinterOnline =
    this.onPrinterImpossible =
    this.onError =
    this.onReceive =
      null
  this.pollingInterval = 200
  this.pollingTimeout = 3e4
  this.isWaitWriteCallback = this.isWaitCallback = !1
  this.claimId = 0
  this.isReleaseRequest = this.isPolling = !1
  if (a) {
    a.pollingTimeout && (this.pollingTimeout = a.pollingTimeout)
    a.pollingInterval && (this.pollingInterval = a.pollingInterval)
    a.url && (this.url = a.url)
  }
}
StarWebPrintExtManager.prototype.connect = function (a) {
  if (a) {
    a.pollingTimeout && (this.pollingTimeout = a.pollingTimeout)
    a.pollingInterval && (this.pollingInterval = a.pollingInterval)
    a.url && (this.url = a.url)
  }
  return !this.isPolling && !this.isWaitCallback
    ? ((this.isReleaseRequest = !1),
      this._sendExtMessage({
        requestType: 'claim',
        isNeedExternalCallBack: !0,
      }),
      !0)
    : !1
}
StarWebPrintExtManager.prototype.disconnect = function () {
  return !this.isReleaseRequest && this.isPolling && !this.isWaitCallback
    ? (this.isReleaseRequest = !0)
    : !1
}
StarWebPrintExtManager.prototype.write = function (a) {
  if (a.request == 0) return !1
  this.claimId == 0 && a.url != 0 && (this.url = a.url)
  this._sendExtMessage({
    requestType: 'write',
    isNeedExternalCallBack: !0,
    request: a.request,
  })
  return !0
}
StarWebPrintExtManager.prototype._getPollingInterval = function () {
  this.pollingInterval < 100 && (this.pollingInterval = 100)
  return this.pollingInterval
}
StarWebPrintExtManager.prototype._getPollingTimeout = function () {
  this.pollingTimeout < 1e4 && (this.pollingTimeout = 1e4)
  return this.pollingTimeout
}
StarWebPrintExtManager.prototype._polling = function () {
  if (this.isReleaseRequest) {
    this.isReleaseRequest = !1
    this._sendExtMessage({
      requestType: 'release',
      isNeedExternalCallBack: !0,
    })
    this._clearClaimId()
  } else {
    this._sendExtMessage({ requestType: 'read', isNeedExternalCallBack: !1 })
  }
}
StarWebPrintExtManager.prototype._analyzeXml = function (a, d) {
  let e = new DOMParser()
  let b
  let f
  b = e.parseFromString(a.responseText, 'text/xml')
  f = b.getElementsByTagName('Response')
  b = e.parseFromString(f.item(0)?.textContent || '', 'text/xml')
  b.getElementsByTagName('root')
  f = b.getElementsByTagName('claimid').item(0)
  let g = b.getElementsByTagName('claim').item(0)
  e = b.getElementsByTagName('event')
  a.managerClaim = g.textContent
  a.managerSuccess = a.traderSuccess
  a.managerCode = a.traderCode
  a.traderSuccess = 0
  a.traderCode = 0
  a.traderStatus = 0

  if (a.managerSuccess == 'true') {
    if (a.managerClaim == 'true') {
      if (f != 0) {
        this.claimId = f.textContent
        this.isPolling = !0
      }
      const c = this
      f = {
        onPrinterImpossible() {
          c.onPrinterImpossible()
        },
        onPrinterOnline() {
          c.onPrinterOnline()
        },
        onPrinterOffline() {
          c.onPrinterOffline()
        },
        onPrinterPaperReady() {
          c.onPrinterPaperReady()
        },
        onPrinterPaperNearEmpty() {
          c.onPrinterPaperNearEmpty()
        },
        onPrinterPaperEmpty() {
          c.onPrinterPaperEmpty()
        },
        onPrinterCoverOpen() {
          c.onPrinterCoverOpen()
        },
        onPrinterCoverClose() {
          c.onPrinterCoverClose()
        },
        onCashDrawerOpen() {
          c.onCashDrawerOpen()
        },
        onCashDrawerClose() {
          c.onCashDrawerClose()
        },
        onBarcodeReaderImpossible() {
          c.onBarcodeReaderImpossible()
        },
        onBarcodeReaderConnect() {
          c.onBarcodeReaderConnect()
        },
        onBarcodeReaderDisconnect() {
          c.onBarcodeReaderDisconnect()
        },
        onBarcodeDataReceive(a) {
          c.onBarcodeDataReceive({ data: a })
        },
        onDisplayImpossible() {
          c.onDisplayImpossible()
        },
        onDisplayConnect() {
          c.onDisplayConnect()
        },
        onDisplayDisconnect() {
          c.onDisplayDisconnect()
        },
        onAccessoryConnectSuccess() {
          c.onAccessoryConnectSuccess()
        },
        onAccessoryConnectFailure() {
          c.onAccessoryConnectFailure()
        },
        onAccessoryDisconnect() {
          c.onAccessoryDisconnect()
        },
        onWrite() {
          c.onWrite()
        },
        onStatusUpdate(a) {
          c.onStatusUpdate({ status: a })
        },
      }
      g = 0
      if (this.isPolling || this.isWaitWriteCallback) {
        if (b.getElementsByTagName('eventcount') != 0)
          try {
            g = b.getElementsByTagName('eventcount').item(0).textContent
          } catch (m) {}
        for (b = 0; b < g; b++)
          for (let h = 0; h < e.length; h++) {
            const k = e
              .item(h)
              .getElementsByTagName('action')
              .item(0).textContent
            if (
              e.item(h).getElementsByTagName('number').item(0).textContent == b
            )
              if (k === 'onBarcodeDataReceive' || k === 'onStatusUpdate') {
                const l = e
                  .item(h)
                  .getElementsByTagName('data')
                  .item(0).textContent
                try {
                  f[k](l)
                } catch (n) {}
              } else
                try {
                  f[k]()
                } catch (p) {}
          }
      }
    }
  } else
    a.managerCode !== 1200 &&
      a.managerCode !== 2001 &&
      !(d && a.managerCode === 1010) &&
      this._clearClaimId()
  return a
}
StarWebPrintExtManager.prototype._clearClaimId = function () {
  this.claimId = 0
  this.isPolling = !1
}
StarWebPrintExtManager.prototype._waitCallPolling = function () {
  const a = this
  setTimeout(function () {
    a._polling()
  }, a._getPollingInterval())
}
StarWebPrintExtManager.prototype._sendExtMessage = function (a) {
  a.isNeedExternalCallBack &&
    (a.requestType === 'write'
      ? (this.isWaitWriteCallback = true)
      : (this.isWaitCallback = true))
  let d = '<extmanager '
  if (
    a.requestType === 'claim' ||
    a.requestType === 'read' ||
    a.requestType === 'write'
  ) {
    d += 'request_type="' + a.requestType + '" '
    a.requestType === 'claim' &&
      (d += 'polling_timeout="' + this._getPollingTimeout() + '" ')
  } else {
    d += 'request_type="release" '
  }

  this.claimId !== 0 && (d += 'claimid="' + this.claimId + '" ')

  d += '>'
  a.requestType === 'write' && a.request && (d += a.request)
  d = d + '</extmanager>'
  const e = new StarWebPrintTrader()
  const b = this
  const f = this._getPollingTimeout()
  const g = a.requestType === 'write'
  if (g) {
    e.onReceive = function (c) {
      c = b._analyzeXml(c, g)
      if (a.isNeedExternalCallBack || c.managerSuccess == 'false') {
        if (b.onReceive != 0) b.onReceive(c)
        b.isWaitWriteCallback = !1
      }
    }
    e.onError = function (a) {
      b.isWaitWriteCallback = !1
      b._clearClaimId()
      if (b.onError != 0) b.onError(a)
    }
  } else {
    e.onReceive = (c) => {
      c = b._analyzeXml(c, g)
      if (a.isNeedExternalCallBack || c.managerSuccess == 'false') {
        if (b.onReceive != 0) b.onReceive(c)
        b.isWaitCallback = !1
      }
      b.isPolling && b._waitCallPolling()
    }
    e.onError = function (a) {
      b.isWaitCallback = !1
      b._clearClaimId()
      if (b.onError != 0) b.onError(a)
    }
  }
  e.sendMessage({ url: this.url, request: d, timeout: f })
}

if (typeof module !== 'undefined' && module.exports) {
  module.exports = StarWebPrintExtManager
}
