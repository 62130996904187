import { MutationTree, ActionTree } from 'vuex'
import dayjs from 'dayjs'

import {
  Reservation,
  Repositories,
  Pagination,
  Staff,
  ReservationLimit,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  reservations: [] as Reservation[],
  allReservations: [] as Reservation[],
  reservationPagination: {} as Pagination,
  availableStaff: [{ id: -1, name: '指名なし' }] as Staff[],
  reservationLimit: {} as ReservationLimit,
  notificationReservationData: {} as Reservation,
  opacityReservationId: -1,
  customerUpcomingReservations: [] as Reservation[],
  mergeReservations: [] as Reservation[],
})

export type ReservationState = ReturnType<typeof state>

export const mutations: MutationTree<ReservationState> = {
  setReservations(state, reservations: Reservation[]) {
    state.reservations = reservations
  },
  setMergeReservations(state, reservations: Reservation[]) {
    state.mergeReservations = reservations
  },
  setAllReservations(state, reservations: Reservation[]) {
    state.allReservations = reservations
  },
  setReservationPagination(state, pagination: Pagination) {
    state.reservationPagination = pagination
  },
  setOpacityReservationId(state, id: number) {
    state.opacityReservationId = id
  },
  addNewReservation(state, reservationData: Reservation) {
    state.reservations.push(reservationData)
    state.allReservations.push(reservationData)
  },
  updateReservation(state, reservationData: Reservation) {
    const reservationsIndex = state.reservations.findIndex(
      (item) => item.id === reservationData.id
    )
    const allReservationsIndex = state.allReservations.findIndex(
      (item) => item.id === reservationData.id
    )
    if (reservationsIndex !== -1) {
      state.reservations.splice(reservationsIndex, 1, reservationData)
    }
    if (allReservationsIndex !== 1) {
      state.allReservations.splice(allReservationsIndex, 1, reservationData)
    }
  },
  deleteReservation(state, id: number) {
    const reservationsIndex = state.reservations.findIndex(
      (item) => item.id === id
    )
    const allReservationsIndex = state.allReservations.findIndex(
      (item) => item.id === id
    )
    if (reservationsIndex !== -1) {
      state.reservations.splice(reservationsIndex, 1)
    }
    if (allReservationsIndex !== 1) {
      state.allReservations.splice(allReservationsIndex, 1)
    }
  },
  setAvailableStaff(state, staff: Staff[]) {
    state.availableStaff = [{ id: -1, name: '指名なし' } as Staff, ...staff]
  },
  setReservationLimit(state, reservationLimit: ReservationLimit) {
    state.reservationLimit = reservationLimit
  },
  showEditReservationForm(state, reservationData: Reservation) {
    state.notificationReservationData = reservationData
  },
  setCustomerUpcomingReservations(state, upcomingReservations: Reservation[]) {
    state.customerUpcomingReservations = upcomingReservations
  },
}

export const actions: ActionTree<ReservationState, RootState> = {
  async fetchReservations(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      page: number
      per: number
      customer_ids?: number[]
      session?: string
      branchId?: number
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { pagination } = (await params.api.reservationRepo.fetchReservations(
      params.branchId || branchId,
      {
        start_date: params.start_date,
        end_date: params.end_date,
        page: 1,
        per: 1,
        customer_ids: params.customer_ids,
        session: params.session ?? 'all',
      }
    )) as { reservations: Reservation[]; pagination: Pagination }
    const res = (await params.api.reservationRepo.fetchReservations(branchId, {
      start_date: params.start_date,
      end_date: params.end_date,
      page: 1,
      per: pagination.total_entries || 1,
      customer_ids: params.customer_ids,
      session: params.session ?? 'all',
    })) as { reservations: Reservation[]; pagination: Pagination }
    commit(
      'setReservations',
      res.reservations.filter(
        (r) => r.reservation_type === 'blocking' || r.status !== 'cancelled'
      )
    )
    commit('setAllReservations', res.reservations)
    commit('setReservationPagination', res.pagination)
  },
  async fetchMergeReservation(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      page: number
      per: number
      customer_ids?: number[]
      session?: string
      has_transaction: boolean
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    let res = (await params.api.reservationRepo.fetchReservations(branchId, {
      start_date: params.start_date,
      end_date: params.end_date,
      page: 1,
      per: 10,
      customer_ids: params.customer_ids,
      session: params.session ?? 'all',
      has_transaction: params.has_transaction,
    })) as { reservations: Reservation[]; pagination: Pagination }
    if (res.pagination.total_entries > 10) {
      res = (await params.api.reservationRepo.fetchReservations(branchId, {
        start_date: params.start_date,
        end_date: params.end_date,
        page: 1,
        per: res.pagination.total_entries || 1,
        customer_ids: params.customer_ids,
        session: params.session ?? 'all',
        has_transaction: params.has_transaction,
      })) as { reservations: Reservation[]; pagination: Pagination }
    }
    commit(
      'setMergeReservations',
      res.reservations.filter(
        (r) => r.reservation_type === 'blocking' || r.status !== 'cancelled'
      )
    )
  },

  async createReservation(
    { commit, state, rootState },
    params: {
      api: Repositories
      reservationData: Reservation
      isFollowup?: boolean
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    if (params.reservationData.reservation_type === 'reservation') {
      delete params.reservationData.customer_name
    }
    const reservation = await params.api.reservationRepo.createReservation(
      branchId,
      params.reservationData
    )
    const index = state.reservations.findIndex(
      (res) => res.id === reservation.id
    )
    if (index === -1) {
      commit('addNewReservation', reservation)
    }
    if (params.isFollowup)
      commit('reservationForm/setFollowUpReservationData', reservation, {
        root: true,
      })
    commit(
      'reservationForm/setReservationItems',
      reservation.reservation_items,
      { root: true }
    )
  },

  async updateReservation(
    { commit, rootState },
    params: { api: Repositories; reservationData: Reservation }
  ) {
    if (
      !dayjs(params.reservationData.start_time).isSame(
        this.$dayjs(params.reservationData.end_time),
        'day'
      )
    ) {
      commit(
        'snackbar/showMessage',
        { content: '申し訳ありませんが、予約できません' },
        { root: true }
      )
      return
    }
    const branchId = (rootState as any).auth.user.activeBranch

    if (params.reservationData.reservation_type === 'reservation') {
      delete params.reservationData.customer_name
    }
    if (params.reservationData.staff_id === -1) {
      delete params.reservationData.staff_id
    }
    const reservation = await params.api.reservationRepo.updateReservation(
      branchId,
      { ...params.reservationData, payment_status: undefined }
    )
    commit('updateReservation', reservation)
    commit(
      'reservationForm/setReservationItems',
      reservation.reservation_items,
      { root: true }
    )
    commit(
      'reservationForm/setReservationTickets',
      reservation.reservation_tickets,
      { root: true }
    )
    commit('reservationForm/setReservationData', reservation, { root: true })
    commit(
      'reservationForm/updateReservationData',
      {
        key: 'payment_status',
        value: reservation.payment_status,
      },
      { root: true }
    )
  },
  async deleteReservation(
    { commit, rootState },
    params: { api: Repositories; reservationId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    await params.api.reservationRepo.deleteReservation(
      branchId,
      params.reservationId
    )
    commit('deleteReservation', params.reservationId)
  },

  async fetchAvailableStaff(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      start_time?: string
      end_time?: string
      account_status?: string[]
      employment_status?: string[]
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const { staff } = await params.api.staffRepo.fetchBranchStaff(branchId, {
      page: params.page,
      per: params.per,
      field_therapist: true,
      start_time: params.start_time,
      end_time: params.end_time,
      account_status: params.account_status,
      employment_status: params.employment_status,
    })
    commit('setAvailableStaff', staff)
  },

  // Reservation limit
  async getReservationLimit(
    { commit, rootState },
    params: {
      api: Repositories
      branchId?: number
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { reservation_limit } =
      await params.api.reservationRepo.fetchReservationLimit(
        params.branchId || branchId
      )
    commit('setReservationLimit', reservation_limit)
  },
  async updateReservationLimit(
    { commit, rootState },
    params: {
      api: Repositories
      reservationLimit: ReservationLimit
      branchId?: number
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { reservation_limit } =
      await params.api.reservationRepo.updateReservationLimit(
        params.branchId || branchId,
        params.reservationLimit
      )
    commit('setReservationLimit', reservation_limit)
  },

  // fetching reservations by page.
  // In the above fetchReservations method, all reservation entries are fetched as they are required for the calendar component
  // #TODO: Refactor the code for fetch and get Reservations into a single function

  async getReservations(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      page: number
      per: number
      category_ids?: number[]
      staff_ids?: number[]
      customer_ids?: number[]
      session?: string
      status?: string
      type?: 'analytical'
      tag_ids?: number[]
      sort?: string[]
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { reservations, pagination } =
      (await params.api.reservationRepo.fetchReservations(branchId, {
        start_date: params.start_date,
        end_date: params.end_date,
        page: params.page,
        per: params.per,
        customer_ids: params.customer_ids,
        staff_ids: params.staff_ids,
        category_ids: params.category_ids,
        session: params.session ?? 'all',
        status: params.status,
        type: params.type,
        tag_ids: params.tag_ids,
        sort: params.sort,
      })) as { reservations: Reservation[]; pagination: Pagination }
    commit(
      'setReservations',
      params.status === 'cancelled'
        ? reservations
        : reservations.filter((r) => r.status !== 'cancelled')
    )
    commit('setAllReservations', reservations)
    commit('setReservationPagination', pagination)
  },
  async fetchCustomerUpcomingReservations(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      customer_ids?: number[]
      session?: string
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const res = (await params.api.reservationRepo.fetchReservations(branchId, {
      start_date: params.start_date,
      end_date: params.end_date,
      page: 1,
      per: 5,
      customer_ids: params.customer_ids,
      session: params.session ?? 'all',
      sort: ['end_date.asc'],
    })) as { reservations: Reservation[]; pagination: Pagination }
    commit(
      'setCustomerUpcomingReservations',
      res.reservations.filter(
        (r) => r.reservation_type === 'blocking' || r.status !== 'cancelled'
      )
    )
  },
}
