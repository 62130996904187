import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Branch, BranchRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): BranchRepo => ({
  async fetchBranches(params?: {
    status?: string
    page?: number
    per?: number
  }) {
    try {
      const res = await $axios.get('therapists/branches', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchBranchLocation(branchId: number) {
    try {
      const res = await $axios.get(`therapists/branches/${branchId}`)
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createBranch(branch: Branch) {
    try {
      const res = await $axios.post(`therapists/branches`, {
        branch,
      })
      if (res.status === 201) {
        return res.data.branch
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async showBranch(
    branchId: number,
    karte_access_token?: string,
    branch_access_token?: string
  ) {
    try {
      const res = await $axios.get(`therapists/branches/${branchId}`, {
        params: {
          karte_access_token,
          branch_access_token,
        },
      })
      if (res.status === 200) {
        return res.data.branch
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateBranch(branch: Branch) {
    try {
      const res = await $axios.put(`therapists/branches/${branch.id}`, {
        branch,
      })
      if (res.status === 200) {
        return res.data.branch
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async fetchStaffBranches(
    staffId: number,
    params?: { page?: number; per?: number }
  ) {
    try {
      const res = await $axios.get(`therapists/staff/${staffId}/branches`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchProductBranches(
    productId: number,
    params?: { page?: number; per?: number }
  ) {
    try {
      const res = await $axios.get(
        `therapists/products/${productId}/branches`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
