import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { DownloadRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): DownloadRepo => ({
  async fetchDownloads() {
    try {
      const res = await $axios.get('therapists/downloads')
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchDownloadWithId(downloadId: number) {
    try {
      const res = await $axios.get(`therapists/downloads/${downloadId}`)
      if (res.status === 200) {
        return res.data.download
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
