import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import {
  CrmBranchAnalytics,
  CrmCustomerCommonAnalytics,
  CrmRevenueCommonAnalytics,
  CrmCustomerCourseCategory,
  CrmCustomerPerDayAPI,
  CrmCustomerTimeOfDay,
  CrmCustomerRepeatRate,
  Repositories,
  CrmStaffResource,
  CrmCustomerAdvertisingSource,
  CrmCustomerApiQueryParams,
  CrmFilterItems,
  CrmSymptomsData,
  CrmWorkingDays,
  CrmCustomerCourseCategoryCohort,
  CrmCustomerCourseCategoryType,
} from '~/types'

export const state = () => ({
  crmBranchAnalytics: {
    current: {
      analytics: {
        customer: 0,
        revenue: 0,
        revenueWithInsurance: 0,
        product: 0,
        averagePrice: 0,
      },
      start_date: '',
      end_date: '',
    },
    previous: {
      analytics: {
        customer: 0,
        revenue: 0,
        revenueWithInsurance: 0,
        product: 0,
        averagePrice: 0,
      },
      start_date: '',
      end_date: '',
    },
  } as CrmBranchAnalytics,
  crmCustomerCommonAnalytics: {
    current: {
      avg_visit_count: 0,
      total_treatment_count: 0,
      avg_stay_time: 0,
      max_cust_perday: 0,
      min_cust_perday: 0,
      customer_cohort: [],
      customer_gender: [],
      customer_age: [],
      non_visiting_customers: [],
      start_date: '',
      end_date: '',
      avg_customer_count: 0,
      customer_ltv: 0,
      customer_ltv_with_insurance: 0,
    },
    comparison: {
      avg_visit_count: 0,
      total_treatment_count: 0,
      avg_stay_time: 0,
      max_cust_perday: 0,
      min_cust_perday: 0,
      customer_cohort: [],
      customer_gender: [],
      customer_age: [],
      non_visiting_customers: [],
      start_date: '',
      end_date: '',
      avg_customer_count: 0,
      customer_ltv: 0,
      customer_ltv_with_insurance: 0,
    },
  } as CrmCustomerCommonAnalytics,
  crmRevenueCommonAnalytics: {
    current: {
      avg_sales_per_day: 0,
      avg_sales_per_visit: 0,
      max_sales_per_day: 0,
      min_sales_per_day: 0,
      payment_status: [],
      payment_method: [],
      customer_type: [],
      customer_age: [],
      productivity: 0,
      utilization_rate: 0,
      advertising_customer_count: 0,
      cost_per_acquisition: 0,
    },
    comparison: {
      avg_sales_per_day: 0,
      avg_sales_per_visit: 0,
      max_sales_per_day: 0,
      min_sales_per_day: 0,
      payment_status: [],
      payment_method: [],
      customer_type: [],
      customer_age: [],
      productivity: 0,
      utilization_rate: 0,
      advertising_customer_count: 0,
      cost_per_acquisition: 0,
    },
  } as CrmRevenueCommonAnalytics,
  crmSymptomsAnalytics: {} as CrmSymptomsData,
  countPerDay: {} as CrmCustomerPerDayAPI,
  workingDays: { current: 0, total: 0 } as CrmWorkingDays,
  showEstimates: false,
  timeOfDay: {} as CrmCustomerTimeOfDay,
  courseCategory: {} as CrmCustomerCourseCategory,
  advertisingSource: {} as CrmCustomerAdvertisingSource,
  customerRepeatRate: {} as CrmCustomerRepeatRate,
  loadingList: {
    isBranchAnalyticsLoading: false,
    isCustomerCommonAnalyticsLoading: false,
    isRevenueCommonAnalyticsLoading: false,
    isCountPerDayLoading: false,
    isCustomerRepeatRateLoading: false,
    isTimeOfDayLoading: false,
    isCourseCategoryLoading: false,
    isAdvertisingSourceLoading: false,
    isCrmSymptomLoading: false,
  },
  crmStaffResource: [] as CrmStaffResource[],
  crmErrorList: {
    branchAnalytics: false,
    customerCommonAnalytics: false,
    revenueCommonAnalytics: false,
    countPerDay: false,
    timeOfDay: false,
    courseCategory: false,
    advertisingSource: false,
    customerRepeatRate: false,
    crmSymptomAnalytics: false,
  },
  selectedCourseCategoryType: 'total',
  selectAdSources: [] as string[],
  isSalesCustomerFilter: false,
  filterItems: [] as CrmFilterItems[],
})

export type CRMAnalyticState = ReturnType<typeof state>

export const mutations: MutationTree<CRMAnalyticState> = {
  resetFilter(state) {
    state.filterItems = []
  },
  addFilter(state, item: CrmFilterItems) {
    state.filterItems.push(item)
  },
  updateFilter(state, item: CrmFilterItems) {
    const index = state.filterItems.findIndex(
      (stateItem) => stateItem.key === item.key
    )
    state.filterItems.splice(index, 1, item)
  },
  deleteFilter(state, item: CrmFilterItems) {
    const index = state.filterItems.findIndex(
      (stateItem) => stateItem.key === item.key
    )
    state.filterItems.splice(index, 1)
  },
  updateIsSalesCustomerFilter(state, isSalesCustomerFilter: boolean) {
    state.isSalesCustomerFilter = isSalesCustomerFilter
  },
  setCrmBranchAnalytics(state, crmBranchAnalytics: CrmBranchAnalytics) {
    state.crmBranchAnalytics = crmBranchAnalytics
  },
  setCrmSymptomAnalytics(state, crmSymptomsAnalytics: CrmSymptomsData) {
    state.crmSymptomsAnalytics = crmSymptomsAnalytics
  },
  setCustomerCommonAnalytics(
    state,
    crmCustomerCommonAnalytics: CrmCustomerCommonAnalytics
  ) {
    state.crmCustomerCommonAnalytics = crmCustomerCommonAnalytics
  },
  setRevenueCommonAnalytics(
    state,
    crmRevenueCommonAnalytics: CrmRevenueCommonAnalytics
  ) {
    state.crmRevenueCommonAnalytics = crmRevenueCommonAnalytics
  },
  setCountPerDay(state, countPerDay: CrmCustomerPerDayAPI) {
    state.countPerDay = countPerDay
  },
  setTimeOfDay(state, timeOfDay: CrmCustomerTimeOfDay) {
    state.timeOfDay = timeOfDay
  },
  setWorkingDays(state, workingDays: CrmWorkingDays) {
    state.workingDays = workingDays
  },
  setShowEstimates(state, showEstimates: boolean) {
    state.showEstimates = showEstimates
  },
  setCourseCategory(state, courseCategory: CrmCustomerCourseCategory) {
    state.courseCategory = courseCategory
  },
  setAdvertisingSource(state, advertisingSource: CrmCustomerAdvertisingSource) {
    state.advertisingSource = advertisingSource
  },
  setCustomerRepeatRate(state, customerRepeatRate: CrmCustomerRepeatRate) {
    state.customerRepeatRate = customerRepeatRate
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  setCrmStaffResource(state, crmStaffResource: CrmStaffResource[]) {
    state.crmStaffResource = crmStaffResource
  },
  setCrmError(state, error: { key: string; value: boolean }) {
    state.crmErrorList[error.key] = error.value
  },
  setSelectedCourseCategoryType(state, selectedCourseCategoryType: string) {
    state.selectedCourseCategoryType = selectedCourseCategoryType
  },
  setSelectAdSources(state, selectAdSources: string[]) {
    state.selectAdSources = selectAdSources
  },
}

export const actions: ActionTree<CRMAnalyticState, RootState> = {
  async getCrmBranchAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      previous_start_date?: string
      previous_end_date?: string
    } & CrmCustomerApiQueryParams
  ) {
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    commit('setLoading', { key: 'isBranchAnalyticsLoading', loading: true })
    try {
      const { common } =
        await params.api.crmAnalyticsRepo.fetchCrmBranchAnalytics({
          branch_ids: branchIds,
          staff_ids: staffIds,
          start_date: params.start_date,
          end_date: params.end_date,
          comparison_start_date: params.previous_start_date,
          comparison_end_date: params.previous_end_date,
          gender: params.gender,
          age: params.age,
          customer_types: params.customer_types,
        })
      const currentRevenue = common.sales?.current.value || 0
      const currentCustomerCount = common.customer_count?.current.value || 0
      const currentInsurance = common.sales?.current.insurance || 0
      const currentEstimate = common.sales?.current.estimate || 0
      const previousEstimate = common.sales?.previous.estimate || 0
      const previousRevenue = common.sales?.previous.value || 0
      const previousCustomerCount = common.customer_count?.previous.value || 0
      const previousInsurance = common.sales?.previous.insurance || 0
      const formatCrmBranchAnalytics = {
        current: {
          analytics: {
            customer: currentCustomerCount || 0,
            revenue: currentRevenue || 0,
            revenueWithInsurance: currentInsurance || 0,
            estimate: currentEstimate || 0,
            product: common.items_sold_count?.current.value || 0,
            salesTarget: common.sales?.current.target_amount || 0,
            averagePrice: currentRevenue / currentCustomerCount || 0,
            averagePriceWithInsurance:
              (currentRevenue + currentInsurance) / currentCustomerCount || 0,
          },
        },
        previous: {
          analytics: {
            customer: previousCustomerCount || 0,
            revenue: previousRevenue || 0,
            revenueWithInsurance: previousInsurance || 0,
            estimate: previousEstimate || 0,
            product: common.items_sold_count?.previous.value || 0,
            salesTarget: common.sales?.previous.target_amount || 0,
            averagePrice: previousRevenue / previousCustomerCount || 0,
            averagePriceWithInsurance:
              (previousRevenue + previousInsurance) / previousCustomerCount ||
              0,
          },
        },
      }
      commit('setCrmBranchAnalytics', formatCrmBranchAnalytics)
    } catch (error) {
      commit('setCrmError', { key: 'branchAnalytics', value: true })
    } finally {
      commit('setLoading', { key: 'isBranchAnalyticsLoading', loading: false })
    }
  },
  async getCustomerCommonAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      previous_start_date?: string
      previous_end_date?: string
    }
  ) {
    commit('setLoading', {
      key: 'isCustomerCommonAnalyticsLoading',
      loading: true,
    })
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    try {
      const common =
        await params.api.crmAnalyticsRepo.fetchCustomerCommonAnalytics({
          staff_ids: staffIds,
          branch_ids: branchIds,
          start_date: params.start_date,
          end_date: params.end_date,
          comparison_start_date: params.previous_start_date,
          comparison_end_date: params.previous_end_date,
        })

      commit('setCustomerCommonAnalytics', common)
    } catch (error) {
      commit('setCrmError', { key: 'customerCommonAnalytics', value: true })
    } finally {
      commit('setLoading', {
        key: 'isCustomerCommonAnalyticsLoading',
        loading: false,
      })
    }
  },
  async getCrmSymptomAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      previous_start_date?: string
      previous_end_date?: string
    }
  ) {
    commit('setLoading', {
      key: 'isCrmSymptomLoading',
      loading: true,
    })
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    try {
      const common = await params.api.crmAnalyticsRepo.fetchCrmSymptomAnalytics(
        {
          staff_ids: staffIds,
          branch_ids: branchIds,
          start_date: params.start_date,
          end_date: params.end_date,
          comparison_start_date: params.previous_start_date,
          comparison_end_date: params.previous_end_date,
        }
      )

      commit('setCrmSymptomAnalytics', common)
    } catch (error) {
      commit('setCrmError', { key: 'crmSymptomAnalytics', value: true })
    } finally {
      commit('setLoading', {
        key: 'isCrmSymptomLoading',
        loading: false,
      })
    }
  },
  async getRevenueCommonAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      comparison_start_date?: string
      comparison_end_date?: string
    } & CrmCustomerApiQueryParams
  ) {
    commit('setLoading', {
      key: 'isRevenueCommonAnalyticsLoading',
      loading: true,
    })
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    try {
      const crmRevenueCommonAnalytics =
        await params.api.crmAnalyticsRepo.fetchRevenueCommonAnalytics({
          branch_ids: branchIds,
          start_date: params.start_date,
          end_date: params.end_date,
          staff_ids: staffIds,
          comparison_start_date: params.comparison_start_date,
          comparison_end_date: params.comparison_end_date,
          gender: params.gender,
          age: params.age,
          customer_types: params.customer_types,
        })
      commit('setRevenueCommonAnalytics', crmRevenueCommonAnalytics)
    } catch (error) {
      commit('setCrmError', { key: 'revenueCommonAnalytics', value: true })
    } finally {
      commit('setLoading', {
        key: 'isRevenueCommonAnalyticsLoading',
        loading: false,
      })
    }
  },
  async getWorkingDays(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      comparison_start_date?: string
      comparison_end_date?: string
    }
  ) {
    commit('setLoading', {
      key: 'isAvgWorkingDaysLoading',
      loading: true,
    })
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    try {
      const workingDays = { current: 0, total: 0 }

      const currentDate = this.$dayjs().format('YYYY/MM/DD')
      const showEstimates: boolean = this.$dayjs(currentDate).isBetween(
        params.start_date,
        params.end_date,
        'day',
        '[)'
      )
      commit('setShowEstimates', showEstimates)

      const currentResult =
        await params.api.crmAnalyticsRepo.fetchWorkingDaysCount({
          branch_ids: branchIds,
          start_date: params.start_date,
          end_date: this.$dayjs().toString(),
        })
      if (currentResult.working_day_count)
        workingDays.current = currentResult.working_day_count

      const totalResult =
        await params.api.crmAnalyticsRepo.fetchWorkingDaysCount({
          branch_ids: branchIds,
          start_date: params.start_date,
          end_date: params.end_date,
        })

      if (totalResult.working_day_count)
        workingDays.total = totalResult.working_day_count

      commit('setWorkingDays', workingDays)
    } catch (error) {
      commit('setCrmError', { key: 'workingDays', value: true })
    } finally {
      commit('setLoading', {
        key: 'isAvgWorkingDaysLoading',
        loading: false,
      })
    }
  },
  async getCountPerDay(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      comparison_start_date?: string
      comparison_end_date?: string
    }
  ) {
    commit('setLoading', {
      key: 'isCountPerDayLoading',
      loading: true,
    })
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    try {
      const countPerDay =
        await params.api.crmAnalyticsRepo.fetchCustomerDayCount({
          staff_ids: staffIds,
          branch_ids: branchIds,
          start_date: params.start_date,
          end_date: params.end_date,
          comparison_start_date: params.comparison_start_date,
          comparison_end_date: params.comparison_end_date,
        })

      commit('setCountPerDay', countPerDay)
    } catch (error) {
      commit('setCrmError', { key: 'countPerDay', value: true })
    } finally {
      commit('setLoading', {
        key: 'isCountPerDayLoading',
        loading: false,
      })
    }
  },
  async getTimeOfDay(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      previous_start_date?: string
      previous_end_date?: string
    }
  ) {
    commit('setLoading', {
      key: 'isTimeOfDayLoading',
      loading: true,
    })
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    try {
      const { time_of_day } =
        await params.api.crmAnalyticsRepo.fetchCustomerTimeOfDay({
          branch_ids: branchIds,
          staff_ids: staffIds,
          start_date: params.start_date,
          end_date: params.end_date,
          comparison_start_date: params.previous_start_date,
          comparison_end_date: params.previous_end_date,
        })

      commit('setTimeOfDay', time_of_day)
    } catch (error) {
      commit('setCrmError', { key: 'timeOfDay', value: true })
    } finally {
      commit('setLoading', {
        key: 'isTimeOfDayLoading',
        loading: false,
      })
    }
  },
  async getCourseCategory(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      previous_start_date: string
      previous_end_date: string
    }
  ) {
    commit('setLoading', {
      key: 'isCourseCategoryLoading',
      loading: true,
    })
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    try {
      let previous_start_date = params.previous_start_date
      let previous_end_date = params.previous_end_date
      if (!params.previous_end_date) {
        previous_end_date = this.$dayjs(params.start_date)
          .subtract(1, 'day')
          .format('YYYYMMDD')
      }
      if (!params.previous_start_date) {
        const dayDifference = this.$dayjs(params.end_date).diff(
          this.$dayjs(params.start_date),
          'day'
        )
        previous_start_date = this.$dayjs(params.start_date)
          .subtract(1 + dayDifference, 'day')
          .format('YYYYMMDD')
      }

      const current =
        await params.api.crmAnalyticsRepo.fetchCustomerCourseCategory_v2({
          branch_ids: branchIds,
          start_date: params.start_date,
          end_date: params.end_date,
          staff_ids: staffIds,
        })

      const comparison =
        await params.api.crmAnalyticsRepo.fetchCustomerCourseCategory_v2({
          branch_ids: branchIds,
          start_date: previous_start_date,
          end_date: previous_end_date,
          staff_ids: staffIds,
        })

      const productTypes_v2 = current

      const formatObject = (
        finalObj: CrmCustomerCourseCategoryType,
        currentObj: CrmCustomerCourseCategoryType,
        comparisonObj: CrmCustomerCourseCategoryType
      ) => {
        if (currentObj.sales) finalObj.current_sales = currentObj.sales || 0
        finalObj.current_customer_count = currentObj.customer_count || 0
        finalObj.current_item_sold_count = currentObj.item_sold_count || 0

        if (comparisonObj.sales) finalObj.comparison_sales = comparisonObj.sales
        if (comparisonObj.customer_count)
          finalObj.comparison_customer_count = comparisonObj.customer_count
        if (comparisonObj.item_sold_count)
          finalObj.comparison_item_sold_count = comparisonObj.item_sold_count

        if (currentObj.insurance_amount)
          finalObj.current_insurance_amount = currentObj.insurance_amount

        if (comparisonObj.insurance_amount)
          finalObj.comparison_insurance_amount = comparisonObj.insurance_amount

        if (finalObj.sales) delete finalObj.sales
        if (finalObj.customer_count) delete finalObj.customer_count
        if (finalObj.item_sold_count) delete finalObj.item_sold_count
      }

      for (
        let cohortIdx = 0;
        cohortIdx < current.visiting_customers.length;
        cohortIdx++
      ) {
        const cohort = current.visiting_customers[cohortIdx]
        for (
          let typeIdx = 0;
          typeIdx < cohort.product_types.length;
          typeIdx++
        ) {
          const finalObj =
            productTypes_v2.visiting_customers[cohortIdx].product_types[typeIdx]
          const currentObj =
            current.visiting_customers[cohortIdx].product_types[typeIdx]
          const comparisonObj =
            comparison.visiting_customers[cohortIdx].product_types[typeIdx]
          formatObject(finalObj, currentObj, comparisonObj)
        }
        const finalObj = productTypes_v2.visiting_customers[cohortIdx].total
        const currentObj = current.visiting_customers[cohortIdx].total
        const comparisonObj = comparison.visiting_customers[cohortIdx].total
        formatObject(finalObj, currentObj, comparisonObj)
      }

      // older version, to be restored if v2 failed

      // const productTypes_v1 =
      //   await params.api.crmAnalyticsRepo.fetchCustomerCourseCategory({
      //     branch_ids: branchIds,
      //     start_date: params.start_date,
      //     end_date: params.end_date,
      //     staff_ids: staffIds,
      //     comparison_start_date: params.previous_start_date,
      //     comparison_end_date: params.previous_end_date,
      //   })

      commit('setCourseCategory', productTypes_v2)
    } catch (error) {
      commit('setCrmError', { key: 'courseCategory', value: true })
    } finally {
      commit('setLoading', {
        key: 'isCourseCategoryLoading',
        loading: false,
      })
    }
  },
  async getAdvertisingSource(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      previous_start_date?: string
      previous_end_date?: string
    }
  ) {
    commit('setLoading', {
      key: 'isAdvertisingSourceLoading',
      loading: true,
    })
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    try {
      const visiting_customers =
        await params.api.crmAnalyticsRepo.fetchCustomerAdvertisingSource({
          branch_ids: branchIds,
          start_date: params.start_date,
          end_date: params.end_date,
          staff_ids: staffIds,
          comparison_start_date: params.previous_start_date,
          comparison_end_date: params.previous_end_date,
        })
      commit('setAdvertisingSource', visiting_customers)
    } catch (error) {
      commit('setCrmError', { key: 'advertisingSource', value: true })
    } finally {
      commit('setLoading', {
        key: 'isAdvertisingSourceLoading',
        loading: false,
      })
    }
  },
  async getCustomerRepeatRate(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      previous_start_date?: string
      previous_end_date?: string
    }
  ) {
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    try {
      commit('setLoading', {
        key: 'isCustomerRepeatRateLoading',
        loading: true,
      })
      const customerRepeatRate =
        await params.api.crmAnalyticsRepo.fetchCustomerRepeatRate({
          branch_ids: branchIds,
          start_date: params.start_date,
          end_date: params.end_date,
          comparison_start_date: params.previous_start_date,
          comparison_end_date: params.previous_end_date,
          staff_ids: staffIds,
        })
      commit('setCustomerRepeatRate', customerRepeatRate)
    } catch (error) {
      commit('setCrmError', { key: 'customerRepeatRate', value: true })
    } finally {
      commit('setLoading', {
        key: 'isCustomerRepeatRateLoading',
        loading: false,
      })
    }
  },
  async getCrmStaffResource(
    { commit },
    params: {
      api: Repositories
      branch_ids: number[]
      start_date: string
      end_date: string
      comparison_start_date?: string
      comparison_end_date?: string
      product_types?: string[]
      customer_types?: string[]
      sort?: string[]
    }
  ) {
    const { staffs } = await params.api.crmAnalyticsRepo.fetchCrmStaffResource({
      branch_ids: params.branch_ids,
      start_date: params.start_date,
      end_date: params.end_date,
      comparison_start_date: params.comparison_start_date,
      comparison_end_date: params.comparison_end_date,
      product_types: params.product_types,
      customer_types: params.customer_types,
      sort: params.sort,
    })
    commit('setCrmStaffResource', staffs)
  },
}
