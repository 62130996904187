import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { CMS, CMSContentRepo } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any,
  type: CMS.ContentType
): CMSContentRepo => ({
  async fetchContent(
    branchId: number,
    params?: {
      page?: number
      per?: number
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/cms/${type}`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createContent(branchId: number, contentObject: any) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/cms/${type}`,
        contentObject
      )
      if (res.status === 201) {
        return Object.values(res.data)[0]
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async fetchContentWithId(branchId: number, contentId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/cms/${type}/${contentId}`
      )
      if (res.status === 200) {
        return Object.values(res.data)[0]
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateContent(branchId: number, contentObject: any) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/cms/${type}/${contentObject.id}`,
        contentObject
      )
      if (res.status === 200) {
        return Object.values(res.data)[0]
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteContent(branchId: number, contentId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/cms/${type}/${contentId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
