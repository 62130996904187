import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { PluginsRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): PluginsRepo => ({
  async fetchPlugins(params?: {
    page?: number
    per?: number
    branch_access_token?: string
  }) {
    try {
      const res = await $axios.get(`therapists/plugins`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchPluginByName(pluginName: string) {
    try {
      const res = await $axios.get(`therapists/plugins/${pluginName}`)
      if (res.status === 200) {
        return res.data.plugin
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
