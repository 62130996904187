import {
  Server,
  Response,
  ActiveModelSerializer,
  Model,
  Factory,
} from 'miragejs'

window.onNuxtReady &&
  window.onNuxtReady(async ({ context }) => {
    if (context.$config.includeMockServer) {
      console.log('mirage js included')
      makeServer()
      await context.store.dispatch('nuxtClientInit', context)
    }
  })

const ApplicationSerializer = ActiveModelSerializer.extend({})

export function makeServer({ environment = 'development' } = {}) {
  const server = new Server({
    environment,
    serializers: {
      application: ApplicationSerializer,
    },
    models: {
      customer: Model,
      shift: Model,
      shiftAssignment: Model,
      unit: Model,
      role: Model,
      permission: Model,
      productCategory: Model,
      branch: Model,
      product: Model,
      item: Model,
      staff: Model,
      licence: Model,
      certification: Model,
      reservation: Model,
    },

    factories: {
      customer: Factory.extend({
        name() {
          return 'test'
        },
        name_kana() {
          return 'test'
        },
        gender: 'male',
        telephone: '070-1872-6224',
      }),
      shift: Factory.extend({
        name(i) {
          return i
        },
        start_time: '9:00',
        end_time: '13:00',
        break_duration: 60,
      }),
      shiftAssignment: Factory.extend({
        date: '2020-09-24',
        shift_id(i) {
          return `${i + 1}`
        },
        staff_id(i) {
          return `${i + 1}`
        },
      }),
      unit: Factory.extend({
        name(i) {
          return 'unit ' + i
        },
        machine_name: 'Machine Name',
        status: 'working',
        max_duration: 60,
      }),
      branch: Factory.extend({
        name(i) {
          return `Branch ${i}`
        },
        institute_name: 'Threesides',
        telephone(i) {
          return `111-1111-111${i}`
        },
        email(i) {
          return `branch${i}@test.com`
        },
        zip_code: '812-0027',
        prefecture: 'Fukuoka',
        city: 'Fukuoka City',
        address: '4-17',
        unit: '201',
        fax_number: '123-1231',
      }),
      productCategory: Factory.extend({
        category_code(i) {
          return `CAT00${i}`
        },
        name(i) {
          return `Massage ${i + 1}`
        },
        display_name(i) {
          return `Oil Massage ${i + 1}`
        },
        status: 'open',
      }),
      product: Factory.extend({
        category_id: '2',
        at_home: false,
        product_code(i) {
          return `PROD00${i}`
        },
        product_type: 'self_payment',
        name(i) {
          return `Product ${i}`
        },
        start_date(i) {
          return `2020-08-1${i}`
        },
        description: 'Product Description',
        price: 10000,
        quantity: 60,
        unit: 'minutes',
        tax_type: 'internal',
      }),
      item: Factory.extend({
        at_home: 'institute',
        display_name(i) {
          return `Branch Product ${i}`
        },
        end_date: '2020-09-11',
        price: 10000,
        quantity: 60,
        unit: 'minutes',
        product_category_code(i) {
          return `CAT00${i}`
        },
        product_category_name: 'cat',
        product_id: '1',
        product_code(i) {
          return `PROD00${i}`
        },
        product_name: 'Product',
        product_type: 'self_payment',
        start_date: '2020-08-11',
        tax_type: 'internal',
      }),
      staff: Factory.extend({
        staff_code: 'STAFF001',
        name(i) {
          return `staff${i}`
        },
        name_kana: 'staff kana',
        email: 'staff@test.com',
        telephone: '111-1111-1111',
        gender: 'male',
        birthday: '1990-08-11',
        job_title: 'therapist',
        role_id: '2',
        experience: 5,
        licence_titles: ['judo', 'anma'],
        employment_status: 'full_time',
        description: 'staff description',
        photo_url: 'photo_url',
        history: 'staff history',
        max_patients: 3,
        relations: {
          branches: '/staff/1/branches',
          products: 'staff/1/products',
        },
      }),
      licence: Factory.extend({
        name(i) {
          return `Licence ${i}`
        },
        slug(i) {
          return `Licence ${i}`
        },
      }),
      certification: Factory.extend({
        licence_id(i) {
          return `${i + 1}`
        },
        acquired_at: '2020-09-10',
      }),
      reservation: Factory.extend({
        status: 'confirmed',
        start_time(i) {
          return `2020-09-${i + 10}T10:10:00+0900`
        },
        end_time(i) {
          return `2020-09-${i + 10}T11:10:00+0900`
        },
        customer_id: '2',
        customer_name: '紫 式部',
        customer_name_kana: 'ムラサキ シキブ',
        customer_code: 'CUST001',
        fixed_therapist: false,
        staff_id: '2',
        staff_code: 'Staff001',
        staff_name: 'Staff Name',
        item_id: '1',
        item_code: 'ITEM001',
        item_name: 'Item name',
        utilizations(i) {
          return [
            {
              unit_id: '2',
              unit_name: 'Unit 1',
              start_time: `2020-09-${i + 10}T10:10:00+0900`,
              end_time: `2020-09-${i + 10}T10:20:00+0900`,
            },
            {
              unit_id: '3',
              unit_name: 'Unit 2',
              start_time: `2020-09-${i + 10}T10:20:00+0900`,
              end_time: `2020-09-${i + 10}T11:10:00+0900`,
            },
          ]
        },
      }),
    },

    seeds(server) {
      server.createList('customer', 30)
      server.createList('shift', 5)
      server.createList('shiftAssignment', 20)
      server.createList('unit', 6)
      server.create('role', {
        name: 'Admin',
        permission_ids: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
        ],
      })
      server.create('role', {
        name: 'Receptionist',
        permission_ids: ['2'],
      })
      server.create('role', {
        name: 'Therapist',
        permission_ids: ['2', '3'],
      })
      server.create('permission', {
        name: 'Customer',
        slug: 'customer_management',
      })
      server.create('permission', {
        name: 'Item',
        slug: 'item_management',
      })
      server.create('permission', {
        name: 'Branch',
        slug: 'branch_management',
      })
      server.create('permission', {
        name: 'Branch',
        slug: 'branch_management',
      })
      server.create('permission', {
        name: 'Role',
        slug: 'role_management',
      })
      server.create('permission', {
        name: 'Category',
        slug: 'category_management',
      })
      server.create('permission', {
        name: 'Product',
        slug: 'product_management',
      })
      server.create('permission', {
        name: 'Reservation',
        slug: 'reservation_management',
      })
      server.create('permission', {
        name: 'Profile',
        slug: 'profile',
      })
      server.create('permission', {
        name: 'Shift',
        slug: 'shift_management',
      })
      server.create('permission', {
        name: 'Staff',
        slug: 'staff_management',
      })
      server.createList('branch', 5)
      server.createList('productCategory', 5)
      server.createList('product', 5)
      server.createList('staff', 10)
      server.createList('licence', 2)
      server.createList('certification', 2)
      server.createList('item', 5)
      server.createList('reservation', 10)
    },
    routes() {
      this.namespace = '/api/v1/therapists'
      this.timing = 300

      this.post('/auth/sign_in', (_, request) => {
        const attrs = JSON.parse(request.requestBody)
        if (attrs.institute_code && attrs.staff_code && attrs.password) {
          if (
            attrs.institute_code === 'threesides' &&
            attrs.staff_code === 'test1' &&
            attrs.password === 'test123'
          ) {
            return new Response(
              200,
              {
                'access-token': 'test_token',
                'token-type': 'Bearer',
                client: 'test_client',
                expiry: 1583199736,
                uid: 'threesides-test1',
              },
              {
                data: {
                  id: '1',
                  institute_code: 'threesides',
                  staff_code: 'test1',
                  provider: 'email',
                  uid: 'threesides-test1',
                  name: 'Testy McTestface',
                  active_branch: '2',
                  role_id: '1',
                  relations: {
                    branches: '/staff/1/branches',
                    role: '/roles/1',
                  },
                },
              }
            )
          } else {
            return new Response(
              401,
              {},
              {
                success: false,
                errors: ['Invalid login credentials. Please try again.'],
              }
            )
          }
        } else if (!attrs.institute_code && attrs.password) {
          return new Response(
            422,
            {},
            { errors: { institute_code: ['must exist'] } }
          )
        } else if (attrs.institute_code && !attrs.password) {
          return new Response(422, {}, { errors: { password: ['must exist'] } })
        } else {
          return new Response(
            422,
            {},
            {
              errors: {
                institute_code: ['must exist'],
                password: ['must exist'],
              },
            }
          )
        }
      })

      this.delete('/auth/sign_out', (_, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          return new Response(
            200,
            {},
            {
              success: true,
            }
          )
        } else {
          return new Response(
            404,
            {},
            {
              success: false,
              errors: ['User was not found or was not logged in.'],
            }
          )
        }
      })

      this.get('/branches/:id/shift_assignments', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const shiftAssignments = schema.shiftAssignments.all()
          const total_entries = shiftAssignments.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(shiftAssignments)
          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.shift_assignments = json.shift_assignments.slice(
            minIndex,
            maxIndex
          )
          json.pagination = {
            page: parseInt(page),
            per: json.shift_assignments.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.post('/branches/:id/shift_assignments', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).shift_assignment
          if (
            requestAttrs.date &&
            requestAttrs.shift_id &&
            requestAttrs.staff_id
          ) {
            return schema.shiftAssignments.create(requestAttrs)
          } else if (
            !requestAttrs.date &&
            requestAttrs.shift_id &&
            requestAttrs.staff_id
          ) {
            return new Response(422, {}, { errors: { date: ['must exist'] } })
          } else if (
            requestAttrs.date &&
            !requestAttrs.shift_id &&
            requestAttrs.staff_id
          ) {
            return new Response(
              422,
              {},
              { errors: { shift_id: ['must exist'] } }
            )
          } else if (
            requestAttrs.date &&
            requestAttrs.shift_id &&
            !requestAttrs.staff_id
          ) {
            return new Response(
              422,
              {},
              { errors: { staff_id: ['must exist'] } }
            )
          } else {
            return new Response(
              422,
              {},
              {
                errors: {
                  date: ['must exist'],
                  shift_id: ['must exist'],
                  staff_id: ['must exist'],
                },
              }
            )
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put(
        '/branches/:branch_id/shift_assignments/:assignment_id',
        (schema, request) => {
          const attrs = request.requestHeaders

          if (
            attrs['access-token'] === 'test_token' &&
            attrs.uid === 'threesides-test1'
          ) {
            const requestAttrs = JSON.parse(
              request.requestBody
            ).shift_assignment
            const assignmentId = request.params.assignment_id
            const assignment = schema.shiftAssignments.find(assignmentId)
            if (assignment) {
              if (
                requestAttrs.date &&
                requestAttrs.shift_id &&
                requestAttrs.staff_id
              ) {
                return assignment.update(requestAttrs)
              } else if (
                !requestAttrs.date &&
                requestAttrs.shift_id &&
                requestAttrs.staff_id
              ) {
                return new Response(
                  422,
                  {},
                  { errors: { date: ['must exist'] } }
                )
              } else if (
                requestAttrs.date &&
                !requestAttrs.shift_id &&
                requestAttrs.staff_id
              ) {
                return new Response(
                  422,
                  {},
                  { errors: { shift_id: ['must exist'] } }
                )
              } else if (
                requestAttrs.date &&
                requestAttrs.shift_id &&
                !requestAttrs.staff_id
              ) {
                return new Response(
                  422,
                  {},
                  { errors: { staff_id: ['must exist'] } }
                )
              } else {
                return new Response(
                  422,
                  {},
                  {
                    errors: {
                      date: ['must exist'],
                      shift_id: ['must exist'],
                      staff_id: ['must exist'],
                    },
                  }
                )
              }
            } else {
              return new Response(404, {}, {})
            }
          } else {
            return new Response(
              401,
              {},
              {
                success: false,
                errors: {
                  message: ['You need to sign in before continuing.'],
                },
              }
            )
          }
        }
      )

      this.delete(
        '/branches/:branch_id/shift_assignments/:assignment_id',
        (schema, request) => {
          const attrs = request.requestHeaders

          if (
            attrs['access-token'] === 'test_token' &&
            attrs.uid === 'threesides-test1'
          ) {
            const assignmentId = request.params.assignment_id

            const assignment = schema.shiftAssignments.find(assignmentId)
            if (assignment) {
              return schema.shiftAssignments.find(assignmentId).destroy()
            } else {
              return new Response(404, {}, {})
            }
          } else {
            return new Response(
              401,
              {},
              {
                success: false,
                errors: {
                  message: ['You need to sign in before continuing.'],
                },
              }
            )
          }
        }
      )

      this.get('/branches/:id/shifts', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const shifts = schema.shifts.all()
          const total_entries = shifts.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(shifts)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.shifts = json.shifts.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.shifts.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.post('/branches/:id/shifts', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).shift
          if (requestAttrs.start_time && requestAttrs.name) {
            return schema.shifts.create(requestAttrs)
          } else if (!requestAttrs.start_time && requestAttrs.name) {
            return new Response(
              422,
              {},
              { errors: { start_time: ['must exist'] } }
            )
          } else if (requestAttrs.start_time && !requestAttrs.name) {
            return new Response(422, {}, { errors: { name: ['must exist'] } })
          } else {
            return new Response(
              422,
              {},
              {
                errors: {
                  start_time: ['must exist'],
                  name: ['must exist'],
                },
              }
            )
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/shifts/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).shift
          const id = request.params.id
          const shift = schema.shifts.find(id)
          if (shift) {
            if (requestAttrs.start_time && requestAttrs.name) {
              return shift.update(requestAttrs)
            } else if (!requestAttrs.start_time && requestAttrs.name) {
              return new Response(
                422,
                {},
                { errors: { start_time: ['must exist'] } }
              )
            } else if (requestAttrs.start_time && !requestAttrs.name) {
              return new Response(422, {}, { errors: { name: ['must exist'] } })
            } else {
              return new Response(
                422,
                {},
                {
                  errors: { start_time: ['must exist'], email: ['must exist'] },
                }
              )
            }
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.delete('/shifts/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const id = request.params.id

          const shift = schema.shifts.find(id)
          if (shift) {
            return schema.shifts.find(id).destroy()
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/branches/:id/units', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const units = schema.units.all()
          const total_entries = units.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(units)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.units = json.units.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.units.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.post('/branches/:id/units', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).unit
          if (requestAttrs.machine_name && requestAttrs.name) {
            return schema.units.create(requestAttrs)
          } else if (!requestAttrs.machine_name && requestAttrs.name) {
            return new Response(
              422,
              {},
              { errors: { machine_name: ['must exist'] } }
            )
          } else if (requestAttrs.machine_name && !requestAttrs.name) {
            return new Response(422, {}, { errors: { name: ['must exist'] } })
          } else {
            return new Response(
              422,
              {},
              {
                errors: {
                  machine_name: ['must exist'],
                  name: ['must exist'],
                },
              }
            )
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/units/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).unit
          const id = request.params.id
          const unit = schema.units.find(id)
          if (unit) {
            if (requestAttrs.machine_name && requestAttrs.name) {
              return unit.update(requestAttrs)
            } else if (!requestAttrs.machine_name && requestAttrs.name) {
              return new Response(
                422,
                {},
                { errors: { machine_name: ['must exist'] } }
              )
            } else if (requestAttrs.machine_name && !requestAttrs.name) {
              return new Response(422, {}, { errors: { name: ['must exist'] } })
            } else {
              return new Response(
                422,
                {},
                {
                  errors: {
                    machine_name: ['must exist'],
                    email: ['must exist'],
                  },
                }
              )
            }
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.delete('/units/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const id = request.params.id

          const unit = schema.units.find(id)
          if (unit) {
            return schema.units.find(id).destroy()
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/branches/:id/customers', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const customers = schema.customers.all()
          const total_entries = customers.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(customers)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.customers = json.customers.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.customers.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.post('/branches/:id/customers', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).customer
          if (requestAttrs.name && requestAttrs.name_kana) {
            return schema.customers.create(requestAttrs)
          } else if (!requestAttrs.name_kana && requestAttrs.name) {
            return new Response(
              422,
              {},
              { errors: { name_kana: ['must exist'] } }
            )
          } else if (requestAttrs.name_kana && !requestAttrs.name) {
            return new Response(422, {}, { errors: { name: ['must exist'] } })
          } else {
            return new Response(
              422,
              {},
              {
                errors: {
                  name_kana: ['must exist'],
                  name: ['must exist'],
                },
              }
            )
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/customers/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).customer
          const id = request.params.id
          const customer = schema.customers.find(id)
          if (customer) {
            if (
              requestAttrs.id &&
              requestAttrs.customer_code &&
              requestAttrs.name &&
              requestAttrs.name_kana
            ) {
              return customer.update(requestAttrs)
            } else if (!requestAttrs.start_time && requestAttrs.name) {
              return new Response(
                422,
                {},
                { errors: { start_time: ['must exist'] } }
              )
            } else if (requestAttrs.start_time && !requestAttrs.name) {
              return new Response(422, {}, { errors: { name: ['must exist'] } })
            } else {
              return new Response(
                422,
                {},
                {
                  errors: { start_time: ['must exist'], email: ['must exist'] },
                }
              )
            }
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.delete('/customers/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const id = request.params.id

          const customer = schema.customers.find(id)
          if (customer) {
            return schema.customers.find(id).destroy()
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/branches/:id/customer_settings', (_, request) => {
        const attrs = request.requestHeaders
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          return new Response(
            200,
            {},
            {
              customer_settings: {
                mode: 1,
                digits_count: 10,
                start_number: 1000,
                pattern: 0,
                prefix: 'CUS',
              },
            }
          )
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/branches/:id/customer_settings', (_, request) => {
        const attrs = request.requestHeaders
        const customer_settings = JSON.parse(
          request.requestBody
        ).customer_settings
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          return new Response(
            200,
            {},
            {
              customer_settings,
            }
          )
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/branches/:id/business_times', (_, request) => {
        const attrs = request.requestHeaders
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          return new Response(
            200,
            {},
            {
              business_times: [
                // {
                //   day: 'monday',
                //   status: 'with_break',
                //   business_start: '10:00',
                //   business_end: '19:30',
                //   break_start: '12:00',
                //   break_end: '13:00',
                // },
                {
                  day: 'tuesday',
                  status: 'with_break',
                  business_start: '08:00',
                  business_end: '18:30',
                  break_start: '12:00',
                  break_end: '13:00',
                },
                {
                  day: 'wednesday',
                  status: 'with_break',
                  business_start: '10:00',
                  business_end: '18:30',
                  break_start: '11:00',
                  break_end: '13:00',
                },
                {
                  day: 'thursday',
                  status: 'with_break',
                  business_start: '09:00',
                  business_end: '18:30',
                  break_start: '13:00',
                  break_end: '14:00',
                },
                {
                  day: 'friday',
                  status: 'with_break',
                  business_start: '09:00',
                  business_end: '18:30',
                  break_start: '12:30',
                  break_end: '13:30',
                },
                {
                  day: 'saturday',
                  status: 'without_break',
                  business_start: '09:00',
                  business_end: '18:30',
                  break_start: '11:00',
                  break_end: '12:30',
                },
                {
                  day: 'sunday',
                  status: 'closed',
                },
                {
                  day: 'closed',
                  status: 'with_break',
                  business_start: '09:00',
                  business_end: '18:30',
                  break_start: '12:00',
                  break_end: '13:00',
                },
              ],
            }
          )
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/branches/:id/business_times', (_, request) => {
        const attrs = request.requestHeaders
        const business_times = JSON.parse(request.requestBody).business_times
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          return new Response(
            200,
            {},
            {
              business_times,
            }
          )
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/roles', function (schema, request) {
        const attrs = request.requestHeaders
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          return schema.roles.all()
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/roles/:id', function (schema, request) {
        const attrs = request.requestHeaders
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const id = request.params.id

          const role = schema.roles.find(id)
          if (role) {
            return schema.roles.find(id)
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.post('/roles', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).role
          if (requestAttrs.permission_ids && requestAttrs.name) {
            return schema.roles.create(requestAttrs)
          } else if (!requestAttrs.permission_ids && requestAttrs.name) {
            return new Response(
              422,
              {},
              { errors: { permission_ids: ['must exist'] } }
            )
          } else if (requestAttrs.permission_ids && !requestAttrs.name) {
            return new Response(422, {}, { errors: { name: ['must exist'] } })
          } else {
            return new Response(
              422,
              {},
              {
                errors: {
                  permission_ids: ['must exist'],
                  name: ['must exist'],
                },
              }
            )
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/roles/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).role
          const id = request.params.id
          const role = schema.roles.find(id)
          if (role) {
            if (requestAttrs.permission_ids && requestAttrs.name) {
              return role.update(requestAttrs)
            } else if (!requestAttrs.permission_ids && requestAttrs.name) {
              return new Response(
                422,
                {},
                { errors: { permission_ids: ['must exist'] } }
              )
            } else if (requestAttrs.permission_ids && !requestAttrs.name) {
              return new Response(422, {}, { errors: { name: ['must exist'] } })
            } else {
              return new Response(
                422,
                {},
                {
                  errors: {
                    permission_ids: ['must exist'],
                    email: ['must exist'],
                  },
                }
              )
            }
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/permissions', function (schema, request) {
        const attrs = request.requestHeaders
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          return schema.permissions.all()
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/branches', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const branches = schema.branches.all()
          const total_entries = branches.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(branches)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.branches = json.branches.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.branches.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/branches/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).branch
          const id = request.params.id
          const branch = schema.branches.find(id)
          if (branch) {
            if (requestAttrs.name) {
              return branch.update(requestAttrs)
            } else {
              return new Response(422, {}, { errors: { name: ['must exist'] } })
            }
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/product_categories', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const productCategories = schema.productCategories.all()
          const total_entries = productCategories.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(productCategories)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.product_categories = json.product_categories.slice(
            minIndex,
            maxIndex
          )
          json.pagination = {
            page: parseInt(page),
            per: json.product_categories.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.post('/product_categories', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).product_category
          if (requestAttrs.category_code && requestAttrs.name) {
            return schema.productCategories.create(requestAttrs)
          } else if (!requestAttrs.category_code && requestAttrs.name) {
            return new Response(
              422,
              {},
              { errors: { category_code: ['must exist'] } }
            )
          } else if (requestAttrs.category_code && !requestAttrs.name) {
            return new Response(422, {}, { errors: { name: ['must exist'] } })
          } else {
            return new Response(
              422,
              {},
              {
                errors: {
                  category_code: ['must exist'],
                  name: ['must exist'],
                },
              }
            )
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/product_categories/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).product_category
          const id = request.params.id
          const productCategory = schema.productCategories.find(id)
          if (productCategory) {
            if (requestAttrs.category_code && requestAttrs.name) {
              return productCategory.update(requestAttrs)
            } else if (!requestAttrs.category_code && requestAttrs.name) {
              return new Response(
                422,
                {},
                { errors: { category_code: ['must exist'] } }
              )
            } else if (requestAttrs.category_code && !requestAttrs.name) {
              return new Response(422, {}, { errors: { name: ['must exist'] } })
            } else {
              return new Response(
                422,
                {},
                {
                  errors: {
                    category_code: ['must exist'],
                    email: ['must exist'],
                  },
                }
              )
            }
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/products', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const products = schema.products.all()
          const total_entries = products.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(products)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.products = json.products.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.products.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.post('/products', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).product
          if (requestAttrs.name) {
            return schema.products.create(requestAttrs)
          } else {
            return new Response(
              422,
              {},
              {
                errors: {
                  name: ['must exist'],
                },
              }
            )
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/products/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).product
          const id = request.params.id
          const product = schema.products.find(id)
          if (product) {
            if (requestAttrs.name) {
              return product.update(requestAttrs)
            } else {
              return new Response(
                422,
                {},
                {
                  errors: { name: ['must exist'] },
                }
              )
            }
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.delete('/products/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const id = request.params.id

          const product = schema.products.find(id)
          if (product) {
            return schema.products.find(id).destroy()
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/branches/:id/products', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const products = schema.products.all()
          const total_entries = products.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(products)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.products = json.products.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.products.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/branches/:id/items', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const items = schema.items.all()
          const total_entries = items.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(items)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.items = json.items.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.items.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.post('/branches/:id/items', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).item
          if (requestAttrs.name) {
            return schema.items.create(requestAttrs)
          } else {
            return new Response(
              422,
              {},
              {
                errors: {
                  name: ['must exist'],
                },
              }
            )
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/branches/:id/items/:item_id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).item
          const itemId = request.params.item_id
          const item = schema.items.find(itemId)
          if (item) {
            if (requestAttrs.name) {
              return item.update(requestAttrs)
            } else {
              return new Response(
                422,
                {},
                {
                  errors: { name: ['must exist'] },
                }
              )
            }
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.delete('/branches/:id/items/:item_id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const itemId = request.params.item_id

          const item = schema.items.find(itemId)
          if (item) {
            return schema.items.find(itemId).destroy()
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/staff/:id/branches', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const branches = schema.branches.all()
          const total_entries = branches.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(branches)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.branches = json.branches.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.branches.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      // this.post('/products', (schema, request) => {
      //   const attrs = request.requestHeaders

      //   if (
      //     attrs['access-token'] === 'test_token' &&
      //     attrs.uid === 'threesides-test1'
      //   ) {
      //     const requestAttrs = JSON.parse(request.requestBody).product
      //     if (requestAttrs.name) {
      //       return schema.products.create(requestAttrs)
      //     } else {
      //       return new Response(
      //         422,
      //         {},
      //         {
      //           errors: {
      //             name: ['must exist'],
      //           },
      //         }
      //       )
      //     }
      //   } else {
      //     return new Response(
      //       401,
      //       {},
      //       {
      //         success: false,
      //         errors: {
      //           message: ['You need to sign in before continuing.'],
      //         },
      //       }
      //     )
      //   }
      // })

      // this.put('/products/:id', (schema, request) => {
      //   const attrs = request.requestHeaders

      //   if (
      //     attrs['access-token'] === 'test_token' &&
      //     attrs.uid === 'threesides-test1'
      //   ) {
      //     const requestAttrs = JSON.parse(request.requestBody).product
      //     const id = request.params.id
      //     const product = schema.products.find(id)
      //     if (product) {
      //       if (requestAttrs.name) {
      //         return product.update(requestAttrs)
      //       } else {
      //         return new Response(
      //           422,
      //           {},
      //           {
      //             errors: { name: ['must exist'] },
      //           }
      //         )
      //       }
      //     } else {
      //       return new Response(404, {}, {})
      //     }
      //   } else {
      //     return new Response(
      //       401,
      //       {},
      //       {
      //         success: false,
      //         errors: {
      //           message: ['You need to sign in before continuing.'],
      //         },
      //       }
      //     )
      //   }
      // })

      // this.delete('/products/:id', (schema, request) => {
      //   const attrs = request.requestHeaders

      //   if (
      //     attrs['access-token'] === 'test_token' &&
      //     attrs.uid === 'threesides-test1'
      //   ) {
      //     const id = request.params.id

      //     const product = schema.products.find(id)
      //     if (product) {
      //       return schema.products.find(id).destroy()
      //     } else {
      //       return new Response(404, {}, {})
      //     }
      //   } else {
      //     return new Response(
      //       401,
      //       {},
      //       {
      //         success: false,
      //         errors: {
      //           message: ['You need to sign in before continuing.'],
      //         },
      //       }
      //     )
      //   }
      // })

      this.get('/staff/:id/products', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const products = schema.products.all()
          const total_entries = products.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(products)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.products = json.products.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.products.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/staff', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const staffs = schema.staffs.all()
          const total_entries = staffs.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(staffs)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.staff = json.staffs.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.staff.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/staff/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).staff
          const id = request.params.id
          const staff = schema.staffs.find(id)
          if (staff) {
            if (requestAttrs.name) {
              return staff.update(requestAttrs)
            } else {
              return new Response(
                422,
                {},
                {
                  errors: { name: ['must exist'] },
                }
              )
            }
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('branches/:id/staff', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const staffs = schema.staffs.all()
          const total_entries = staffs.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(staffs)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.staff = json.staffs.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.staff.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/licences', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const licences = schema.licences.all()
          const total_entries = licences.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(licences)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.licence = json.licences.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.licence.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/staff/:id/certifications', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const certifications = schema.certifications.all()
          const total_entries = certifications.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(certifications)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.certifications = json.certifications.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.certifications.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.get('/branches/:id/reservations', function (schema, request) {
        const attrs = request.requestHeaders
        const page = request.queryParams.page || 1
        const per = request.queryParams.per || 15
        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const reservations = schema.reservations.all()
          const total_entries = reservations.length
          const total_pages = Math.ceil(total_entries / per)
          const json = this.serialize(reservations)

          const minIndex = (page - 1) * per
          const maxIndex = page * per
          json.reservations = json.reservations.slice(minIndex, maxIndex)
          json.pagination = {
            page: parseInt(page),
            per: json.reservations.length,
            total_pages,
            total_entries,
          }
          return json
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.post('/branches/:id/reservations', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).reservation
          if (requestAttrs.start_time && requestAttrs.customer_id) {
            return schema.reservations.create(requestAttrs)
          } else if (!requestAttrs.start_time && requestAttrs.customer_id) {
            return new Response(
              422,
              {},
              { errors: { start_time: ['must exist'] } }
            )
          } else if (requestAttrs.start_time && !requestAttrs.customer_id) {
            return new Response(
              422,
              {},
              { errors: { customer_id: ['must exist'] } }
            )
          } else {
            return new Response(
              422,
              {},
              {
                errors: {
                  start_time: ['must exist'],
                  customer_id: ['must exist'],
                },
              }
            )
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.put('/branches/:branch_id/reservations/:id', (schema, request) => {
        const attrs = request.requestHeaders

        if (
          attrs['access-token'] === 'test_token' &&
          attrs.uid === 'threesides-test1'
        ) {
          const requestAttrs = JSON.parse(request.requestBody).reservation
          const id = request.params.id
          const reservation = schema.reservations.find(id)
          if (reservation) {
            if (requestAttrs.start_time && requestAttrs.customer_id) {
              return reservation.update(requestAttrs)
            } else if (!requestAttrs.start_time && requestAttrs.customer_id) {
              return new Response(
                422,
                {},
                { errors: { start_time: ['must exist'] } }
              )
            } else if (requestAttrs.start_time && !requestAttrs.customer_id) {
              return new Response(
                422,
                {},
                { errors: { customer_id: ['must exist'] } }
              )
            } else {
              return new Response(
                422,
                {},
                {
                  errors: {
                    start_time: ['must exist'],
                    customer_id: ['must exist'],
                  },
                }
              )
            }
          } else {
            return new Response(404, {}, {})
          }
        } else {
          return new Response(
            401,
            {},
            {
              success: false,
              errors: {
                message: ['You need to sign in before continuing.'],
              },
            }
          )
        }
      })

      this.delete(
        '/branches/:branch_id/reservations/:id',
        (schema, request) => {
          const attrs = request.requestHeaders

          if (
            attrs['access-token'] === 'test_token' &&
            attrs.uid === 'threesides-test1'
          ) {
            const id = request.params.id

            const reservation = schema.reservations.find(id)
            if (reservation) {
              return schema.reservations.find(id).destroy()
            } else {
              return new Response(404, {}, {})
            }
          } else {
            return new Response(
              401,
              {},
              {
                success: false,
                errors: {
                  message: ['You need to sign in before continuing.'],
                },
              }
            )
          }
        }
      )
    },
  })
  return server
}
