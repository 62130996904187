import { MutationTree, ActionTree } from 'vuex'
import dayjs from 'dayjs'
import { RootState } from '.'
import {
  StaffPerformance,
  StaffPerformanceParams,
  Repositories,
  CrmCustomerRepeatRate,
  StaffPerformanceProductCategories,
  ProductCategoryAnalyticsParams,
  Pagination,
  CrmCustomerCourseCategory,
  CrmProductTypeApiQueryParams,
} from '~/types'

const initStaffPerformance = {
  current: {
    sales: 0,
    average_unit_price: 0,
    customer_count: 0,
    man_hour_productivity: 0,
    utilization_rate: 0,
    visiting_customers: [],
    non_visiting_customers: [],
    product_types: {
      anma_insurance: { customer_count: 0, sales: 0 },
      anma_self_payment: { customer_count: 0, sales: 0 },
      hari_insurance: { customer_count: 0, sales: 0 },
      hari_self_payment: { customer_count: 0, sales: 0 },
      insurance: { customer_count: 0, sales: 0 },
      judo_insurance: { customer_count: 0, sales: 0 },
      judo_self_payment: { customer_count: 0, sales: 0 },
      other: { customer_count: 0, sales: 0 },
      product: { customer_count: 0, sales: 0 },
      self_payment: { customer_count: 0, sales: 0 },
      tickets: { customer_count: 0, sales: 0 },
      total: { customer_count: 0, sales: 0 },
    },
    product_categories: [],
    visit_count: 0,
  },
  comparison: {
    sales: 0,
    average_unit_price: 0,
    customer_count: 0,
    man_hour_productivity: 0,
    utilization_rate: 0,
    visiting_customers: [],
    non_visiting_customers: [],
    product_types: {
      anma_insurance: { customer_count: 0, sales: 0 },
      anma_self_payment: { customer_count: 0, sales: 0 },
      hari_insurance: { customer_count: 0, sales: 0 },
      hari_self_payment: { customer_count: 0, sales: 0 },
      insurance: { customer_count: 0, sales: 0 },
      judo_insurance: { customer_count: 0, sales: 0 },
      judo_self_payment: { customer_count: 0, sales: 0 },
      other: { customer_count: 0, sales: 0 },
      product: { customer_count: 0, sales: 0 },
      self_payment: { customer_count: 0, sales: 0 },
      tickets: { customer_count: 0, sales: 0 },
      total: { customer_count: 0, sales: 0 },
    },
    visit_count: 0,
  },
}

const initCustomerRepeatRate = {
  visiting_customers: [],
}

export const state = () => ({
  staffPerformance: initStaffPerformance as StaffPerformance,
  activeStaffId: -1 as number,
  staffPerformanceProductCategories: [] as StaffPerformanceProductCategories[],
  staffPerformanceProductTypes: [] as CrmCustomerCourseCategory[],
  isOpenDialog: false,
  customerRepeatRate: initCustomerRepeatRate as CrmCustomerRepeatRate,
  allCustomerRepeatRate: initCustomerRepeatRate as CrmCustomerRepeatRate,
  activeRepeatRateBranchId: -1,
  productCategoriesPagination: {} as Pagination,
  productCategoriesTotal: {} as StaffPerformanceProductCategories,
  viewTypes: [] as string[],
  startEndDate: {
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
  },
})

export type CrmHQStaffPerformance = ReturnType<typeof state>

export const mutations: MutationTree<CrmHQStaffPerformance> = {
  setCrmCustomerParams(state, staffPerformance: StaffPerformance) {
    state.staffPerformance = staffPerformance
  },
  setActiveStaffId(state, id: number) {
    state.activeStaffId = id
  },
  setStaffPerformanceProductCategories(
    state,
    productCategories: StaffPerformanceProductCategories[]
  ) {
    state.staffPerformanceProductCategories = productCategories
  },
  setIsOpenDialog(state, status: boolean) {
    state.isOpenDialog = status
  },

  setCustomerRepeatRate(state, repeatRate: CrmCustomerRepeatRate) {
    state.customerRepeatRate = repeatRate
  },
  setAllCustomerRepeatRate(state, repeatRate: CrmCustomerRepeatRate) {
    state.allCustomerRepeatRate = repeatRate
  },
  setActiveRepeatRateBranchId(state, id: number) {
    state.activeRepeatRateBranchId = id
  },
  setProductCategoriesPagination(state, pagination: Pagination) {
    state.productCategoriesPagination = pagination
  },
  setStaffPerformanceProductCategoriesTotal(
    state,
    total: StaffPerformanceProductCategories
  ) {
    state.productCategoriesTotal = total
  },
  setViewTypes(state, value: string[]) {
    state.viewTypes = value
  },
  setStaffPerformanceProductTypes(state, values: CrmCustomerCourseCategory[]) {
    state.staffPerformanceProductTypes = values
  },
  setStartEndDate(
    state,
    dashboardStartEndDate: { startDate: string; endDate: string }
  ) {
    state.startEndDate = dashboardStartEndDate
  },
}
export const actions: ActionTree<CrmHQStaffPerformance, RootState> = {
  async getCrmCustomerAnalytics(
    { commit },
    params: {
      api: Repositories
      staff_id: number
    } & StaffPerformanceParams
  ) {
    const staffPerformance =
      await params.api.crmAnalyticsRepo.fetchCrmStaffPerformance(
        params.staff_id,
        {
          branch_ids: params.branch_ids,
          start_date: params.start_date,
          end_date: params.end_date,
          comparison_start_date: params.comparison_start_date,
          comparison_end_date: params.comparison_end_date,
        }
      )
    commit('setCrmCustomerParams', staffPerformance)
  },
  async getStaffPerformanceProductTypes(
    { commit },
    params: {
      api: Repositories
    } & CrmProductTypeApiQueryParams
  ) {
    const productTypes =
      await params.api.crmAnalyticsRepo.fetchCustomerCourseCategory({
        branch_ids: params.branch_ids,
        start_date: params.start_date,
        end_date: params.end_date,
        comparison_start_date: params.comparison_start_date,
        comparison_end_date: params.comparison_end_date,
        staff_ids: params.staff_ids,
      })
    commit('setStaffPerformanceProductTypes', productTypes)
  },
  async getHQCustomerRepeatRate(
    { commit },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      branch_ids: number[]
      staff_ids?: number[]
    }
  ) {
    const customerRepeatRate =
      await params.api.crmAnalyticsRepo.fetchCustomerRepeatRate({
        branch_ids: params.branch_ids,
        start_date: params.start_date,
        end_date: params.end_date,
        staff_ids: params.staff_ids,
      })
    commit('setCustomerRepeatRate', customerRepeatRate)
  },
  async getAllHQCustomerRepeatRate(
    { commit },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      branch_ids: number[]
    }
  ) {
    const customerRepeatRate =
      await params.api.crmAnalyticsRepo.fetchCustomerRepeatRate({
        branch_ids: params.branch_ids,
        start_date: params.start_date,
        end_date: params.end_date,
      })
    commit('setAllCustomerRepeatRate', customerRepeatRate)
  },
  async getProductCategoryAnalyticsResource(
    { commit },
    params: {
      api: Repositories
    } & ProductCategoryAnalyticsParams
  ) {
    const { pagination, product_categories, total } =
      await params.api.crmAnalyticsRepo.fetchProductCategoryAnalyticsResource({
        page: params.page,
        per: params.per,
        branch_ids: params.branch_ids,
        start_date: params.start_date,
        end_date: params.end_date,
        comparison_start_date: params.comparison_start_date,
        comparison_end_date: params.comparison_end_date,
        staff_ids: params.staff_ids,
        sort: params.sort,
      })
    commit('setProductCategoriesPagination', pagination)
    commit('setStaffPerformanceProductCategories', product_categories)
    commit('setStaffPerformanceProductCategoriesTotal', total)
  },
}
