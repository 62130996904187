// Get current language setting
export const getLanguageSetting = (): string => {
  const language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    // IE
    (window.navigator as any)?.userLanguage ||
    (window.navigator as any)?.browserLanguage
  return language || 'ja'
}
