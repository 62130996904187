import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { BusinessTimesRepo, BusinessTime, CustomBusinessTime } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): BusinessTimesRepo => ({
  async fetchBusinessTimes(branchId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/business_times`
      )
      if (res.status === 200) {
        return res.data.business_times
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async updateBusinessTimes(
    branchId: number,
    businessTimesData: BusinessTime[]
  ) {
    businessTimesData.forEach((businessTime) => {
      switch (businessTime.status) {
        case 'without_break':
          delete businessTime?.break_end
          delete businessTime?.break_start
          break
        case 'closed':
          delete businessTime?.business_start
          delete businessTime?.business_end
          delete businessTime?.break_end
          delete businessTime?.break_start
          break
        default:
          break
      }
    })
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/business_times`,
        {
          business_times: businessTimesData,
        }
      )
      if (res.status === 200) {
        return res.data.business_times
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },

  async fetchCustomBusinessTimes(branchId: number, params) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/custom_business_times`,
        { params }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },

  async fetchCustomBusinessTime(branchId: number, businessTimeId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/custom_business_times/${businessTimeId}`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async postCustomBusinessTime(branchId: number, params: CustomBusinessTime) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/custom_business_times`,
        {
          ...params,
        }
      )
      if (res.status === 201) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateCustomBusinessTime(branchId: number, params: CustomBusinessTime) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/custom_business_times/${params.id}`,
        { ...params }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteCustomBusinessTime(branchId: number, businessTimeId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/custom_business_times/${businessTimeId}`
      )
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
