





























import Vue from 'vue'
import { UserPlugin } from '~/models/UserPlugin'
import { UserPermission } from '~/models/UserPermission'
export default Vue.extend({
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showBranchList: false,
      expand: false,
      selectedSideBarItemLink: '/clinic_setting',
    }
  },
  computed: {
    userPermission(): UserPermission {
      return this.$store.state.auth.userPermission
    },
    userPlugin(): UserPlugin {
      return this.$store.state.auth.userPlugin
    },
    sideBarItems(): {
      heading: any
      isSingle?: boolean
      subComponent: {
        text: any
        link: string
        id: string
        show: boolean
      }[]
    }[] {
      return [
        {
          heading: this.$t('clinicSettings.branches'),
          subComponent: [
            {
              text: this.$t('clinicSettings.manageBranch'),
              link: '/clinic_setting/branches',
              id: 'manage_branches',
              show: this.userPermission.isAllowed('hq_branch'),
            },
            {
              text: this.$t('clinicSettings.clinic'),
              link: '/clinic_setting',
              id: 'manage_this_branch',
              show: true,
            },
            // {
            //   text: this.$t('clinicSettings.holiday'),
            //   link: '/clinic_setting/holidays',
            //   id: 'holiday_this_branch',
            //   show: true,
            // },
            {
              text: this.$t('clinicSettings.unitSetting'),
              link: '/clinic_setting/units',
              id: 'unit_this_branch',
              show: true,
            },
          ],
        },
        {
          heading: this.$t('clinicSettings.staffSetting'),
          subComponent: [
            {
              text: this.$t('clinicSettings.manageStaff'),
              link: '/clinic_setting/staff',
              id: 'manage_staff',
              show: this.userPermission.isAllowed('hq_staff'),
            },
            {
              text: this.$t('clinicSettings.staffPermission'),
              link: '/clinic_setting/permissions',
              id: 'manage_staff_permission',
              show: this.userPermission.isAllowed('hq_staff'),
            },
          ],
        },
        {
          heading: this.$t('clinicSettings.shiftSettings'),
          isSingle: true,
          subComponent: [
            {
              text: this.$t('clinicSettings.shiftsSetting'),
              link: '/clinic_setting/shift',
              id: 'shifts_setting',
              show: this.userPermission.isAllowed('branch_shift'),
            },
          ],
        },
        {
          heading: this.$t('clinicSettings.products'),
          subComponent: [
            {
              text: this.$t('clinicSettings.categories'),
              link: '/clinic_setting/categories',
              id: 'product_categories',
              show: this.userPermission.isAllowed('hq_product'),
            },
            {
              text: this.$t('clinicSettings.productList'),
              link: '/clinic_setting/products',
              id: 'product_list',
              show: this.userPermission.isAllowed('hq_product'),
            },
            {
              text: this.$t('clinicSettings.tickets'),
              link: '/clinic_setting/tickets',
              id: 'product_tickets',
              show: this.userPermission.isAllowed('hq_product'),
            },
            {
              text: this.$t('clinicSettings.coupons'),
              link: '/clinic_setting/coupons',
              id: 'product_coupons',
              show: this.userPermission.isAllowed('hq_product'),
            },
            {
              text: this.$t('clinicSettings.items'),
              link: '/clinic_setting/items',
              id: 'product_this_branch_menu',
              show: this.userPermission.isAllowed('branch_item'),
            },
          ],
        },

        {
          heading: this.$t('clinicSettings.ReservationSet'),
          subComponent: [
            {
              text: this.$t('clinicSettings.branchListWeb'),
              link: '/clinic_setting/reservation_branch_list',
              id: 'web_reservationg_branch_list',
              show: true,
            },
            {
              text: this.$t('clinicSettings.webReservation'),
              link: '/clinic_setting/web_reservation',
              id: 'web_reservationg_this_branch',
              show: true,
            },
          ],
        },
        {
          heading: this.$t('clinicSettings.customerKarteList'),
          isSingle: false,
          subComponent: [
            {
              text: this.$t('clinicSettings.customerKarte'),
              link: '/clinic_setting/customer_karte',
              id: 'customer_karte',
              show: this.userPermission.isAllowed('branch_karte'),
            },
          ],
        },
        {
          heading: this.$t('clinicSettings.customKarteList'),
          subComponent: [
            {
              text: this.$t('clinicSettings.branchListKarte'),
              link: '/clinic_setting/branch_list',
              id: 'custom_karte_branch_list',
              show: true,
            },
            {
              text: this.$t('clinicSettings.customKarte'),
              link: '/clinic_setting/karte_form_settings',
              id: 'custom_karte_this_branch',
              show: true,
            },
          ],
        },

        {
          heading: this.$t('clinicSettings.goalSetup'),
          subComponent: [
            {
              text: this.$t('clinicSettings.masterGoalsList'),
              link: '/clinic_setting/goals',
              id: 'goal_setup_branch_list',
              show: this.userPermission.isAllowed('branch_accounting'),
            },
            {
              text: this.$t('clinicSettings.branchGoalsList'),
              link: '/clinic_setting/branch_goals',
              id: 'goal_setup_this_branch',
              show: this.userPermission.isAllowed('branch_accounting'),
            },
          ],
        },
        {
          heading: this.$t('topBarTitle.customerIdSetting'),
          isSingle: true,
          subComponent: [
            {
              text: this.$t('clinicSettings.customerIdSetting'),
              link: '/clinic_setting/customer_numbering',
              id: 'customer_id_this_branch',
              show: true,
            },
          ],
        },

        {
          heading: this.$t('clinicSettings.printerSetup'),
          isSingle: true,
          subComponent: [
            {
              text: this.$t('clinicSettings.printerSetup'),
              link: '/clinic_setting/printer_setting',
              id: 'printer_setup_this_branch',
              show: true,
            },
          ],
        },

        {
          heading: this.$t('clinicSettings.square'),
          isSingle: true,
          subComponent: [
            {
              text: this.$t('clinicSettings.squarePayment'),
              link: '/clinic_setting/square',
              id: 'square_this_branch',
              show:
                this.userPlugin.isEnabled('square_payments') &&
                this.userPermission.isAllowed('hq_square'),
            },
          ],
        },
        {
          heading: this.$t('cpa.advertisingSettings'),
          subComponent: [
            {
              text: this.$t('cpa.branchList'),
              link: '/clinic_setting/cpaList',
              id: 'cpa_branch_list',
              show: true,
            },
            {
              text: this.$t('cpa.thisBranch'),
              link: '/clinic_setting/cpa',
              id: 'cpa_this_branch',
              show: true,
            },
          ],
        },
      ]
    },
  },
})
