import { MutationTree, ActionTree } from 'vuex'
import { Repositories, Pagination, KarteEntry } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  karteEntries: [] as KarteEntry[],
  karteEntryPagination: {} as Pagination,
})

export type KarteEntryState = ReturnType<typeof state>

export const mutations: MutationTree<KarteEntryState> = {
  setKarteEntries(state, karteEntries: KarteEntry[]) {
    state.karteEntries = karteEntries
  },
  setKarteEntryPagination(state, pagination: Pagination) {
    state.karteEntryPagination = pagination
  },
}

export const actions: ActionTree<KarteEntryState, RootState> = {
  async fetchKarteEntries(
    { commit, rootState },
    params: {
      api: Repositories
      customerId: number
      form_id: number
      start_date: string
      end_date: string
      page?: number
      per?: number
      assessment_name?: string
      assessment_id?: number
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { karte_entries, pagination } =
      await params.api.karteEntryRepo.fetchEntries(
        branchId,
        params.customerId,
        {
          form_id: params.form_id,
          start_date: params.start_date,
          end_date: params.end_date,
          page: params.page,
          per: params.per,
          assessment_name: params.assessment_name,
          assessment_id: params.assessment_id,
        }
      )
    commit('setKarteEntries', karte_entries)
    commit('setKarteEntryPagination', pagination)
  },
  async updateKarteEntry(
    { commit, rootState },
    params: {
      api: Repositories
      customerId: number
      karteEntry: KarteEntry
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { karte_entry } = await params.api.karteEntryRepo.updateEntry(
      branchId,
      params.customerId,
      params.karteEntry
    )
    commit('setKarteEntry', karte_entry)
  },
}
