import { NuxtAxiosInstance } from '@nuxtjs/axios'
import qs from 'qs'
import processError from '~/helpers/processError'
import {
  Ticket,
  TicketRepo,
  TicketPackQueryParams,
  PaginationQueryParams,
} from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): TicketRepo => ({
  async fetchTickets(params?: {
    page?: number
    per?: number
    branch_ids?: number[]
    start_date?: string
    end_date?: string
  }) {
    try {
      const res = await $axios.get('therapists/tickets', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createTicket(ticketData: Ticket) {
    try {
      const res = await $axios.post('therapists/tickets', {
        ticket: ticketData,
      })
      if (res.status === 201) {
        return res.data.ticket
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async fetchTicketById(ticketId: number) {
    try {
      const res = await $axios.get(`therapists/tickets/${ticketId}`)
      if (res.status === 200) {
        return res.data.ticket
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateTicket(ticket: Ticket) {
    try {
      const res = await $axios.put(`therapists/tickets/${ticket.id}`, {
        ticket,
      })
      if (res.status === 200) {
        return res.data.ticket
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async fetchTicketOptions(params?: { page?: number; per?: number }) {
    try {
      const res = await $axios.get('therapists/ticket_options', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerTicketPacks(
    branchId: number,
    customerId: number,
    params?: { page?: number; per?: number }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/${customerId}/ticket_packs`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerTicketPackRefundability(params: {
    branchId: number
    customerId: number
    id: number
  }) {
    const { branchId, customerId, id } = params
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/${customerId}/ticket_packs/${id}/refundability`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async refundCustomerTicketPack(params: {
    branchId: number
    customerId: number
    id: number
  }) {
    const { branchId, customerId, id } = params
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/customers/${customerId}/ticket_packs/${id}/refund`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchTicketPacks(
    params?: TicketPackQueryParams & PaginationQueryParams
  ) {
    try {
      /* With qs, convert
        { attr: { after: "2024-01-01", before: "2024-01-31" } }
      into
        ?attr[after]=2024-01-01&attr[before]=2024-01-31
      */

      const res = await $axios.get('therapists/ticket_packs', {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        },
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
