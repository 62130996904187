import { RolePermission, PermissionSlug } from '~/types'

export class UserPermission {
  userPermission: PermissionSlug
  permissions: RolePermission[]
  constructor(userPermission: PermissionSlug, permissions: RolePermission[]) {
    this.userPermission = userPermission
    this.permissions = permissions
  }

  isAllowed(slug: string): boolean {
    const isMobileDevice = /android|iphone/i.test(navigator.userAgent)
    if (
      isMobileDevice &&
      !this.userPermission.permission_slugs.includes('mobile_access')
    ) {
      return false
    }
    const permission = this.permissions?.find(
      (permission) => permission.slug === slug
    )
    if (permission) {
      return this.userPermission.permission_slugs.includes(permission.slug)
    }
    return false
  }
}
