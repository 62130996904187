import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import {
  Repositories,
  CrmItemsResource,
  Pagination,
  CrmItemDetailedAnalytics,
  ProductCategory,
  Item,
  CrmSaleHistory,
  CrmProductsResource,
} from '~/types'

const itemsResource = {
  items: [],
  total: {
    current_customer_count: 0,
    comparison_customer_count: 0,
    current_sales: 0,
    comparison_sales: 0,
    current_item_sold_count: 0,
    comparison_item_sold_count: 0,
    current_average_unit_price: 0,
    comparison_average_unit_price: 0,
  },
}

const graphItemsResource = {
  items: [],
  total: {
    current_customer_count: 0,
    comparison_customer_count: 0,
    current_sales: 0,
    comparison_sales: 0,
    current_item_sold_count: 0,
    comparison_item_sold_count: 0,
    current_average_unit_price: 0,
    comparison_average_unit_price: 0,
  },
}
const comparisonGraphItemsResource = {
  items: [],
  total: {
    current_customer_count: 0,
    comparison_customer_count: 0,
    current_sales: 0,
    comparison_sales: 0,
    current_item_sold_count: 0,
    comparison_item_sold_count: 0,
    current_average_unit_price: 0,
    comparison_average_unit_price: 0,
  },
}

const productsDetailedAnalytics = {
  current: {
    quantity_sold: 0,
    customer_count: 0,
    average_unit_price: 0,
    sales: 0,
    sales_contribution: 0,
    customer_contribution: 0,
    visiting_customers: [],
    customer_gender: [],
    customer_age: [],
  },
  comparison: {
    quantity_sold: 0,
    customer_count: 0,
    average_unit_price: 0,
    sales: 0,
    sales_contribution: 0,
    customer_contribution: 0,
    visiting_customers: [],
    customer_gender: [],
    customer_age: [],
  },
}

const productsResource = {
  products: [],
  total: {
    current_customer_count: 0,
    comparison_customer_count: 0,
    current_sales: 0,
    comparison_sales: 0,
    current_item_sold_count: 0,
    comparison_item_sold_count: 0,
    current_average_unit_price: 0,
    comparison_average_unit_price: 0,
  },
}

export const state = () => ({
  // Crm product, tickets, coupons, table id
  activeProductId: -1,
  // CrmProductSelectMenu type product | ticket | coupon
  activeProductMenuType: 'product',
  itemsResource: itemsResource as CrmItemsResource,
  graphItemsResource: graphItemsResource as CrmItemsResource,
  comparisonGraphItemsResource:
    comparisonGraphItemsResource as CrmItemsResource,
  productsDetailedAnalytics:
    productsDetailedAnalytics as CrmItemDetailedAnalytics,
  itemsResourcePagination: {} as Pagination,
  productCategories: [] as ProductCategory[],
  productCategoryPagination: {} as Pagination,
  items: [] as Item[],
  itemPagination: {} as Pagination,
  sales_history: [] as CrmSaleHistory[],
  loadingList: {
    isGraphItemsResourceLoading: false,
    isProductsDetailedAnalyticsLoading: false,
    isItemsResourceLoading: false,
    isProductCategoriesLoading: false,
    isSalesHistoryLoading: false,
    isProductsResourceLoading: false,
  },
  salesTablePagination: {
    page: 1,
    itemsPerPage: 10,
  },
  crmProductErrorList: {
    salesHistory: false,
    graphItemsResource: false,
    itemsDetailedAnalytics: false,
    itemsResourse: false,
    productsResourse: false,
  },
  productsResource: productsResource as CrmProductsResource,
  productsResourcePagination: {} as Pagination,
})

export type CRMAnalyticState = ReturnType<typeof state>

export const mutations: MutationTree<CRMAnalyticState> = {
  setItemsResource(state, itemsResource: CrmItemsResource) {
    state.itemsResource = itemsResource
  },
  setItemsResourcePagination(state, pagination: Pagination) {
    state.itemsResourcePagination = pagination
  },

  setGraphItemsResource(state, graphItemsResource: CrmItemsResource) {
    state.graphItemsResource = graphItemsResource
  },
  setComparisonGraphItemsResource(state, graphItemsResource: CrmItemsResource) {
    state.comparisonGraphItemsResource = graphItemsResource
  },
  setProductsDetailedAnalytics(state, product: CrmItemDetailedAnalytics) {
    state.productsDetailedAnalytics = product
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  setActiveProductId(state, value: number) {
    state.activeProductId = value
  },
  setActiveProductMenuType(state, type: string) {
    state.activeProductMenuType = type
  },
  setProductCategories(state, productCategories: ProductCategory[]) {
    state.productCategories = productCategories
  },
  setProductCategoryPagination(state, pagination: Pagination) {
    state.productCategoryPagination = pagination
  },
  setItems(state, items: Item[]) {
    state.items = items
  },
  setItemPagination(state, pagination: Pagination) {
    state.itemPagination = pagination
  },
  setSalesHistory(state, history: CrmSaleHistory[]) {
    state.sales_history = history
  },
  setCrmError(state, error: { key: string; value: boolean }) {
    state.crmProductErrorList[error.key] = error.value
  },
  setProductsResource(state, productsResource: CrmProductsResource) {
    state.productsResource = productsResource
  },
  setProductsResourcePagination(state, pagination: Pagination) {
    state.productsResourcePagination = pagination
  },
}

export const actions: ActionTree<CRMAnalyticState, RootState> = {
  async getItemsResource(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      page: number
      per: number
      comparison_start_date?: string
      comparison_end_date?: string
      product_types?: string[]
      product_category_ids?: string[]
      item_ids?: number[]
      item_name?: string
      sort?: string[]
    }
  ) {
    commit('setLoading', {
      key: 'isItemsResourceLoading',
      loading: true,
    })
    try {
      const branchIds = (rootState as any).hqCrm.selectedBranchIds
      const staffIds = (rootState as any).hqCrm.selectedStaffIds
      const items = await params.api.crmAnalyticsRepo.fetchItemsResource({
        branch_ids: branchIds,
        start_date: params.start_date,
        end_date: params.end_date,
        page: params.page,
        per: params.per,
        comparison_start_date: params.comparison_start_date,
        comparison_end_date: params.comparison_end_date,
        product_types: params.product_types,
        product_category_ids: params.product_category_ids,
        item_ids: params.item_ids,
        item_name: params.item_name,
        sort: params.sort,
        staff_ids: staffIds,
      })
      commit('setItemsResource', items)
      commit('setItemsResourcePagination', items.pagination)
    } catch (error) {
      commit('setCrmError', { key: 'itemsResourse', value: true })
    } finally {
      commit('setLoading', {
        key: 'isItemsResourceLoading',
        loading: false,
      })
    }
  },
  async getGraphProductsResource(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      page?: number
      per?: number
      comparison_start_date?: string
      comparison_end_date?: string
      product_types?: string[]
      product_category_ids?: string[]
      product_ids?: number[]
      name?: string
      sort?: string[]
    }
  ) {
    try {
      commit('setLoading', {
        key: 'isGraphItemsResourceLoading',
        loading: true,
      })
      const branchIds = (rootState as any).hqCrm.selectedBranchIds
      const staffIds = (rootState as any).hqCrm.selectedStaffIds
      const products = await params.api.crmAnalyticsRepo.fetchProductsResource({
        branch_ids: branchIds,
        start_date: params.start_date,
        end_date: params.end_date,
        staff_ids: staffIds,
        page: params.page,
        per: params.per,
        comparison_start_date: params.comparison_start_date,
        comparison_end_date: params.comparison_end_date,
        product_types: params.product_types,
        product_category_ids: params.product_category_ids,
        product_ids: params.product_ids,
        name: params.name,
        sort: params.sort,
      })
      commit('setProductsResource', products)
    } catch (error) {
      commit('setCrmError', { key: 'graphItemsResource', value: true })
    } finally {
      commit('setLoading', {
        key: 'isGraphItemsResourceLoading',
        loading: false,
      })
    }
  },
  async getComparisonGraphItemsResource(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      page?: number
      per?: number
      comparison_start_date?: string
      comparison_end_date?: string
      product_types?: string[]
      product_category_ids?: string[]
      product_ids?: number[]
      name?: string
      sort?: string[]
    }
  ) {
    commit('setLoading', {
      key: 'isGraphItemsResourceLoading',
      loading: true,
    })
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    const items = await params.api.crmAnalyticsRepo.fetchProductsResource({
      branch_ids: branchIds,
      start_date: params.start_date,
      end_date: params.end_date,
      page: params.page,
      per: params.per,
      staff_ids: staffIds,
      comparison_start_date: params.comparison_start_date,
      comparison_end_date: params.comparison_end_date,
      product_types: params.product_types,
      product_category_ids: params.product_category_ids,
      product_ids: params.product_ids,
      sort: params.sort,
    })
    commit('setComparisonGraphItemsResource', items)
    commit('setLoading', {
      key: 'isGraphItemsResourceLoading',
      loading: false,
    })
  },
  async getProductsDetailedAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      product_id: number
      start_date: string
      end_date: string
      comparison_start_date?: string
      comparison_end_date?: string
    }
  ) {
    commit('setLoading', {
      key: 'isProductsDetailedAnalyticsLoading',
      loading: true,
    })
    const staffIds = (rootState as any).hqCrm.selectedStaffIds
    const branchIds = (rootState as any).hqCrm.selectedBranchIds
    try {
      const products =
        await params.api.crmAnalyticsRepo.fetchProductsDetailedAnalytics(
          params.product_id,
          {
            staff_ids: staffIds,
            branch_ids: branchIds,
            start_date: params.start_date,
            end_date: params.end_date,
            comparison_start_date: params.comparison_start_date,
            comparison_end_date: params.comparison_end_date,
          }
        )
      commit('setProductsDetailedAnalytics', products)
      commit('setCrmError', { key: 'productsDetailedAnalytics', value: false })
    } catch (error) {
      commit('setProductsDetailedAnalytics', productsDetailedAnalytics)

      commit('setCrmError', { key: 'productsDetailedAnalytics', value: true })
    }
    commit('setLoading', {
      key: 'isProductsDetailedAnalyticsLoading',
      loading: false,
    })
  },
  async getAllProductCategories(
    { commit },
    params: { api: Repositories; page: number; per: number }
  ) {
    commit('setLoading', {
      key: 'isProductCategoriesLoading',
      loading: true,
    })
    const { pagination, product_categories } =
      await params.api.productCategoryRepo.fetchProductCategories({
        page: params.page,
        per: params.per,
      })
    if (pagination.total_entries > 40) {
      const product =
        await params.api.productCategoryRepo.fetchProductCategories({
          page: pagination.total_entries,
          per: params.per,
        })
      commit('setProductCategories', product.product_categories)
      commit('setProductCategoryPagination', product.pagination)
    } else {
      commit('setProductCategories', product_categories)
      commit('setProductCategoryPagination', pagination)
    }
    commit('setLoading', {
      key: 'isProductCategoriesLoading',
      loading: false,
    })
  },
  async getAllItems(
    { commit, rootState },
    params: {
      api: Repositories
      staff_id: number
      sort?: string[]
      is_available_online?: boolean
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { pagination } = await params.api.itemRepo.fetchItems(branchId, {
      page: 1,
      per: 1,
      staff_id: params.staff_id,
      sort: params.sort,
      is_available_online: params.is_available_online,
    })
    const { items } = await params.api.itemRepo.fetchItems(branchId, {
      page: 1,
      per: pagination.total_entries,
      staff_id: params.staff_id,
      sort: params.sort,
      is_available_online: params.is_available_online,
    })
    commit('setItems', items)
    commit('setItemPagination', pagination)
  },
  async getSalesHistory(
    { commit, rootState },
    params: {
      api: Repositories
      product_types?: string[]
      start_data: string
      end_date: string
    }
  ) {
    try {
      commit('setLoading', {
        key: 'isSalesHistoryLoading',
        loading: true,
      })
      const branchIds = (rootState as any).hqCrm.selectedBranchIds
      const staffIds = (rootState as any).hqCrm.selectedStaffIds
      const { sales_history } =
        await params.api.crmAnalyticsRepo.fetchSalesHistory({
          product_types: params.product_types,
          branch_ids: branchIds,
          staff_ids: staffIds,
          start_date: params.start_data,
          end_date: params.end_date,
        })
      commit('setSalesHistory', sales_history)
    } catch (error) {
      commit('setCrmError', { key: 'salesHistory', value: true })
    } finally {
      commit('setLoading', {
        key: 'isSalesHistoryLoading',
        loading: false,
      })
    }
  },
  async getProductResource(
    { commit },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      page: number
      per: number
      branch_ids?: number[]
      comparison_start_date?: string
      comparison_end_date?: string
      product_ids?: number[]
      product_types?: string[]
      product_category_ids?: string[]
      sort?: string[]
      staff_ids?: number[]
      text?: string
    }
  ) {
    try {
      commit('setLoading', {
        key: 'isProductsResourceLoading',
        loading: true,
      })
      const products = await params.api.crmAnalyticsRepo.fetchProductsResource({
        branch_ids: params.branch_ids,
        start_date: params.start_date,
        end_date: params.end_date,
        page: params.page,
        per: params.per,
        product_ids: params.product_ids,
        comparison_start_date: params.comparison_start_date,
        comparison_end_date: params.comparison_end_date,
        product_types: params.product_types,
        product_category_ids: params.product_category_ids,
        sort: params.sort,
        staff_ids: params.staff_ids,
        text: params.text,
      })
      commit('setProductsResource', products)
      commit('setProductsResourcePagination', products.pagination)
    } catch (error) {
      commit('setCrmError', { key: 'itemsResourse', value: true })
    } finally {
      commit('setLoading', {
        key: 'isProductsResourceLoading',
        loading: false,
      })
    }
  },
}
