//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'

export default Vue.extend({
  name: 'CommonSettingsIcon',
  props: {
    iconColor: {
      required: false,
      default: 'rgba(164, 171, 184, 0.7)',
      type: String,
    },
    iconSize: {
      type: String,
      required: false,
      default: '24',
    },
  },
})
