







































































import Vue, { PropOptions } from 'vue'
import { getPrefectures } from '~/helpers/prefectureUtils'
import { Branch } from '~/types'
import { isMatchUrl } from '~/helpers/urlUtils'
import { UserPermission } from '~/models/UserPermission'
import { filterPrefecture } from '~/helpers/hq/hqHome'

export default Vue.extend({
  props: {
    userBranches: {
      type: Array,
      required: true,
    } as PropOptions<Branch[]>,
  },
  data() {
    return {
      searchQuery: '',
      selectedMode: '',
      selectedBranches: [] as Branch[],
    }
  },
  computed: {
    isHQBranch(): boolean {
      return isMatchUrl(this.$route.path, 'hq')
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
    branches(): Branch[] {
      return this.$store.state.branch.branches
    },
    isCrmShow(): boolean {
      return (
        this.userPermission.isAllowed('hq_crm') ||
        this.userPermission.isAllowed('branch_crm')
      )
    },
    filterPrefecture(): string[] {
      return filterPrefecture(this.searchUserBranches)
    },
    userPermission(): UserPermission {
      return this.$store.state.auth.userPermission
    },
    isAllowedPermission(): Boolean {
      return this.userPermission.isAllowed('hq_crm')
    },
    divideUserBranches(): { prefecture: string; branches: Branch[] }[] {
      return this.filterPrefecture.map((prefecture) => {
        const branches = this.searchUserBranches.filter((branch) => {
          return (getPrefectures(branch.prefecture) || 'その他') === prefecture
        })
        return {
          prefecture,
          branches,
        }
      })
    },
    searchUserBranches(): Branch[] {
      const branches = []
      // If the mode is branches
      if (this.selectedMode === 'branches') {
        branches.push(...this.userBranches)
      }
      // If you have HQ CRM access rights
      else if (this.isAllowedPermission) {
        branches.push(...this.branches)
      } else {
        branches.push(...this.userBranches)
      }
      return branches.filter((data) => {
        return (
          !this.searchQuery ||
          data.name.toLowerCase().match(this.searchQuery.toLowerCase())
        )
      })
    },
    selectedBranchIds(): number[] {
      return this.$store.state.hqCrm.selectedBranchIds
    },
    selectedTopBarMode(): 'branches' | 'crm' {
      return this.$store.state.clinicSettings.selectedTopBarMode
    },
  },
  created() {
    this.selectedMode = this.selectedTopBarMode
    const branches = [] as Branch[]
    this.selectedBranchIds.forEach((x: number) => {
      const index = this.userBranches.find((y: Branch) => {
        return y.id === x
      })
      if (index) {
        branches.push(index)
        this.selectedBranches = branches
      }
    })
  },
  methods: {
    setSearchQuery(value: string | null) {
      if (value) {
        return (this.searchQuery = value)
      }
      this.searchQuery = ''
    },
    setSelectedMode(val: 'branches' | 'crm') {
      this.selectedMode = val
      if (val === 'crm') {
        this.$store.commit('clinicSettings/setSelectedTopBarMode', val)
        this.$router.push(this.localePath('/hq/crm/hq_crm'))
      }
    },
    setSelectedBranches(branch: Branch) {
      const isAlreadyAddId = this.selectedBranches.some(
        (item) => item.id === branch.id
      )
      // Add branch item
      if (isAlreadyAddId) {
        // One branch must always be selected.
        if (this.selectedBranches.length === 1) return
        this.selectedBranches = this.selectedBranches.filter(
          (item) => item.id !== branch.id
        )
      }
      // Delete branch item
      else {
        this.selectedBranches.push(branch)
      }
      this.$store.commit(
        'hqCrm/setSelectedBranchIds',
        this.selectedBranches.map((item) => item.id)
      )
    },
  },
})
