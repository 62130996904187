












































































import Vue from 'vue'
import { User } from '~/models/User'
import { isMatchUrl } from '~/helpers/urlUtils'

export default Vue.extend({
  data() {
    return {
      serviceValue: false,
      showFeedbackForm: false,
      isNotificationOpen: false,
      value: false,
    }
  },
  computed: {
    user(): User {
      return this.$store.state.auth.user
    },
    isDisplaySizeSmall() {
      return this.$vuetify.breakpoint.sm
    },
    selectedTopBarMode(): 'branches' | 'crm' {
      return this.$store.state.clinicSettings.selectedTopBarMode
    },
  },
  created() {
    if (isMatchUrl(this.$route.path, 'hq')) {
      this.$store.commit('clinicSettings/setSelectedTopBarMode', 'crm')
    } else {
      this.$store.commit('clinicSettings/setSelectedTopBarMode', 'branches')
    }
  },
  methods: {
    showExternalSite(type: string) {
      if (type === 'helpDoc') {
        window.open(
          'https://threesides.notion.site/c912cb8145454527ac47080325b207b4'
        )
      } else if (type === 'feedbackForm') {
        window.open('https://forms.gle/UDHU9eaanRFA6uBe8')
      }
    },
    async logout() {
      localStorage.removeItem('lastVisitedPage')
      try {
        await this.$api.authRepo.logout()
        const amplitude = (this as any).$amplitude
        amplitude.setUserId('')
      } catch (error) {
        this.$notifier.showMessage({
          content: (error as Error).message,
          color: 'error',
        })
      }
    },
    goToCurrencySetting() {
      this.$router.push(this.localePath('/clinic_setting/web_reservation'))
    },
  },
})
