import { MutationTree, ActionTree } from 'vuex'
import {
  Customer,
  CustomerSettings,
  Repositories,
  Pagination,
  CustomerFilter,
  SearchCustomer,
  Location,
} from '~/types'
import { RootState } from '~/store'
import { transformCustomerFilters } from '~/helpers/customerUtils'

export const state = () => ({
  customerSettings: {
    mode: 'automatic',
  } as CustomerSettings,
  name: '',
  filters: [] as CustomerFilter[],
  tag_ids: [] as number[],
  customers: [] as Customer[],
  customersPagination: {} as Pagination,
  searchCustomers: [] as SearchCustomer[],
  searchCustomersPagination: {} as Pagination,
  customer: {} as Customer,
  loadingList: {
    isCustomerLoading: false,
  },
  selectedProfileTab: '',
  currentCustomerBranchId: null as number | null,
  isShrinkLayout: false as Boolean,
  isTags: false,
  visit_date: {} as { before: string; after: string },
  locations: [] as any,
  customerLocation: [] as Location[],
})

export type CustomerState = ReturnType<typeof state>

export const mutations: MutationTree<CustomerState> = {
  setCustomerSettings(state, customerSettings: CustomerSettings) {
    state.customerSettings = customerSettings
  },
  setCustomers(state, customers: Customer[]) {
    state.customers = customers
  },
  setCustomersLocation(state, customersLocation: Location[]) {
    state.customerLocation = customersLocation
  },
  setCustomer(state, customer: Customer) {
    state.customer = customer
  },

  setCustomersPagination(state, pagination: Pagination) {
    state.customersPagination = pagination
  },
  setSearchCustomers(state, searchCustomers: SearchCustomer[]) {
    state.searchCustomers = searchCustomers
  },
  addNewSearchCustomer(state, customerData: Customer) {
    state.searchCustomers.unshift(customerData)
  },
  updateSearchCustomer(state, customerData: Customer) {
    const index = state.searchCustomers.findIndex(
      (item) => item.id === customerData.id
    )
    if (index !== -1) state.searchCustomers.splice(index, 1, customerData)
  },
  deleteSearchCustomer(state, id: number) {
    const index = state.searchCustomers.findIndex((item) => item.id === id)
    if (index !== -1) state.searchCustomers.splice(index, 1)
  },
  setSearchCustomersPagination(state, pagination: Pagination) {
    state.searchCustomersPagination = pagination
  },
  addNewCustomer(state, customerData: Customer) {
    state.customers.unshift(customerData)
  },
  updateCustomer(state, customerData: Customer) {
    const index = state.customers.findIndex(
      (item) => item.id === customerData.id
    )
    if (index !== -1) state.customers.splice(index, 1, customerData)
  },
  deleteCustomer(state, id: number) {
    const index = state.customers.findIndex((item) => item.id === id)
    if (index !== -1) state.customers.splice(index, 1)
  },
  updatePagination(state) {
    state.customersPagination.total_entries += 1
    state.customersPagination.total_pages = Math.ceil(
      state.customersPagination.total_entries / 10
    )
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  setFilterParams(
    state,
    params: { name: string; filters: CustomerFilter[]; tag_ids: number[] }
  ) {
    state.name = params.name
    state.filters = params.filters
    state.tag_ids = params.tag_ids
  },
  setProfileTab(state, ProfileTab: string) {
    state.selectedProfileTab = ProfileTab
  },
  setIsShrinkLayout(state, isShrinkLayout: Boolean) {
    state.isShrinkLayout = isShrinkLayout
  },
  setIsTag(state, isTags: boolean) {
    state.isTags = isTags
  },
  resetIsTags(state) {
    state.isTags = false
  },
  setTagIds(state, ids: number[]) {
    state.tag_ids = ids
  },
  setVisitDate(state, date: { before: string; after: string }) {
    state.visit_date = date
  },
  setCurrentCustomerBranchId(state, branchId: number) {
    state.currentCustomerBranchId = branchId
  },
}

export const actions: ActionTree<CustomerState, RootState> = {
  show(
    { commit },
    payload?: {
      tag_ids: number[]
      visit_date?: { before: string; after: string }
    }
  ) {
    if (payload) {
      commit('setTagIds', payload.tag_ids)
      commit('setVisitDate', payload.visit_date)
    }
  },
  async fetchCustomerSettings(
    { commit, rootState },
    params: { api: Repositories }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const customerSettings =
      await params.api.customerRepo.fetchCustomerSettings(branchId)
    commit('setCustomerSettings', customerSettings)
  },

  async updateCustomerSettings(
    { commit, rootState },
    params: { api: Repositories; customerSettingsData: CustomerSettings }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const customerSettings =
      await params.api.customerRepo.updateCustomerSettings(
        branchId,
        params.customerSettingsData
      )
    commit('setCustomerSettings', customerSettings)
  },

  // Customer
  async fetchCustomers(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      name?: string
      customer_code?: string
      sort?: string[]
      filters?: CustomerFilter[]
      tag_ids?: number[]
      is_exact?: boolean
      visit_date?: { before: string; after: string }
    }
  ) {
    commit('setLoading', { key: 'isCustomerLoading', loading: true })

    const branchId = (rootState as any).auth.user.activeBranch
    const { customers, pagination } =
      await params.api.customerRepo.fetchCustomers(branchId, {
        page: params.page,
        per: params.per,
        name: params.name,
        customer_code: params.customer_code,
        tag_ids: params.tag_ids,
        sort: params.sort,
        visit_date: params.visit_date,
        is_exact: params.is_exact,
        filters: params.filters
          ? transformCustomerFilters(params.filters)
          : undefined,
      })
    commit('setCustomers', customers)
    commit('setCustomersPagination', pagination)
    commit('setLoading', { key: 'isCustomerLoading', loading: false })
  },
  async fetchCustomersLocation(
    { commit, rootState },
    params: {
      api: Repositories
      name?: string
      customer_code?: string
      sort?: string[]
      filters?: CustomerFilter[]
      tag_ids?: number[]
      is_exact?: boolean
      visit_date?: { before: string; after: string }
    }
  ) {
    commit('setLoading', { key: 'isCustomerLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { customers } = await params.api.customerRepo.fetchCustomersLocation(
      branchId,
      {
        name: params.name,
        customer_code: params.customer_code,
        tag_ids: params.tag_ids,
        sort: params.sort,
        visit_date: params.visit_date,
        is_exact: params.is_exact,
        filters: params.filters
          ? transformCustomerFilters(params.filters)
          : undefined,
      }
    )
    commit('setCustomersLocation', customers)
    commit('setLoading', { key: 'isCustomerLoading', loading: false })
  },
  async searchCustomer(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      searchQuery?: string
      is_exact?: boolean
      filters?: CustomerFilter[]
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { customers, pagination } =
      await params.api.customerRepo.fetchSearchCustomers(branchId, {
        page: params.page,
        per: params.per,
        q: params.searchQuery,
        is_exact: params.is_exact,
      })
    commit('setSearchCustomers', customers)
    commit('setSearchCustomersPagination', pagination)
  },
  async createCustomer(
    { commit, rootState },
    params: { api: Repositories; customerData: Customer }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const customer = await params.api.customerRepo.createCustomer(
      branchId,
      params.customerData
    )
    commit('addNewCustomer', customer)
    commit('updatePagination')
  },
  async getCustomerWithId(
    { state, commit, rootState },
    params: {
      api: Repositories
      customerId: number
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const newCustomer = await params.api.customerRepo.fetchCustomerWithId(
      branchId,
      params.customerId
    )

    if (
      state.customers.findIndex(
        (customer) => customer.id === newCustomer.id
      ) === -1
    ) {
      commit('addNewCustomer', newCustomer)
      commit('updatePagination')
    }
    if (
      state.searchCustomers.findIndex(
        (customer) => customer.id === newCustomer.id
      ) === -1
    ) {
      commit('addNewSearchCustomer', newCustomer)
    }
  },
  async updateCustomer(
    { commit, rootState },
    params: {
      api: Repositories
      customerData: Customer
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const customer = (await params.api.customerRepo.updateCustomer(
      branchId,
      params.customerData
    )) as Customer
    if (customer.hidden) {
      commit('deleteCustomer', customer.id)
      commit('deleteSearchCustomer', customer.id)
    } else {
      commit('updateCustomer', customer)
      commit('updateSearchCustomer', customer)
      commit('setCustomer', customer)
    }
  },
  async deleteCustomer(
    { commit, rootState },
    params: { api: Repositories; customerId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    await params.api.customerRepo.deleteCustomer(branchId, params.customerId)
    commit('deleteCustomer', params.customerId)
  },
}
