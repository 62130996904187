import { MutationTree, ActionTree } from 'vuex'
import { CMS, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  content: {} as CMS.Content,
  contentList: [] as CMS.Content[],
  contentPagination: {} as Pagination,
  contentType: '' as CMS.ContentType,
  loadingList: {
    isContentLoading: false,
  },
})

export type CMSContentState = ReturnType<typeof state>

export const mutations: MutationTree<CMSContentState> = {
  setContent(state, content: CMS.Content) {
    state.content = content
  },
  setContentList(state, contentList: CMS.Content[]) {
    state.contentList = contentList
  },
  setContentPagination(state, pagination: Pagination) {
    state.contentPagination = pagination
  },
  setContentType(state, contentType: CMS.ContentType) {
    state.contentType = contentType
  },
  addContent(state, contentData: CMS.Content) {
    state.content = contentData
  },
  updateContent(state, contentData: CMS.Content) {
    state.content = contentData
  },
  deleteContent(state, id: number) {
    const index = state.contentList.findIndex((item) => item.id === id)
    if (index !== -1) state.contentList.splice(index, 1)
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<CMSContentState, RootState> = {
  async fetchContent(
    { commit, rootState, state },
    params: {
      api: Repositories
      page: number
      per: number
    }
  ) {
    commit('setLoading', { key: 'isContentLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const result = (await params.api
      .cmsContentRepo(state.contentType)
      .fetchContent(branchId, {
        page: params.page,
        per: params.per,
      })) as { contentType: CMS.Content[]; pagination: Pagination }
    commit('setContentList', result[state.contentType])
    commit('setContentPagination', result.pagination)
    commit('setLoading', { key: 'isContentLoading', loading: false })
  },
  async createContent(
    { commit, rootState, state },
    params: { api: Repositories; contentData: CMS.Content }
  ) {
    commit('setLoading', { key: 'isContentLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const contentData = await params.api
      .cmsContentRepo(state.contentType)
      .createContent(branchId, params.contentData)
    commit('addContent', contentData)
    commit('setLoading', { key: 'isContentLoading', loading: false })
  },
  async getContentWithId(
    { commit, rootState, state },
    params: { api: Repositories; contentId: number }
  ) {
    commit('setLoading', { key: 'isContentLoading', loading: true })
    commit('setContent', {})
    const branchId = (rootState as any).auth.user.activeBranch
    const content = await params.api
      .cmsContentRepo(state.contentType)
      .fetchContentWithId(branchId, params.contentId)
    commit('setContent', content)
    commit('setLoading', { key: 'isContentLoading', loading: false })
  },
  async updateContent(
    { commit, rootState, state },
    params: { api: Repositories; contentData: CMS.Content }
  ) {
    commit('setLoading', { key: 'isContentLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const contentData = await params.api
      .cmsContentRepo(state.contentType)
      .updateContent(branchId, params.contentData)
    commit('updateContent', contentData)
    commit('setLoading', { key: 'isContentLoading', loading: false })
  },
  async deleteContent(
    { commit, rootState, state },
    params: { api: Repositories; contentId: number }
  ) {
    commit('setLoading', { key: 'isContentLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api
      .cmsContentRepo(state.contentType)
      .deleteContent(branchId, params.contentId)
    commit('deleteContent', params.contentId)
    commit('setLoading', { key: 'isContentLoading', loading: false })
  },
}
