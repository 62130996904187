import { MutationTree, GetterTree, ActionTree } from 'vuex'
import {
  Goal,
  Repositories,
  Pagination,
  StaffGoal,
  StaffGoalTarget,
  StaffDateRange,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  branchGoals: [] as Goal[],
  staffGoals: [] as StaffGoal[],
  allBranchesGoals: [] as Goal[],
  allBranchesGoalsPagination: {} as Pagination,
  selectedBranchIds: [] as number[],
  currentStaffDateRange: {} as StaffDateRange,
  currentBranchId: -1 as number,
  currentBranchName: '' as string,
  currentBranchGoal: {} as Goal,
  branchGoalsPagination: {} as Pagination,
  staffGoalsPagination: {} as Pagination,
  loadingList: {
    isGoalsLoading: false,
  },
})

export type GoalState = ReturnType<typeof state>

export const mutations: MutationTree<GoalState> = {
  setBranchGoals(state, goals: Goal[]) {
    state.branchGoals = goals
  },
  setBranchGoalsPagination(state, pagination: Pagination) {
    state.branchGoalsPagination = pagination
  },
  setAllBranchesGoals(state, goals: Goal[]) {
    state.allBranchesGoals = goals
  },
  setAllBranchesGoalsPagination(state, pagination: Pagination) {
    state.allBranchesGoalsPagination = pagination
  },
  setStaffGoals(state, staffGoals: StaffGoal[]) {
    state.staffGoals = staffGoals
  },
  setStaffGoalsPagination(state, pagination: Pagination) {
    state.staffGoalsPagination = pagination
  },
  setSelectedBranchIds(state, branchIds: number[]) {
    state.selectedBranchIds = branchIds
  },
  addNewBranchGoal(state, goalData: Goal) {
    state.branchGoals.unshift(goalData)
  },
  updateCurrentBranchId(state, branchId: number) {
    state.currentBranchId = branchId
  },
  updateCurrentBranchName(state, branchName: string) {
    state.currentBranchName = branchName
  },
  updateCurrentBranchGoal(state, currentBranchGoal: Goal) {
    state.currentBranchGoal = currentBranchGoal
  },
  updateCurrentStaffDateRange(state, dateRange: StaffDateRange) {
    state.currentStaffDateRange = dateRange
  },
  updateStaffGoalsValues(state, staffGoals: StaffGoal[]) {
    staffGoals.forEach((tempStaffGoal: StaffGoal) => {
      const index = state.staffGoals.findIndex(
        (item) => item.id === tempStaffGoal.id
      )
      if (index !== -1) state.staffGoals.splice(index, 1, tempStaffGoal)
    })
  },
  updateBranchGoal(state, goalData: Goal) {
    const index = state.branchGoals.findIndex((item) => item.id === goalData.id)
    if (index !== -1) state.branchGoals.splice(index, 1, goalData)
  },
  deleteBranchGoal(state, id: number) {
    const index = state.branchGoals.findIndex((item) => item.id === id)
    if (index !== -1) state.branchGoals.splice(index, 1)
  },

  deleteStaffGoals(state, ids: number[]) {
    ids.forEach((id: number) => {
      const index = state.staffGoals.findIndex((item) => item.id === id)
      if (index !== -1) state.branchGoals.splice(index, 1)
    })
  },
  updatePagination(state) {
    state.branchGoalsPagination.total_entries += 1
    state.branchGoalsPagination.total_pages = Math.ceil(
      state.branchGoalsPagination.total_entries / 10
    )
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const getters: GetterTree<GoalState, RootState> = {
  getGoalWithId: (state) => (id: Number) => {
    const index = state.branchGoals.findIndex((item) => item.id === id)
    return state.branchGoals[index]
  },
}

export const actions: ActionTree<GoalState, RootState> = {
  async fetchBranchGoals(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      start_date: string
      end_date: string
    }
  ) {
    commit('setLoading', { key: 'isGoalsLoading', loading: true })
    const branchId =
      (rootState as any).goal.currentBranchId !== -1
        ? (rootState as any).goal.currentBranchId
        : (rootState as any).auth.user.activeBranch
    const { sales_targets, pagination } =
      await params.api.goalRepo.fetchBranchGoals(branchId, {
        page: params.page,
        per: params.per,
        start_date: params.start_date,
        end_date: params.end_date,
      })
    commit('setBranchGoals', sales_targets)
    commit('setBranchGoalsPagination', pagination)
    commit('setLoading', { key: 'isGoalsLoading', loading: false })
  },
  async fetchBranchCurrentGoal(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      branch_id: number
      start_date: string
      end_date: string
    }
  ) {
    commit('setLoading', { key: 'isGoalsLoading', loading: true })
    const { sales_targets, pagination } =
      await params.api.goalRepo.fetchBranchGoals(params.branch_id, {
        page: params.page,
        per: params.per,
        start_date: params.start_date,
        end_date: params.end_date,
      })
    const currentGoal = sales_targets.length
      ? sales_targets[0]
      : { target_amount: 0 }
    commit('updateCurrentBranchGoal', currentGoal)
    commit('setLoading', { key: 'isGoalsLoading', loading: false })
  },
  async fetchAllBranchesGoals(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
      search?: string
      branch_ids?: number[]
      sort?: string[]
      start_date: string
      end_date: string
    }
  ) {
    commit('setLoading', { key: 'isGoalsLoading', loading: true })
    const { branches, pagination } =
      await params.api.goalRepo.fetchAllBranchesGoals({
        page: params.page,
        per: params.per,
        sort: params.sort,
        search: params.search,
        branch_ids: params.branch_ids,
        start_date: params.start_date,
        end_date: params.end_date,
      })
    commit('setAllBranchesGoals', branches)
    commit('setAllBranchesGoalsPagination', pagination)
    commit('setLoading', { key: 'isGoalsLoading', loading: false })
  },

  async fetchStaffGoals(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      search?: string
      sort?: string[]
      start_date: string
      end_date: string
    }
  ) {
    commit('setLoading', { key: 'isGoalsLoading', loading: true })
    const branchId =
      (rootState as any).goal.currentBranchId !== -1
        ? (rootState as any).goal.currentBranchId
        : (rootState as any).auth.user.activeBranch
    const { sales_targets, pagination } =
      await params.api.goalRepo.fetchStaffGoals(branchId, {
        page: params.page,
        per: params.per,
        search: params.search,
        sort: params.sort,
        start_date: params.start_date,
        end_date: params.end_date,
      })
    commit('setStaffGoals', sales_targets)
    commit('setStaffGoalsPagination', pagination)
    commit('setLoading', { key: 'isGoalsLoading', loading: false })
  },
  async updateStaffGoals(
    { commit, rootState },
    params: {
      api: Repositories
      staffGoalsData: StaffGoalTarget[]
      start_date: string
      end_date: string
    }
  ) {
    commit('setLoading', { key: 'isGoalsLoading', loading: true })
    const branchId =
      (rootState as any).goal.currentBranchId !== -1
        ? (rootState as any).goal.currentBranchId
        : (rootState as any).auth.user.activeBranch
    const { sales_targets } = await params.api.goalRepo.updateStaffGoals(
      branchId,
      {
        staffGoalsData: params.staffGoalsData,
        start_date: params.start_date,
        end_date: params.end_date,
      }
    )
    commit('updateStaffGoalsValues', sales_targets)
    commit('setLoading', { key: 'isGoalsLoading', loading: false })
  },

  async createBranchGoal(
    { commit, rootState },
    params: { api: Repositories; branchId: number; goalData: Goal }
  ) {
    commit('setLoading', { key: 'isGoalsLoading', loading: true })
    const branchId =
      (rootState as any).goal.currentBranchId !== -1
        ? (rootState as any).goal.currentBranchId
        : (rootState as any).auth.user.activeBranch
    const goal = await params.api.goalRepo.createBranchGoal(
      branchId,
      params.goalData
    )
    commit('addNewBranchGoal', goal)
    commit('updatePagination')
    commit('setLoading', { key: 'isGoalsLoading', loading: false })
  },

  async updateBranchGoal(
    { commit, rootState },
    params: { api: Repositories; goalData: Goal }
  ) {
    commit('setLoading', { key: 'isGoalsLoading', loading: true })
    const branchId =
      (rootState as any).goal.currentBranchId !== -1
        ? (rootState as any).goal.currentBranchId
        : (rootState as any).auth.user.activeBranch
    const goal = await params.api.goalRepo.updateBranchGoal(
      branchId,
      params.goalData
    )
    commit('updateBranchGoal', goal)
    commit('updatePagination')
    commit('setLoading', { key: 'isGoalsLoading', loading: false })
  },

  async deleteBranchGoal(
    { commit, rootState },
    params: { api: Repositories; branchGoalId: number }
  ) {
    const branchId =
      (rootState as any).goal.currentBranchId !== -1
        ? (rootState as any).goal.currentBranchId
        : (rootState as any).auth.user.activeBranch
    await params.api.goalRepo.deleteBranchGoal(branchId, params.branchGoalId)
    commit('deleteBranchGoal', params.branchGoalId)
  },

  async deleteStaffGoals(
    { commit, rootState },
    params: { api: Repositories; staffGoalIds: number[] }
  ) {
    const branchId =
      (rootState as any).goal.currentBranchId !== -1
        ? (rootState as any).goal.currentBranchId
        : (rootState as any).auth.user.activeBranch
    await params.api.goalRepo.deleteStaffGoals(branchId, params.staffGoalIds)
    commit('deleteStaffGoals', params.staffGoalIds)
  },
}
