import Vue from 'vue'
import ActionCableVue from 'actioncable-vue'

if (process.client) {
  Vue.use(ActionCableVue, {
    debug: process.env.NODE_ENV === 'development',
    debugLevel: 'all',
    connectionUrl: 'wss://api-develop.threease.com/websockets',
    connectImmediately: false,
  })
}
