import { MutationTree, ActionTree } from 'vuex'
import { Invoice, Transaction, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  invoices: [] as Invoice[],
  reservationInvoice: {} as Invoice,
  transactions: [] as Transaction[],
  invoicePagination: {} as Pagination,
  isInvoiceEdited: false,
  transactionPagination: {} as Pagination,
})

export type CustomerState = ReturnType<typeof state>

export const mutations: MutationTree<CustomerState> = {
  setInvoices(state, invoices: Invoice[]) {
    state.invoices = invoices
  },
  setInvoicesPagination(state, pagination: Pagination) {
    state.invoicePagination = pagination
  },
  setReservationInvoice(state, invoice: Invoice) {
    state.reservationInvoice = invoice
  },
  setTransactions(state, transactions: Transaction[]) {
    state.transactions = transactions
  },
  setTransactionPagination(state, pagination: Pagination) {
    state.transactionPagination = pagination
  },
  addTransaction(state, transactionData: Transaction) {
    state.transactions.unshift(transactionData)
  },

  updateTransaction(state, transactionData: Transaction) {
    const index = state.transactions.findIndex(
      (trxn) => trxn.id === transactionData.id
    )
    if (index !== -1) state.transactions.splice(index, 1, transactionData)
  },
  updateTransactionPagination(state) {
    state.transactionPagination.total_entries += 1
    state.transactionPagination.total_pages = Math.ceil(
      state.transactionPagination.total_entries / 10
    )
  },
  updateIsInvoiceEdited(state, isEdited: boolean) {
    state.isInvoiceEdited = isEdited
  },
}

export const actions: ActionTree<CustomerState, RootState> = {
  async fetchInvoices({ commit, rootState }, params: { api: Repositories }) {
    const branchId = (rootState as any).auth.user.activeBranch
    const invoices = await params.api.invoiceRepo.fetchInvoices(branchId)
    commit('setInvoices', invoices)
  },
  async fetchReservationInvoice(
    { commit, rootState },
    params: { api: Repositories; reservationId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const invoice = await params.api.invoiceRepo.showReservationInvoice(
      branchId,
      params.reservationId
    )
    commit('setReservationInvoice', invoice)
  },
  async fetchTransactions(
    { commit, rootState },
    params: { api: Repositories; invoiceId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const result = await params.api.invoiceRepo.fetchTransactions(
      branchId,
      params.invoiceId
    )
    commit('setTransactions', result.transactions)
    commit('setTransactionPagination', result.pagination)
  },
  async updateInvoice(
    { commit, dispatch, rootState, state },
    params: {
      api: Repositories
      branchId: number
      invoiceId: number
      reservationIds: number[]
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const result = await params.api.invoiceRepo.updateInvoice(
      branchId,
      params.invoiceId,
      params.reservationIds
    )
    const reservationInvoice = { ...state.reservationInvoice }
    reservationInvoice.invoice = result

    commit('setReservationInvoice', reservationInvoice)
    await dispatch(
      'reservationForm/fetchUpdatedReservation',
      { api: params.api },
      {
        root: true,
      }
    )
  },
  async createTransaction(
    { state, commit, dispatch, rootState },
    params: {
      api: Repositories
      invoiceId: number
      transactionData: Transaction
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const transaction = await params.api.invoiceRepo.createTransaction(
      branchId,
      params.invoiceId,
      params.transactionData
    )
    commit('addTransaction', transaction)
    commit('updateTransactionPagination')
    await dispatch(
      'reservationForm/fetchUpdatedReservation',
      { api: params.api },
      {
        root: true,
      }
    )

    // fetching all reservations when merged reservation transaction is complete(to update state of multiple merged reservations)
    if (state.reservationInvoice.invoice.reservation_data.length > 1) {
      const calendarStartDate = (rootState as any).reservationCalendar
        .calendarStartDate
      const startDate = new Date(calendarStartDate.valueOf())
      const endDate = new Date(calendarStartDate.valueOf())
      await dispatch(
        'reservation/fetchReservations',
        {
          api: params.api,
          start_date: this.$dayjs(startDate).format('YYYYMMDD'),
          end_date: this.$dayjs(endDate).format('YYYYMMDD'),
          page: 1,
          per: 60,
        },
        {
          root: true,
        }
      )
    }
  },
  async updateTransaction(
    { commit, dispatch, rootState },
    params: {
      api: Repositories
      invoiceId: number
      transactionData: Transaction
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const transaction = await params.api.invoiceRepo.updateTransaction(
      branchId,
      params.transactionData
    )
    commit('updateTransaction', transaction)
    await dispatch(
      'reservationForm/fetchUpdatedReservation',
      { api: params.api },
      {
        root: true,
      }
    )
  },
}
