















































import Vue from 'vue'
import { Guides, Feature } from '~/types/demo'

export default Vue.extend({
  data: () => ({
    showDialog: false,
    guides: [] as Guides[],
    guidesTitle: '',
    guidesDescription: '',
  }),
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'demo/setShowGuide') {
        this.showDialog = true
      }
      if (mutation.type === 'demo/setGuides') {
        this.guidesTitle = state.demo.guides.title
        this.guidesDescription = state.demo.guides.description
        const guides = state.demo.guides.features
        this.guides = guides?.map((item: Feature) => {
          // eslint-disable-next-line nuxt/no-globals-in-created
          const getElementId = document.getElementById(item.id)
          const position = getElementId?.getBoundingClientRect()
          return {
            id: item.id,
            title: item.title,
            subtitle: item.subtitle,
            imagePosition: item.imagePosition,
            top: position?.top,
            left: position?.left,
            right: position?.right,
            bottom: position?.bottom,
            width: position?.width,
            height: position?.height,
            x: position?.x,
            y: position?.y,
          }
        })
      }
    })
  },
  methods: {
    closeDialog() {
      this.showDialog = false
    },
    getTopPosition(item: Feature) {
      let top = ''
      switch (item.imagePosition) {
        case 'top-left-arrow':
          top = item.y + item.height + 'px'
          break
        case 'top-arrow':
          top = item.top + 35 + 'px'
          break
        case 'left-arrow':
          top = item.top - 15 + 'px'
          break
        case 'bottom-arrow':
          top = ''
          break
        default:
          top = item.top + 'px'
      }
      return top
    },
    getLeftPosition(item: Feature) {
      let left = ''
      switch (item.imagePosition) {
        case 'left-arrow':
          left = item.left + 120 + 'px'
          break
        case 'top-arrow':
          left = item.left + 10 + 'px'
          break
        case 'bottom-arrow':
          left = item.left + 'px'
          break
        default:
          left =
            item.id === 'analytics-dashboard'
              ? item.x + 60 + 'px'
              : item.x + item.width / 2 + 'px'
      }
      return left
    },
    getBottomPosition(item: Feature) {
      let bottom = ''
      switch (item.imagePosition) {
        case 'bottom-arrow':
          bottom = 10 + 'px'
          break
      }
      return bottom
    },
    getGuidesArrowStyle(item: Feature) {
      let bottom = ''
      switch (item.imagePosition) {
        case 'bottom-arrow':
          bottom = 'text-center'
          break
        case 'top-arrow':
          bottom = 'ml-3'
          break
      }
      return bottom
    },
    getGuidesStyle(item: Feature) {
      let GuidesStyle = ''
      switch (item.imagePosition) {
        case 'top-arrow':
          GuidesStyle = 'flex-column mt-1'
          break
        case 'bottom-arrow':
          GuidesStyle = 'flex-column-reverse'
          break
        case 'left-arrow':
          GuidesStyle = 'align-center'
          break
        case 'bottom-left-arrow':
          GuidesStyle = 'align-center'
          break
        default:
          GuidesStyle = 'align-start'
      }
      return GuidesStyle
    },
  },
})
