import { MutationTree } from 'vuex'

export const state = () => ({
  actionButtonText: '',
  actionButtonColor: '',
  callback: null,
  cancelCallback: null,
  checkBoxText: '',
  checkBoxVal: false,
  closeButtonText: '',
  showCloseButton: true,
  dropDownText: '',
  dropDownList: [],
  dropDownValue: [],
  showDropDown: false,
  subTitle: '',
  cautionText: '',
  title: '',
  customTitle: '',
  customSubTitle: '',
  deleteIconRequired: true,
  iconLink: '',
  showCancelRes: false,
  cancelRes: null,
  multiple: true,
  cancelResText: '予約キャンセル',
  placeholderText: '',
  htmlContent: '',
})

export type ConfirmWindowState = ReturnType<typeof state>

export const mutations: MutationTree<ConfirmWindowState> = {
  setCheckboxVal(state, val) {
    state.checkBoxVal = val
  },
  setDropDownVal(state, val) {
    state.dropDownValue = val
  },
  show(state, payload) {
    state.actionButtonText = payload.actionButtonText ?? ''
    state.actionButtonColor = payload.actionButtonColor ?? ''
    state.callback = payload.callback
    state.cancelCallback = payload.cancelCallback
    state.checkBoxText = payload.checkBoxText ?? ''
    state.closeButtonText = payload.closeButtonText ?? ''
    state.showCloseButton = payload.showCloseButton ?? true
    state.subTitle = payload.subTitle ?? ''
    state.cautionText = payload.cautionText ?? ''
    state.title = payload.title
    state.deleteIconRequired = payload.deleteIconRequired ?? true
    state.iconLink = payload.iconLink ?? ''
    state.showCancelRes = payload.showCancelRes === true
    state.cancelRes = payload.cancelRes
    state.cancelResText = payload.cancelResText
    state.showDropDown = payload.showDropDown
    state.dropDownText = payload.dropDownText
    state.dropDownList = payload.dropDownList
    state.multiple = payload.multiple
    state.placeholderText = payload.placeholderText
    state.htmlContent = payload.htmlContent
  },
}
