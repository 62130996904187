import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Unit, UnitRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): UnitRepo => ({
  async fetchUnits(branchId: number, params?: { page?: number; per?: number }) {
    try {
      const res = await $axios.get(`therapists/branches/${branchId}/units`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createUnit(branchId: number, unitData: Unit) {
    try {
      const res = await $axios.post(`therapists/branches/${branchId}/units`, {
        unit: unitData,
      })
      if (res.status === 201) {
        return res.data.unit
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateUnit(branchId: number, unit: Unit) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/units/${unit.id}`,
        {
          unit,
        }
      )
      if (res.status === 200) {
        return res.data.unit
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteUnit(branchId: number, unitId: number) {
    try {
      await $axios.delete(`therapists/branches/${branchId}/units/${unitId}`)
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
