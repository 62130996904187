







































import Vue from 'vue'

export default Vue.extend({
  props: {
    fillColor: {
      type: String,
      required: false,
      default: '',
    },
    textWeight: {
      type: String,
      required: false,
      default: '',
    },
  },
})
