import { MutationTree, ActionTree } from 'vuex'
import {
  Repositories,
  KarteTemplate,
  KarteTemplateCategory,
  KarteTemplatesParams,
  KarteKarteTemplateCategoriesGetParams,
  KarteTemplateCategoriesParams,
  Pagination,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  karteTemplates: [] as KarteTemplate[],
  karteTemplateCategories: [] as KarteTemplateCategory[],
  karteTemplatePagination: {
    page: 1,
    per: 1,
    total_pages: 1,
    total_entries: 1,
  } as Pagination,
  karteTemplateCategoryPagination: {
    page: 1,
    per: 1,
    total_pages: 1,
    total_entries: 1,
  } as Pagination,
})

export type KarteTemplateState = ReturnType<typeof state>

export const mutations: MutationTree<KarteTemplateState> = {
  setKarteTemplates(state, value: KarteTemplate[]) {
    state.karteTemplates = value
  },
  setKarteTemplateCategories(state, value: KarteTemplateCategory[]) {
    state.karteTemplateCategories = value
  },
  setKarteTemplatePagination(state, value) {
    state.karteTemplatePagination = value
  },
  setKarteTemplateCategoryPagination(state, value) {
    state.karteTemplateCategoryPagination = value
  },
  addKarteTemplate(state, value: KarteTemplate) {
    state.karteTemplates.push(value)
  },
  addKarteTemplateCategory(state, value: KarteTemplateCategory) {
    state.karteTemplateCategories.push(value)
  },
  updateKarteTemplate(state, value: KarteTemplate) {
    state.karteTemplates = state.karteTemplates.map((item) =>
      item.id === value.id ? value : item
    )
  },
  updateKarteTemplateCategory(state, value: KarteTemplateCategory) {
    state.karteTemplateCategories = state.karteTemplateCategories.map((item) =>
      item.id === value.id ? value : item
    )
  },
  removeKarteTemplate(state, id: number) {
    state.karteTemplates = state.karteTemplates.filter((item) => item.id !== id)
  },
  removeKarteTemplateCategory(state, value: number) {
    state.karteTemplateCategories = state.karteTemplateCategories.filter(
      (item) => item.id !== value
    )
  },
}

export const actions: ActionTree<KarteTemplateState, RootState> = {
  async fetchKarteTemplates(
    { commit },
    params: {
      api: Repositories
      search?: string
      template_types?: number[]
      category_ids?: number[]
      branch_ids?: number[]
      page: number
      per: number
    }
  ) {
    const { templates, pagination } =
      await params.api.karteTemplatesRepo.fetchKarteTemplates({
        search: params?.search,
        template_types: params?.template_types,
        category_ids: params?.category_ids,
        branch_ids: params?.branch_ids,
        page: params?.page,
        per: params?.per,
      })
    commit('setKarteTemplates', templates)
    commit('setKarteTemplatePagination', pagination)
  },
  async postKarteTemplate(
    { commit },
    params: {
      api: Repositories
    } & KarteTemplatesParams
  ) {
    const { template } = await params.api.karteTemplatesRepo.postKarteTemplate({
      category_id: params.category_id,
      branch_ids: params.branch_ids,
      title: params.title,
      content: params.content,
      template_type: params.template_type,
    })
    commit('addKarteTemplate', template)
  },
  async putKarteTemplate(
    { commit },
    params: {
      api: Repositories
    } & KarteTemplatesParams
  ) {
    const { template } = await params.api.karteTemplatesRepo.putKarteTemplate({
      id: params.id,
      category_id: params.category_id,
      branch_ids: params.branch_ids,
      title: params.title,
      content: params.content,
      template_type: params.template_type,
    })
    commit('updateKarteTemplate', template)
  },
  async deleteKarteTemplate(
    { commit },
    params: {
      api: Repositories
      id: number
    }
  ) {
    await params.api.karteTemplatesRepo.deleteKarteTemplate(params.id)
    commit('removeKarteTemplate', params.id)
  },

  async fetchKarteTemplateCategories(
    { commit },
    params: {
      api: Repositories
    } & KarteKarteTemplateCategoriesGetParams
  ) {
    const { template_categories, pagination } =
      await params.api.KarteTemplateCategoriesRepo.fetchKarteTemplateCategories(
        {
          template_types: params.template_types,
          search: params.search,
          branch_ids: params.branch_ids,
          page: params.page,
          per: params.per,
        }
      )
    commit('setKarteTemplateCategories', template_categories)
    commit('setKarteTemplateCategoryPagination', pagination)
  },
  async postKarteTemplateCategory(
    { commit },
    params: {
      api: Repositories
    } & KarteTemplateCategoriesParams
  ) {
    const { template_category } =
      await params.api.KarteTemplateCategoriesRepo.postKarteTemplateCategory({
        name: params.name,
      })
    commit('addKarteTemplateCategory', template_category)
  },
  async putKarteTemplateCategory(
    { commit },
    params: {
      api: Repositories
    } & KarteTemplateCategoriesParams
  ) {
    const { template_category } =
      await params.api.KarteTemplateCategoriesRepo.putKarteTemplateCategory({
        id: params.id,
        name: params.name,
      })
    commit('updateKarteTemplateCategory', template_category)
  },
  async deleteKarteTemplateCategory(
    { commit },
    params: {
      api: Repositories
      id: number
    }
  ) {
    await params.api.KarteTemplateCategoriesRepo.deleteKarteTemplateCategory(
      params.id
    )
    commit('removeKarteTemplateCategory', params.id)
  },
}
