import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { CustomerRepo, CustomerSettings, Customer, CustomerTag } from '~/types'
const qs = require('qs')

export default ($axios: NuxtAxiosInstance, $sentry: any): CustomerRepo => ({
  async fetchCustomerSettings(branchId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customer_settings`
      )
      if (res.status === 200) {
        return res.data.customer_settings
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async updateCustomerSettings(
    branchId: number,
    customerSettingsData: CustomerSettings
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/customer_settings`,
        {
          customer_settings: customerSettingsData,
        }
      )
      if (res.status === 200) {
        return res.data.customer_settings
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },

  async fetchCustomers(
    branchId: number,
    params?: {
      page?: number
      per?: number
      name?: string
      customer_code?: string
      filters?: object[]
      tag_ids?: number[]
      sort?: string[]
      is_exact?: boolean
      visit_date?: { before: string; after: string }
    }
  ) {
    try {
      params?.filters?.forEach((filter) => {
        Object.assign(params, filter)
      })
      delete params?.filters
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers`,
        {
          params,
          paramsSerializer: (params) => {
            return Object.keys(params)
              .filter((key) => params[key])
              .map((key) => {
                return qs.stringify(
                  { [key]: params[key] },
                  {
                    arrayFormat:
                      key === 'tag_ids' || key === 'sort'
                        ? 'brackets'
                        : 'indices',
                    encode: false,
                  }
                )
              })
              .join('&')
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomersLocation(
    branchId: number,
    params?: {
      name?: string
      customer_code?: string
      filters?: object[]
      tag_ids?: number[]
      sort?: string[]
      visit_date?: { before: string; after: string }
    }
  ) {
    try {
      params?.filters?.forEach((filter) => {
        Object.assign(params, filter)
      })
      delete params?.filters
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/locations`,
        {
          params,
          paramsSerializer: (params) => {
            return Object.keys(params)
              .filter((key) => params[key])
              .map((key) => {
                return qs.stringify(
                  { [key]: params[key] },
                  {
                    arrayFormat:
                      key === 'tag_ids' || key === 'sort'
                        ? 'brackets'
                        : 'indices',
                    encode: false,
                  }
                )
              })
              .join('&')
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchKarteQuestionnaireCustomerToken(
    branchId: number,
    customerId: number,
    params?: {
      branch_access_token?: string
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/${customerId}/karte_link`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data.karte_access_token
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchSearchCustomers(
    branchId: number,
    params?: {
      page?: number
      per?: number
      q?: string
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/search`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerWithId(branchId: number, customerId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/${customerId}`
      )
      if (res.status === 200) {
        return res.data.customer
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createCustomer(
    branchId: number,
    customerData: Customer,
    branch_access_token?: string
  ) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/customers`,
        {
          customer: {
            ...customerData,
            // When sorting by email, an empty string does not reflect sorting.
            email: customerData?.email === '' ? null : customerData?.email,
          },
          branch_access_token,
        }
      )
      if (res.status === 201) {
        return res.data.customer
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async showCustomer(
    branchId: number,
    customerId: number,
    params: { karte_access_token?: string; branch_access_token?: string }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/${customerId}`,
        { params }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateCustomer(
    branchId: number,
    customer: Customer,
    karte_access_token?: string,
    branch_access_token?: string
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/customers/${customer.id}`,
        {
          customer: {
            ...customer,
            // When sorting by email, an empty string does not reflect sorting.
            email: customer?.email === '' ? null : customer?.email,
          },
          karte_access_token,
          branch_access_token,
        }
      )
      if (res.status === 200) {
        return res.data.customer
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateCustomersTags(
    branchId: number,
    customerIds: number[],
    addIds: number[],
    removeIds: number[]
  ) {
    try {
      const res = await $axios.patch(
        `therapists/branches/${branchId}/customers/batch_update`,
        {
          customer_ids: customerIds,
          customer_tags: { add_ids: addIds, remove_ids: removeIds },
        }
      )
      if (res.status === 200) {
        return res.data.customer
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async mergeCustomer(branchId: number, customer: Customer) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/customers/${customer.id}/merge`,
        {
          customer,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteCustomer(branchId: number, customerId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/customers/${customerId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
