import { AuthHeader } from '~/types'

export class User {
  id: number
  accessToken: string
  tokenType: string
  client: string
  expiry: number
  uid: string
  name: string
  activeBranch: number
  constructor(
    id: number,
    accessToken: string,
    tokenType: string,
    client: string,
    expiry: number,
    uid: string,
    name: string,
    activeBranch: number
  ) {
    this.id = id
    this.accessToken = accessToken
    this.tokenType = tokenType
    this.client = client
    this.expiry = expiry
    this.uid = uid
    this.name = name
    this.activeBranch = activeBranch
  }

  toJson() {
    const json = {
      id: this.id,
      accessToken: this.accessToken,
      tokenType: this.tokenType,
      client: this.client,
      expiry: this.expiry,
      uid: this.uid,
      name: this.name,
      activeBranch: this.activeBranch,
    }
    return json
  }

  generateAuthHeader(): AuthHeader {
    return {
      'access-token': this.accessToken,
      'token-type': this.tokenType,
      client: this.client,
      expiry: this.expiry,
      uid: this.uid,
    }
  }
}
