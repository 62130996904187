import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { LicenceRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): LicenceRepo => ({
  async fetchLicences(params?: { page?: number; per?: number }) {
    try {
      const res = await $axios.get('therapists/licences', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchStaffCertifications(
    staffId: number,
    params?: { page?: number; per?: number }
  ) {
    try {
      const res = await $axios.get(
        `therapists/staff/${staffId}/certifications`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
