export class ApiError extends Error {
  constructor(message: string | undefined) {
    super(message)
    this.name = 'ApiError'
  }
}

export class BatchError extends Error {
  constructor(message: string | undefined) {
    super(message)
    this.name = 'BatchError'
  }
}

export class UnProcessableEntityError extends Error {
  constructor(message: string | undefined) {
    super(message)
    this.name = 'UnProcessableEntityError'
  }
}

export class GenericError extends Error {
  constructor(message: string | undefined) {
    super(message)
    this.name = 'GenericError'
  }
}
