import { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  KarteTemplateCategoriesRepo,
  KarteTemplateCategoriesParams,
  KarteKarteTemplateCategoriesGetParams,
} from '~/types'
import processError from '~/helpers/processError'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): KarteTemplateCategoriesRepo => ({
  async fetchKarteTemplateCategories(
    params: KarteKarteTemplateCategoriesGetParams
  ) {
    try {
      const res = await $axios.get(`/therapists/karte/template_categories`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async postKarteTemplateCategory(
    template_category: KarteTemplateCategoriesParams
  ) {
    try {
      const res = await $axios.post(`/therapists/karte/template_categories`, {
        template_category,
      })
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchKarteTemplateCategory(id: number) {
    try {
      const res = await $axios.get(
        `/therapists/karte/template_categories/${id}`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async putKarteTemplateCategory(params: KarteTemplateCategoriesParams) {
    try {
      const res = await $axios.put(
        `/therapists/karte/template_categories/${params.id}`,
        {
          template_category: {
            name: params.name,
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async deleteKarteTemplateCategory(id: number) {
    try {
      await $axios.delete(`/therapists/karte/template_categories/${id}`)
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
