import { MutationTree, ActionTree } from 'vuex'
import { Holiday, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  holidays: [] as Holiday[],
  holidayPagination: {} as Pagination,
  loadingList: {
    isHolidaysLoading: false,
  },
})

export type HolidayState = ReturnType<typeof state>

export const mutations: MutationTree<HolidayState> = {
  setHolidays(state, holidays: Holiday[]) {
    state.holidays = holidays
  },
  setHolidayPagination(state, pagination: Pagination) {
    state.holidayPagination = pagination
  },
  addHoliday(state, holidayData: Holiday) {
    state.holidays.unshift(holidayData)
  },
  updateHoliday(state, holidayData: Holiday) {
    const index = state.holidays.findIndex((item) => item.id === holidayData.id)
    if (index !== -1) state.holidays.splice(index, 1, holidayData)
  },
  deleteHoliday(state, id: number) {
    const index = state.holidays.findIndex((item) => item.id === id)
    if (index !== -1) state.holidays.splice(index, 1)
  },
  updatePagination(state) {
    state.holidayPagination.total_entries += 1
    state.holidayPagination.total_pages = Math.ceil(
      state.holidayPagination.total_entries / 10
    )
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<HolidayState, RootState> = {
  async fetchHolidays(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      start_date: string
      end_date?: string | null
    }
  ) {
    commit('setLoading', { key: 'isHolidaysLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { planned_holidays, pagination } =
      await params.api.plannedHolidayRepo.fetchHolidays(branchId, {
        start_date: params.start_date,
        end_date: params.end_date || null,
        page: params.page,
        per: params.per,
      })
    commit('setHolidays', planned_holidays)
    commit('setHolidayPagination', pagination)
    commit('setLoading', { key: 'isHolidaysLoading', loading: false })
  },

  async createHoliday(
    { commit, rootState },
    params: { api: Repositories; holidayData: Holiday }
  ) {
    commit('setLoading', { key: 'isHolidaysLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const planned_holiday = await params.api.plannedHolidayRepo.createHoliday(
      branchId,
      params.holidayData
    )
    commit('addHoliday', planned_holiday)
    commit('updatePagination')
    commit('setLoading', { key: 'isHolidaysLoading', loading: false })
  },

  async updateHoliday(
    { commit, rootState },
    params: { api: Repositories; holidayData: Holiday }
  ) {
    commit('setLoading', { key: 'isHolidaysLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch

    const holiday = await params.api.plannedHolidayRepo.updateHoliday(
      branchId,
      params.holidayData
    )
    commit('updateHoliday', holiday)
    commit('setLoading', { key: 'isHolidaysLoading', loading: false })
  },
  async deleteHoliday(
    { commit, rootState },
    params: { api: Repositories; holidayId: number }
  ) {
    commit('setLoading', { key: 'isHolidaysLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch

    await params.api.plannedHolidayRepo.deleteHoliday(
      branchId,
      params.holidayId
    )
    commit('deleteHoliday', params.holidayId)
    commit('setLoading', { key: 'isHolidaysLoading', loading: false })
  },
}
