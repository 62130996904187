import dayjs from 'dayjs'
import { MutationTree, ActionTree } from 'vuex'
import {
  ReminderSetting,
  Repositories,
  AutomatedMail,
  Pagination,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  startEndDate: {
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
  },
  reminders: [] as ReminderSetting[],
  automatedMails: [] as AutomatedMail[],
  automatedMailPagination: {} as Pagination,
  isAutomatedMailModalShow: false as Boolean,
  automatedMailData: {} as AutomatedMail,
})

export type AutomatedMailSettingsState = ReturnType<typeof state>

export const mutations: MutationTree<AutomatedMailSettingsState> = {
  setReminderMails(state, reminders: ReminderSetting[]) {
    state.reminders = reminders
  },
  updateReminders(state, reminder: ReminderSetting) {
    const index = state.reminders.findIndex((item) => item.id === reminder.id)
    if (index === -1) return
    state.reminders.splice(
      index,
      1,
      Object.assign({}, state.reminders[index], {
        ...reminder,
      })
    )
  },
  addNewAutomatedMail(state, automatedMail: AutomatedMail) {
    state.automatedMails.unshift(automatedMail)
  },
  setAutomatedMails(state, automatedMails: AutomatedMail[]) {
    state.automatedMails = [...state.automatedMails, ...automatedMails]
  },
  resetAutomatedMails(state) {
    state.automatedMails = []
  },
  setAutomatedMailPagination(state, pagination: Pagination) {
    state.automatedMailPagination = pagination
  },
  updateAutomatedMail(state, automatedMailData: AutomatedMail) {
    const index = state.automatedMails.findIndex(
      (item) => item.id === automatedMailData.id
    )
    if (index === -1) return
    state.automatedMails.splice(
      index,
      1,
      Object.assign({}, state.automatedMails[index], {
        ...automatedMailData,
      })
    )

    // When you are on the web booking email, you can only activate one, so turn off the other active.
    if (
      automatedMailData?.mail_type === 'web_reservation_mail' &&
      automatedMailData?.is_enabled
    ) {
      state.automatedMails = state.automatedMails.map((item) => {
        if (item.mail_type !== 'web_reservation_mail') return item
        if (item.id === automatedMailData.id) return item
        return { ...item, is_enabled: false }
      })
    }
  },
  deleteAutomatedMail(state, id: number) {
    const index = state.automatedMails.findIndex((item) => item.id === id)
    if (index !== -1) state.automatedMails.splice(index, 1)
  },
  setAutomatedMailEditData(state, automatedMailData: AutomatedMail) {
    state.automatedMailData = automatedMailData
  },
  setAutomatedMailModal(state, value: Boolean) {
    state.isAutomatedMailModalShow = value
  },
}

export const actions: ActionTree<AutomatedMailSettingsState, RootState> = {
  async getReminderSettings(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
    }
  ) {
    const branchId = Number((rootState as any).auth.user.activeBranch)
    const { reminder_settings } =
      await params.api.automatedMailRepo.fetchAutomatedMailSettings(branchId, {
        start_date: params.start_date,
        end_date: params.end_date,
      })
    commit('setReminderMails', reminder_settings)
  },
  async setAutomatedMailSettings(
    { commit, rootState },
    params: { api: Repositories; reminder_setting: ReminderSetting }
  ) {
    const branchId = Number((rootState as any).auth.user.activeBranch)
    const { reminder_setting } =
      await params.api.automatedMailRepo.setAutomatedMailSettings(
        branchId,
        params.reminder_setting
      )
    commit('updateReminders', reminder_setting)
  },
  async fetchAutomatedMails(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
    }
  ) {
    const branchId = Number((rootState as any).auth.user.activeBranch)
    const { automated_mails, pagination } =
      await params.api.automatedMailRepo.fetchAutomatedMails(branchId, {
        page: params.page || 1,
        per: params.per,
      })
    commit('setAutomatedMails', automated_mails)
    commit('setAutomatedMailPagination', pagination)
  },
  async createAutomatedMail(
    { commit, rootState },
    params: { api: Repositories; automatedMailData: AutomatedMail }
  ) {
    const branchId = Number((rootState as any).auth.user.activeBranch)
    const automated_mail =
      await params.api.automatedMailRepo.createAutomatedMail(
        branchId,
        params.automatedMailData
      )
    commit('addNewAutomatedMail', automated_mail)
  },
  async updateAutomatedMail(
    { commit, rootState },
    params: { api: Repositories; automatedMailData: AutomatedMail }
  ) {
    const branchId = Number((rootState as any).auth.user.activeBranch)
    const automatedMailData =
      await params.api.automatedMailRepo.updateAutomatedMail(
        branchId,
        params.automatedMailData
      )
    commit('updateAutomatedMail', automatedMailData)
  },
  async deleteAutomatedMail(
    { commit, rootState },
    params: { api: Repositories; automatedMailId: number }
  ) {
    const branchId = Number((rootState as any).auth.user.activeBranch)
    await params.api.automatedMailRepo.deleteAutomatedMail(
      branchId,
      params.automatedMailId
    )
    commit('deleteAutomatedMail', params.automatedMailId)
  },
}
