export const RESERVATION_STATUSES = [
  {
    text: '仮予約',
    value: 'pending',
    color: '#000',
  },
  {
    text: '本予約',
    value: 'confirmed',
    color: '#6e9eff',
  },
  {
    text: '受付済',
    value: 'reception',
    color: '#ea7cb3',
  },
  {
    text: '進行中',
    value: 'in_progress',
    color: '#f2994a',
  },
  {
    text: '完了',
    value: 'completed',
    color: '#36bf36',
  },
  {
    text: 'キャンセル済み',
    value: 'cancelled',
    color: '#A0A0A0',
  },
]

export const getReservationStatuses = ($t: any) => {
  return [
    {
      text: $t('reservations.pending'),
      value: 'pending',
      color: '#000',
    },
    {
      text: $t('reservations.confirmed'),
      value: 'confirmed',
      color: '#6e9eff',
    },
    {
      text: $t('reservations.reception'),
      value: 'reception',
      color: '#ea7cb3',
    },
    {
      text: $t('reservations.inProgress'),
      value: 'in_progress',
      color: '#f2994a',
    },
    {
      text: $t('reservations.completed'),
      value: 'completed',
      color: '#36bf36',
    },
    {
      text: $t('reservations.cancelled'),
      value: 'cancelled',
      color: '#A0A0A0',
    },
  ]
}

export const getReservationCancelReason = ($t: any) => {
  return [
    {
      text: $t('reservations.cancelReasonList.customer_absent'),
      value: 'customer_absent',
    },
    {
      text: $t('reservations.cancelReasonList.cancelled_by_customer'),
      value: 'cancelled_by_customer',
    },
    {
      text: $t('reservations.cancelReasonList.cancelled_by_staff'),
      value: 'cancelled_by_staff',
    },
    {
      text: $t('reservations.cancelReasonList.staff_not_available'),
      value: 'staff_not_available',
    },
    {
      text: $t('reservations.cancelReasonList.faulty_unit'),
      value: 'faulty_unit',
    },
    {
      text: $t('reservations.cancelReasonList.others'),
      value: 'others',
    },
  ]
}
