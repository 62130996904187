import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { JwtRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): JwtRepo => ({
  async fetchJwtNolt() {
    try {
      const res = await $axios.get('therapists/jwt/nolt')
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
