import { MutationTree, ActionTree } from 'vuex'
import dayjs from 'dayjs'
import { RootState } from '~/store'

import {
  Repositories,
  StartEndDate,
  Product,
  HQHistoryTabStatus,
  HistoryApiQueryParams,
  Pagination,
  TotalHistory,
  AdSourceHistory,
} from '~/types'

import { NON_VISITING_CUSTOMERS } from '~/helpers/crm/crmConstantUtils'

const selectBreakawayTypes = NON_VISITING_CUSTOMERS.map((type) => type.value)
const initStartEndDate = {
  startDate: dayjs()
    .subtract(12, 'month')
    .startOf('month')
    .format('YYYY-MM-DD'),
  endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
}

export const state = () => ({
  pageTitle: '' as string,
  visitingBranchHistories: [] as History[],
  totalBranchHistories: [] as TotalHistory[],
  startEndDate: initStartEndDate as StartEndDate,
  tabStatus: 'sales' as HQHistoryTabStatus,
  selectBranchHistoryIds: [] as number[],
  selectProductTypes: [] as string[],
  selectCustomerTypes: [] as string[],
  selectAdSources: [] as string[],
  selectBreakawayTypes: selectBreakawayTypes as string[],
  nonVisitingCustomerBranchHistories: [] as History[],
  products: [] as Product[],
  selectProductIds: [] as number[],
  selectedBranchId: -1 as number,
  selectedBranchName: '',
  selectedBranchDate: '',
  inactiveCustomerToggleType: 'normal',
  totalProductHistories: [] as TotalHistory[],
  currentProductPagination: {} as { key: number; value: Pagination[] },
  currentProductHistories: {} as { key: number; value: History[] },
  currentAdSourcePagination: {} as { key: number; value: Pagination[] },
  currentAdSourceHistories: {} as { key: number; value: AdSourceHistory[] },
  inactiveCustomerType: 'all' as 'all' | 'cured' | 'breakaway',
})

export type HQRanking = ReturnType<typeof state>

export const mutations: MutationTree<HQRanking> = {
  setPageTitle(state, pageTitle: string) {
    state.pageTitle = pageTitle
  },
  setSelectedBranchId(state, selectedBranchId: number) {
    state.selectedBranchId = selectedBranchId
  },
  setSelectedBranchName(state, selectedBranchName: string) {
    state.selectedBranchName = selectedBranchName
  },
  setInactiveCustomerToggleType(state, inactiveCustomerToggleType: string) {
    state.inactiveCustomerToggleType = inactiveCustomerToggleType
  },
  setInactiveCustomerType(
    state,
    inactiveCustomerType: 'all' | 'cured' | 'breakaway'
  ) {
    state.inactiveCustomerType = inactiveCustomerType
  },
  setSelectedBranchDate(state, selectedBranchDate: string) {
    state.selectedBranchDate = selectedBranchDate
  },
  setVisitingBranchHistories(state, branchHistories: History[]) {
    state.visitingBranchHistories = branchHistories
  },
  setTotalHistories(state, totalBranchHistories: TotalHistory[]) {
    state.totalBranchHistories = totalBranchHistories
  },
  setTabStatus(state, status: HQHistoryTabStatus) {
    state.tabStatus = status
  },
  setStartEndDate(state, date: StartEndDate) {
    state.startEndDate = date
  },
  setSelectBranchHistoryIds(state, ids: number[]) {
    state.selectBranchHistoryIds = ids
  },
  setSelectProductTypes(state, type) {
    state.selectProductTypes = type
  },
  setSelectCustomerTypes(state, type) {
    state.selectCustomerTypes = type
  },
  setSelectAdSources(state, type) {
    state.selectAdSources = type
  },
  setSelectBreakawayTypes(state, type) {
    state.selectBreakawayTypes = type
  },
  setProducts(state, products: Product[]) {
    state.products = products
  },
  initSelectProductTypes(state) {
    state.selectProductTypes = []
  },
  updateSelectCustomerTypes(state, type) {
    state.selectCustomerTypes = type
  },
  initSelectCustomerTypes(state) {
    state.selectCustomerTypes = []
  },
  initSelectBreakawayTypes(state) {
    state.selectBreakawayTypes = selectBreakawayTypes
  },
  initStartEndDate(state) {
    state.startEndDate = initStartEndDate
  },
  setNonVisitingCustomerBranchHistories(state, branchHistories: History[]) {
    state.nonVisitingCustomerBranchHistories = branchHistories
  },
  setSelectProductIds(state, ids: number[]) {
    state.selectProductIds = ids
  },
  setTotalProductHistories(state, totalProductHistories: TotalHistory[]) {
    state.totalProductHistories = totalProductHistories
  },
  setProductPagination(
    state,
    params: { branchId: number; productPagination: Pagination }
  ) {
    state.currentProductPagination = {
      ...state.currentProductPagination,
      ...{ [params.branchId]: params.productPagination },
    }
  },
  setAdSourcePagination(
    state,
    params: { branchId: number; adSourcePagination: Pagination }
  ) {
    state.currentAdSourcePagination = {
      ...state.currentAdSourcePagination,
      ...{ [params.branchId]: params.adSourcePagination },
    }
  },
  updatePagination(state, branchId: number) {
    state.currentProductPagination[branchId].total_entries += 1
    state.currentProductPagination[branchId].total_pages = Math.ceil(
      state.currentProductPagination[branchId].total_entries / 10
    )
  },
  updateCurrentProductHistories(
    state,
    params: { branchId: number; productHistory: History[] }
  ) {
    state.currentProductHistories = {
      ...state.currentProductHistories,
      ...{ [params.branchId]: params.productHistory },
    }
  },
  resetCurrentProductHistories(state) {
    state.currentProductHistories = {} as {
      key: number
      value: History[]
    }
  },
  updateCurrentAdSourceHistories(
    state,
    params: { branchId: number; AdSourceHistory: AdSourceHistory[] }
  ) {
    state.currentAdSourceHistories = {
      ...state.currentAdSourceHistories,
      ...{ [params.branchId]: params.AdSourceHistory },
    }
  },
  resetCurrentAdSourceHistories(state) {
    state.currentAdSourceHistories = {} as {
      key: number
      value: AdSourceHistory[]
    }
  },
  resetCurrentProductPagination(state) {
    state.currentProductPagination = {} as {
      key: number
      value: Pagination[]
    }
  },
  resetCurrentAdSourcePagination(state) {
    state.currentAdSourcePagination = {} as {
      key: number
      value: Pagination[]
    }
  },
}

export const actions: ActionTree<HQRanking, RootState> = {
  async getVisitingBranchHistories(
    { commit },
    params: {
      api: Repositories
    } & HistoryApiQueryParams
  ) {
    const { branches, total_history } =
      await params.api.crmBranchHistoryAnalyticsRepo.fetchBranchHistoryForVisitingCustomer(
        {
          branch_ids: params.branch_ids,
          start_date: params.start_date,
          end_date: params.end_date,
          group_by: params.group_by,
          product_types: params.product_types,
          customer_types: params.customer_types,
          product_ids: params.product_ids,
          ad_sources: params.ad_sources,
        }
      )
    commit('setVisitingBranchHistories', branches)
    commit('setTotalHistories', total_history)
  },
  async getNonVisitingBranchHistories(
    { commit },
    params: {
      api: Repositories
    } & HistoryApiQueryParams
  ) {
    const { branches } =
      await params.api.crmBranchHistoryAnalyticsRepo.fetchBranchHistoryForNonVisitingCustomer(
        {
          branch_ids: params.branch_ids,
          start_date: params.start_date,
          end_date: params.end_date,
          customer_types: params.customer_types,
        }
      )
    commit('setNonVisitingCustomerBranchHistories', branches)
  },
  async fetchAllProducts(
    { commit },
    params: { api: Repositories; sort: string[] }
  ) {
    const { pagination } = await params.api.productRepo.fetchProducts({
      page: 1,
      per: 1,
    })
    if (pagination === 0) return commit('setProducts', [])
    const { products } = await params.api.productRepo.fetchProducts({
      page: 1,
      per: pagination.total_entries || 1,
      sort: params.sort,
    })
    commit('setProducts', products)
  },
  async getProductHistories(
    { commit },
    params: {
      api: Repositories
    } & HistoryApiQueryParams
  ) {
    const { products, total_history, pagination } =
      await params.api.crmProductHistoryAnalyticsRepo.fetchProductHistory({
        branch_ids: params.branch_ids,
        start_date: params.start_date,
        end_date: params.end_date,
        group_by: params.group_by,
        product_types: params.product_types,
        customer_types: params.customer_types,
        product_ids: params.product_ids,
        page: params.page,
        per: params.per,
      })
    commit('updateCurrentProductHistories', {
      branchId: params.branch_ids[0],
      productHistory: products,
    })
    commit('setTotalProductHistories', total_history)
    commit('setProductPagination', {
      branchId: params.branch_ids[0],
      productPagination: pagination,
    })
  },
  async getAdSourceHistories(
    { commit },
    params: {
      api: Repositories
    } & HistoryApiQueryParams
  ) {
    const { sources, pagination } =
      await params.api.crmAdSourceAnalyticsRepo.fetchAdSourceHistory({
        branch_ids: params.branch_ids,
        start_date: params.start_date,
        end_date: params.end_date,
        group_by: params.group_by,
        product_types: params.product_types,
        customer_types: params.customer_types,
        ad_sources: params.ad_sources,
        product_ids: params.product_ids,
        page: params.page,
        per: params.per,
      })
    commit('updateCurrentAdSourceHistories', {
      branchId: params.branch_ids[0],
      AdSourceHistory: sources,
    })
    commit('setAdSourcePagination', {
      branchId: params.branch_ids[0],
      adSourcePagination: pagination,
    })
  },
}
