































import Vue, { PropOptions } from 'vue'
import { Branch } from '~/types'
import { isMatchUrl } from '~/helpers/urlUtils'
export default Vue.extend({
  props: {
    locationName: {
      type: String,
      required: true,
    },
    branches: {
      type: Array,
      required: true,
    } as PropOptions<Branch[]>,
  },
  data() {
    return {
      isOpen: true,
    }
  },
  computed: {
    branchId(): number {
      return this.$store.state.auth.user.activeBranch
    },
    isHQBranch(): boolean {
      return isMatchUrl(this.$route.path, 'hq')
    },
  },
  methods: {
    branchClicked(id?: number) {
      this.$store.commit('auth/setActiveBranch', id)
      this.$store.commit('clinicSettings/setSelectedTopBarMode', 'branches')
      if (this.isHQBranch) this.$router.push(this.localePath('/reservations'))
      this.$emit('close')
    },
  },
})
