import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LoginData, AuthHeader, AuthRepo } from '~/types'
import { User } from '~/models/User'
import processError from '~/helpers/processError'
import logout from '~/helpers/logout'

export default (
  store: any,
  $axios: NuxtAxiosInstance,
  redirect: any,
  $sentry: any
): AuthRepo => ({
  async login(loginData: LoginData) {
    try {
      const res = await $axios.post('therapists/auth/sign_in', loginData)
      if (res.status === 200) {
        const authHeader: AuthHeader = res.headers
        const newUser = new User(
          res.data.data.id,
          authHeader['access-token'],
          authHeader['token-type'],
          authHeader.client,
          authHeader.expiry,
          authHeader.uid,
          res.data.data.name,
          res.data.data.active_branch
        )
        store.commit('auth/setUser', newUser)
        localStorage.setItem('user', JSON.stringify(newUser.toJson()))
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },

  async logout() {
    try {
      const res = await $axios.delete('therapists/auth/sign_out')
      if (res.status === 200) {
        logout(store, redirect)
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
