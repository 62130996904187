





























import Vue from 'vue'
export default Vue.extend({
  props: {
    depressed: {
      type: Boolean,
      required: false,
      default: true,
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconName: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: [String, Number],
      required: false,
      default: '36',
    },
    width: {
      type: [String, Number],
      required: false,
      default: '100',
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    plain: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    loaderText: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    click(e: any) {
      const amplitude = (this as any).$amplitude
      amplitude.trackEvent('Button Click', {
        buttonText: e.target.innerText,
      })
    },
  },
})
