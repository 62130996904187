import { MutationTree, ActionTree } from 'vuex'
import {
  Customer,
  CustomKarteResponse,
  KarteEntry,
  Repositories,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  UPDATED_FORM_BRANCHES: [18] as number[],
  customer: {} as Customer,
  karteEntry: {} as KarteEntry,
  responses: [] as CustomKarteResponse[],
  isAgreedConditions: false,
  karteFormAccessToken: undefined as undefined | string,
  karteBranchAccessToken: undefined as undefined | string,
})

export type ItemState = ReturnType<typeof state>

export const mutations: MutationTree<ItemState> = {
  setCustomer(state, customer: Customer) {
    state.customer = customer
  },
  setKarteEntry(state, karteEntry: KarteEntry) {
    state.karteEntry = karteEntry
  },
  setKarteFormAccessToken(state, karteFormAccessToken: string | undefined) {
    state.karteFormAccessToken = karteFormAccessToken
  },
  setKarteBranchAccessToken(state, karteBranchAccessToken: string | undefined) {
    state.karteBranchAccessToken = karteBranchAccessToken
  },
  setKarteFormBranches(state, karteFormBranchesIds: number[]) {
    state.UPDATED_FORM_BRANCHES = karteFormBranchesIds
  },
  setResponses(state, responses: CustomKarteResponse[]) {
    state.responses = responses
  },
  updateResponses(state, response: CustomKarteResponse) {
    const index = state.responses.findIndex(
      (x) => x.question_id === response.question_id
    )
    index === -1
      ? state.responses.push(response)
      : (state.responses[index] = response)
  },
  setIsAgreedConditions(state, isAgreedConditions: boolean) {
    state.isAgreedConditions = isAgreedConditions
  },
}

export const actions: ActionTree<ItemState, RootState> = {
  async fetchCustomer(
    { commit },
    params: {
      api: Repositories
      branchId: number
      customerId: number
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    const { customer } = await params.api.customerRepo.showCustomer(
      params.branchId,
      params.customerId,
      {
        karte_access_token: params.karte_access_token,
        branch_access_token: params.branch_access_token,
      }
    )
    commit('setCustomer', customer)
  },
  async createCustomer(
    { commit },
    params: {
      api: Repositories
      branchId: number
      customerData: Customer
      branch_access_token?: string
    }
  ) {
    const customer = await params.api.customerRepo.createCustomer(
      params.branchId,
      params.customerData,
      params.branch_access_token
    )
    commit('setCustomer', customer)
  },
  async updateCustomer(
    { commit },
    params: {
      api: Repositories
      branchId: number
      customerData: Customer
      karte_access_token: string
      branch_access_token?: string
    }
  ) {
    const customer = (await params.api.customerRepo.updateCustomer(
      params.branchId,
      params.customerData,
      params.karte_access_token,
      params.branch_access_token
    )) as Customer
    commit('setCustomer', customer)
  },
  async fetchKarteEntry(
    { commit },
    params: {
      api: Repositories
      branchId: number
      customerId: number
      entryId: number
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    const karteEntry = await params.api.karteEntryRepo.showEntry(
      params.branchId,
      params.customerId,
      params.entryId,
      {
        karte_access_token: params.karte_access_token,
        branch_access_token: params.branch_access_token,
      }
    )

    commit('setKarteEntry', karteEntry)
  },
  async createKarteEntry(
    { commit },
    params: {
      api: Repositories
      branchId: number
      customerId: number
      karteData: KarteEntry
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    const karteEntry = await params.api.karteEntryRepo.createEntry(
      params.branchId,
      params.customerId,
      params.karteData,
      {
        karte_access_token: params.karte_access_token,
        branch_access_token: params.branch_access_token,
      }
    )

    commit('setKarteEntry', karteEntry)
  },
  async updateKarteEntry(
    { commit },
    params: {
      api: Repositories
      branchId: number
      customerId: number
      karteData: KarteEntry
    }
  ) {
    const karteEntry = await params.api.karteEntryRepo.updateEntry(
      params.branchId,
      params.customerId,
      params.karteData
    )
    commit('setKarteEntry', karteEntry)
  },
  async fetchKarteFormResponses(
    { commit, rootState },
    params: {
      api: Repositories
      customerId: number
      entryId?: number
      sectionId?: number
      branchId?: number
      page?: number
      per?: number
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { responses } =
      await params.api.karteFormResponseRepo.fetchKarteResponses(
        branchId || params.branchId,
        params.customerId,
        {
          entry_id: params.entryId,
          section_id: params.sectionId,
          page: params.page,
          per: params.per,
          karte_access_token: params.karte_access_token,
          branch_access_token: params.branch_access_token,
        }
      )
    commit('setResponses', responses)
  },
  async createKarteFormResponse(
    { state, commit },
    params: {
      api: Repositories
      branchId: number
      questionId: number
      optionValues?: string[]
      answer?: string
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    const { answer } = params
    const { response } = await params.api.karteFormResponseRepo.createResponse(
      params.branchId,
      state.customer.id,
      {
        entry_id: state.karteEntry.id,
        question_id: params.questionId,
        option_values: params.optionValues,
        answer,
        karte_access_token: params.karte_access_token,
        branch_access_token: params.branch_access_token,
      }
    )
    commit('updateResponses', response)
  },
  async updateKarteFormResponse(
    { state, commit },
    params: {
      api: Repositories
      customerId: number
      branchId: number
      formId: number
      questionId: number
      entry_id: number
      optionValues?: string[]
      answer?: string
      remarks?: string
      responseId: number
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    const { answer } = params
    const { response } = await params.api.karteFormResponseRepo.updateResponse(
      params.branchId,
      state.customer.id ? state.customer.id : params.customerId,
      params.responseId,
      {
        entry_id: state.karteEntry.id ? state.karteEntry.id : params.entry_id,
        question_id: params.questionId,
        option_values: params.optionValues,
        answer,
        remarks: params.remarks,
        karte_access_token: params.karte_access_token,
        branch_access_token: params.branch_access_token,
      }
    )
    commit('updateResponses', response)
  },
  async deleteKarteFormResponse(
    { state },
    params: {
      api: Repositories
      branchId: number
      formId: number
      responseId: number
    }
  ) {
    await params.api.karteFormResponseRepo.deleteResponse(
      params.branchId,
      state.customer.id,
      params.responseId,
      params.formId
    )
  },
}
