import { MutationTree, ActionTree } from 'vuex'
import { Customer, Repositories, CustomerStatus } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  similarCustomers: [] as Customer[],
  customerStatus: 'confirmed' as CustomerStatus,
})

export type VerifyCustomerState = ReturnType<typeof state>

export const mutations: MutationTree<VerifyCustomerState> = {
  setSimilarCustomer(state, customers: Customer[]) {
    state.similarCustomers = customers
  },
  setCustomerStatus(state, payload: CustomerStatus) {
    state.customerStatus = payload
  },
}

export const actions: ActionTree<VerifyCustomerState, RootState> = {
  // Similar Customers
  async fetchSimilarCustomers(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      name?: string
      customer_code?: string
      birthday?: string
      telephone?: string
      email?: string
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { customers } = await params.api.customerRepo.fetchCustomers(
      branchId,
      {
        page: params.page,
        per: params.per,
        name: params.name,
        customer_code: params.customer_code,
        birthday: params.birthday,
        telephone: params.telephone,
        email: params.email,
      }
    )
    commit('setSimilarCustomer', customers)
  },
}
