import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { AdvertisingRepo } from '~/types'
import { Expenses } from '~/types/advertisingSource'

export default ($axios: NuxtAxiosInstance, $sentry: any): AdvertisingRepo => ({
  async fetchAdvertisingSource(params: {
    page: number
    per: number
    branch_ids?: number[]
    sources?: string[]
    start_date: string
    end_date: string
    search?: string
  }) {
    try {
      const res = await $axios.get('therapists/advertising/expenses', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateAdvertisingSource(branchId: number, expenses: {}) {
    try {
      const res = await $axios.patch(
        `therapists/branches/${branchId}/advertising/expenses/batch_update`,
        {
          expenses,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
