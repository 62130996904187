import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Holiday, PlannedHolidayRepo } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): PlannedHolidayRepo => ({
  async fetchHolidays(
    branchId: number,
    params?: {
      start_date: string
      end_date: string
      page?: number
      per?: number
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/planned_holidays`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createHoliday(branchId: number, holidayData: Holiday) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/planned_holidays`,
        {
          planned_holiday: holidayData,
        }
      )
      if (res.status === 201) {
        return res.data.planned_holiday
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateHoliday(branchId: number, planned_holiday: Holiday) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/planned_holidays/${planned_holiday.id}`,
        {
          planned_holiday,
        }
      )
      if (res.status === 200) {
        return res.data.planned_holiday
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteHoliday(branchId: number, holidayId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/planned_holidays/${holidayId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
