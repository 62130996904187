import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { MailRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): MailRepo => ({
  async fetchMails(
    branch_id: number,
    params: {
      page?: number
      per?: number
      sort?: string[]
    }
  ) {
    try {
      const res = await $axios.get(`/message/branches/${branch_id}/mails`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomersByMailId(
    mailId: number,
    params: {
      page?: number
      per?: number
    }
  ) {
    try {
      const res = await $axios.get(`/message/mails/${mailId}/customers`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchMailAnalytics(
    branch_id: number,
    params: {
      start_date: string
      end_date: string
    }
  ) {
    try {
      const res = await $axios.get(
        `/message/branches/${branch_id}/analytics/mails`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createCustomerMails(params: {
    branch_id: number
    customer_ids: number[]
    subject: string
    body: string
  }) {
    const formatParams = {
      customer_ids: params.customer_ids,
      subject: params.subject,
      body: params.body,
    }
    try {
      const res = await $axios.post(
        `message/branches/${params.branch_id}/mails`,
        {
          mail: formatParams,
        }
      )
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
