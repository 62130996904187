

































































import Vue from 'vue'
import { NotificationData, NotificationCategory, Pagination } from '~/types'

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showNotifications: false,
      loading: false,
      currentNotificationPage: 1,
      isFetchingNextPage: false,
      notificationList: [] as NotificationData[],
      pageNumber: 1,
      selectedNotificationType: 'unread',
      notificationTypes: [
        { text: this.$t('notifications.unread'), value: 'unread' },
        { text: this.$t('notifications.all'), value: 'all' },
      ],
    }
  },
  async fetch() {
    await this.getNotifications(this.currentNotificationPage)
    this.notificationList = this.notifications
  },
  computed: {
    allNotificationsFetched(): boolean {
      return !(
        this.$store.state.notifications.notificationPagination.total_pages >
        this.currentNotificationPage
      )
    },
    notifications(): NotificationData[] {
      return this.$store.state.notifications.notifications
    },
    notificationPagination(): Pagination {
      return this.$store.state.notifications.notificationPagination
    },
    isDisplaySizeSmall() {
      return this.$vuetify.breakpoint.sm
    },
  },
  watch: {
    value() {
      this.showNotifications = this.value
    },
    async showNotifications(val: boolean) {
      this.$emit('input', val)
      if (val) {
        return
      }
      this.currentNotificationPage = 1
      this.selectedNotificationType = 'unread'
      await this.getNotifications(this.currentNotificationPage)
      this.notificationList = this.notifications
    },
    async selectedNotificationType() {
      this.currentNotificationPage = 1
      await this.getNotifications(this.currentNotificationPage)
      this.notificationList = this.notifications
    },
  },
  methods: {
    async getNotifications(pageNumber: number) {
      this.loading = true
      try {
        await this.$store.dispatch('notifications/fetchNotifications', {
          api: this.$api,
          page: pageNumber,
          per: 10,
          acknowledged:
            this.selectedNotificationType === 'unread' ? false : undefined,
        })
      } catch (error) {
        this.$notifier.showMessage({
          content: (error as Error).message,
          color: 'error',
        })
      }
      this.loading = false
    },
    getCategoryString(categorySlug: NotificationCategory) {
      if (categorySlug === 'product_update') {
        return this.$t('notifications.productUpdate')
      } else {
        return this.$t('notifications.news')
      }
    },
    async loadMoreNotificationData() {
      if (!this.loading && !this.isFetchingNextPage) {
        this.isFetchingNextPage = true
        this.currentNotificationPage += 1
        await this.getNotifications(this.currentNotificationPage)
        this.notificationList.push(...this.notifications)
        setTimeout(() => {
          this.isFetchingNextPage = false
        }, 500)
      }
    },
  },
})
