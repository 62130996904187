import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { TransactionsRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): TransactionsRepo => ({
  async fetchTransactions(
    branchId: number,
    params: {
      start_date: string
      end_date: string
      page: number
      per: number
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/transactions`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
