import { MutationTree, ActionTree } from 'vuex'
import { ReservationTag, Repositories } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  reservationTags: [] as ReservationTag[],
})

export type ReservationTagState = ReturnType<typeof state>

export const mutations: MutationTree<ReservationTagState> = {
  setReservationTags(state, reservationTags: ReservationTag[]) {
    state.reservationTags = reservationTags
  },
  addNewReservationTag(state, reservationTagData: ReservationTag) {
    state.reservationTags.unshift(reservationTagData)
  },
  updateReservationTag(state, reservationTagData: ReservationTag) {
    const index = state.reservationTags.findIndex(
      (item) => item.tag_id === reservationTagData.tag_id
    )
    if (index !== -1) state.reservationTags.splice(index, 1, reservationTagData)
  },
  deleteReservationTag(state, id: number) {
    const index = state.reservationTags.findIndex((item) => item.tag_id === id)
    if (index !== -1) state.reservationTags.splice(index, 1)
  },
}

export const actions: ActionTree<ReservationTagState, RootState> = {
  async fetchReservationTags(
    { commit, rootState },
    params: {
      api: Repositories
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { reservation_tags } =
      await params.api.reservationTagRepo.fetchReservationTags(branchId)
    commit('setReservationTags', reservation_tags)
  },
  async createReservationTag(
    { commit, rootState },
    params: { api: Repositories; reservationTagData: ReservationTag }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const reservationTag =
      await params.api.reservationTagRepo.createReservationTag(
        branchId,
        params.reservationTagData
      )
    commit('addNewReservationTag', reservationTag)
  },
  async updateReservationTag(
    { commit, rootState },
    params: { api: Repositories; reservationTagData: ReservationTag }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const reservationTagSet =
      (await params.api.reservationTagRepo.updateReservationTag(
        branchId,
        params.reservationTagData
      )) as ReservationTag

    commit('updateReservationTag', reservationTagSet)
  },
  async deleteReservationTag(
    { commit, rootState },
    params: { api: Repositories; reservationTagId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    await params.api.reservationTagRepo.deleteReservationTag(
      branchId,
      params.reservationTagId
    )
    commit('deleteReservationTag', params.reservationTagId)
  },
}
