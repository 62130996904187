import { RESERVATION_STATUSES } from './constants/reservationStatuses'
import { CustomerFilter } from '~/types'

export function getReservationStatusStyle(reservationStatus: string) {
  const style = 'border-radius: 5px;color: white;padding:5px;'
  switch (reservationStatus) {
    case 'pending':
      return style + 'background: #F26EA6'
    case 'confirmed':
      return style + 'background: #DB4C00'
    case 'reception':
      return style + 'background: #ea7cb3'
    case 'in_progress':
      return style + 'background: #f2994a'
    case 'completed':
      return style + 'background: #36BF36'
    case 'cancelled':
      return style + 'background: #A0A0A0'
    default:
      return ''
  }
}

export function getReservationStatusColor(reservationStatus: string) {
  switch (reservationStatus) {
    case 'pending':
      return '#F26EA6'
    case 'confirmed':
      return '#DB4C00'
    case 'reception':
      return '#ea7cb3'
    case 'in_progress':
      return '#f2994a'
    case 'completed':
      return '#36BF36'
    case 'cancelled':
      return '#A0A0A0'
    default:
      return ''
  }
}

export function getReservationStatus(reservationStatus: string) {
  const status = RESERVATION_STATUSES.find(
    (status) => status.value === reservationStatus
  )
  return status ? status.text : ''
}

export function transformCustomerFilters(customerFilters: CustomerFilter[]) {
  const params = [] as any
  customerFilters.forEach((filter) => {
    const obj = {}
    obj[`${filter.rowKey}`] = getFilterValue(filter)
    params.push(obj)
  })
  return params
}
export function transformCustomerDownloadFilters(
  customerFilters: CustomerFilter[]
) {
  const params = {} as any
  customerFilters.forEach((filter) => {
    params[`${filter.rowKey}`] = getFilterValue(filter)
  })
  return params
}
function getFilterValue(filter: CustomerFilter) {
  if (
    filter.rowKey === 'last_visit' ||
    filter.rowKey === 'birthday' ||
    filter.rowKey === 'visit_date'
  ) {
    switch (filter.operator) {
      case 'range':
        return { before: filter.value.secondary, after: filter.value.primary }
      case '<=':
        return { before: filter.value.primary }
      case '>=':
        return { after: filter.value.primary }
    }
  }

  switch (filter.operator) {
    case '=':
      return filter.rowKey === 'age' ||
        filter.rowKey === 'remaining_ticket_count'
        ? { gte: filter.value.primary, lte: filter.value.primary }
        : filter.value.primary
    case '<=':
      return { lte: filter.value.primary }
    case '>=':
      return { gte: filter.value.primary }
    case 'range':
      return { gte: filter.value.primary, lte: filter.value.secondary }
    default:
      return filter.value.primary
  }
}
