import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Goal, GoalRepo, StaffGoalTarget } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): GoalRepo => ({
  async fetchBranchGoals(
    branchId: number,
    params?: { page?: number; per?: number }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/sales_targets`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchAllBranchesGoals(params?: { page?: number; per?: number }) {
    try {
      const res = await $axios.get(`therapists/branches/sales_targets`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchStaffGoals(
    branchId: number,
    params?: {
      page?: number
      per?: number
      start_date?: string
      end_date?: string
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/staff/sales_targets`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateStaffGoals(
    branchId: number,
    params?: {
      staffGoalsData: StaffGoalTarget[]
      start_date: string
      end_date: string
    }
  ) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/staff/sales_targets/batch`,
        {
          batch: {
            targets: params?.staffGoalsData,
            start_date: params?.start_date,
            end_date: params?.end_date,
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createBranchGoal(branchId: number, goalData: Goal) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/sales_targets`,
        {
          sales_target: goalData,
        }
      )
      if (res.status === 201) {
        return res.data.sales_target
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateBranchGoal(branchId: number, goalData: Goal) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/sales_targets/${goalData.id}`,
        {
          sales_target: goalData,
        }
      )
      if (res.status === 200) {
        return res.data.sales_target
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteBranchGoal(branchId: number, goalId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/sales_targets/${goalId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async deleteStaffGoals(branchId: number, staffGoalIds: number[]) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/staff/sales_targets/batch_delete`,
        {
          data: {
            target_ids: staffGoalIds,
          },
        }
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
