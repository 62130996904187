


























































































































import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    object: {} as any,
    callback: () => ({}),
    cancelRes: () => ({}),
    cancelCallback: () => ({}),
    showDialog: false,
  }),
  computed: {
    checkBoxVal: {
      get(): Boolean {
        return this.$store.state.confirmWindow.checkBoxVal
      },
      set(val: Boolean) {
        this.$store.commit('confirmWindow/setCheckboxVal', val)
      },
    },
    dropDownVal: {
      get(): string[] {
        return this.$store.state.confirmWindow.dropDownValue
      },
      set(val: string[]) {
        return this.$store.commit('confirmWindow/setDropDownVal', val)
      },
    },
  },
  watch: {
    showDialog() {
      this.$store.commit('confirmWindow/setCheckboxVal', false)
      this.$store.commit('confirmWindow/setDropDownVal', [])
    },
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'confirmWindow/show') {
        this.object = {
          title: state.confirmWindow.title,
          subTitle: state.confirmWindow.subTitle,
          cautionText: state.confirmWindow.cautionText,
          actionButtonColor: state.confirmWindow.actionButtonColor
            ? state.confirmWindow.actionButtonColor
            : 'error',
          actionButtonText: state.confirmWindow.actionButtonText
            ? state.confirmWindow.actionButtonText
            : this.$t('common.delete'),
          checkBoxText: state.confirmWindow.checkBoxText,
          closeButtonText: state.confirmWindow.closeButtonText
            ? state.confirmWindow.closeButtonText
            : this.$t('common.cancel'),
          showDropDown: state.confirmWindow.showDropDown,
          dropDownText: state.confirmWindow.dropDownText,
          showCloseButton: state.confirmWindow.showCloseButton,
          deleteIconRequired: state.confirmWindow.deleteIconRequired,
          iconLink: state.confirmWindow.iconLink
            ? state.confirmWindow.iconLink
            : require('@/assets/deleteIcon.svg'),
          showCancelRes: state.confirmWindow.showCancelRes === true,
          cancelResText: state.confirmWindow.cancelResText,
          dropDownList: state.confirmWindow.dropDownList,
          multiple: state.confirmWindow.multiple,
          placeholderText: state.confirmWindow.placeholderText,
          htmlContent: state.confirmWindow.htmlContent,
        }
        this.showDialog = true
        this.callback = state.confirmWindow.callback
        this.cancelRes = state.confirmWindow.cancelRes
        const defaultCancelAction = () => ({})
        this.cancelCallback =
          state.confirmWindow.cancelCallback || defaultCancelAction
      }
    })
  },
  methods: {
    confirmClicked() {
      this.callback()
      this.showDialog = false
    },
    cancelResClicked() {
      this.cancelRes()
      this.showDialog = false
    },
    cancelClicked() {
      this.cancelCallback()
      this.showDialog = false
    },
  },
})
