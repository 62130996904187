import { MutationTree, ActionTree } from 'vuex'
import dayjs from 'dayjs'
import { Repositories, CustomerInsurance } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  customerInsuranceCards: [] as CustomerInsurance[],
})

export type CustomerInsuranceState = ReturnType<typeof state>

export const mutations: MutationTree<CustomerInsuranceState> = {
  setInsuranceCards(state, cards: CustomerInsurance[]) {
    state.customerInsuranceCards = cards
  },
  updateInsuranceCard(state, card: CustomerInsurance) {
    const index = state.customerInsuranceCards.findIndex(
      (item) => item.id === card.id
    )
    if (index !== -1) state.customerInsuranceCards.splice(index, 1, card)
  },
  addInsuranceCard(state, card: CustomerInsurance) {
    state.customerInsuranceCards.push(card)
    state.customerInsuranceCards.sort(function (a, b) {
      const date1 = dayjs(a.received_on)
      const date2 = dayjs(b.received_on)

      if (date1.isSameOrAfter(date2)) return -1
      if (date1.isBefore(date2)) return 1
      return 0
    })
  },
  deleteCustomerInsuranceCard(
    state,
    params: { cardId: number; customerId: number }
  ) {
    const index = state.customerInsuranceCards.findIndex(
      (item) => item.id === params.cardId
    )
    state.customerInsuranceCards.splice(index, 1)
  },
}

export const actions: ActionTree<CustomerInsuranceState, RootState> = {
  async fetchCustomerInsuranceCards(
    { commit },
    params: {
      api: Repositories
      customerId: number
    }
  ) {
    const insuranceCards =
      await params.api.customerInsuranceRepo.fetchCustomerInsuranceCards(
        params.customerId
      )
    commit('setInsuranceCards', insuranceCards)
  },
  async updateCustomerInsuranceCards(
    { commit },
    params: {
      api: Repositories
      customerId: number
      cardId: number
      insuranceCard: CustomerInsurance
    }
  ) {
    const insuranceCard =
      await params.api.customerInsuranceRepo.updateCustomerSettings(
        params.customerId,
        params.cardId,
        params.insuranceCard
      )
    commit('updateInsuranceCard', insuranceCard)
  },
  async createCustomerInsuranceCards(
    { commit },
    params: {
      api: Repositories
      customerId: number
      insuranceCard: CustomerInsurance
    }
  ) {
    const insuranceCard =
      await params.api.customerInsuranceRepo.createCustomerInsuranceCard(
        params.customerId,
        params.insuranceCard
      )
    commit('addInsuranceCard', insuranceCard)
  },
  async deleteCustomerInsuranceCard(
    { commit },
    params: {
      api: Repositories
      customerId: number
      cardId: number
    }
  ) {
    await params.api.customerInsuranceRepo.deleteCustomerInsuranceCard(
      params.customerId,
      params.cardId
    )
    commit('deleteCustomerInsuranceCard', {
      customerId: params.customerId,
      cardId: params.cardId,
    })
  },
}
