import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import { MailAnalytics, Repositories } from '~/types'

export const state = () => ({
  mailAnalytics: {} as MailAnalytics,
  loadingList: {
    isMailAnalyticsLoading: false,
  },
})

export type MailAnalyticState = ReturnType<typeof state>

export const mutations: MutationTree<MailAnalyticState> = {
  setMailAnalytics(state, mailAnalytics: MailAnalytics) {
    state.mailAnalytics = mailAnalytics
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<MailAnalyticState, RootState> = {
  async getMailAnalytics(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
    }
  ) {
    commit('setLoading', { key: 'isMailAnalyticsLoading', loading: true })
    const branchId = Number((rootState as any).auth.user.activeBranch)
    const { analytics } = await params.api.mailRepo.fetchMailAnalytics(
      branchId,
      {
        start_date: params.start_date,
        end_date: params.end_date,
      }
    )
    commit('setMailAnalytics', analytics)
    commit('setLoading', { key: 'isMailAnalyticsLoading', loading: false })
  },
}
