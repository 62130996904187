import { MutationTree, ActionTree } from 'vuex'
import { Repositories, Pagination, KarteReport, Staff } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  karteReports: [] as KarteReport[],
  karteReportsAllStaff: [] as Staff[],
  karteReportPagination: {} as Pagination,
  loadingList: {
    isKarteReportsLoading: false,
  },
  isExpandReport: false as boolean,
  showReportForm: false as boolean,
})

export type KarteReportState = ReturnType<typeof state>

export const mutations: MutationTree<KarteReportState> = {
  setKarteReports(state, reports: KarteReport[]) {
    state.karteReports = reports
  },
  setKarteReportPagination(state, pagination: Pagination) {
    state.karteReportPagination = pagination
  },
  setKarteReportsAllStaff(state, allStaff: Staff[]) {
    state.karteReportsAllStaff = allStaff
  },
  addNewKarteReport(state, report: KarteReport) {
    state.karteReports.unshift(report)
  },
  updateKarteReport(state, reportData: KarteReport) {
    const index = state.karteReports.findIndex(
      (report) => report.id === reportData.id
    )
    if (index !== -1) state.karteReports.splice(index, 1, reportData)
  },
  updatePagination(state) {
    state.karteReportPagination.total_entries += 1
    state.karteReportPagination.total_pages = Math.ceil(
      state.karteReportPagination.total_entries / 10
    )
  },
  deleteKarteReport(state, reportId: number) {
    state.karteReports = state.karteReports.filter((report) => {
      return report.id !== reportId
    })
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  setExpandReport(state, isExpandReport: boolean) {
    state.isExpandReport = isExpandReport
  },
  setShowReportForm(state, showReportForm: boolean) {
    state.showReportForm = showReportForm
  },
}

export const actions: ActionTree<KarteReportState, RootState> = {
  async fetchKarteReports(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      customerId: number
      startDate?: string
      endDate?: string
      reportText?: string
      assessmentId?: number
    }
  ) {
    commit('setLoading', { key: 'isKarteReportsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { karte_reports, pagination } =
      await params.api.karteReportRepo.fetchKarteReports(branchId, {
        customer_id: params.customerId,
        page: params.page,
        per: params.per,
        sort: ['date.desc'],
        start_date: params.startDate,
        end_date: params.endDate,
        report_text: params.reportText,
        assessment_id: params.assessmentId,
      })
    commit('setKarteReports', karte_reports)
    commit('setKarteReportPagination', pagination)
    commit('setLoading', { key: 'isKarteReportsLoading', loading: false })
  },
  async fetchKarteStaffs(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const { staff } = await params.api.staffRepo.fetchBranchStaff(branchId, {
      page: params.page,
      per: params.per,
      field_therapist: true,
    })
    commit('setKarteReportsAllStaff', staff)
  },
  async createKarteReport(
    { commit, rootState },
    params: { api: Repositories; reportData: KarteReport }
  ) {
    commit('setLoading', { key: 'isKarteReportsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const report = await params.api.karteReportRepo.createKarteReport(
      branchId,
      params.reportData
    )
    commit('addNewKarteReport', report)
    commit('updatePagination')
    commit('setLoading', { key: 'isKarteReportsLoading', loading: false })
  },

  async updateKarteReport(
    { commit, rootState },
    params: { api: Repositories; reportData: KarteReport }
  ) {
    commit('setLoading', { key: 'isKarteReportsLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const report = await params.api.karteReportRepo.updateKarteReport(
      branchId,
      params.reportData
    )
    commit('updateKarteReport', report)
    commit('setLoading', { key: 'isKarteReportsLoading', loading: false })
  },
  async deleteKarteReport(
    { commit, rootState },
    params: { api: Repositories; reportId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api.karteReportRepo.deleteKarteReport(
      branchId,
      params.reportId
    )
    commit('deleteKarteReport', params.reportId)
  },
}
