





























import Vue from 'vue'
import { User } from '~/models/User'
import { Reservation, SquareDevice, Transaction } from '~/types'
import { isMatchUrl } from '~/helpers/urlUtils'
import { getLanguageSetting } from '~/helpers/language'

export default Vue.extend({
  name: 'Default',
  middleware: ['authenticated', 'accessCheck'],
  data() {
    return {
      fixed: false,
      right: true,
      rightDrawer: true,
      title: 'Threease pro',
      isBranchSettingsSideBarOpen: true,
      debounce: null as NodeJS.Timeout | null,
    }
  },

  computed: {
    route() {
      return this.$route.fullPath
    },
    isClinicSetting(): Boolean {
      return isMatchUrl(this.$route.path, 'clinic_setting')
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
    devices(): SquareDevice[] {
      return this.$store.state.square.devices
    },
    isHQBranch(): boolean {
      return isMatchUrl(this.$route.path, 'hq')
    },
    width(): string {
      if (this.isBranchSettingsSideBarOpen && this.isClinicSetting) return '250'
      return '55'
    },
    height(): string {
      if (this.isBranchSettingsSideBarOpen && this.isClinicSetting)
        return '95vh'
      return '100vh'
    },
    miniVariant(): boolean {
      return !this.isBranchSettingsSideBarOpen
    },
  },
  channels: {
    NotificationChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (this.checkReceivedData(data, 'reservation')) {
          this.handleReservation(
            (data as { reservation: Reservation }).reservation
          )
        }
        if (this.checkReceivedData(data, 'delete_reservation')) {
          this.handleDeleteReservation(
            (data as { delete_reservation: { id: number } }).delete_reservation
          )
        }
        if (this.checkReceivedData(data, 'device_code')) {
          this.handleDevicePairing(
            (data as { device_code: SquareDevice }).device_code
          )
        }
        if (this.checkReceivedData(data, 'transaction')) {
          this.handleCheckoutUpdate(
            (data as { transaction: Transaction }).transaction
          )
        }
      },
      disconnected() {},
    },
  },
  watch: {
    route() {
      localStorage.setItem('lastVisitedPage', this.$route.fullPath)
    },
    isBranchSettingsSideBarOpen(val: boolean) {
      this.$store.commit('clinicSettings/setBranchSettingsSideBar', val)
    },
  },
  mounted() {
    this.subscribeToNotifications()
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (this.debounce) clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        const amplitude = (this as any).$amplitude
        amplitude.trackEvent('Scroll', {
          scrollY: window.scrollY,
        })
      }, 2000)
    },
    checkReceivedData(receivedData: any, key: string): boolean {
      return Object.keys(receivedData).includes(key)
    },
    handleReservation(reservation: Reservation) {
      if (reservation) {
        const reservations: Reservation[] =
          this.$store.state.reservation.reservations
        const localReservation = reservations.find(
          (res) => res.id === reservation.id
        )
        const lang = getLanguageSetting()
        if (!localReservation) {
          this.$store.commit('reservation/addNewReservation', reservation)
          const formattedTime = this.$dayjs(reservation.start_time).format(
            lang === 'ja' ? 'MM月DD日HH:mm' : 'MM/DD/HH:mm'
          )
          this.$notifier.showMessage({
            content: `${this.$t(
              'notificationMessages.reservationNotification',
              {
                customerName: reservation.customer_name ?? '',
                time: formattedTime,
              }
            )}`,
            color: 'success',
            timeout: 5000,
          })
        } else {
          this.$store.commit('reservation/updateReservation', reservation)
          if (localReservation.status === 'pending') {
            if (reservation.source === 'web') this.playNotificationSound()
            const formattedTime = this.$dayjs(reservation.start_time).format(
              lang === 'ja' ? 'MM月DD日HH:mm' : 'MM/DD/HH:mm'
            )

            this.$notifier.showMessage({
              content: `${this.$t(
                'notificationMessages.reservationNotification',
                {
                  customerName: reservation.customer_name ?? '',
                  time: formattedTime,
                }
              )}`,
              color: 'success',
              timeout: -1,
              data: { reservation },
            })
          }
        }
      }
    },
    handleDeleteReservation(data: { id: number }) {
      if (data && data.id) {
        this.$store.commit('reservation/deleteReservation', data.id)
      }
    },
    handleDevicePairing(devices: SquareDevice) {
      this.$store.commit('square/setPairingStatus', '')
      if (!devices.device_id) {
        this.$store.commit('square/setPairingStatus', 'error')
        this.$notifier.showMessage({
          content: this.$t('squareError.deviceNotPaired'),
          color: 'error',
          timeout: 5000,
        })
        return
      }
      const localDevices = this.devices

      const devicesIndex = localDevices.findIndex(
        (device) => devices.device_id === device.device_id
      )
      if (devicesIndex === -1) {
        this.$store.commit('square/setPairingStatus', 'error')
        return
      }
      this.$store.commit('square/updateDeviceContent', {
        value: devices.status,
        index: devicesIndex,
        key: 'status',
      })
      this.$store.commit('square/setPairingStatus', 'success')
      this.$notifier.showMessage({
        content: this.$t('squareError.devicePaired'),
        color: 'success',
        timeout: 5000,
      })
    },
    handleCheckoutUpdate(transaction: Transaction) {
      if (!transaction) return
      const { details } = transaction
      if (details && details.length > 0)
        this.showTransactionErrorMessage(
          details[0].category ? details[0].category : details[0].code
        )
      else {
        this.$store.commit('invoice/updateTransaction', transaction)
        this.$notifier.showMessage({
          content: this.$t('squareError.transactionUpdate'),
          color: 'success',
          timeout: 5000,
        })
      }
    },
    branchChanged() {
      this.$cable.unsubscribe('NotificationChannel')
      this.subscribeToNotifications()
    },

    subscribeToNotifications() {
      if (this.$config.includeMockServer) {
        return
      }
      const { accessToken, uid, client, activeBranch } = this.$store.state.auth
        .user as User

      ;(this.$cable as any).connection.connect(
        `${this.$config.websocketUrl}?token=${encodeURIComponent(
          accessToken
        )}&uid=${encodeURIComponent(uid)}&client=${encodeURIComponent(client)}`
      )
      this.$cable.subscribe({
        channel: 'NotificationChannel',
        branch_id: activeBranch,
      })
    },
    playNotificationSound() {
      const notificationSound = new Audio('/notification.mp3')
      notificationSound.play()
    },
    showTransactionErrorMessage(code: string) {
      switch (code) {
        case 'UNAUTHORIZED':
          this.showErrorMessage(this.$t('squareError.unauthorized'))
          break
        case 'ACCESS_TOKEN_EXPIRED':
          this.showErrorMessage(this.$t('squareError.accessTokenExpired'))
          break
        case 'ACCESS_TOKEN_REVOKED':
          this.showErrorMessage(this.$t('squareError.accessTokenRevoked'))
          break
        case 'INSUFFICIENT_SCOPES':
          this.showErrorMessage(this.$t('squareError.insufficientScopes'))
          break
        case 'FORBIDDEN':
          this.showErrorMessage(this.$t('squareError.forbidden'))
          break
        case 'CURRENCY_MISMATCH':
          this.showErrorMessage(this.$t('squareError.currencyMismatch'))
          break
        case 'PAYMENT_METHOD_ERROR':
          this.showErrorMessage(this.$t('squareError.paymentMethodError'))
          break
        case 'TIMED_OUT':
          this.showErrorMessage(this.$t('squareError.timedOut'))
          break
        case 'BUYER_CANCELED':
          this.showErrorMessage(this.$t('squareError.buyerCancelled'))
          break
        case 'SELLER_CANCELED':
          this.showErrorMessage(this.$t('squareError.sellerCancelled'))
          break
        default:
          this.showErrorMessage(this.$t('squareError.tryAgain'))
          break
      }
    },
    showErrorMessage(message: any) {
      this.$notifier.showMessage({
        content: message,
        color: 'error',
      })
    },
  },
})
