









































































import Vue from 'vue'
import { User } from '~/models/User'
import { validateForm } from '~/helpers/formUtils'
import { Feedback } from '~/types'

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      feedbackTypes: [
        { text: this.$t('feedback.bug'), value: 'bug' },
        { text: this.$t('feedback.help'), value: 'help' },
        { text: this.$t('feedback.request'), value: 'request' },
        { text: this.$t('feedback.other'), value: 'other' },
      ],
      feedback: {} as Feedback,
      loading: false,
    }
  },
  computed: {
    user(): User {
      return this.$store.state.auth.user
    },
  },
  methods: {
    validateForm(submitCallBack: any) {
      validateForm(this, submitCallBack)
    },
    async sendFeedback() {
      this.loading = true
      this.feedback.branch_id = this.user.activeBranch
      this.feedback.page_url = window.location.pathname
      this.feedback.device = navigator.userAgent

      try {
        await this.$api.feedbackRepo.createFeedback(this.feedback)
        this.$emit('input')
        this.$notifier.showMessage({
          content: this.$t('feedback.sentSuccessfully'),
          color: 'success',
        })
      } catch (error) {
        this.$notifier.showMessage({
          content: (error as Error).message,
          color: 'error',
        })
      }
      this.loading = false
    },
  },
})
