import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { ProductCategory, ProductCategoryRepo } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): ProductCategoryRepo => ({
  async fetchProductCategories(params?: { page?: number; per?: number }) {
    try {
      const res = await $axios.get('therapists/product_categories', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createProductCategory(productCategoryData: ProductCategory) {
    try {
      const res = await $axios.post('therapists/product_categories', {
        product_category: productCategoryData,
      })
      if (res.status === 201) {
        return res.data.product_category
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateProductCategory(productCategory: ProductCategory) {
    try {
      const res = await $axios.put(
        `therapists/product_categories/${productCategory.id}`,
        {
          product_category: productCategory,
        }
      )
      if (res.status === 200) {
        return res.data.product_category
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async setAllProductCategoriesOrder(
    branchId: number,
    category: { id: number; position: number }
  ) {
    try {
      const res = await $axios.put(
        `therapists/product_categories/${category.id}/shuffle`,
        {
          product_category: category,
        }
      )
      if (res.status === 200) {
        return res.data.category
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteProductCategory(productCategoryId: number) {
    try {
      const res = await $axios.delete(
        `therapists/product_categories/${productCategoryId}`
      )
      if (res.status === 200) {
        return res.data.product_category
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
