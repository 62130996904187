import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Staff, StaffRepo, Credential, Preset } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): StaffRepo => ({
  async fetchStaff(params?: {
    page?: number
    per?: number
    branch_ids?: number[]
    start_date?: string
    end_date?: string
    search?: string
    account_status?: string[]
    employment_status?: string[]
    preset_ids?: number[]
  }) {
    try {
      const res = await $axios.get('therapists/staff', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateStaff(staff: Staff) {
    try {
      // Authority Issues: branch_setting and hq_dashboard are always required for staff's permission_slug
      const permissionSlugs = [...staff?.permission_slugs]
      if (!permissionSlugs.includes('hq_dashboard'))
        permissionSlugs.push('hq_dashboard')
      if (!permissionSlugs.includes('branch_setting'))
        permissionSlugs.push('branch_setting')
      const res = await $axios.put(`therapists/staff/${staff.id}`, {
        staff: { ...staff, permission_slugs: permissionSlugs },
      })
      if (res.status === 200) {
        return res.data.staff
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async fetchStaffById(params: {
    staffId: number
    karte_access_token?: string
    branch_access_token?: string
  }) {
    try {
      const res = await $axios.get(`therapists/staff/${params.staffId}`, {
        params: {
          karte_access_token: params.karte_access_token,
          branch_access_token: params.branch_access_token,
        },
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchBranchStaff(
    branchId: number,
    params?: {
      page?: number
      per?: number
      field_therapist?: boolean
      is_available_online?: boolean
      start_time?: string
      end_time?: string
    }
  ) {
    try {
      const res = await $axios.get(`therapists/branches/${branchId}/staff`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchProductStaff(
    productId: number,
    params?: { page?: number; per?: number }
  ) {
    try {
      const res = await $axios.get(`therapists/products/${productId}/staff`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createStaff(staffData: Staff) {
    try {
      // Authority Issues: branch_setting and hq_dashboard are always required for staff's permission_slug
      const permissionSlugs = [...staffData.permission_slugs]
      if (!permissionSlugs.includes('hq_dashboard'))
        permissionSlugs.push('hq_dashboard')
      if (!permissionSlugs.includes('branch_setting'))
        permissionSlugs.push('branch_setting')
      const res = await $axios.post('therapists/staff', {
        staff: { ...staffData, permission_slugs: permissionSlugs },
      })
      if (res.status === 201) {
        return res.data.staff
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async resetPassword(staffId: number, credentialData: Credential) {
    const { password, send_credentials } = credentialData
    try {
      const res = await $axios.put(`therapists/staff/${staffId}/password`, {
        password,
        send_credentials,
      })
      if (res.status === 204) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async fetchPresets(params?: { page?: number; per?: number }) {
    try {
      const res = await $axios.get(`therapists/permissions/presets`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createPreset(presetData: Preset) {
    try {
      // Authority Issues: branch_setting and hq_dashboard are always required for staff's permission_slug
      const permissionSlugs = [...presetData.permission_slugs]
      if (!permissionSlugs.includes('hq_dashboard'))
        permissionSlugs.push('hq_dashboard')
      if (!permissionSlugs.includes('branch_setting'))
        permissionSlugs.push('branch_setting')
      const res = await $axios.post('therapists/permissions/presets', {
        preset: { ...presetData, permission_slugs: permissionSlugs },
      })
      if (res.status === 201) {
        return res.data.preset
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updatePreset(presetData: Preset) {
    const { name, permission_slugs, id } = presetData
    try {
      // Authority Issues: branch_setting and hq_dashboard are always required for staff's permission_slug
      const permissionSlugs = [...permission_slugs]
      if (!permissionSlugs.includes('hq_dashboard'))
        permissionSlugs.push('hq_dashboard')
      if (!permissionSlugs.includes('branch_setting'))
        permissionSlugs.push('branch_setting')
      const res = await $axios.put(`therapists/permissions/presets/${id}`, {
        name,
        permission_slugs: permissionSlugs,
      })
      if (res.status === 200) {
        return res.data.preset
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deletePreset(presetId: number) {
    try {
      await $axios.delete(`therapists/permissions/presets/${presetId}`)
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
