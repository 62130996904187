














import Vue from 'vue'

export default Vue.extend({
  props: {
    width: {
      type: String,
      required: false,
      default: '70',
    },
    height: {
      type: String,
      required: false,
      default: '70',
    },
  },
})
