import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Assignment, ShiftAssignmentRepo } from '~/types'
import { processBatchErrors } from '~/helpers/processBatchErrors'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): ShiftAssignmentRepo => ({
  async fetchAssignments(
    branchId: number,
    params?: {
      page?: number
      per?: number
      start_date?: string
      end_date?: string
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/shift_assignments`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createAssignment(branchId: number, assignmentData: Assignment) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/shift_assignments`,
        {
          shift_assignment: assignmentData,
        }
      )
      if (res.status === 201) {
        return res.data.shift_assignment
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async batchAssign(branchId: number, assignmentData: Assignment[]) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/shift_assignments/batch`,
        {
          shift_assignments: assignmentData,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      processBatchErrors.processBatchErrorObject(err, $sentry)
    }
  },
  async updateAssignment(branchId: number, assignment: Assignment) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/shift_assignments/${assignment.id}`,
        {
          shift_assignment: assignment,
        }
      )
      if (res.status === 200) {
        return res.data.shift_assignment
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteAssignment(branchId: number, assignmentId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/shift_assignments/${assignmentId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
