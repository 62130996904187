import { MutationTree, ActionTree } from 'vuex'
import { CustomKarteSection, Repositories } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  karteSections: [] as CustomKarteSection[],
})

export type KarteSectionsState = ReturnType<typeof state>

export const mutations: MutationTree<KarteSectionsState> = {
  setKarteSections(state, karteSections: CustomKarteSection[]) {
    state.karteSections = karteSections
  },
}

export const actions: ActionTree<KarteSectionsState, RootState> = {
  async fetchKarteSections(
    { commit },
    params: {
      api: Repositories
      branchId: number
      formId: number
      karte_access_token: string
      branch_access_token?: string
    }
  ) {
    const { sections } = await params.api.karteSectionsRepo.fetchKarteSections(
      params.branchId,
      params.formId,
      {
        karte_access_token: params.karte_access_token,
        branch_access_token: params.branch_access_token,
      }
    )
    commit('setKarteSections', sections)
  },
}
