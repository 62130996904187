// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/NotoSans-Regular.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Noto Sans\";font-weight:400;font-style:normal;src:local(\"Noto Sans\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}body{font-family:\"Noto Sans\"!important;color:#333;font-size:14px}body h2{font-size:1.4rem!important}body h2,body h3{color:#333;cursor:inherit}body h3,body h4{font-size:1.1rem!important}body h4{color:#333;cursor:inherit}body .caption{font-size:.8rem!important;color:#333}body .bold{font-weight:700}body .ga-3{gap:12px!important}body .v-radio label{font-size:14px}body .theme--light.v-select .v-select__selections,body .theme--light.v-select label{font-size:.8rem!important;color:#333}body .main-text-color{color:#333!important}body .main-font-size{font-size:14px!important}body table th{font-weight:700!important}body table td,body table th{font-size:.8rem!important}body table .first-col-sticky{position:sticky!important;left:0!important;background:inherit}body table .last-col-sticky{position:sticky!important;right:0!important;background:inherit}body table .z-index-2{z-index:2!important}body table .z-index-3{z-index:3!important}.v-application{color:#333}.v-application .body-1,.v-application .body-2,.v-application .caption,.v-application .headline,.v-application .item-title,.v-application .overline,.v-application .subtitle-1,.v-application .subtitle-2,.v-application .title,.v-application [class*=display-],.v-application [class*=text-]{font-family:\"Noto Sans\"!important}.v-dialog__content .v-dialog{max-height:100%!important}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
