import { MutationTree, ActionTree } from 'vuex'

import { RootState } from '~/store'
import { CustomKarteSection, Repositories } from '~/types'

export const state = () => ({
  activeSectionId: -1,
  sections: [] as CustomKarteSection[],
  deleteSection: false,
})

export type CustomKarte = ReturnType<typeof state>

export const mutations: MutationTree<CustomKarte> = {
  setSections(state, sections: CustomKarteSection[]) {
    state.sections = sections
  },
  setDeleteSection(state, deleteSection: boolean) {
    state.deleteSection = deleteSection
  },
  setActiveSectionId(state, id: number) {
    state.activeSectionId = id
  },
  addSection(state, section: CustomKarteSection) {
    state.sections.push(section)
  },
  updateSection(state, section: CustomKarteSection) {
    state.sections = state.sections.map((item) => {
      return item.id === section.id ? section : item
    })
  },
  deleteSection(state, sectionId?: number) {
    state.sections = state.sections.filter((item) => item.id !== sectionId)
  },
}

export const actions: ActionTree<CustomKarte, RootState> = {
  async fetchKarteSections(
    { commit },
    params: {
      api: Repositories
      karte_access_token?: string
      branch_access_token?: string
      branchId: number
      formId: number
    }
  ) {
    const { sections } = await params.api.karteSectionsRepo.fetchKarteSections(
      params.branchId,
      params.formId,
      {
        karte_access_token: params.karte_access_token,
        branch_access_token: params.branch_access_token,
      }
    )
    commit('setSections', sections)
  },
  async createKarteSection(
    { commit },
    params: {
      api: Repositories
      title: string
      description?: string
      hidden?: boolean
      formId: number
      branchId: number
    }
  ) {
    const { section } = await params.api.karteSectionsRepo.createKarteSection(
      params.branchId,
      params.formId,
      {
        title: params.title,
        description: params.description,
        hidden: params.hidden,
      }
    )
    commit('addSection', section)
    commit('setActiveSectionId', section.id)
  },
  async updateKarteSection(
    { commit },
    params: {
      api: Repositories
      id: number
      title: string
      description?: string
      position?: number
      hidden?: boolean
      branchId: number
      formId: number
    }
  ) {
    const { section } = await params.api.karteSectionsRepo.updateKarteSection(
      params.branchId,
      params.formId,
      {
        id: params.id,
        title: params.title,
        description: params.description,
        position: params.position,
        hidden: params.hidden,
      }
    )
    commit('updateSection', section)
  },
  async deleteKarteSection(
    { commit },
    params: {
      api: Repositories
      id: number
      branchId: number
      formId: number
    }
  ) {
    await params.api.karteSectionsRepo.deleteKarteSection(
      params.branchId,
      params.formId,
      params.id
    )
    commit('deleteSection', params.id)
  },
  async deleteKarteQuestion(
    { commit },
    params: {
      api: Repositories
      id: number
      branchId: number
      formId: number
    }
  ) {
    await params.api.karteQuestionsRepo.deleteKarteQuestion(
      params.branchId,
      params.id,
      params.formId
    )
    commit('deleteQuestionItem', params.id)
  },
}
