import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { CashRegisterRepo, CashRegister } from '~/types/accounting'

export default ($axios: NuxtAxiosInstance, $sentry: any): CashRegisterRepo => ({
  async fetchCashRegisters(
    branchId: number,
    params?: {
      date?: string
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/cash_register`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCashRegisterWithId(branchId: number, cashRegisterId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/cash_register/${cashRegisterId}`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createCashRegister(branchId: number, cashRegisterData: CashRegister) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/cash_register`,
        cashRegisterData
      )
      if (res.status === 201) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateCashRegister(branchId: number, cashRegisterData: CashRegister) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/cash_register/${cashRegisterData.id}`,
        {
          cash_register: cashRegisterData,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
