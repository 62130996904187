import { Plugin } from '@nuxt/types'
import { CMS, Repositories } from '~/types'
import authRepository from '~/repository/authRepository'
import assessmentRepository from '~/repository/assessmentRepository'
import shiftRepository from '~/repository/shiftRepository'
import shiftAssignmentRepository from '~/repository/shiftAssignmentRepository'
import unitRepository from '~/repository/unitRepository'
import cmsAuthorRepository from '~/repository/cmsAuthorRepository'
import cmsCategoryRepository from '~/repository/cmsCategoryRepository'
import cmsContentRepository from '~/repository/cmsContentRepository'
import customerRepository from '~/repository/customerRepository'
import cashRegisterRepository from '~/repository/cashRegisterRepository'
import businessTimesRepository from '~/repository/businessTimesRepository'
import roleRepository from '~/repository/roleRepository'
import permissionRepository from '~/repository/permissionRepository'
import productCategoryRepository from '~/repository/productCategoryRepository'
import branchRepository from '~/repository/branchRepository'
import productRepository from '~/repository/productRepository'
import staffRepository from '~/repository/staffRepository'
import licenceRepository from '~/repository/licenceRepository'
import invoiceRepository from '~/repository/invoiceRepository'
import itemRepository from '~/repository/itemRepository'
import reservationRepository from '~/repository/reservationRepository'
import homeRepository from '~/repository/homeRepository'
import breakRepository from '~/repository/breakRepository'
import analyticsRepository from '~/repository/analyticsRepository'

import crmAnalyticsRepository from '~/repository/crmAnalyticsRepository'
import crmBranchHistoryAnalyticsRepository from '~/repository/crmBranchHistoryAnalyticsRepository'
import crmProductHistoryAnalyticsRepository from '~/repository/crmProductHistoryAnalyticsRepository'
import crmAdSourceAnalyticsRepository from '~/repository/crmAdSourceAnalyticsRepository'
import plannedHolidayRepository from '~/repository/plannedHolidayRepository'
import pluginsRepository from '~/repository/pluginsRepository'
import karteEntryRepository from '~/repository/karteEntryRepository'
import karteReportRepository from '~/repository/karteReportRepository'
import karteSymptomAreaRepository from '~/repository/karteSymptomAreaRepository'
import feedbackRepository from '~/repository/feedbackRepository'
import ticketRepository from '~/repository/ticketRepository'
import notificationRepository from '~/repository/notificationRepository'
import squareRepository from '~/repository/squareRepository'
import customerInsuranceRepository from '~/repository/customerInsuranceRepository'
import reservationTagRepository from '~/repository/reservationTagRepository'
import customerTagRepository from '~/repository/customerTagRepository'
import downloadRepository from '~/repository/downloadRepository'
import jwtRepository from '~/repository/jwtRepository'
import hqRepository from '~/repository/hqRepository'
import mailRepository from '~/repository/mailRepository'
import automatedMailRepository from '~/repository/automatedMailRepository'
import reservationSlotsRepository from '~/repository/reservationSlotsRepository'
import transactionsRepository from '~/repository/transactionsRepository'
import karteSectionsRepository from '~/repository/karteSectionsRepository'
import karteQuestionsRepository from '~/repository/karteQuestionsRepository'
import karteFormResponseRepository from '~/repository/karteFormResponseRepository'
import storeCreditRepository from '~/repository/storeCreditRepository'
import couponRepository from '~/repository/couponRepository'
import demoRepository from '~/repository/demoRepository'
import staffEmploymentHistoryRepository from '~/repository/staffEmploymentHistoryRepository'
import advertisingRepository from '~/repository/advertisingRepository'
import goalRepository from '~/repository/goalRepository'
import karteFormResourceRepository from '~/repository/karteFormResourceRepository'
import karteTemplatesRepository from '~/repository/karteTemplatesRepository'
import karteSymptomRegistrationRepository from '~/repository/karteSymptomRegistrationRepository'
import karteTemplateCategoriesRepository from '~/repository/karteTemplateCategoriesRepository'
import karteSymptomCategoriesRepository from '~/repository/karteSymptomCategoriesRepository'

declare module 'vue/types/vue' {
  interface Vue {
    $api: Repositories
  }
}

const myPlugin: Plugin = (ctx, inject) => {
  const repositories: Repositories = {
    analyticsRepo: analyticsRepository(ctx.$axios, ctx.$sentry),
    assessmentRepo: assessmentRepository(ctx.$axios, ctx.$sentry),
    authRepo: authRepository(ctx.store, ctx.$axios, ctx.redirect, ctx.$sentry),
    branchRepo: branchRepository(ctx.$axios, ctx.$sentry),
    breakRepo: breakRepository(ctx.$axios, ctx.$sentry),
    businessTimesRepo: businessTimesRepository(ctx.$axios, ctx.$sentry),
    cmsAuthorRepo: cmsAuthorRepository(ctx.$axios, ctx.$sentry),
    cmsCategoryRepo: cmsCategoryRepository(ctx.$axios, ctx.$sentry),
    cmsContentRepo: (type: CMS.ContentType) => {
      return cmsContentRepository(ctx.$axios, ctx.$sentry, type)
    },
    crmBranchHistoryAnalyticsRepo: crmBranchHistoryAnalyticsRepository(
      ctx.$axios,
      ctx.$sentry
    ),
    crmProductHistoryAnalyticsRepo: crmProductHistoryAnalyticsRepository(
      ctx.$axios,
      ctx.$sentry
    ),
    crmAdSourceAnalyticsRepo: crmAdSourceAnalyticsRepository(
      ctx.$axios,
      ctx.$sentry
    ),
    crmAnalyticsRepo: crmAnalyticsRepository(ctx.$axios, ctx.$sentry),
    customerRepo: customerRepository(ctx.$axios, ctx.$sentry),
    cashRegisterRepo: cashRegisterRepository(ctx.$axios, ctx.$sentry),
    transactionsRepo: transactionsRepository(ctx.$axios, ctx.$sentry),
    downloadRepo: downloadRepository(ctx.$axios, ctx.$sentry),
    reservationTagRepo: reservationTagRepository(ctx.$axios, ctx.$sentry),
    customerTagRepo: customerTagRepository(ctx.$axios, ctx.$sentry),
    feedbackRepo: feedbackRepository(ctx.$axios, ctx.$sentry),
    homeRepo: homeRepository(ctx.$axios, ctx.$sentry),
    invoiceRepo: invoiceRepository(ctx.$axios, ctx.$sentry),
    itemRepo: itemRepository(ctx.$axios, ctx.$sentry),
    jwtRepo: jwtRepository(ctx.$axios, ctx.$sentry),
    karteEntryRepo: karteEntryRepository(ctx.$axios, ctx.$sentry),
    karteReportRepo: karteReportRepository(ctx.$axios, ctx.$sentry),
    karteSymptomAreaRepo: karteSymptomAreaRepository(ctx.$axios, ctx.$sentry),
    licenceRepo: licenceRepository(ctx.$axios, ctx.$sentry),
    permissionRepo: permissionRepository(ctx.$axios, ctx.$sentry),
    plannedHolidayRepo: plannedHolidayRepository(ctx.$axios, ctx.$sentry),
    pluginsRepo: pluginsRepository(ctx.$axios, ctx.$sentry),
    productCategoryRepo: productCategoryRepository(ctx.$axios, ctx.$sentry),
    productRepo: productRepository(ctx.$axios, ctx.$sentry),
    reservationRepo: reservationRepository(ctx.$axios, ctx.$sentry),
    roleRepo: roleRepository(ctx.$axios, ctx.$sentry),
    shiftAssignmentRepo: shiftAssignmentRepository(ctx.$axios, ctx.$sentry),
    shiftRepo: shiftRepository(ctx.$axios, ctx.$sentry),
    goalRepo: goalRepository(ctx.$axios, ctx.$sentry),
    staffRepo: staffRepository(ctx.$axios, ctx.$sentry),
    ticketRepo: ticketRepository(ctx.$axios, ctx.$sentry),
    unitRepo: unitRepository(ctx.$axios, ctx.$sentry),
    notificationRepo: notificationRepository(ctx.$axios, ctx.$sentry),
    squareRepo: squareRepository(ctx.$axios, ctx.$sentry),
    customerInsuranceRepo: customerInsuranceRepository(ctx.$axios, ctx.$sentry),
    hqRepo: hqRepository(ctx.$axios, ctx.$sentry),
    mailRepo: mailRepository(ctx.$axios, ctx.$sentry),
    automatedMailRepo: automatedMailRepository(ctx.$axios, ctx.$axios),
    reservationSlotsRepo: reservationSlotsRepository(ctx.$axios, ctx.$sentry),
    karteSectionsRepo: karteSectionsRepository(ctx.$axios, ctx.$sentry),
    karteQuestionsRepo: karteQuestionsRepository(ctx.$axios, ctx.$sentry),
    karteFormResponseRepo: karteFormResponseRepository(ctx.$axios, ctx.$sentry),
    storeCreditRepo: storeCreditRepository(ctx.$axios, ctx.$sentry),
    couponRepo: couponRepository(ctx.$axios, ctx.$sentry),
    demoRepo: demoRepository(ctx.$axios, ctx.$sentry),
    staffEmploymentHistoryRepo: staffEmploymentHistoryRepository(
      ctx.$axios,
      ctx.$sentry
    ),
    karteFormResourceRepo: karteFormResourceRepository(ctx.$axios, ctx.$sentry),
    advertisingRepo: advertisingRepository(ctx.$axios, ctx.$sentry),
    karteTemplatesRepo: karteTemplatesRepository(ctx.$axios, ctx.$sentry),
    karteSymptomRegistrationRepo: karteSymptomRegistrationRepository(
      ctx.$axios,
      ctx.$sentry
    ),
    KarteTemplateCategoriesRepo: karteTemplateCategoriesRepository(
      ctx.$axios,
      ctx.$sentry
    ),
    KarteSymptomCategoriesRepo: karteSymptomCategoriesRepository(
      ctx.$axios,
      ctx.$sentry
    ),
  }
  inject('api', repositories)
}

export default myPlugin
