




















import Vue from 'vue'
import { UserPlugin } from '~/models/UserPlugin'
import { UserPermission } from '~/models/UserPermission'
import { isMatchUrl } from '~/helpers/urlUtils'
export default Vue.extend({
  props: {
    width: {
      type: String,
      required: false,
      default: '75',
    },
    miniVariant: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    userPermission(): UserPermission {
      return this.$store.state.auth.userPermission
    },
    userPlugin(): UserPlugin {
      return this.$store.state.auth.userPlugin
    },
    sideBarItems(): {
      text: any
      icon: string
      link: string
      show: boolean
    }[] {
      return [
        {
          text: this.$t('sidebar.crm'),
          icon: 'leaderboard',
          link: '/hq/crm/hq_crm',
          show:
            this.userPlugin.isEnabled('crm') &&
            this.userPermission.isAllowed('hq_crm'),
        },
      ]
    },
  },
  methods: {
    isActive(item: {
      text: any
      icon: string
      link: string
      show: boolean
    }): boolean {
      const url = this.$route.path
      return (
        (item.link === '/hq/staff' && isMatchUrl(url, '/hq/permissions')) ||
        (item.link === '/hq/products' && isMatchUrl(url, '/hq/categories')) ||
        (item.link === '/hq/products' && isMatchUrl(url, '/hq/tickets')) ||
        (item.link === '/hq/products' && isMatchUrl(url, '/hq/coupons')) ||
        (item.link === '/hq/crm/staff' && isMatchUrl(url, '/hq/crm'))
      )
    },
  },
})
