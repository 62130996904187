import dayjs from 'dayjs'
import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import { Branch } from '~/types'

const initTabLastModifiedStatus = {
  customer: false,
  revenue: false,
  product: false,
}
export const state = () => ({
  startEndDate: {
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
  },
  viewType: 'graph',
  tabStatus: 'revenue',
  tabLastModifiedStatus: Object.assign({}, initTabLastModifiedStatus),
  selectedBranchesNames: [] as Branch[],
})

export type AnalyticState = ReturnType<typeof state>

export const mutations: MutationTree<AnalyticState> = {
  setStartEndDate(
    state,
    dashboardStartEndDate: { startDate: string; endDate: string }
  ) {
    state.startEndDate = dashboardStartEndDate
  },
  setViewType(state, viewType: string) {
    state.viewType = viewType
  },
  setSelectedBranchesNames(state, names: Branch[]) {
    state.selectedBranchesNames = names
  },
  setTabStatus(state, viewType: string) {
    state.tabStatus = viewType
  },
  setTabLastModifiedStatus(
    state,
    value: { status: boolean; tabStatus: string }
  ) {
    state.tabLastModifiedStatus[value.tabStatus] = value.status
  },
  initTabLastModifiedStatus(state) {
    state.tabLastModifiedStatus = Object.assign({}, initTabLastModifiedStatus)
  },
}

export const actions: ActionTree<AnalyticState, RootState> = {}
