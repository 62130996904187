import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { KarteSectionsRepo } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): KarteSectionsRepo => ({
  async fetchKarteSections(
    branchId: number,
    formId: number,
    params?: { karte_access_token?: string; branch_access_token?: string }
  ) {
    try {
      const res = await $axios.get(
        `/therapists/branches/${branchId}/karte/forms/${formId}/sections`,
        { params }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createKarteSection(
    branchId: number,
    formId: number,
    params: { title: string; description: string; hidden: boolean }
  ) {
    try {
      const res = await $axios.post(
        `/therapists/branches/${branchId}/karte/forms/${formId}/sections`,
        {
          section: params,
        }
      )
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateKarteSection(
    branchId: number,
    formId: number,
    params: {
      id: number
      title: string
      description?: string
      position?: number
      hidden?: boolean
    }
  ) {
    try {
      const res = await $axios.put(
        `/therapists/branches/${branchId}/karte/forms/${formId}/sections/${params.id}`,
        {
          section: params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async deleteKarteSection(
    branchId: number,
    formId: number,
    section_id: number
  ) {
    try {
      await $axios.delete(
        `/therapists/branches/${branchId}/karte/forms/${formId}/sections/${section_id}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
