import { MutationTree, ActionTree } from 'vuex'
import { CustomKarteQuestion, Repositories } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  karteQuestions: [] as CustomKarteQuestion[],
})

export type KarteQuestionsState = ReturnType<typeof state>

export const mutations: MutationTree<KarteQuestionsState> = {
  setKarteQuestions(state, questions: CustomKarteQuestion[]) {
    state.karteQuestions = questions
  },
}

export const actions: ActionTree<KarteQuestionsState, RootState> = {
  async fetchKarteQuestions(
    { commit },
    params: {
      api: Repositories
      branchId: number
      formId: number
      sectionId: number
      karte_access_token: string
      branch_access_token?: string
    }
  ) {
    const { questions } =
      await params.api.karteQuestionsRepo.fetchKarteQuestions(
        params.branchId,
        params.formId,
        {
          section_ids: [params.sectionId],
          karte_access_token: params.karte_access_token,
          branch_access_token: params.branch_access_token,
        }
      )
    commit('setKarteQuestions', questions)
  },
}
