















import Vue from 'vue'

export default Vue.extend({
  props: {
    text: {
      type: String,
      default: null,
    },
    link: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
})
