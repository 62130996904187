import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'

export default ($axios: NuxtAxiosInstance, $sentry: any) => ({
  async fetchAvailableTherapists(
    branch_id: number,
    params?: {
      page?: number
      per?: number
      start_time: string
      course_id?: number
    }
  ) {
    if (params?.course_id === -1) delete params?.course_id
    try {
      const res = await $axios.get(`home/providers/${branch_id}/therapists`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
