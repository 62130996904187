import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import { Staff, Reservation, Unit, MailFormData } from '~/types'

const IS_RESERVATION_CALENDAR_SIDEBAR_OPEN = 'isReservationCalendarSidebarOpen'
const STAFF_ID_ORDER = 'staffIdOrder'
const UNIT_ID_ORDER = 'unitIdOrder'
const DRAFT_RESERVATION_DATA = 'draftReservationData'
const DRAFT_MAIL_DATA = 'draftMailData'
const STAR_PRINTER_PAPER_SIZE = 'starPrinterPaperSize'
const PRINTER_TYPE = 'printerType'
const IS_PRINTER_EMPTY_SPACE = 'isPrinterEmptySpace'
const IS_DISPLAY_SPECIFICATIONS = 'isDisplaySpecifications'
const PRINTER_FREE_TEXT = 'printer_free_text'
const IS_EXACT = 'is_exact'
const PRINTER_REGISTRANT_NUMBER = 'printer_registrant_number'
const CURRENCY = 'currency'

export const state = () => ({
  isReservationCalendarSidebarOpen: true,
  activeViewType: 'unit' as 'staff' | 'unit',
  activeDetailViewStaffList: [] as number[],
  staffIdOrder: [] as number[],
  unitIdOrder: [] as number[],
  storedStaffPreferredUnitList: [] as {
    staff_id: number
    unit_ids: number[]
  }[],
  staffUnitList: [] as {
    staff_id: number
    unit_ids: number[]
  }[],
  reservationDraftData: [] as Reservation[],
  draftMailData: [] as MailFormData[],
  activeDraftDataIndex: -1 as number,
  activeDraftMailDataIndex: -1 as number,
  starPrinterPaperSize: '192px',
  printerType: 'normal',
  isPrinterEmptySpace: false,
  isDisplaySpecifications: false,
  printerFreeText: '',
  printerRegistrantNumber: '',
  currency: '',
  is_exact: false,
})

export type LayoutSettingsState = ReturnType<typeof state>

export const mutations: MutationTree<LayoutSettingsState> = {
  setReservationCalendarSidebar(state, value: boolean) {
    state.isReservationCalendarSidebarOpen = value
  },
  setStaffIdOrder(state, ids: number[]) {
    state.staffIdOrder = ids
    localStorage.setItem(STAFF_ID_ORDER, JSON.stringify(state.staffIdOrder))
  },
  setUnitIdOrder(state, ids: number[]) {
    state.unitIdOrder = ids
    localStorage.setItem(UNIT_ID_ORDER, JSON.stringify(state.unitIdOrder))
  },
  toggleReservationCalendarSideBar(state) {
    state.isReservationCalendarSidebarOpen =
      !state.isReservationCalendarSidebarOpen
    localStorage.setItem(
      IS_RESERVATION_CALENDAR_SIDEBAR_OPEN,
      JSON.stringify(state.isReservationCalendarSidebarOpen)
    )
  },
  setActiveViewType(state, viewType) {
    state.activeViewType = viewType
    localStorage.setItem(
      'reservationCalendarActiveView',
      JSON.stringify(state.activeViewType)
    )
  },
  setActiveDetailViewStaff(state, staffIdList) {
    state.activeDetailViewStaffList = staffIdList
    localStorage.setItem(
      'reservationCalendarActiveDetailStaff',
      JSON.stringify(state.activeDetailViewStaffList)
    )
  },
  setStaffPreferredUnitList(state, payloadList) {
    state.storedStaffPreferredUnitList = payloadList
  },
  setStaffUnitList(state, payloadList) {
    state.staffUnitList = payloadList
  },
  setReservationDraftData(state, reservationData) {
    state.reservationDraftData = reservationData
  },
  setActiveDraftDataIndex(state, activeIndex) {
    state.activeDraftDataIndex = activeIndex
  },
  setDraftMailData(state, draftMailData) {
    state.draftMailData = draftMailData
  },
  setStarPrinterPaperSize(state, size) {
    state.starPrinterPaperSize = size
  },
  setPrinterType(state, type) {
    state.printerType = type
  },
  setIsPrinterEmptySpace(state, status) {
    state.isPrinterEmptySpace = status
  },
  setIsDisplaySpecifications(state, status) {
    state.isDisplaySpecifications = status
  },
  setActiveDraftMailDataIndex(state, activeIndex) {
    state.activeDraftMailDataIndex = activeIndex
  },
  setPrinterFreeText(state, printerFreeText) {
    state.printerFreeText = printerFreeText
  },
  setPrinterRegistrantNumber(state, printerRegistrantNumber) {
    state.printerRegistrantNumber = printerRegistrantNumber
  },
  setIsExact(state, isExact) {
    state.is_exact = isExact
  },
  setCurrency(state, currency) {
    state.currency = currency
  },
  saveStarPrintSize(state) {
    localStorage.setItem(STAR_PRINTER_PAPER_SIZE, state.starPrinterPaperSize)
  },
  savePrinterType(state) {
    localStorage.setItem(PRINTER_TYPE, state.printerType)
  },
  saveIsPrinterEmptySpace(state) {
    localStorage.setItem(IS_PRINTER_EMPTY_SPACE, `${state.isPrinterEmptySpace}`)
  },
  saveIsDisplaySpecifications(state) {
    localStorage.setItem(
      IS_DISPLAY_SPECIFICATIONS,
      `${state.isDisplaySpecifications}`
    )
  },
  savePrinterFreeText(state) {
    localStorage.setItem(PRINTER_FREE_TEXT, `${state.printerFreeText}`)
  },
  savePrinterRegistrantNumber(state) {
    localStorage.setItem(
      PRINTER_REGISTRANT_NUMBER,
      `${state.printerRegistrantNumber}`
    )
  },
  saveIsExact(state) {
    localStorage.setItem(IS_EXACT, `${state.is_exact}`)
  },
  saveStaffPreferredUnitList(state) {
    localStorage.setItem(
      'staffPreferredUnitList',
      JSON.stringify(state.storedStaffPreferredUnitList)
    )
  },
  saveCurrency(state) {
    localStorage.setItem(CURRENCY, state.currency)
  },
  resetStaffPreferredUnitList(state) {
    state.storedStaffPreferredUnitList = JSON.parse(
      JSON.stringify(state.staffUnitList)
    )
    localStorage.removeItem('staffPreferredUnitList')
  },
  updateStaffPreferredUnitList(
    state,
    payload: { staff_id: number; unit_ids: number[] }
  ) {
    const index = state.storedStaffPreferredUnitList.findIndex(
      (i) => i.staff_id === payload.staff_id
    )

    if (index !== -1)
      state.storedStaffPreferredUnitList.splice(index, 1, payload)
    else state.storedStaffPreferredUnitList.push(payload)
  },

  updateActiveDetailViewStaff(
    state,
    params: { open: boolean; staffId: number }
  ) {
    const index = state.activeDetailViewStaffList.findIndex(
      (id) => id === params.staffId
    )
    if (params.open && index === -1)
      state.activeDetailViewStaffList.push(params.staffId)
    else if (!params.open && index !== -1)
      state.activeDetailViewStaffList.splice(index, 1)
    localStorage.setItem(
      'reservationCalendarActiveDetailStaff',
      JSON.stringify(state.activeDetailViewStaffList)
    )
  },
  updateReservationDraftData(
    state,
    reservationData: Reservation & { branchId: number }
  ) {
    if (state.activeDraftDataIndex !== -1) {
      state.reservationDraftData.splice(
        state.activeDraftDataIndex,
        1,
        reservationData
      )
      state.activeDraftDataIndex = -1
      localStorage.setItem(
        DRAFT_RESERVATION_DATA,
        JSON.stringify(state.reservationDraftData)
      )
    } else {
      state.reservationDraftData.unshift(reservationData)
      localStorage.setItem(
        DRAFT_RESERVATION_DATA,
        JSON.stringify(state.reservationDraftData)
      )
    }
  },
  updateDraftMailData(state, mailData: MailFormData) {
    if (state.activeDraftMailDataIndex !== -1) {
      state.draftMailData.splice(state.activeDraftMailDataIndex, 1, mailData)
      state.activeDraftMailDataIndex = -1
      localStorage.setItem(DRAFT_MAIL_DATA, JSON.stringify(state.draftMailData))
      return
    }
    state.draftMailData.unshift(mailData)
    localStorage.setItem(DRAFT_MAIL_DATA, JSON.stringify(state.draftMailData))
  },
  deleteReservationDraftData(state, deleteIndex: number) {
    if (deleteIndex === -1) return
    state.reservationDraftData.splice(deleteIndex, 1)
    state.activeDraftDataIndex = -1
    localStorage.setItem(
      DRAFT_RESERVATION_DATA,
      JSON.stringify(state.reservationDraftData)
    )
  },
  deleteDraftMailData(state, deleteIndex: number) {
    if (deleteIndex === -1) return
    state.draftMailData.splice(deleteIndex, 1)
    state.activeDraftMailDataIndex = -1
    localStorage.setItem(DRAFT_MAIL_DATA, JSON.stringify(state.draftMailData))
  },
}
export const actions: ActionTree<LayoutSettingsState, RootState> = {
  setInitialLayoutState({ commit }) {
    const staffPreferredUnitList = localStorage.getItem(
      'staffPreferredUnitList'
    )
    let activeViewType = localStorage.getItem('reservationCalendarActiveView')
    const activeDetailViewStaffList = localStorage.getItem(
      'reservationCalendarActiveDetailStaff'
    )
    if (staffPreferredUnitList) {
      commit('setStaffPreferredUnitList', JSON.parse(staffPreferredUnitList))
    }
    if (activeViewType) {
      activeViewType = JSON.parse(activeViewType)
      if (['staff', 'unit'].includes(activeViewType!))
        commit('setActiveViewType', activeViewType)
    }
    if (activeDetailViewStaffList) {
      commit('setActiveDetailViewStaff', JSON.parse(activeDetailViewStaffList))
    }

    const isReservationCalendarSidebarOpen = localStorage.getItem(
      IS_RESERVATION_CALENDAR_SIDEBAR_OPEN
    )
    if (isReservationCalendarSidebarOpen) {
      commit(
        'setReservationCalendarSidebar',
        JSON.parse(isReservationCalendarSidebarOpen)
      )
    }

    const staffIdOrder = localStorage.getItem(STAFF_ID_ORDER)
    if (staffIdOrder) {
      commit('setStaffIdOrder', JSON.parse(staffIdOrder))
    }
    const unitIdOrder = localStorage.getItem(UNIT_ID_ORDER)
    if (unitIdOrder) {
      commit('setUnitIdOrder', JSON.parse(unitIdOrder))
    }
    const starPrintSize = localStorage.getItem(STAR_PRINTER_PAPER_SIZE)
    if (starPrintSize) {
      commit('setStarPrinterPaperSize', starPrintSize)
    }
    const printerType = localStorage.getItem(PRINTER_TYPE)
    if (printerType) {
      commit('setPrinterType', printerType)
    }

    const isPrinterEmptySpace = localStorage.getItem(IS_PRINTER_EMPTY_SPACE)
    if (isPrinterEmptySpace) {
      commit('setIsPrinterEmptySpace', isPrinterEmptySpace === 'true')
    }

    const isDisplaySpecifications = localStorage.getItem(
      IS_DISPLAY_SPECIFICATIONS
    )
    if (isDisplaySpecifications) {
      commit('setIsDisplaySpecifications', isDisplaySpecifications === 'true')
    }

    const printerFreeText = localStorage.getItem(PRINTER_FREE_TEXT)
    if (printerFreeText) {
      commit('setPrinterFreeText', printerFreeText)
    }

    const printerRegistrantNumber = localStorage.getItem(
      PRINTER_REGISTRANT_NUMBER
    )
    if (printerRegistrantNumber) {
      commit('setPrinterRegistrantNumber', printerRegistrantNumber)
    }

    const isExact = localStorage.getItem(IS_EXACT)
    if (isExact) {
      commit('setIsExact', isExact === 'true')
    }

    const currency = localStorage.getItem(CURRENCY)
    if (currency) {
      commit('setCurrency', currency)
    }
  },
  setInitialDraftState({ commit }) {
    const reservationDraftData = localStorage.getItem(DRAFT_RESERVATION_DATA)
    if (reservationDraftData) {
      commit('setReservationDraftData', JSON.parse(reservationDraftData))
    }
  },
  setInitialDraftMailState({ commit }) {
    const DraftMailData = localStorage.getItem(DRAFT_MAIL_DATA)
    if (DraftMailData) {
      commit('setDraftMailData', JSON.parse(DraftMailData))
    }
  },
  restoreStaffSortInReservationCalendar({ commit, rootState }) {
    const staffIdOrder: number[] = (rootState as any).layoutSettings
      .staffIdOrder
    const allStaffCopy: Staff[] = JSON.parse(
      JSON.stringify((rootState as any).staff.allStaff)
    )
    allStaffCopy.sort(
      (a, b) =>
        staffIdOrder.findIndex((id) => id === a.id) -
        staffIdOrder.findIndex((id) => id === b.id)
    )
    commit('staff/setAllStaff', allStaffCopy, { root: true })
    const staff: Staff[] = (rootState as any).staff.staff
    const ids = staff.map((staffData) => staffData.id as number)
    ids.sort(
      (a, b) =>
        allStaffCopy.findIndex((v) => v.id === a) -
        allStaffCopy.findIndex((v) => v.id === b)
    )
    commit('staff/updateStaffList', ids, { root: true })
  },
  restoreUnitSortInReservationCalendar({ commit, rootState }) {
    const unitIdOrder: number[] = (rootState as any).layoutSettings.unitIdOrder
    const allUnitCopy: Unit[] = JSON.parse(
      JSON.stringify((rootState as any).unit.units)
    )
    allUnitCopy.sort(
      (a, b) =>
        unitIdOrder.findIndex((id) => id === a.id) -
        unitIdOrder.findIndex((id) => id === b.id)
    )
    commit('unit/setUnits', allUnitCopy, { root: true })
  },
}
