import { MutationTree, ActionTree } from 'vuex'
import { Repositories, KarteFormResource, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  loadingList: {
    isKarteFormResourceLoading: false,
  },
  karteFormResource: [] as KarteFormResource[],
  karteFormPagination: {} as Pagination,
})

export type KarteFormResourceState = ReturnType<typeof state>

export const mutations: MutationTree<KarteFormResourceState> = {
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
  setBranchFormResource(state, karteFormResources: KarteFormResource[]) {
    state.karteFormResource = karteFormResources
  },
  setKarteFormPagination(state, pagination: Pagination) {
    state.karteFormPagination = pagination
  },
  setActiveBranchFormResource(state, karteFormResource: KarteFormResource) {
    const karte_id = state.karteFormResource.findIndex(
      (item) => item.id === karteFormResource.id
    )
    if (karte_id !== -1)
      state.karteFormResource.splice(karte_id, 1, karteFormResource)
  },
  addNewKarteFormResource(state, karteFormResource: KarteFormResource) {
    state.karteFormResource.unshift(karteFormResource)
  },
  deleteKarteFormResource(state, id: number) {
    const index = state.karteFormResource.findIndex((item) => item.id === id)
    if (index !== -1) state.karteFormResource.splice(index, 1)
  },
}

export const actions: ActionTree<KarteFormResourceState, RootState> = {
  async fetchKarteFormResource(
    { commit },
    params: {
      api: Repositories
      branchId: number
      page: number
      per: number
      search: string
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    commit('setLoading', { key: 'isKarteFormResourceLoading', loading: true })
    const { forms, pagination } =
      await params.api.karteFormResourceRepo.fetchKarteFormResource(
        params.branchId,
        {
          page: params.page,
          per: params.per,
          search: params.search,
          karte_access_token: params.karte_access_token,
          branch_access_token: params.branch_access_token,
        }
      )
    commit('setBranchFormResource', forms)
    commit('setKarteFormPagination', pagination)
    commit('setLoading', { key: 'isKarteFormResourceLoading', loading: false })
  },
  async fetchKarteFormResourceById(
    { commit },
    params: {
      api: Repositories
      branchId: number
      formId: number
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    commit('setLoading', { key: 'isKarteFormResourceLoading', loading: true })
    const form =
      await params.api.karteFormResourceRepo.fetchKarteFormResourceById(
        params.branchId,
        params.formId,
        params.karte_access_token,
        params.branch_access_token
      )
    commit('setActiveBranchFormResource', form)
    commit('setLoading', { key: 'isKarteFormResourceLoading', loading: false })
  },
  async createKarteFormResource(
    { commit },
    params: {
      api: Repositories
      branchId: number
      karteFormResource: {
        title: string
        form_background_color: string
        header_background_color: string
        header_type: 'basic' | 'custom'
        products_ids: number[]
        form_type: 'default' | 'other'
      }
    }
  ) {
    commit('setLoading', { key: 'isKarteFormResourceLoading', loading: true })
    const karteFormResource =
      await params.api.karteFormResourceRepo.createKarteFormResource(
        params.branchId,
        params.karteFormResource
      )
    commit('addNewKarteFormResource', karteFormResource)
    commit('setLoading', { key: 'isKarteFormResourceLoading', loading: false })
  },
  async updateKarteFormResource(
    { commit },
    params: {
      api: Repositories
      karteFormResource?: KarteFormResource
      branchId: number
      formId: number
    }
  ) {
    commit('setLoading', { key: 'isKarteFormResourceLoading', loading: true })
    const forms =
      await params.api.karteFormResourceRepo.updateKarteFormResource(
        params.branchId,
        params.formId,
        params.karteFormResource
      )
    commit('setActiveBranchFormResource', forms)
    commit('setLoading', { key: 'isKarteFormResourceLoading', loading: false })
  },
  async deleteKarteFormResource(
    { commit },
    params: {
      api: Repositories
      branchId: number
      formId: number
    }
  ) {
    await params.api.karteFormResourceRepo.deleteFormResource(
      params.branchId,
      params.formId
    )
    commit('deleteKarteFormResource', params.formId)
  },
}
