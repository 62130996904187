import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { CMS, CMSCategoryRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): CMSCategoryRepo => ({
  async fetchCategories(
    branchId: number,
    params?: {
      page?: number
      per?: number
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/cms/categories`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createCategory(branchId: number, categoryData: CMS.Category) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/cms/categories`,
        { category: categoryData }
      )
      if (res.status === 201) {
        return res.data.category
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async fetchCategoryWithId(branchId: number, categoryId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/cms/categories/${categoryId}`
      )
      if (res.status === 200) {
        return res.data.category
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateCategory(branchId: number, categoryData: CMS.Category) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/cms/categories/${categoryData.id}`,
        { category: categoryData }
      )
      if (res.status === 200) {
        return res.data.category
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteCategory(branchId: number, categoryId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/cms/categories/${categoryId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
