import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Feedback, FeedbackRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): FeedbackRepo => ({
  async createFeedback(feedbackData: Feedback) {
    try {
      const res = await $axios.post(`therapists/feedback`, {
        feedback: feedbackData,
      })
      if (res.status === 201) {
        return res.data.feedback
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
