import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { StaffEmploymentHistoryRepo } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): StaffEmploymentHistoryRepo => ({
  async fetchStaffEmploymentHistory(
    staff_id: number,
    params?: { start_date?: string; end_date?: string; branch_ids?: number[] }
  ) {
    try {
      const res = await $axios.get(
        `therapists/staff/${staff_id}/employment_histories`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createStaffEmploymentHistory(staff_id: number, employment_history) {
    try {
      const res = await $axios.post(
        `therapists/staff/${staff_id}/employment_histories`,
        {
          employment_history,
        }
      )
      if (res.status === 201) {
        return res.data.employment_history
      }
    } catch (err) {
      processError(err, $sentry)
    }
  },
  async updateStaffEmploymentHistory(employment_history: {
    id: number
    branch_id: number
    start_date: string
    end_date: string
  }) {
    try {
      const res = await $axios.put(
        `therapists/employment_histories/${employment_history.id}`,
        {
          employment_history,
        }
      )
      if (res.status === 200) {
        return res.data.employment_history
      }
    } catch (err) {
      processError(err, $sentry)
    }
  },
  async deleteStaffEmploymentHistory(id: number) {
    try {
      await $axios.delete(`therapists/employment_histories/${id}`)
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
