import { MutationTree } from 'vuex'

export const state = () => ({
  content: '',
  color: '',
  timeout: 5000,
  data: {},
  background: '',
  light: false,
  class: 'snack-bar',
  icon: null,
  iconColor: '#fff',
})

export type SnackbarState = ReturnType<typeof state>

export const mutations: MutationTree<SnackbarState> = {
  showMessage(state, payload) {
    state.content = payload.content
    state.color = payload.color
    state.timeout = payload.timeout ?? 5000
    state.data = payload.data ?? {}
    state.background = payload.background ?? '#000'
    state.light = payload.light ?? false
    state.class = payload.class ?? 'snack-bar'
    state.icon = payload.icon ?? null
    state.iconColor = payload.iconColor ?? '#fff'
  },
}
