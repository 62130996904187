import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { PermissionRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): PermissionRepo => ({
  async fetchPermissions() {
    try {
      const res = await $axios.get('therapists/permissions')
      if (res.status === 200) {
        return res.data.permissions
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
