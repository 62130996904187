import { MutationTree, ActionTree } from 'vuex'
import { Repositories, Pagination, StoreCredit } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  customerStoreCredits: [] as StoreCredit[],
  customerAvailableCredits: 0 as Number,
  customerStoreCreditsPagination: {} as Pagination,
})

export type CustomerStoreCreditsState = ReturnType<typeof state>

export const mutations: MutationTree<CustomerStoreCreditsState> = {
  setStoreCredits(state, couponTransactions: StoreCredit[]) {
    state.customerStoreCredits = couponTransactions
  },
  setAvailableCredits(state, availableCredits: number) {
    state.customerAvailableCredits = availableCredits
  },
  setStoreCreditsPagination(state, pagination: Pagination) {
    state.customerStoreCreditsPagination = pagination
  },
}

export const actions: ActionTree<CustomerStoreCreditsState, RootState> = {
  async fetchCustomerStoreCreditsHistory(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      customer_id: number
      branchId: number
    }
  ) {
    const branchId = params.branchId
      ? params.branchId
      : (rootState as any).auth.user.activeBranch
    const customerId = params.customer_id
    const { coupon_transactions, available_credits, pagination } =
      (await params.api.storeCreditRepo.fetchStoreCredits(
        branchId,
        customerId,
        {
          page: params.page || 1,
          per: params.per,
        }
      )) as {
        coupon_transactions: StoreCredit[]
        available_credits: number
        pagination: Pagination
      }
    commit('setStoreCredits', coupon_transactions)
    commit('setAvailableCredits', available_credits)
    commit('setStoreCreditsPagination', pagination)
  },
}
