



































import Vue, { PropOptions } from 'vue'
import { NotificationCategory, NotificationData } from '~/types'

export default Vue.extend({
  props: {
    notification: {
      type: Object,
      required: true,
    } as PropOptions<NotificationData>,
  },
  data() {
    return {
      isContentOpen: false,
    }
  },
  methods: {
    getCategoryString(categorySlug: NotificationCategory) {
      if (categorySlug === 'product_update') {
        return {
          text: this.$t('notifications.productUpdate'),
          backgroundColor: '#f4e9ff',
          textColor: '#9b51e0',
        }
      } else {
        return {
          text: this.$t('notifications.news'),
          backgroundColor: '#fcead9',
          textColor: '#f2994a',
        }
      }
    },
    async acknowledgedNotification(notification: NotificationData) {
      try {
        await this.$store.dispatch('notifications/acknowledgeNotification', {
          api: this.$api,
          notificationData: { ...notification, acknowledged: true },
        })
      } catch (error) {
        this.$notifier.showMessage({
          content: (error as Error).message,
          color: 'error',
        })
      }
    },
  },
})
