import { Branch } from '~/types'
import { getPrefectures } from '~/helpers/prefectureUtils'

export function filterPrefecture(branches: Branch[]) {
  return branches
    .map((branch) => {
      return getPrefectures(branch.prefecture) || 'その他'
    })
    .filter((branch, i, self) => {
      return self.indexOf(branch) === i
    })
}
export function divideBranches(prefecture: string[], branches: Branch[]) {
  return prefecture.map((prefecture) => {
    const filterBranches = branches.filter((branch) => {
      return (getPrefectures(branch.prefecture) || 'その他') === prefecture
    })
    const children = filterBranches.map((branch) => {
      return {
        id: Number(branch.id),
        label: branch.name,
      }
    })
    return {
      id: prefecture,
      label: prefecture,
      children,
    }
  })
}
