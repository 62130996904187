import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Assessment, AssessmentRepo, AssessmentImageIds } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): AssessmentRepo => ({
  async fetchAssessment(branchId: number, customerId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/${customerId}/assessments`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async updateAssessment(
    assessmentData: Assessment,
    branchId: number,
    customerId: number
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/customers/${customerId}/assessments`,
        {
          assessment: assessmentData,
        }
      )
      if (res.status === 200) {
        return res.data.assessment
      }
    } catch (error) {
      return processError(error, $sentry)
    }
  },
  async fetchAssessments(
    branchId: number,
    customerId: number,
    params: {
      page?: number
      per?: number
      title?: string
      id?: number
      treatment_types?: string[]
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/${customerId}/assessments`,
        { params }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createAssessment(
    branchId: number,
    customerId: number,
    assessmentData: Assessment
  ) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/customers/${customerId}/assessments`,
        {
          assessment: assessmentData,
        }
      )
      if (res.status === 200) {
        return res.data.assessment
      }
    } catch (error) {
      return processError(error, $sentry)
    }
  },
  async editAssessment(
    branchId: number,
    customerId: number,
    assessment: Assessment,
    assessmentId: number
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/customers/${customerId}/assessments/${assessmentId}`,
        { assessment }
      )
      if (res.status === 200) {
        return res.data.assessment
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteAssessment(
    branchId: number,
    customerId: number,
    assessmentId: number
  ) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/customers/${customerId}/assessments/${assessmentId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchAssessmentImages(branchId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/karte_assessment_images`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async addAssessmentImages(
    branchId: number,
    assessmentImagesIds: AssessmentImageIds
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/karte_assessment_images`,
        {
          karte_assessment_image: { image_ids: assessmentImagesIds },
        }
      )
      if (res.status === 200) {
        return res.data.karte_assessment_images
      }
    } catch (error) {
      return processError(error, $sentry)
    }
  },
})
