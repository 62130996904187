import { MutationTree, ActionTree } from 'vuex'
import { Repositories, CustomerTag } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  customerTags: [] as CustomerTag[],
  selectedCustomerTagType: 'is',
  crmCustomerTags: [] as CustomerTag[],
})

export type CustomerTagState = ReturnType<typeof state>

export const mutations: MutationTree<CustomerTagState> = {
  setCustomerTags(state, customerTags: CustomerTag[]) {
    state.customerTags = customerTags
  },
  setSelectedCustomerTagType(state, selectedCustomerTagType: string) {
    state.selectedCustomerTagType = selectedCustomerTagType
  },
  addNewCustomerTag(state, customerTagData: CustomerTag) {
    state.customerTags.unshift(customerTagData)
  },
  updateCustomerTag(state, customerTagData: CustomerTag) {
    const index = state.customerTags.findIndex(
      (item) => item.tag_id === customerTagData.tag_id
    )
    if (index !== -1) state.customerTags.splice(index, 1, customerTagData)
  },
  deleteCustomerTag(state, id: number) {
    const index = state.customerTags.findIndex((item) => item.tag_id === id)
    if (index !== -1) state.customerTags.splice(index, 1)
  },
  setCrmCustomerTags(state, crmCustomerTags: CustomerTag[]) {
    state.crmCustomerTags = crmCustomerTags
  },
}

export const actions: ActionTree<CustomerTagState, RootState> = {
  async fetchCustomerTags(
    { commit, rootState },
    params: {
      api: Repositories
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { customer_tags } =
      await params.api.customerTagRepo.fetchCustomerTags(branchId)
    commit('setCustomerTags', customer_tags)
  },
  async createCustomerTag(
    { commit, rootState },
    params: { api: Repositories; customerTagData: CustomerTag }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const customer_tag = await params.api.customerTagRepo.createCustomerTag(
      branchId,
      params.customerTagData
    )
    commit('addNewCustomerTag', customer_tag)
  },
  async updateCustomerTag(
    { commit, rootState },
    params: { api: Repositories; customerTagData: CustomerTag }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const customer_tag = (await params.api.customerTagRepo.updateCustomerTag(
      branchId,
      params.customerTagData
    )) as CustomerTag

    commit('updateCustomerTag', customer_tag)
  },
  async deleteCustomerTag(
    { commit, rootState },
    params: { api: Repositories; customerTagId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    await params.api.customerTagRepo.deleteCustomerTag(
      branchId,
      params.customerTagId
    )
    commit('deleteCustomerTag', params.customerTagId)
  },
  async fetchCrmCustomerTags(
    { commit },
    params: {
      api: Repositories
      branchIds: number[]
    }
  ) {
    const { customer_tags } =
      await params.api.customerTagRepo.fetchCrmCustomerTags({
        branch_ids: params.branchIds,
      })
    commit('setCrmCustomerTags', customer_tags)
  },
}
