import { MutationTree } from 'vuex'
import { Guides } from '~/types/demo'

const GUIDES = {
  dashboard: {
    features: [
      {
        id: 'analytics-dashboard',
        title: 'Analytics',
        subtitle: 'Get analytics for the selected time period.',
        imagePosition: 'bottom-left-arrow',
      },
      {
        id: 'datepicker',
        title: 'Time period',
        subtitle:
          'you can switch between daily, monthly, yearly and custom view.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'download-button',
        title: 'Download',
        subtitle: 'Download report. It gives you details of sales. ',
        imagePosition: 'top-arrow',
      },
      {
        id: 'reservation-list',
        title: 'Reservation List',
        subtitle:
          'Get list of all the reservation. you can filer those for easy management.',
        imagePosition: 'bottom-arrow',
      },
    ],
    title: 'Home',
    description:
      'Home is a branch control centre. It gives summary of branch’s daily/weekly/monthly/custom time activities. View all the analytics and reservations.',
  },
  reservations: {
    features: [
      {
        id: 'tab-view',
        title: 'Change View',
        subtitle: 'Click on the tabs to change therapist view',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'search',
        title: 'Search',
        subtitle: 'Search reservations using customer name',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'calendar-collapse-button',
        title: 'Therapist/Bed',
        subtitle: 'Click on the slots under therapist/bed to make reservation.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'datepicker',
        title: 'Date',
        subtitle: 'Change date. Shift to day or week view',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'reservation-status',
        title: 'Reservation Status',
        subtitle:
          'View reservation count based on status. Select reservations that you want to view in calendar.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'therapist-list',
        title: 'Therapist List',
        subtitle:
          'View therapist day count. Select therapist to add them to calendar',
        imagePosition: 'top-arrow',
      },
    ],
    title: 'Reservation Management',
    description:
      "Threes Pro's reservation system is a system specialized for osteopathic clinics and chiropractic clinics. It is possible to handle complicated reservations such as management by person in charge and bed, and more efficient store management will be possible.",
  },
  accounting: {
    features: [
      {
        id: 'cash-register',
        title: 'Cash Register',
        subtitle:
          'No need to manually track cash transactions, Just put opening and closing amounts. Threease will do all the calcualtions and inform you in case of any descrepency.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'calculated-amounts',
        title: 'Calculated Amounts',
        subtitle:
          'Threease will automatically make all the required calculations.',
        imagePosition: 'left-arrow',
      },
      {
        id: 'transaction-list',
        title: 'Transaction List',
        subtitle: 'Get list of all the transactions for easy tracking,',
        imagePosition: 'top-arrow',
      },
    ],
    title: 'Accounting',
    description:
      'The POS function of Threes Pro automatically reflects information in CRM in real time, eliminating the need for cash register closing and cash register adjustment after business closing, eliminating unnecessary overtime, realizing efficient store management, and management. It will be easier.',
  },
  shifts: {
    features: [
      {
        id: 'therapist-slots',
        title: 'Therapist/Bed',
        subtitle: 'Click on the slots under therapist/bed to make reservation.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'reservation-status',
        title: 'Reservation Status',
        subtitle:
          'View reservation count based on status. Select reservations that you want to view in calendar.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'date',
        title: 'Date',
        subtitle: 'Change date. Shift to day or week view',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'therapist-list',
        title: 'Therapist List',
        subtitle:
          'View therapist day count. Select therapist to add them to calendar',
        imagePosition: 'top-arrow',
      },
    ],
    title: 'Shift Management',
    description:
      "Threes Pro's reservation system is a system specialized for osteopathic clinics and chiropractic clinics. It is possible to handle complicated reservations such as management by person in charge and bed, and more efficient store management will be possible.",
  },
  customers: {
    features: [
      {
        id: 'search',
        title: 'Search',
        subtitle: 'Search reservations using customer name',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'therapist-list',
        title: 'Therapist List',
        subtitle:
          'View therapist day count. Select therapist to add them to calendar',
        imagePosition: 'top-arrow',
      },
      {
        id: 'customer-button',
        title: 'Customer',
        subtitle: 'Click on add customer to send new customer.',
        imagePosition: 'top-arrow',
      },
    ],
    title: 'Customer Management',
    description:
      "Threes Pro's reservation system is a system specialized for osteopathic clinics and chiropractic clinics. It is possible to handle complicated reservations such as management by person in charge and bed, and more efficient store management will be possible.",
  },
  products: {
    features: [
      {
        id: 'product-button',
        title: 'Product',
        subtitle: 'Click on add product to send new product.',
        imagePosition: 'top-arrow',
      },
      {
        id: 'therapist-list',
        title: 'Therapist List',
        subtitle:
          'View therapist day count. Select therapist to add them to calendar',
        imagePosition: 'top-arrow',
      },
    ],
    title: 'Item Management',
    description:
      "Threes Pro's reservation system is a system specialized for osteopathic clinics and chiropractic clinics. It is possible to handle complicated reservations such as management by person in charge and bed, and more efficient store management will be possible.",
  },
  crm: {
    features: [
      {
        id: 'category',
        title: 'Category',
        subtitle: 'Select category to view the analytics.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'datepicker',
        title: 'Time period',
        subtitle:
          'you can switch between daily, monthly, yearly and custom view.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'view',
        title: 'View',
        subtitle: 'Shift between graph and table view for easy analysis.',
        imagePosition: 'top-arrow',
      },
      {
        id: 'analytics',
        title: 'Analytics',
        subtitle: 'Get analytics for the selected.',
        imagePosition: 'top-arrow',
      },
      {
        id: 'drill-down',
        title: 'Drill Down',
        subtitle: 'Click on analytics to get deeper insights.',
        imagePosition: 'top-arrow',
      },
    ],
    title: 'CRM',
    description:
      'Many CRM systems are designed separately for store operation operations and each system, which requires data entry for CRM analysis. With Threes Pro, data can be automatically collected during store operations, enabling real-time data analysis without multiple inputs.',
  },
  message: {
    features: [
      {
        id: 'message-card',
        title: 'Message',
        subtitle:
          'Threease offers free 100o messages. you can track those and view your plan details.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'message-button',
        title: 'Message',
        subtitle: 'Click on add message to send new message.',
        imagePosition: 'top-arrow',
      },
    ],
    title: 'Messages',
    description:
      'Since the Threes Pro is equipped with a DM function, it is easy to contact customers, and in addition to guiding their products and campaigns, it is very useful for building relationships with customers such as sending health information, and improving sales. Will also be connected.',
  },
  clinic_setting: {
    features: [
      {
        id: 'business-timing-table',
        title: 'Business Timing',
        subtitle: 'Set working hours and breaks,',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'reservation-status',
        title: 'Reservation Status',
        subtitle:
          'View reservation count based on status. Select reservations that you want to view in calendar.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'holidays-table',
        title: 'Holidays',
        subtitle: 'Set holidays for your clinc.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'shifts-table',
        title: 'Shifts',
        subtitle: 'Mnanage shifts.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'therapist-list',
        title: 'Therapist List',
        subtitle:
          'View therapist day count. Select therapist to add them to calendar',
        imagePosition: 'top-arrow',
      },
      {
        id: 'web-reservation-qr-code',
        title: 'Web Reservation',
        subtitle: 'Set web availability, therapist and courses.',
        imagePosition: 'bottom-arrow',
      },
      {
        id: 'karte-form-qr-code',
        title: 'Karte Form',
        subtitle: 'Manage karte form settings.',
        imagePosition: 'bottom-arrow',
      },
      {
        id: 'square-settings',
        title: 'Square Setting',
        subtitle: 'Manage square setting form settings.',
        imagePosition: 'bottom-arrow',
      },
    ],
    title: 'My Clinic',
    description:
      'Here you can control all the settings for your clinic. You can set busiess timing, holiday, units, shifts, web reservation -karte etc.',
  },
  hq_home: {
    features: [
      {
        id: 'branch-select',
        title: 'Branch Select',
        subtitle: 'Easily switch to different branches.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'analytics',
        title: 'Analytics',
        subtitle: 'Get overall branch analytics.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'filter',
        title: 'Filter',
        subtitle: 'You can filter analytics based on BRanch and Date.',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'branches-list',
        title: 'Branches',
        subtitle: 'List of all the branches and analytics',
        imagePosition: 'bottom-arrow',
      },
    ],
    title: 'HQ/Home',
    description:
      'In HQ you can control all your HQ settings. Control branches, staff and products.',
  },
  hq_branches: {
    features: [
      {
        id: 'therapist-list',
        title: 'Therapist List',
        subtitle:
          'View therapist day count. Select therapist to add them to calendar',
        imagePosition: 'top-arrow',
      },
    ],
    title: 'Branches',
    description:
      'View list of all the branches. Update branch information easily,',
  },
  hq_staff: {
    features: [
      {
        id: 'tab-view',
        title: 'Staff/Roles',
        subtitle: 'Switch between staff and roles. ',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'search',
        title: 'Search',
        subtitle: 'Search staff easily',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'branch-display-item',
        title: 'Branch Display',
        subtitle: 'Filter staff based on branches.',
        imagePosition: 'top-left-arrow',
      },
    ],
    title: 'Staff',
    description:
      'view list of all the staff . updaate staff information. Allocate branches and product to the staff.',
  },
  hq_products: {
    features: [
      {
        id: 'tab-view',
        title: 'Change View',
        subtitle:
          'Change view between product category, products, tickets and coupons.',
        imagePosition: 'top-left-arrow',
      },
    ],
    title: 'Products',
    description:
      'Shift tabs between product category, products, tickets and coupons. you can create, update and delete products. Branches and staff can be allocated to the products.',
  },
  hq_crm_staff: {
    features: [
      {
        id: 'staff-list',
        title: 'Staff List',
        subtitle: 'Get list of all the staff and get ranking.',
        imagePosition: 'left-arrow',
      },
      {
        id: 'staff-performance',
        title: 'Staff Performance',
        subtitle: 'Get performance for individual staff. Get all the analytics',
        imagePosition: 'top-left-arrow',
      },
      {
        id: 'branch-ranking',
        title: 'Branch Ranking',
        subtitle:
          'Easily compare different branches on different metrics namely sales, unit sales, active customers inactive customers.',
        imagePosition: 'top-arrow',
      },
      {
        id: 'filter',
        title: 'Filters',
        subtitle:
          'You can select certain branches and time period to see the analytics.',
        imagePosition: 'top-left-arrow',
      },
    ],
    title: 'CRM',
    description:
      'Many CRM systems are designed separately for store operation operations and each system, which requires data entry for CRM analysis. With Threes Pro, data can be automatically collected during store operations, enabling real-time data analysis without multiple inputs.',
  },
}

export const state = () => ({
  showGuide: false,
  guides: [] as Guides[],
})

export type demoState = ReturnType<typeof state>

export const mutations: MutationTree<demoState> = {
  setShowGuide(state, val) {
    state.showGuide = val
  },
  setGuides(state, val) {
    const value = val.replace(/-/g, '_')
    state.guides = GUIDES[value] || []
  },
}
