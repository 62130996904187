import { ValidationObserver } from 'vee-validate'

export function preventNegativeValue(val: number | undefined): any {
  if (val) {
    return Math.abs(val)
  }
}

export function scrollToErrorElement(context: any) {
  // validate observer
  const observer = context.$refs.observer as InstanceType<
    typeof ValidationObserver
  >
  setTimeout(() => {
    const errors = Object.entries(observer.errors)
      .map(([key, value]) => ({ key, value }))
      .filter((error) => error.value.length)
    // scroll to element
    observer.refs[errors[0].key].$el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, 60)
}

export function validateForm(context: any, submitForm: any) {
  // validate observer
  const observer = context.$refs.observer as InstanceType<
    typeof ValidationObserver
  >
  observer.validate().then((isValid: boolean) => {
    if (isValid) {
      submitForm()
    } else {
      scrollToErrorElement(context)
    }
  })
}
