import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { Role, RoleRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): RoleRepo => ({
  async fetchRoles() {
    try {
      const res = await $axios.get('therapists/roles', {
        params: { page: 1, per: 80 },
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchRoleWithId(id: number) {
    try {
      const res = await $axios.get(`therapists/roles/${id}`)
      if (res.status === 200) {
        return res.data.role
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createRole(roleData: Role) {
    try {
      const res = await $axios.post('therapists/roles', {
        role: roleData,
      })
      if (res.status === 201) {
        return res.data.role
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateRole(roleData: Role) {
    try {
      const res = await $axios.put(`therapists/roles/${roleData.id}`, {
        role: roleData,
      })
      if (res.status === 200) {
        return res.data.role
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
