import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { KarteTemplatesParams, KarteTemplatesRepo } from '~/types'
import processError from '~/helpers/processError'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): KarteTemplatesRepo => ({
  async fetchKarteTemplates(params: {
    category_ids?: number[]
    branch_ids?: number[]
  }) {
    try {
      const res = await $axios.get(`/therapists/karte/templates`, { params })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async postKarteTemplate(template: KarteTemplatesParams) {
    try {
      const res = await $axios.post(`/therapists/karte/templates`, {
        template,
      })
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchKarteTemplate(id: number) {
    try {
      const res = await $axios.get(`/therapists/karte/templates/${id}`)
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async putKarteTemplate(template: KarteTemplatesParams) {
    try {
      const res = await $axios.put(
        `/therapists/karte/templates/${template.id}`,
        {
          template,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async deleteKarteTemplate(id: number) {
    try {
      await $axios.delete(`/therapists/karte/templates/${id}`)
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
