import { MutationTree, ActionTree } from 'vuex'
import fileDownload from 'js-file-download'
import axios from 'axios'
import {
  Download,
  Repositories,
  Pagination,
  CrmCommonApiQueryParams,
  CrmCustomerApiQueryParams,
  TicketPackQueryParams,
} from '~/types'
import { RootState } from '~/store'
import { transformCustomerDownloadFilters } from '~/helpers/customerUtils'

const REPORT_DOWNLOAD_ID = 'reportDownloadId'

export const state = () => ({
  downloads: [] as Download[],
  downloadPagination: {} as Pagination,

  currentDownload: {} as Download,
  snackbarContent: { display: false, downloadStatus: '' },

  reportDownloadId: -1 as number,
})

export type DownloadState = ReturnType<typeof state>

export const mutations: MutationTree<DownloadState> = {
  setDownloads(state, downloads: Download[]) {
    state.downloads = downloads
  },
  setDownloadPagination(state, pagination: Pagination) {
    state.downloadPagination = pagination
  },
  setCurrentDownload(state, currentDownload: Download) {
    state.currentDownload = currentDownload
  },
  setReportDownloadId(state, downloadId: number) {
    state.reportDownloadId = downloadId
    localStorage.setItem(
      REPORT_DOWNLOAD_ID,
      JSON.stringify(state.reportDownloadId)
    )
  },
  setSnackbarContent(
    state,
    content: { display: boolean; downloadStatus: string }
  ) {
    state.snackbarContent = content
  },
  resetStore(state) {
    state.reportDownloadId = -1 as number
    state.currentDownload = {} as Download
    state.downloads = [] as Download[]
    state.downloadPagination = {} as Pagination
    state.snackbarContent = { display: false, downloadStatus: '' }
  },
}

export const actions: ActionTree<DownloadState, RootState> = {
  async fetchDownloads(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
    }
  ) {
    const { downloads, pagination } =
      await params.api.downloadRepo.fetchDownloads()
    commit('setDownloads', downloads)
    commit('setDownloadPagination', pagination)
  },
  async fetchCurrentDownload(
    { commit, state },
    params: {
      api: Repositories
    }
  ) {
    const download = await params.api.downloadRepo.fetchDownloadWithId(
      state.reportDownloadId
    )
    commit('setCurrentDownload', download)
  },

  async exportClinicReport(
    { commit, dispatch },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      branch_option?: 'all' | 'custom'
      branch_ids?: number[]
    }
  ) {
    const { report } = await params.api.analyticsRepo.exportClinicReport({
      start_date: params.start_date,
      end_date: params.end_date,
      branch_option: params.branch_option ?? 'all',
      branch_ids: params.branch_ids ?? [],
    })
    commit('setReportDownloadId', report.download_id)

    dispatch(
      'pollDownload',
      params.start_date === params.end_date
        ? `${params.start_date}_report.xlsx`
        : `${params.start_date}-${params.end_date}_report.xlsx`
    )
  },
  restoreDownloadIds({ commit, dispatch }) {
    const reportDownloadId = localStorage.getItem(REPORT_DOWNLOAD_ID)
    if (reportDownloadId) {
      commit('setReportDownloadId', Number(reportDownloadId))
      if (Number(reportDownloadId) !== -1) dispatch('pollDownload')
    }
  },
  async exportCustomerReport(
    { commit, dispatch, rootState },
    params: {
      api: Repositories
      export_attributes?: string[]
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const filters = (rootState as any).customer.filters
    const tranformFilters = filters
      ? transformCustomerDownloadFilters(filters)
      : undefined
    tranformFilters.name = (rootState as any).customer.name
    tranformFilters.tag_ids = (rootState as any).customer.tag_ids
    const { report } = await params.api.analyticsRepo.exportCustomerReport({
      branch_option: 'custom',
      branch_ids: [branchId],
      export_attributes: params.export_attributes,
      filters: tranformFilters || undefined,
    })
    commit('setReportDownloadId', report.download_id)

    dispatch('pollDownload', 'customer_report.xlsx')
  },
  async exportKarteResponses(
    { commit, dispatch },
    params: {
      api: Repositories
      customer_ids?: number[]
      start_date?: string
      branchId: number
      end_date?: string
      form_id?: number
    }
  ) {
    const res = await params?.api.karteFormResponseRepo.exportKarteResponses({
      branchId: params.branchId,
      customer_ids: params.customer_ids,
      start_date: params.start_date,
      end_date: params.end_date,
      form_id: params.form_id,
    })
    commit('setReportDownloadId', res.export.download_id)
    dispatch(
      'pollDownload',
      params.start_date && params.end_date
        ? params?.start_date === params?.end_date
          ? `${params?.start_date}_report.xlsx`
          : `${params?.start_date}-${params?.end_date}_report.xlsx`
        : 'karte_report.xlsx'
    )
  },

  async exportCrmCustomerReport(
    { commit, dispatch },
    params: {
      api: Repositories
      filters: CrmCommonApiQueryParams & CrmCustomerApiQueryParams
    }
  ) {
    const res = await params.api.crmAnalyticsRepo.exportCrmCustomerReport(
      params.filters
    )
    commit('setReportDownloadId', res.export.download_id)

    dispatch(
      'pollDownload',
      params.filters.start_date === params.filters.end_date
        ? `${params.filters.start_date}_report.xlsx`
        : `${params.filters.start_date}-${params.filters.end_date}_report.xlsx`
    )
  },

  async exportProductReport(
    { commit, dispatch },
    params: {
      api: Repositories
      branch_ids: number[]
      staff_ids: number[]
      start_date: string
      end_date: string
    }
  ) {
    const res = await params.api.crmAnalyticsRepo.exportProductReport({
      branch_ids: params.branch_ids,
      staff_ids: params.staff_ids,
      start_date: params.start_date,
      end_date: params.end_date,
    })
    commit('setReportDownloadId', res.export.download_id)

    dispatch(
      'pollDownload',
      params.start_date === params.end_date
        ? `${params.start_date}_report.xlsx`
        : `${params.start_date}-${params.end_date}_report.xlsx`
    )
  },

  async exportTicketPacksHistory(
    { commit, dispatch },
    params: {
      api: Repositories
      filters: TicketPackQueryParams
    }
  ) {
    const res = await params.api.analyticsRepo.exportTicketPacksHistory(
      params.filters
    )
    commit('setReportDownloadId', res.report.download_id)

    dispatch('pollDownload', 'tickets_history.xlsx')
  },

  async exportTicketPacksPerformanceReport(
    { commit, dispatch },
    params: {
      api: Repositories
      filters: any
    }
  ) {
    const res =
      await params.api.analyticsRepo.exportTicketPacksPerformanceReport(
        params.filters
      )
    commit('setReportDownloadId', res.report.download_id)

    dispatch('pollDownload', 'tickets_performance_report.xlsx')
  },
  async exportTicketPacksAnnualReport(
    { commit, dispatch },
    params: {
      api: Repositories
    }
  ) {
    const res = await params.api.analyticsRepo.exportTicketPacksAnnualReport()
    commit('setReportDownloadId', res.report.download_id)

    dispatch('pollDownload', 'tickets_annual_report.xlsx')
  },

  pollDownload({ state, commit, dispatch }, reportName: string) {
    // Download Progress

    commit('setSnackbarContent', {
      display: true,
      downloadStatus: 'in_progress',
    })
    const interval = setInterval(async () => {
      if (state.reportDownloadId === -1) {
        clearInterval(interval)
      } else {
        try {
          await dispatch('fetchCurrentDownload', {
            api: (this as any).$api,
          })
        } catch (error) {
          commit('setSnackbarContent', {
            display: true,
            downloadStatus: 'failed',
          })
          clearInterval(interval)
          setTimeout(() => {
            commit('setSnackbarContent', { display: false, downloadStatus: '' })

            commit('setReportDownloadId', -1)
          }, 10000)
        }
        switch (state.currentDownload.status) {
          case 'in_progress':
            break
          case 'completed':
            commit('setSnackbarContent', {
              display: true,
              downloadStatus: 'completed',
            })
            clearInterval(interval)
            if (state.currentDownload.file_url) {
              try {
                const res = await axios.get(state.currentDownload.file_url, {
                  responseType: 'blob',
                })

                fileDownload(res.data, reportName)
              } catch (error) {
                commit('setSnackbarContent', {
                  display: true,
                  downloadStatus: 'failed',
                })
                setTimeout(() => {
                  commit('setSnackbarContent', {
                    display: false,
                    downloadStatus: '',
                  })
                  commit('setReportDownloadId', -1)
                }, 5000)
              }
            }
            setTimeout(() => {
              commit('setSnackbarContent', {
                display: false,
                downloadStatus: '',
              })
              commit('setReportDownloadId', -1)
            }, 5000)
            break
          case 'failed':
            commit('setSnackbarContent', {
              display: true,
              downloadStatus: 'failed',
            })
            clearInterval(interval)
            setTimeout(() => {
              commit('setSnackbarContent', {
                display: false,
                downloadStatus: '',
              })
              commit('setReportDownloadId', -1)
            }, 5000)
            break
          default:
            commit('setSnackbarContent', {
              display: false,
              downloadStatus: '',
            })
            commit('setReportDownloadId', -1)
            clearInterval(interval)
            break
        }
      }
    }, 2000)
  },
}
