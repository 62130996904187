import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { InvoiceRepo, Transaction } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): InvoiceRepo => ({
  async fetchInvoices(
    branchId: number,
    params?: {
      page?: number
      per?: number
      reservation_id?: number
    }
  ) {
    try {
      const res = await $axios.get(`therapists/branches/${branchId}/invoices`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async showInvoice(branchId: number, invoiceId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/invoices/${invoiceId}`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async showReservationInvoice(branchId: number, reservationId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/reservations/${reservationId}/invoice`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchTransactions(
    branchId: number,
    invoiceId: number,
    params?: {
      page?: number
      per?: number
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/invoices/${invoiceId}/transactions`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createTransaction(
    branchId: number,
    invoiceId: number,
    transactionData: Transaction
  ) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/invoices/${invoiceId}/transactions`,
        {
          transaction: transactionData,
        }
      )
      if (res.status === 201) {
        return res.data.transaction
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async showTransaction(
    branchId: number,
    invoiceId: number,
    transactionId: number
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/invoices/${invoiceId}/transactions/${transactionId}`
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateTransaction(branchId: number, transaction: Transaction) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/transactions/${transaction.id}`,
        {
          transaction,
        }
      )
      if (res.status === 200) {
        return res.data.transaction
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateInvoice(
    branchId: number,
    invoiceId: number,
    reservationIds: number[]
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/invoices/${invoiceId}`,
        {
          invoice: {
            reservation_ids: reservationIds,
          },
        }
      )
      if (res.status === 200) {
        return res.data.invoice
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
