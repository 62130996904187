import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { CMS, CMSAuthorRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): CMSAuthorRepo => ({
  async fetchAuthors(
    branchId: number,
    params?: {
      page?: number
      per?: number
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/cms/authors`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createAuthor(branchId: number, authorData: CMS.Author) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/cms/authors`,
        { author: authorData }
      )
      if (res.status === 201) {
        return res.data.author
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async fetchAuthorWithId(branchId: number, authorId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/cms/authors/${authorId}`
      )
      if (res.status === 200) {
        return res.data.author
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateAuthor(branchId: number, authorData: CMS.Author) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/cms/authors/${authorData.id}`,
        { author: authorData }
      )
      if (res.status === 200) {
        return res.data.author
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteAuthor(branchId: number, authorId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/cms/authors/${authorId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
