import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { KarteFormResourceRepo } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): KarteFormResourceRepo => ({
  async fetchKarteFormResource(
    branchId: number,
    params: {
      page: number
      per: number
      search?: string
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/karte/forms`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchKarteFormResourceById(
    branchId: number,
    formId: number,
    karte_access_token?: string,
    branch_access_token?: string
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/karte/forms/${formId}`,
        {
          params: {
            karte_access_token,
            branch_access_token,
          },
        }
      )
      if (res.status === 200) {
        return res.data.form
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createKarteFormResource(
    branchId: number,
    params: {
      title: string
      products_ids: number[]
      header_type: 'basic' | 'custom'
      form_type: 'default' | 'other'
    }
  ) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/karte/forms`,
        {
          form: params,
        }
      )
      if (res.status === 201) {
        return res.data.form
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateKarteFormResource(
    branchId: number,
    formId: number,
    params: {
      title: string
      form_background_color: string
      header_background_color: string
      header_type: string
      products_ids: number[]
    }
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/karte/forms/${formId}`,
        {
          form: params,
        }
      )
      if (res.status === 200) {
        return res.data.form
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteFormResource(branchId: number, formId: number) {
    try {
      const res = await $axios.delete(
        `therapists/branches/${branchId}/karte/forms/${formId}`
      )
      if (res.status === 204) {
        return
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
