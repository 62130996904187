































































import Vue from 'vue'

const LAST_CHANGELOG_DATE = 'lastChangelogDate'
const CHANGELOG_URL =
  'https://threesides.notion.site/145fffd4151a4eeb9bc26f77586b4bdd'
export default Vue.extend({
  data() {
    return {
      showChangelogModal: false,
      changelog: {} as { date?: string; title?: string; image?: string },
    }
  },
  created() {
    if (Object.keys(this.changelog).length > 0) {
      const lastChangelogDate = localStorage.getItem(LAST_CHANGELOG_DATE)
      if (lastChangelogDate) {
        if (
          !this.$dayjs(this.changelog.date).isSame(
            this.$dayjs(lastChangelogDate)
          )
        ) {
          this.showChangelogModal = true
          localStorage.setItem(
            LAST_CHANGELOG_DATE,
            this.changelog.date ? this.changelog.date.toString() : ''
          )
        }
      } else {
        this.showChangelogModal = true
        localStorage.setItem(
          LAST_CHANGELOG_DATE,
          this.changelog.date ? this.changelog.date.toString() : ''
        )
      }
    }
  },
  methods: {
    openUrl() {
      window.open(CHANGELOG_URL, '_blank')
    },
  },
})
