import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { KarteSymptomArea, KarteSymptomAreaRepo } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): KarteSymptomAreaRepo => ({
  async fetchKarteSymptomAreas(
    branchId: number,
    params: {
      customer_id: number
      page?: number
      per?: number
      assessment_image_ids: number[]
      assessment_id: number
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/karte_symptom_areas`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createKarteSymptomArea(
    branchId: number,
    symptomArea: KarteSymptomArea
  ) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/karte_symptom_areas`,
        {
          karte_symptom_area: symptomArea,
        }
      )
      if (res.status === 201) {
        return res.data.karte_symptom_area
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateKarteSymptomArea(
    branchId: number,
    symptomArea: KarteSymptomArea
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/karte_symptom_areas/${symptomArea.id}`,
        {
          karte_symptom_area: symptomArea,
        }
      )
      if (res.status === 200) {
        return res.data.karte_symptom_area
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteKarteSymptomArea(branchId: number, symptomAreaId: number) {
    try {
      const res = await $axios.delete(
        `therapists/branches/${branchId}/karte_symptom_areas/${symptomAreaId}`
      )
      if (res.status === 200) {
        return res.data.karte_symptom_area
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
