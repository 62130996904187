import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { KarteFormResponseRepo } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): KarteFormResponseRepo => ({
  async fetchKarteResponses(
    branchId: number,
    customerId: number,
    params: {
      entry_id?: number
      section_id?: number
      page?: number
      per?: number
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customers/${customerId}/karte/responses`,
        { params }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchKarteQuestionnaireToken(
    branchId: number,
    params: { formId: number }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/karte_questionnaire_token`,
        { params }
      )
      if (res.status === 200) {
        return res.data.branch_token
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createResponse(
    branchId: number,
    customerId: number,
    params: {
      entry_id?: number
      question_id?: number
      option_values?: string[]
      answer?: string
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/customers/${customerId}/karte/responses`,
        params
      )
      if (res.status === 201) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateResponse(
    branchId: number,
    customerId: number,
    responseId: number,
    params: {
      entry_id?: number
      question_id?: number
      option_values?: string[]
      answer?: string
      remarks?: string
      karte_access_token?: string
      branch_access_token?: string
    }
  ) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/customers/${customerId}/karte/responses/${responseId}`,
        params
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteResponse(
    branchId: number,
    customerId: number,
    responseId: number
  ) {
    try {
      const res = await $axios.delete(
        `therapists/branches/${branchId}/customers/${customerId}/karte/responses/${responseId}`
      )
      if (res.status === 204) {
        return res.data
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async exportKarteResponses(params?: {
    branchId: number
    customer_ids?: number[]
    start_date?: string
    end_date?: string
    form_id?: number
  }) {
    try {
      const res = await $axios.post(
        `karte/branches/${params?.branchId}/responses/export`,
        {
          export: {
            form_id: params?.form_id,
            customer_ids: params?.customer_ids,
            start_date: params?.start_date,
            end_date: params?.end_date,
          },
        }
      )
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
