import { MutationTree, ActionTree } from 'vuex'
import { CMS, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  categories: [] as CMS.Category[],
  categoryPagination: {} as Pagination,
  loadingList: {
    isCategoriesLoading: false,
  },
})

export type CMSCategoryState = ReturnType<typeof state>

export const mutations: MutationTree<CMSCategoryState> = {
  setCategories(state, categories: CMS.Category[]) {
    state.categories = categories
  },
  setCategoryPagination(state, pagination: Pagination) {
    state.categoryPagination = pagination
  },
  addCategory(state, categoryData: CMS.Category) {
    state.categories.unshift(categoryData)
  },
  updateCategory(state, categoryData: CMS.Category) {
    const index = state.categories.findIndex(
      (item) => item.id === categoryData.id
    )
    if (index !== -1) state.categories.splice(index, 1, categoryData)
  },
  deleteCategory(state, id: number) {
    const index = state.categories.findIndex((item) => item.id === id)
    if (index !== -1) state.categories.splice(index, 1)
  },
  updateCategoryPagination(state) {
    state.categoryPagination.total_entries += 1
    state.categoryPagination.total_pages = Math.ceil(
      state.categoryPagination.total_entries / 10
    )
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<CMSCategoryState, RootState> = {
  async fetchCategories(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
    }
  ) {
    commit('setLoading', { key: 'isCategoriesLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { categories, pagination } =
      (await params.api.cmsCategoryRepo.fetchCategories(branchId, {
        page: params.page,
        per: params.per,
      })) as { categories: CMS.Category[]; pagination: Pagination }
    commit('setCategories', categories)
    commit('setCategoryPagination', pagination)
    commit('setLoading', { key: 'isCategoriesLoading', loading: false })
  },
  async createCategory(
    { commit, rootState },
    params: { api: Repositories; categoryData: CMS.Category }
  ) {
    commit('setLoading', { key: 'isCategoriesLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const categoryData = await params.api.cmsCategoryRepo.createCategory(
      branchId,
      params.categoryData
    )
    commit('addCategory', categoryData)
    commit('updateCategoryPagination')
    commit('setLoading', { key: 'isCategoriesLoading', loading: false })
  },
  async getCategoryWithId(
    { commit, rootState, state },
    params: { api: Repositories; categoryId: number }
  ) {
    commit('setLoading', { key: 'isCategoriesLoading', loading: true })
    const categoryIndex = state.categories.findIndex(
      (category: CMS.Category) => category.id === params.categoryId
    )
    if (categoryIndex === -1) {
      const branchId = (rootState as any).auth.user.activeBranch
      const category = await params.api.cmsCategoryRepo.fetchCategoryWithId(
        branchId,
        params.categoryId
      )
      commit('addCategory', category)
      commit('updateCategoryPagination')
      commit('setLoading', { key: 'isCategoriesLoading', loading: false })
    }
  },
  async updateCategory(
    { commit, rootState },
    params: { api: Repositories; categoryData: CMS.Category }
  ) {
    commit('setLoading', { key: 'isCategoriesLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const category = await params.api.cmsCategoryRepo.updateCategory(
      branchId,
      params.categoryData
    )
    commit('updateCategory', category)
    commit('setLoading', { key: 'isCategoriesLoading', loading: false })
  },
  async deleteCategory(
    { commit, rootState },
    params: { api: Repositories; categoryId: number }
  ) {
    commit('setLoading', { key: 'isCategoriesLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api.cmsCategoryRepo.deleteCategory(branchId, params.categoryId)
    commit('deleteCategory', params.categoryId)
    commit('setLoading', { key: 'isCategoriesLoading', loading: false })
  },
}
