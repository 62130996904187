import { MutationTree, ActionTree } from 'vuex'
import {
  BusinessTime,
  CustomBusinessTime,
  Pagination,
  Repositories,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  businessTimes: [
    { day: 'monday', status: 'with_break' },
    { day: 'tuesday', status: 'with_break' },
    { day: 'wednesday', status: 'with_break' },
    { day: 'thursday', status: 'with_break' },
    { day: 'friday', status: 'with_break' },
    { day: 'saturday', status: 'with_break' },
    { day: 'sunday', status: 'with_break' },
    { day: 'holiday', status: 'with_break' },
  ] as BusinessTime[],
  isEmptyBusinessTimes: false,
  // custom businessTimes
  customBusinessTimes: [] as CustomBusinessTime[],
  customBusinessTimesPagination: {} as Pagination,
  customCalenderBusinessTimes: [] as CustomBusinessTime[],
  selectBusinessTime: {} as CustomBusinessTime,
})

export type BusinessTimesState = ReturnType<typeof state>

export const mutations: MutationTree<BusinessTimesState> = {
  setBusinessTimes(state, businessTimes: BusinessTime[]) {
    const updatedBusinessTimes = [] as BusinessTime[]
    state.businessTimes.forEach((businessTime) => {
      updatedBusinessTimes.push(
        businessTimes.find((val) => val.day === businessTime.day) ??
        businessTime
      )
    })
    state.businessTimes = updatedBusinessTimes
  },
  setIsEmptyBusinessTimes(state, payload: boolean) {
    state.isEmptyBusinessTimes = payload
  },

  // custom businessTimes
  setCustomBusinessTimes(state, times: CustomBusinessTime[]) {
    state.customBusinessTimes = times
  },
  setCustomBusinessTimesPagination(state, pagination: Pagination) {
    state.customBusinessTimesPagination = pagination
  },
  setCustomCalenderBusinessTimes(state, times: CustomBusinessTime[]) {
    state.customCalenderBusinessTimes = times
  },
  setSelectBusinessTime(state, time: CustomBusinessTime) {
    state.selectBusinessTime = time
  },
  addCustomBusinessTimes(state, time: CustomBusinessTime) {
    state.customBusinessTimes.unshift(time)
  },
  updateCustomBusinessTimes(state, time: CustomBusinessTime) {
    state.customBusinessTimes = state.customBusinessTimes.map((item) =>
      item.id === time.id ? time : item
    )
  },
  deleteCustomBusinessTimes(state, id: number) {
    state.customBusinessTimes = state.customBusinessTimes.filter(
      (item) => item.id !== id
    )
  },
}

export const actions: ActionTree<BusinessTimesState, RootState> = {
  async fetchBusinessTimes(
    { commit, rootState },
    params: { api: Repositories }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch

    const businessTimes: BusinessTime[] =
      await params.api.businessTimesRepo.fetchBusinessTimes(branchId)
    commit('setBusinessTimes', businessTimes)
    if (businessTimes.length > 0) {
      commit('setIsEmptyBusinessTimes', false)
    } else {
      commit('setIsEmptyBusinessTimes', true)
    }
  },

  async updateBusinessTimes(
    { commit, rootState },
    params: { api: Repositories; businessTimesData: BusinessTime[] }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const businessTimes =
      await params.api.businessTimesRepo.updateBusinessTimes(
        branchId,
        params.businessTimesData
      )
    commit('setBusinessTimes', businessTimes)
  },

  // custom businessTimes
  async fetchCustomBusinessTimes(
    { commit, rootState },
    params: {
      api: Repositories
      start_date?: string
      end_date?: string
      page: number
      per: number
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { custom_business_times, pagination } =
      await params.api.businessTimesRepo.fetchCustomBusinessTimes(branchId, {
        start_date: params?.start_date,
        end_date: params?.end_date,
        page: params.page,
        per: params.per,
      })
    commit('setCustomBusinessTimesPagination', pagination)
    commit('setCustomBusinessTimes', custom_business_times)
  },
  async fetchNextPageCustomBusinessTimes(
    { commit, rootState },
    params: { api: Repositories; page: number; per: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const currentState = (rootState as any).businessTimes.customBusinessTimes
    const { custom_business_times, pagination } =
      await params.api.businessTimesRepo.fetchCustomBusinessTimes(branchId, {
        page: params.page,
        per: params.per,
      })
    commit('setCustomBusinessTimesPagination', pagination)
    commit(
      'setCustomBusinessTimes',
      currentState.concat([...custom_business_times])
    )
  },
  async fetchCustomCalenderBusinessTimes(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      start_date?: string
      end_date?: string
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { custom_business_times } =
      await params.api.businessTimesRepo.fetchCustomBusinessTimes(branchId, {
        page: params.page,
        per: params.per,
        start_date: params?.start_date,
        end_date: params?.end_date,
      })
    commit('setCustomCalenderBusinessTimes', custom_business_times)
  },
  async fetchCustomBusinessTime(
    { commit, rootState },
    params: { api: Repositories; businessTimeId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { custom_business_time } =
      await params.api.businessTimesRepo.fetchCustomBusinessTime(
        branchId,
        params.businessTimeId
      )
    commit('setSelectBusinessTime', custom_business_time)
  },
  async postCustomBusinessTime(
    { commit, rootState },
    params: { api: Repositories; params: CustomBusinessTime }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { custom_business_time } =
      await params.api.businessTimesRepo.postCustomBusinessTime(
        branchId,
        params.params
      )
    commit('addCustomBusinessTimes', custom_business_time)
  },
  async updateCustomBusinessTime(
    { commit, rootState },
    params: { api: Repositories; params: CustomBusinessTime }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { custom_business_time } =
      await params.api.businessTimesRepo.updateCustomBusinessTime(
        branchId,
        params.params
      )
    commit('updateCustomBusinessTimes', custom_business_time)
  },
  async deleteCustomBusinessTime(
    { commit, rootState },
    params: { api: Repositories; businessTimeId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api.businessTimesRepo.deleteCustomBusinessTime(
      branchId,
      params.businessTimeId
    )
    commit('deleteCustomBusinessTimes', params.businessTimeId)
  },
}
