import { MutationTree, ActionTree } from 'vuex'
import { Pagination, Repositories, Coupon } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  coupons: [] as Coupon[],
  couponPagination: {} as Pagination,
  loadingList: {
    isCouponsLoading: false,
  },
})

export type CouponState = ReturnType<typeof state>

export const mutations: MutationTree<CouponState> = {
  setCoupons(state, coupons: Coupon[]) {
    state.coupons = coupons
  },
  setCouponPagination(state, pagination: Pagination) {
    state.couponPagination = pagination
  },
  addNewCoupon(state, couponData: Coupon) {
    state.coupons.unshift(couponData)
  },
  updateCoupon(state, couponData: Coupon) {
    const index = state.coupons.findIndex((item) => item.id === couponData.id)
    if (index !== -1) state.coupons.splice(index, 1, couponData)
  },
  updatePagination(state) {
    state.couponPagination.total_entries += 1
    state.couponPagination.total_pages = Math.ceil(
      state.couponPagination.total_entries / 10
    )
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<CouponState, RootState> = {
  async fetchCoupons(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
      branchIds: number[]
      start_date: string
      end_date?: string
    }
  ) {
    commit('setLoading', { key: 'isCouponsLoading', loading: true })
    const { coupons, pagination } = await params.api.couponRepo.fetchCoupons({
      page: params.page,
      per: params.per,
      branch_ids: params.branchIds,
      start_date: params.start_date,
      end_date: params.end_date,
    })
    commit('setCoupons', coupons)
    commit('setCouponPagination', pagination)
    commit('setLoading', { key: 'isCouponsLoading', loading: false })
  },
  async createCoupon(
    { commit },
    params: { api: Repositories; couponData: Coupon }
  ) {
    const coupon = await params.api.couponRepo.createCoupon(params.couponData)
    commit('addNewCoupon', coupon)
    commit('updatePagination')
  },
  async updateCoupon(
    { commit },
    params: { api: Repositories; couponData: Coupon }
  ) {
    const coupon = await params.api.couponRepo.updateCoupon(params.couponData)
    commit('updateCoupon', coupon)
  },
}
