import { MutationTree, ActionTree } from 'vuex'
import { Repositories, Pagination, KarteSymptomArea } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  karteSymptomAreas: [] as KarteSymptomArea[],
  karteSymptomAreaPagination: {} as Pagination,
  isExpandFirstColumn: false as Boolean,
  isExpandSecondColumn: false as Boolean,
})

export type KarteSymptomAreaState = ReturnType<typeof state>

export const mutations: MutationTree<KarteSymptomAreaState> = {
  setKarteSymptomAreas(state, symptomAreas: KarteSymptomArea[]) {
    state.karteSymptomAreas = symptomAreas
  },
  setKarteSymptomAreaPagination(state, pagination: Pagination) {
    state.karteSymptomAreaPagination = pagination
  },
  addNewKarteSymptomArea(state, symptomArea: KarteSymptomArea) {
    state.karteSymptomAreas.unshift(symptomArea)
  },
  updateKarteSymptomArea(state, symptomAreaData: KarteSymptomArea) {
    const index = state.karteSymptomAreas.findIndex(
      (symptomArea) => symptomArea.id === symptomAreaData.id
    )
    if (index !== -1) state.karteSymptomAreas.splice(index, 1, symptomAreaData)
  },
  updatePagination(state) {
    state.karteSymptomAreaPagination.total_entries += 1
    state.karteSymptomAreaPagination.total_pages = Math.ceil(
      state.karteSymptomAreaPagination.total_entries / 1
    )
  },
  setExpandFirstColumn(state, isExpandFirstColumn: Boolean) {
    state.isExpandFirstColumn = isExpandFirstColumn
  },
  setExpandSecondColumn(state, isExpandSecondColumn: Boolean) {
    state.isExpandSecondColumn = isExpandSecondColumn
  },
  deleteKarteSymptomAreas(state, id: number) {
    const index = state.karteSymptomAreas.findIndex((item) => item.id === id)
    if (index !== -1) state.karteSymptomAreas.splice(index, 1)
  },
}

export const actions: ActionTree<KarteSymptomAreaState, RootState> = {
  async fetchKarteSymptomAreas(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
      customerId: number
      branchId: number
      assessment_image_ids: number[]
      assessment_id: number
    }
  ) {
    const branchId = params.branchId
      ? params.branchId
      : (rootState as any).auth.user.activeBranch
    const { karte_symptom_areas, pagination } =
      await params.api.karteSymptomAreaRepo.fetchKarteSymptomAreas(branchId, {
        customer_id: params.customerId,
        page: params.page,
        per: params.per,
        assessment_image_ids: params.assessment_image_ids,
        assessment_id: params.assessment_id,
      })
    commit('setKarteSymptomAreas', karte_symptom_areas)
    commit('setKarteSymptomAreaPagination', pagination)
  },
  async createKarteSymptomArea(
    { commit, rootState },
    params: {
      api: Repositories
      symptomAreaData: KarteSymptomArea
      branchId: number
    }
  ) {
    const branchId = params.branchId
      ? params.branchId
      : (rootState as any).auth.user.activeBranch
    const symptomArea =
      await params.api.karteSymptomAreaRepo.createKarteSymptomArea(
        branchId,
        params.symptomAreaData
      )
    commit('addNewKarteSymptomArea', symptomArea)
    commit('updatePagination')
  },

  async updateKarteSymptomArea(
    { commit, rootState },
    params: {
      api: Repositories
      symptomAreaData: KarteSymptomArea
      branchId: number
    }
  ) {
    const branchId = params.branchId
      ? params.branchId
      : (rootState as any).auth.user.activeBranch
    const symptomArea =
      await params.api.karteSymptomAreaRepo.updateKarteSymptomArea(
        branchId,
        params.symptomAreaData
      )
    commit('updateKarteSymptomArea', symptomArea)
  },
  async deleteKarteSymptomArea(
    { commit, rootState },
    params: { api: Repositories; symptomAreaId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api.karteSymptomAreaRepo.deleteKarteSymptomArea(
      branchId,
      params.symptomAreaId
    )
    commit('deleteKarteSymptomAreas', params.symptomAreaId)
  },
}
