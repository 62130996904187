import { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  KarteSymptomCategoriesRepo,
  KarteSymptomCategoriesParams,
} from '~/types'
import processError from '~/helpers/processError'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): KarteSymptomCategoriesRepo => ({
  async fetchKarteSymptomCategories(params: KarteSymptomCategoriesParams) {
    try {
      const res = await $axios.get(`/therapists/karte/symptom_categories`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async postKarteSymptomCategory(
    symptom_category: KarteSymptomCategoriesParams
  ) {
    try {
      const res = await $axios.post(`/therapists/karte/symptom_categories`, {
        ...symptom_category,
      })
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchKarteSymptomCategory(id: number) {
    try {
      const res = await $axios.get(`/therapists/karte/symptom_categories/${id}`)
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async putKarteSymptomCategory(params: KarteSymptomCategoriesParams) {
    try {
      const res = await $axios.put(
        `/therapists/karte/symptom_categories/${params.id}`,
        {
          symptom_category: {
            name: params.name,
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async deleteKarteSymptomCategory(id: number) {
    try {
      await $axios.delete(`/therapists/karte/symptom_categories/${id}`)
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
