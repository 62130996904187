export const prefectures = [
  {
    code: 1,
    name: '北海道',
    en_name: 'Hokkaidô',
  },
  {
    code: 2,
    name: '青森県',
    en_name: 'Aomori',
  },
  {
    code: 3,
    name: '岩手県',
    en_name: 'Iwate',
  },
  {
    code: 4,
    name: '宮城県',
    en_name: 'Miyagi',
  },
  {
    code: 5,
    name: '秋田県',
    en_name: 'Akita',
  },
  {
    code: 6,
    name: '山形県',
    en_name: 'Yamagata',
  },
  {
    code: 7,
    name: '福島県',
    en_name: 'Hukusima',
  },
  {
    code: 8,
    name: '茨城県',
    en_name: 'Ibaraki',
  },
  {
    code: 9,
    name: '栃木県',
    en_name: 'Totigi',
  },
  {
    code: 10,
    name: '群馬県',
    en_name: 'Gunma',
  },
  {
    code: 11,
    name: '埼玉県',
    en_name: 'Saitama',
  },
  {
    code: 12,
    name: '千葉県',
    en_name: 'Tiba',
  },
  {
    code: 13,
    name: '東京都',
    en_name: 'Tôkyô',
  },
  {
    code: 14,
    name: '神奈川県',
    en_name: 'Kanagawa',
  },
  {
    code: 15,
    name: '新潟県',
    en_name: 'Niigata',
  },
  {
    code: 16,
    name: '富山県',
    en_name: 'Toyama',
  },
  {
    code: 17,
    name: '石川県',
    en_name: 'Isikawa',
  },
  {
    code: 18,
    name: '福井県',
    en_name: 'Fukui',
  },
  {
    code: 19,
    name: '山梨県',
    en_name: 'Yamanasi',
  },
  {
    code: 20,
    name: '長野県',
    en_name: 'Nagano',
  },
  {
    code: 21,
    name: '岐阜県',
    en_name: 'Gihu',
  },
  {
    code: 22,
    name: '静岡県',
    en_name: 'Sizuoka',
  },
  {
    code: 23,
    name: '愛知県',
    en_name: 'Aiti',
  },
  {
    code: 24,
    name: '三重県',
    en_name: 'Mie',
  },
  {
    code: 25,
    name: '滋賀県',
    en_name: 'Siga',
  },
  {
    code: 26,
    name: '京都府',
    en_name: 'Kyôto',
  },
  {
    code: 27,
    name: '大阪府',
    en_name: 'Ôsaka',
  },
  {
    code: 28,
    name: '兵庫県',
    en_name: 'Hyôgo',
  },
  {
    code: 29,
    name: '奈良県',
    en_name: 'Nara',
  },
  {
    code: 30,
    name: '和歌山県',
    en_name: 'Wakayama',
  },
  {
    code: 31,
    name: '鳥取県',
    en_name: 'Tottori',
  },
  {
    code: 32,
    name: '島根県',
    en_name: 'Simane',
  },
  {
    code: 33,
    name: '岡山県',
    en_name: 'Okayama',
  },
  {
    code: 34,
    name: '広島県',
    en_name: 'Hirosima',
  },
  {
    code: 35,
    name: '山口県',
    en_name: 'Yamaguti',
  },
  {
    code: 36,
    name: '徳島県',
    en_name: 'Tokusima',
  },
  {
    code: 37,
    name: '香川県',
    en_name: 'Kagawa',
  },
  {
    code: 38,
    name: '愛媛県',
    en_name: 'Ehime',
  },
  {
    code: 39,
    name: '高知県',
    en_name: 'Kouchi',
  },
  {
    code: 40,
    name: '福岡県',
    en_name: 'Fukuoka',
  },
  {
    code: 41,
    name: '佐賀県',
    en_name: 'Saga',
  },
  {
    code: 42,
    name: '長崎県',
    en_name: 'Nagasaki',
  },
  {
    code: 43,
    name: '熊本県',
    en_name: 'Kumamoto',
  },
  {
    code: 44,
    name: '大分県',
    en_name: 'Oita',
  },
  {
    code: 45,
    name: '宮崎県',
    en_name: 'Miyazaki',
  },
  {
    code: 46,
    name: '鹿児島県',
    en_name: 'Kagosima',
  },
  {
    code: 47,
    name: '沖縄県',
    en_name: 'Okinawa',
  },
]
export function getPrefectures(checkPrefecture: string): string | undefined {
  const matchPrefecture = prefectures.find((prefecture) => {
    return (
      prefecture.en_name === checkPrefecture ||
      prefecture.name === checkPrefecture
    )
  })
  return matchPrefecture?.name
}
