import { MutationTree, ActionTree } from 'vuex'
import {
  Product,
  Repositories,
  Pagination,
  Branch,
  ProductBatch,
  Staff,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  products: [] as Product[],
  productStaffs: [] as Staff[],
  productPagination: {} as Pagination,
  remainingProducts: [] as Product[],
  remainingProductPagination: {} as Pagination,
  productBranches: [] as Branch[],
  branchProducts: [] as Product[],
})

export type ProductState = ReturnType<typeof state>

export const mutations: MutationTree<ProductState> = {
  setProducts(state, products: Product[]) {
    state.products = products
  },
  setProductPagination(state, pagination: Pagination) {
    state.productPagination = pagination
  },
  setProductStaffs(state, staffs: Staff[]) {
    state.productStaffs = staffs
  },
  addNewProduct(state, productData: Product) {
    state.products.unshift(productData)
  },
  updateProduct(state, productData: Product) {
    const index = state.products.findIndex((item) => item.id === productData.id)
    if (index !== -1) state.products.splice(index, 1, productData)
  },
  deleteProduct(state, id: number) {
    const index = state.products.findIndex((item) => item.id === id)
    if (index !== -1) state.products.splice(index, 1)
  },
  setRemainingProducts(state, remainingProducts: Product[]) {
    state.remainingProducts = remainingProducts
  },
  setRemainingProductPagination(state, pagination: Pagination) {
    state.remainingProductPagination = pagination
  },
  updatePagination(state) {
    state.productPagination.total_entries += 1
    state.productPagination.total_pages = Math.ceil(
      state.productPagination.total_entries / 10
    )
  },
  setProductBranches(state, branches: Branch[]) {
    state.productBranches = branches
  },
  setBranchProducts(state, branchProducts: Product[]) {
    state.branchProducts = branchProducts
  },
}

export const actions: ActionTree<ProductState, RootState> = {
  async fetchProducts(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
      product_types: string[]
      search?: string
      for_new_customers?: boolean
    }
  ) {
    const { products, pagination } = await params.api.productRepo.fetchProducts(
      {
        page: params.page,
        per: params.per,
        search: params.search,
        product_types: params.product_types,
        for_new_customers: params.for_new_customers,
      }
    )
    commit('setProducts', products)
    commit('setProductPagination', pagination)
  },

  async createProduct(
    { commit },
    params: { api: Repositories; productData: Product }
  ) {
    const product = await params.api.productRepo.createProduct(
      params.productData
    )
    commit('addNewProduct', product)
    commit('updatePagination')
  },

  async updateProduct(
    { commit },
    params: { api: Repositories; productData: Product }
  ) {
    const product = await params.api.productRepo.updateProduct(
      params.productData
    )
    commit('updateProduct', product)
  },
  async hideProduct(
    { commit },
    params: { api: Repositories; productData: Product }
  ) {
    const data = Object.assign({}, params.productData)
    data.hidden = true
    const product = await params.api.productRepo.updateProduct(data)
    commit('deleteProduct', product.id)
  },
  async deleteProduct(
    { commit },
    params: { api: Repositories; productId: number }
  ) {
    await params.api.productRepo.deleteProduct(params.productId)
    commit('deleteProduct', params.productId)
  },
  async batchProductUpdate(
    { commit },
    params: { api: Repositories; productId: number; batch: ProductBatch }
  ) {
    const { batch } = await params.api.productRepo.updateBatchProduct(
      params.productId,
      params.batch
    )

    commit('updateProduct', batch.product)
  },

  async batchProductHide(
    { commit },
    params: { api: Repositories; productIds: number[] }
  ) {
    const { products } = (await params.api.productRepo.batchProductHide(
      params.productIds,
      {
        hidden: true,
      }
    )) as { products: Product[] }
    products.forEach((product) => {
      commit('deleteProduct', product.id)
    })
  },

  async fetchRemainingProducts(
    { commit, rootState },
    params: { api: Repositories; page: number; per: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { products, pagination } =
      await params.api.productRepo.fetchBranchProducts(branchId, {
        active: false,
        page: params.page,
        per: params.per,
      })
    commit('setRemainingProducts', products)
    commit('setRemainingProductPagination', pagination)
  },

  async fetchProductBranches(
    { commit },
    params: { api: Repositories; productId: number; page: number; per: number }
  ) {
    const { branches } = await params.api.branchRepo.fetchProductBranches(
      params.productId,
      {
        page: params.page,
        per: params.per,
      }
    )
    commit('setProductBranches', branches)
  },
  async fetchProductStaffs(
    { commit },
    params: { api: Repositories; productId: number; page: number; per: number }
  ) {
    const { staff } = (await params.api.staffRepo.fetchProductStaff(
      params.productId,
      {
        page: params.page,
        per: params.per,
      }
    )) as { staff: Staff[] }

    commit('setProductStaffs', staff)
  },
  async fetchBranchProductIds(
    { commit },
    params: { api: Repositories; branchId: number; page: number; per: number }
  ) {
    const { products } = await params.api.productRepo.fetchBranchProducts(
      params.branchId,
      {
        active: true,
        page: params.page,
        per: params.per,
      }
    )
    commit('setBranchProducts', products)
  },
}
