import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import { Repositories } from '~/types'

export const state = () => ({
  slotTime: 20,
})

export type ReservationCalendarState = ReturnType<typeof state>

export const mutations: MutationTree<ReservationCalendarState> = {
  setSlotTime(state, slotTime: number) {
    state.slotTime = slotTime
  },
}

export const actions: ActionTree<ReservationCalendarState, RootState> = {
  async fetchTimeSlot(
    { commit, rootState },
    params: { api: Repositories; branchId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { time_slot_setting } =
      await params.api.reservationRepo.fetchReservationTimeSlot(
        params.branchId || branchId
      )
    commit('setSlotTime', time_slot_setting.duration)
  },
  async updateTimeSlot(
    { commit, rootState },
    params: { api: Repositories; duration: number; branchId: number }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { time_slot_setting } =
      await params.api.reservationRepo.updateReservationTimeSlot(
        params.branchId || branchId,
        {
          time_slot_setting: {
            duration: params.duration,
          },
        }
      )
    commit('setSlotTime', time_slot_setting.duration)
  },
}
