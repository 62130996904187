import logout from '~/helpers/logout'

export default function ({ $axios, store, redirect }) {
  $axios.onRequest((config) => {
    const user = store.state.auth.user
    if (JSON.stringify(user) !== '{}') {
      Object.assign(config.headers.common, user.generateAuthHeader())
    }
  })

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 401) {
      logout(store, redirect)
    }
  })
}
