import { MutationTree, ActionTree } from 'vuex'
import { Repositories, License } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  licences: [] as License[],
})

export type LicenceState = ReturnType<typeof state>

export const mutations: MutationTree<LicenceState> = {
  setLicences(state, licences: License[]) {
    state.licences = licences
  },
}

export const actions: ActionTree<LicenceState, RootState> = {
  async fetchLicences(
    { commit },
    params: { api: Repositories; page: number; per: number }
  ) {
    const { licences } = await params.api.licenceRepo.fetchLicences()
    commit('setLicences', licences)
  },
}
