import { Plugin } from '@nuxt/types'
declare module 'vue/types/vue' {
  interface Vue {
    $notifier: { showMessage: any }
  }
}

const notifyPlugin: Plugin = (ctx, inject) => {
  inject('notifier', {
    showMessage(payload: any) {
      ctx.store.commit('snackbar/showMessage', payload)
    },
  })
}

export default notifyPlugin
