export const VALIDATIONS = {
  ja: {
    required: '必須入力です',
    email: '適切なメールアドレスを入力してください',
    min: 'ドは6桁以上で設定してください',
    min_value: (value: number) => `${value}より大きくする必要があります`,
    min_coupon: (value: number) =>
      `お支払い金額はクーポンの金額よりも高く設定してください。(${value}円)`,
    max: '入力長を超えました',
    max_value: (value: number) => `${value}未満である必要があります`,
    integer: '整数を入力してください',
    password: 'パスワードの確認が一致しません',
    lesserThanDate: 'は終了日より前にする必要があります',
    greaterThanDate: 'は開始日より後にする必要があります',
    lesserThanTime: 'は終了時間より前にする必要があります',
    lesserThanEqualTime: 'は終了時間より前にする必要があります',
    greaterThanTime: 'は開始時より後にする必要があります',
    greaterThanEqualTime: 'は開始時より後にする必要があります',
    isPastDate: '日付は過去でなければなりません',
    allowedGenderValues: '1(男性)または2(女性)を入力してください',
    allowedSendDmValues: '1(可)または2(不可)を選択してください',
    zipcodeFormat: 'xxx-yyyy形式で入力してください',
    dateFormat: '正しい形式で入力してください',
    dateFormatYYYYMMDD: 'YYYYMMDDで入力ください',
    'dateFormatYYYY-MM-DD': '無効な日付の形式です。',
    validTel: '無効な電話番号',
    validBirthdayVal: '生年月日を正しく入力してください',
    validEmail: '正しい形式で入力してください',
    validAlphaNumerical: 'コードは「英数字」のみ対応しております。',
    validCustomBusinessStartEndDate: '日付を選択してください。',
    preset_required: '必須入力です',
    startTime: '開始時間を入力してください。',
    endTime: '終了時間を入力してください。',
    correctTime: '正しい時間を入力してください。',
    startBeforeEnd: '開始時間を終了時間より前に、設定してください。',
  },
  en: {
    required: 'This is required',
    email: 'Please enter a valid email address',
    min: 'Please set the code with 6 digits or more',
    min_value: (value: number) => `Must be greater than ${value}`,
    min_coupon: (value: number) =>
      `Set the payment amount higher than the coupon amount. (${value} yen)`,
    max: 'Input length exceeded',
    max_value: (value: number) => `Must be less than ${value}`,
    integer: 'Please enter an integer',
    password: 'Password confirmation does not match',
    lesserThanDate: 'Must be before the end date',
    greaterThanDate: 'Must be after the start date',
    lesserThanTime: 'Must be before the end time',
    lesserThanEqualTime: 'Must be before the end time',
    greaterThanTime: 'Must be after start time',
    greaterThanEqualTime: 'Must be after start time',
    isPastDate: 'Date must be in the past',
    allowedGenderValues: 'Enter 1 (male) or 2 (female)',
    allowedSendDmValues: 'Please select 1 (Yes) or 2 (No)',
    zipcodeFormat: 'Please enter in xxx-yyyy format',
    dateFormat: 'Please enter in the correct format',
    dateFormatYYYYMMDD: 'Please enter in YYYYMMDD',
    'dateFormatYYYY-MM-DD': 'Invalid date format',
    validTel: 'Invalid phone number',
    validBirthdayVal: 'Please enter your date of birth correctly',
    validEmail: 'Please enter in the correct format',
    validAlphaNumerical:
      'Only alphanumeric characters are supported for the code.',
    validCustomBusinessStartEndDate: 'Please choose a date.',
    preset_required: 'This is required',
    startTime: 'Please enter a start time.',
    endTime: 'Please enter an end time.',
    correctTime: 'Please enter the correct time.',
    startBeforeEnd: 'Set the start time before the end time.',
  },
}
