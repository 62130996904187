import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'

// import processError from '~/helpers/processError'
import { ReservationSlotsRepo } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): ReservationSlotsRepo => ({
  async fetchReservationSlots(
    branchId: number,
    params?: {
      start_date: string
      end_date: string
      staff_ids: number[]
    }
  ) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/reservation_slots`,
        {
          params,
        }
      )

      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
