






























import Vue, { PropOptions } from 'vue'
import { Branch } from '~/types'

export default Vue.extend({
  props: {
    branches: {
      type: Array,
      required: true,
    } as PropOptions<Branch[]>,
    selectedBranchIds: {
      type: Array,
      required: true,
    } as PropOptions<number[]>,
    prefecture: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: true,
    }
  },
})
