import { MutationTree, ActionTree } from 'vuex'
import { CMS, Repositories, Pagination } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  authors: [] as CMS.Author[],
  authorPagination: {} as Pagination,
  loadingList: {
    isAuthorLoading: false,
  },
})

export type CMSAuthorState = ReturnType<typeof state>

export const mutations: MutationTree<CMSAuthorState> = {
  setAuthors(state, authors: CMS.Author[]) {
    state.authors = authors
  },
  setAuthorPagination(state, pagination: Pagination) {
    state.authorPagination = pagination
  },
  addAuthor(state, authorData: CMS.Author) {
    state.authors.unshift(authorData)
  },
  updateAuthor(state, authorData: CMS.Author) {
    const index = state.authors.findIndex((item) => item.id === authorData.id)
    if (index !== -1) state.authors.splice(index, 1, authorData)
  },
  deleteAuthor(state, id: number) {
    const index = state.authors.findIndex((item) => item.id === id)
    if (index !== -1) state.authors.splice(index, 1)
  },
  updateAuthorPagination(state) {
    state.authorPagination.total_entries += 1
    state.authorPagination.total_pages = Math.ceil(
      state.authorPagination.total_entries / 10
    )
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<CMSAuthorState, RootState> = {
  async fetchAuthors(
    { commit, rootState },
    params: {
      api: Repositories
      page: number
      per: number
    }
  ) {
    commit('setLoading', { key: 'isAuthorLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const { authors, pagination } =
      (await params.api.cmsAuthorRepo.fetchAuthors(branchId, {
        page: params.page,
        per: params.per,
      })) as { authors: CMS.Author[]; pagination: Pagination }
    commit('setAuthors', authors)
    commit('setAuthorPagination', pagination)
    commit('setLoading', { key: 'isAuthorLoading', loading: false })
  },
  async createAuthor(
    { commit, rootState },
    params: { api: Repositories; authorData: CMS.Author }
  ) {
    commit('setLoading', { key: 'isAuthorLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const authorData = await params.api.cmsAuthorRepo.createAuthor(
      branchId,
      params.authorData
    )
    commit('addAuthor', authorData)
    commit('updateAuthorPagination')
    commit('setLoading', { key: 'isAuthorLoading', loading: false })
  },
  async getAuthorWithId(
    { commit, rootState, state },
    params: { api: Repositories; authorId: number }
  ) {
    commit('setLoading', { key: 'isAuthorLoading', loading: true })
    const authorIndex = state.authors.findIndex(
      (author: CMS.Author) => author.id === params.authorId
    )
    if (authorIndex === -1) {
      const branchId = (rootState as any).auth.user.activeBranch
      const author = await params.api.cmsAuthorRepo.fetchAuthorWithId(
        branchId,
        params.authorId
      )
      commit('addAuthor', author)
      commit('updateAuthorPagination')
    }
    commit('setLoading', { key: 'isAuthorLoading', loading: false })
  },
  async updateAuthor(
    { commit, rootState },
    params: { api: Repositories; authorData: CMS.Author }
  ) {
    commit('setLoading', { key: 'isAuthorLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    const author = await params.api.cmsAuthorRepo.updateAuthor(
      branchId,
      params.authorData
    )
    commit('updateAuthor', author)
    commit('setLoading', { key: 'isAuthorLoading', loading: false })
  },
  async deleteAuthor(
    { commit, rootState },
    params: { api: Repositories; authorId: number }
  ) {
    commit('setLoading', { key: 'isAuthorLoading', loading: true })
    const branchId = (rootState as any).auth.user.activeBranch
    await params.api.cmsAuthorRepo.deleteAuthor(branchId, params.authorId)
    commit('deleteAuthor', params.authorId)
    commit('setLoading', { key: 'isAuthorLoading', loading: false })
  },
}
