import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import { Reservation, Repositories, Pagination } from '~/types'
export const state = () => ({
  karteReservationData: { customerId: undefined, openDialogType: null } as {
    customerId: number | undefined
    openDialogType: null | 'karte' | 'report_form'
  },
  karteReservations: [] as Reservation[],
})

export type KarteReservationState = ReturnType<typeof state>

export const mutations: MutationTree<KarteReservationState> = {
  setKarteReservationData(
    state,
    reservationData: {
      customerId: number | undefined
      openDialogType: null | 'karte' | 'report_form'
    }
  ) {
    state.karteReservationData = reservationData
  },
  setKarteReservations(state, reservations: Reservation[]) {
    state.karteReservations = reservations
  },
}

export const actions: ActionTree<KarteReservationState, RootState> = {
  async fetchKarteReservations(
    { commit, rootState },
    params: {
      api: Repositories
      start_date: string
      end_date: string
      page: number
      per: number
      customer_ids?: number[]
      session?: string
      sort?: string[]
    }
  ) {
    const branchId = (rootState as any).auth.user.activeBranch
    const { pagination } = (await params.api.reservationRepo.fetchReservations(
      branchId,
      {
        start_date: params.start_date,
        end_date: params.end_date,
        page: 1,
        per: 1,
        customer_ids: params.customer_ids,
        session: params.session ?? 'all',
        sort: params.sort,
      }
    )) as { reservations: Reservation[]; pagination: Pagination }
    const res = (await params.api.reservationRepo.fetchReservations(branchId, {
      start_date: params.start_date,
      end_date: params.end_date,
      page: 1,
      per: pagination.total_entries || 1,
      customer_ids: params.customer_ids,
      sort: params.sort,
      session: params.session ?? 'all',
    })) as { reservations: Reservation[]; pagination: Pagination }
    commit(
      'setKarteReservations',
      res.reservations.filter(
        (r) => r.reservation_type === 'blocking' || r.status !== 'cancelled'
      )
    )
  },
}
