import { MutationTree, ActionTree } from 'vuex'
import { Square, Repositories, SquareLocations, SquareDevice } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  square: {} as Square,
  locations: [] as SquareLocations[],
  devices: [] as SquareDevice[],
  locationId: null as string | null,
  pairingStatus: '' as '' | 'success' | 'error',
})

export type SquareState = ReturnType<typeof state>

export const mutations: MutationTree<SquareState> = {
  setSquare(state, square: Square) {
    state.square = square
  },

  setLocations(state, locations: SquareLocations[]) {
    state.locations = locations
  },
  setDevices(state, devices: SquareDevice[]) {
    state.devices = devices
  },
  setLocationId(state, locationId: string) {
    state.locationId = locationId
  },
  setPairingStatus(state, status: '' | 'success' | 'error') {
    state.pairingStatus = status
  },
  updateDeviceContent(state, { value, index, key }) {
    state.devices[index][key] = value
  },
  updateDevice(state, { value, index }) {
    state.devices.splice(index, 1, value)
  },
  pushDevices(state, device: SquareDevice) {
    state.devices.push(device)
  },
  deleteDevice(state, index) {
    state.devices.splice(index, 1)
  },
}

export const actions: ActionTree<SquareState, RootState> = {
  async fetchSquare({ commit }, params: { api: Repositories }) {
    const { square_account } = await params.api.squareRepo.fetchSquare()
    commit('setSquare', square_account)
  },
  async fetchSquareLocations({ commit }, params: { api: Repositories }) {
    const { locations } = await params.api.squareRepo.fetchSquareLocations()
    commit('setLocations', locations)
  },
  async fetchSquareDetails(
    { commit, rootState },
    params: { api: Repositories; branchId?: number }
  ) {
    const branchId = params.branchId
      ? params.branchId
      : (rootState as any).auth.user.activeBranch
    const { payment_details } = await params.api.squareRepo.fetchSquareDetails(
      branchId
    )
    commit('setLocationId', payment_details.location_id)
  },
  async fetchSquareDevices(
    { commit, rootState },
    params: { api: Repositories; branchId?: number }
  ) {
    const branchId = params.branchId
      ? params.branchId
      : (rootState as any).auth.user.activeBranch
    try {
      const { devices } = await params.api.squareRepo.fetchSquareDevices(
        branchId
      )
      commit('setDevices', devices || [])
    } catch (error) {
      commit('setDevices', [])
    }
  },
  async createSquareDevice(
    { commit, rootState },
    params: {
      api: Repositories
      deviceName: string
      index: number
      branchId?: number
    }
  ) {
    const branchId = params.branchId
      ? params.branchId
      : (rootState as any).auth.user.activeBranch
    const { device } = await params.api.squareRepo.createdSquareDevice(
      branchId,
      {
        name: params.deviceName,
      },
      (rootState as any).auth.user.activeBranch
    )
    commit('updateDevice', { value: device, index: params.index })
  },
  async updateSquareDetails(
    { commit, rootState },
    params: { api: Repositories; locationId: string; branchId?: number }
  ) {
    const branchId = params.branchId
      ? params.branchId
      : (rootState as any).auth.user.activeBranch
    const { payment_details } = await params.api.squareRepo.updateSquareDetails(
      branchId,
      {
        location_id: params.locationId ? params.locationId : null,
      }
    )
    commit('setLocationId', payment_details.location_id)
  },
}
