import { MutationTree } from 'vuex'
import { User } from '~/models/User'
import { UserPermission } from '~/models/UserPermission'
import { UserPlugin } from '~/models/UserPlugin'
import { Branch, Pagination } from '~/types'

export const state = () => ({
  user: {} as User,
  userPermission: {} as UserPermission,
  userPlugin: {} as UserPlugin,
  userBranches: [] as Branch[],
  userBranchPagination: {} as Pagination,
})

export type AuthState = ReturnType<typeof state>

export const mutations: MutationTree<AuthState> = {
  setUser(state, user: User) {
    state.user = user
  },

  resetUser(state) {
    state.user = {} as User
  },
  setActiveBranch(state, branchId) {
    state.user.activeBranch = branchId
    localStorage.setItem('user', JSON.stringify(state.user.toJson()))
  },
  setUserPermission(state, userPermission: UserPermission) {
    state.userPermission = userPermission
  },
  setUserPlugin(state, userPlugin: UserPlugin) {
    state.userPlugin = userPlugin
  },
  setUserBranches(state, userBranches: Branch[]) {
    state.userBranches = userBranches
  },
  setUserBranchPagination(state, userBranchPagination: Pagination) {
    state.userBranchPagination = userBranchPagination
  },
}
