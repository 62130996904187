














































import Vue from 'vue'
import { UserPermission } from '~/models/UserPermission'
export default Vue.extend({
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedItem: 0 as number,
    }
  },
  computed: {
    userPermission(): UserPermission {
      return this.$store.state.auth.userPermission
    },
    isAllowedPermission(): Boolean {
      return this.userPermission.isAllowed('hq_product_edit')
    },
    sideBarItems(): {
      text: any
      link: string
      id?: string
      isShow: boolean
    }[] {
      return [
        {
          text: 'CRM',
          link: '/hq/crm/hq_crm',
          id: 'hq_crm',
          isShow: true,
        },
        {
          text: this.$t('staffs.ranking'),
          link: '/hq/crm/crm_staff',
          id: 'staff-list',
          isShow: this.userPermission.isAllowed('hq_crm'),
        },
        {
          text: this.$t('hq.annualRanking'),
          link: '/hq/crm/annual_trends',
          id: 'product-ranking',
          isShow: this.userPermission.isAllowed('hq_crm'),
        },
      ]
    },
  },
  created() {
    const sidebarItem = this.sideBarItems.find((item) => {
      return item.link === this.$route.path
    })
    if (!sidebarItem) return
    this.$store.commit('hqCrm/setPageTitle', sidebarItem.text)
  },
  methods: {
    changePage(val: string) {
      this.$store.commit('hqCrm/setPageTitle', val)
      this.$store.commit('hqProductHistory/setSalesToggleType', 'products')
    },
  },
})
