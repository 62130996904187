import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { DemoRepo } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): DemoRepo => ({
  async fetchDemoTimeSpent() {
    try {
      const res = await $axios.get('therapists/demo/time_spent')
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createDemoTimeSpent() {
    try {
      const res = await $axios.post('therapists/demo/time_spent', {
        time_spent_in_seconds: 60,
      })
      if (res.status === 201) {
        return res.data.time_spent_in_seconds
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
})
