import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { CustomerTagRepo, CustomerTag } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): CustomerTagRepo => ({
  async fetchCustomerTags(branchId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customer_tags`,
        { params: { page: 1, per: 80 } }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCrmCustomerTags(params: { branch_ids: number[] }) {
    try {
      const res = await $axios.get(`crm/customer_tags`, {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerTagWithId(branchId: number, customerTagId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/customer_tags/${customerTagId}`
      )
      if (res.status === 200) {
        return res.data.customer_tag
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createCustomerTag(branchId: number, customerTagData: CustomerTag) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/customer_tags`,
        {
          customer_tag: customerTagData,
        }
      )
      if (res.status === 201) {
        return res.data.customer_tag
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateCustomerTag(branchId: number, customerTag: CustomerTag) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/customer_tags/${customerTag.tag_id}`,
        {
          customer_tag: customerTag,
        }
      )
      if (res.status === 200) {
        return res.data.customer_tag
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteCustomerTag(branchId: number, customerTagId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/customer_tags/${customerTagId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
