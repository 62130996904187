




































import Vue from 'vue'
import { getRouteName } from '~/helpers/urlUtils'

export default Vue.extend({
  computed: {
    currentPageName() {
      return getRouteName(this.$route.name)
    },
  },
  methods: {
    handelRedirectPage() {
      this.$router.push(this.localePath('/dashboard'))
    },
    async logout() {
      localStorage.removeItem('lastVisitedPage')
      try {
        await this.$api.authRepo.logout()
        const amplitude = (this as any).$amplitude
        amplitude.setUserId('')
      } catch (error) {
        this.$notifier.showMessage({
          content: (error as Error).message,
          color: 'error',
        })
      }
    },
  },
})
