import { NuxtAxiosInstance } from '@nuxtjs/axios'
import processError from '~/helpers/processError'
import { ReservationTagRepo, ReservationTag } from '~/types'

export default (
  $axios: NuxtAxiosInstance,
  $sentry: any
): ReservationTagRepo => ({
  async fetchReservationTags(branchId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/reservation_tags`,
        { params: { page: 1, per: 80 } }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchReservationTagWithId(branchId: number, reservationTagId: number) {
    try {
      const res = await $axios.get(
        `therapists/branches/${branchId}/reservation_tags/${reservationTagId}`
      )
      if (res.status === 200) {
        return res.data.reservation_tag
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async createReservationTag(
    branchId: number,
    reservationTagData: ReservationTag
  ) {
    try {
      const res = await $axios.post(
        `therapists/branches/${branchId}/reservation_tags`,
        {
          reservation_tag: reservationTagData,
        }
      )
      if (res.status === 201) {
        return res.data.reservation_tag
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async updateReservationTag(branchId: number, reservationTag: ReservationTag) {
    try {
      const res = await $axios.put(
        `therapists/branches/${branchId}/reservation_tags/${reservationTag.tag_id}`,
        {
          reservation_tag: reservationTag,
        }
      )
      if (res.status === 200) {
        return res.data.reservation_tag
      }
    } catch (err) {
      return processError(err, $sentry)
    }
  },
  async deleteReservationTag(branchId: number, reservationTagId: number) {
    try {
      await $axios.delete(
        `therapists/branches/${branchId}/reservation_tags/${reservationTagId}`
      )
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
