import { MutationTree, ActionTree } from 'vuex'
import { Pagination, Repositories, HQBranches, Branch } from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  analyticsBranches: [] as HQBranches[],
  branchesPagination: {} as Pagination,
  userBranches: [] as Branch[],
  loadingList: {
    isAnalyticsBranchesLoading: false,
    isUserBranchesLoading: false,
  },
})

export type HQHomeState = ReturnType<typeof state>

export const mutations: MutationTree<HQHomeState> = {
  setBranches(state, branches: HQBranches[]) {
    state.analyticsBranches = branches
  },
  setBranchesPagination(state, pagination: Pagination) {
    state.branchesPagination = pagination
  },
  setUserBranches(state, branches: Branch[]) {
    state.userBranches = branches
  },
  setLoading(state, value: { key: string; loading: boolean }) {
    state.loadingList[value.key] = value.loading
  },
}

export const actions: ActionTree<HQHomeState, RootState> = {
  async fetchHQBranches(
    { commit },
    params: {
      api: Repositories
      page: number
      per: number
      branch_name?: string
      branchIds?: number[]
      startDate?: string
      endDate?: string
      sort?: string[]
    }
  ) {
    commit('setLoading', { key: 'isAnalyticsBranchesLoading', loading: true })
    const { branches, pagination } = await params.api.hqRepo.fetchHQBranches({
      branch_ids: params.branchIds,
      branch_name: params.branch_name,
      start_date: params.startDate,
      end_date: params.endDate,
      sort: params.sort,
      page: params.page,
      per: params.per,
    })
    commit('setBranches', branches)
    commit('setBranchesPagination', pagination)
    commit('setLoading', { key: 'isAnalyticsBranchesLoading', loading: false })
  },
  async getBranches(
    { commit },
    params: {
      api: Repositories
      staffId: number
      page: number
      per: number
    }
  ) {
    commit('setLoading', { key: 'isUserBranchesLoading', loading: true })
    try {
      const { branches } = await params.api.branchRepo.fetchStaffBranches(
        params.staffId,
        {
          page: params.page,
          per: params.per,
        }
      )
      commit('setUserBranches', branches)
    } catch (error) { }
    commit('setLoading', { key: 'isUserBranchesLoading', loading: false })
  },
}
