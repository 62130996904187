import { MutationTree, ActionTree } from 'vuex'
import {
  Repositories,
  Pagination,
  KarteSymptomsParams,
  KarteSymptomCategoriesParams,
} from '~/types'
import { RootState } from '~/store'

export const state = () => ({
  karteSymptoms: [] as KarteSymptomsParams[],
  karteSymptomCategories: [] as KarteSymptomCategoriesParams[],
  karteSymptomPagination: {
    page: 1,
    per: 1,
    total_pages: 1,
    total_entries: 1,
  } as Pagination,
  karteSymptomCategoryPagination: {
    page: 1,
    per: 1,
    total_pages: 1,
    total_entries: 1,
  } as Pagination,
})

export type KarteSymptomsState = ReturnType<typeof state>

export const mutations: MutationTree<KarteSymptomsState> = {
  setKarteSymptoms(state, value: KarteSymptomsParams[]) {
    state.karteSymptoms = value
  },
  setKarteSymptomCategories(state, value: KarteSymptomCategoriesParams[]) {
    state.karteSymptomCategories = value
  },
  setKarteSymptomPagination(state, value) {
    state.karteSymptomPagination = value
  },
  setKarteSymptomCategoryPagination(state, value) {
    state.karteSymptomCategoryPagination = value
  },
  addKarteSymptom(state, value: KarteSymptomsParams) {
    state.karteSymptoms.push(value)
  },
  addKarteSymptomCategory(state, value: KarteSymptomCategoriesParams) {
    state.karteSymptomCategories.push(value)
  },
  updateKarteSymptom(state, value: KarteSymptomsParams) {
    state.karteSymptoms = state.karteSymptoms.map((item) =>
      item.id === value.id ? value : item
    )
  },
  updateKarteSymptomCategory(state, value: KarteSymptomCategoriesParams) {
    state.karteSymptomCategories = state.karteSymptomCategories.map((item) =>
      item.id === value.id ? value : item
    )
  },
  removeKarteSymptom(state, id: number) {
    state.karteSymptoms = state.karteSymptoms.filter((item) => item.id !== id)
  },
  removeKarteSymptomCategory(state, value: number) {
    state.karteSymptomCategories = state.karteSymptomCategories.filter(
      (item) => item.id !== value
    )
  },
}

export const actions: ActionTree<KarteSymptomsState, RootState> = {
  async fetchKarteSymptoms(
    { commit },
    params: {
      api: Repositories
      search?: string
      category_ids?: number[]
      branch_ids?: number[]
      page: number
      per: number
    }
  ) {
    const { symptoms, pagination } =
      await params.api.karteSymptomRegistrationRepo.fetchKarteSymptoms({
        search: params?.search,
        category_ids: params?.category_ids,
        branch_ids: params?.branch_ids,
        page: params?.page,
        per: params?.per,
      })
    commit('setKarteSymptoms', symptoms)
    commit('setKarteSymptomPagination', pagination)
  },
  async postKarteSymptom(
    { commit },
    params: {
      api: Repositories
    } & KarteSymptomsParams
  ) {
    const { symptom } =
      await params.api.karteSymptomRegistrationRepo.postKarteSymptom({
        category_id: params.category_id,
        branch_ids: params.branch_ids,
        title: params.title,
        description: params.description,
      })
    commit('addKarteSymptom', symptom)
  },
  async putKarteSymptom(
    { commit },
    params: {
      api: Repositories
    } & KarteSymptomsParams
  ) {
    const { symptom } =
      await params.api.karteSymptomRegistrationRepo.putKarteSymptom({
        id: params.id,
        category_id: params.category_id,
        branch_ids: params.branch_ids,
        title: params.title,
        description: params.description,
      })
    commit('updateKarteSymptom', symptom)
  },
  async deleteKarteSymptom(
    { commit },
    params: {
      api: Repositories
      id: number
    }
  ) {
    await params.api.karteSymptomRegistrationRepo.deleteKarteSymptom(params.id)
    commit('removeKarteSymptom', params.id)
  },

  async fetchKarteSymptomCategories(
    { commit },
    params: {
      api: Repositories
    } & KarteSymptomCategoriesParams
  ) {
    const { symptom_categories, pagination } =
      await params.api.KarteSymptomCategoriesRepo.fetchKarteSymptomCategories({
        search: params.search,
        branch_ids: params.branch_ids,
        id: params.id,
        page: params.page,
        per: params.per,
      })
    commit('setKarteSymptomCategories', symptom_categories)
    commit('setKarteSymptomCategoryPagination', pagination)
  },
  async postKarteSymptomCategory(
    { commit },
    params: {
      api: Repositories
    } & KarteSymptomCategoriesParams
  ) {
    const { symptom_category } =
      await params.api.KarteSymptomCategoriesRepo.postKarteSymptomCategory({
        name: params.name,
      })
    commit('addKarteSymptomCategory', symptom_category)
  },
  async putKarteSymptomCategory(
    { commit },
    params: {
      api: Repositories
    } & KarteSymptomCategoriesParams
  ) {
    const { symptom_category } =
      await params.api.KarteSymptomCategoriesRepo.putKarteSymptomCategory({
        id: params.id,
        name: params.name,
      })
    commit('updateKarteSymptomCategory', symptom_category)
  },
  async deleteKarteSymptomCategory(
    { commit },
    params: {
      api: Repositories
      id: number
    }
  ) {
    await params.api.KarteSymptomCategoriesRepo.deleteKarteSymptomCategory(
      params.id
    )
    commit('removeKarteSymptomCategory', params.id)
  },
}
