import { MutationTree, ActionTree } from 'vuex'
import dayjs from 'dayjs'
import { RootState } from '~/store'
import { Reservation } from '~/types'

export const state = () => ({
  isLoading: false,
  viewType: 'day',
  calendarStartDate: new Date() as Date,
  startEndDate: {
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  },
  searchReservationData: null as { reservation: Reservation } | null,
  hoverCustomerReservationIds: [] as number[],
})
export type ReservationCalendarState = ReturnType<typeof state>

export const mutations: MutationTree<ReservationCalendarState> = {
  toggleLoading(state) {
    state.isLoading = !state.isLoading
  },
  setViewType(state, viewType: string) {
    state.viewType = viewType

    // Setting Reservation Calendar Start date and end date
    if (state.viewType === 'day') {
      state.startEndDate.startDate = dayjs(state.calendarStartDate).format(
        'YYYY-MM-DD'
      )
      state.startEndDate.endDate = dayjs(state.calendarStartDate).format(
        'YYYY-MM-DD'
      )
    } else if (state.viewType === 'week') {
      state.startEndDate.startDate = dayjs(state.calendarStartDate)
        .startOf('week')
        .format('YYYY-MM-DD')
      state.startEndDate.endDate = dayjs(state.calendarStartDate)
        .endOf('week')
        .format('YYYY-MM-DD')
    }
  },
  setCalendarStartDate(state, date: Date | string) {
    state.calendarStartDate = dayjs(date).toDate()

    // Setting Reservation Calendar Start date and end date
    if (state.viewType === 'day') {
      state.startEndDate.startDate = dayjs(date).format('YYYY-MM-DD')
      state.startEndDate.endDate = dayjs(date).format('YYYY-MM-DD')
    } else if (state.viewType === 'week') {
      state.startEndDate.startDate = dayjs(date)
        .startOf('week')
        .format('YYYY-MM-DD')
      state.startEndDate.endDate = dayjs(date)
        .endOf('week')
        .format('YYYY-MM-DD')
    }
  },
  setSearchReservationData(state, args: { reservation: Reservation } | null) {
    state.searchReservationData = args
  },
  setHoverCustomerReservationIds(state, ids: number[]) {
    state.hoverCustomerReservationIds = ids
  },
}

export const actions: ActionTree<ReservationCalendarState, RootState> = {}
